import { FieldInterval } from "@crispico/foundation-react/entity_crud/CrudSettings";
import { EntityDescriptor, FieldDescriptor, FIELDS_LAST_UPDATE, getFieldLastUpdateDate } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import _ from "lodash";
import moment from "moment";
import React, { ReactElement } from "react";
import { Icon, StrictTableProps, Table } from "semantic-ui-react";
import { AppMetaTempGlobals } from "../AppMetaTempGlobals";
import { Optional } from "../CompMeta";

export interface CrudViewerProps {
    entity: any,
    entityDescriptor: EntityDescriptor,
    fields: string[],
    onRowClick?: (field: string) => void;
    renderFunctionsForAdditionalColumns?: ((fieldDescriptor: FieldDescriptor) => ReactElement | null)[];
    maxFields?: number,
    showEmpty?: boolean,
    hideFieldsWithLastUpdateDateEmpty?: boolean,
    tableProps?: StrictTableProps
    showOnlyLabelColumn?: boolean
}

export class CrudViewer<P extends CrudViewerProps = CrudViewerProps> extends React.Component<P> {

    constructor(props: P) {
        super(props);
    }

    renderRow(props: CrudViewerProps, field: string, numberOfFields: number) {
        const hasFieldsLastUpdate = props.entity && props.entity[FIELDS_LAST_UPDATE];
        if (props.maxFields && numberOfFields > props.maxFields) {
            return null;
        }
        const fdc = props.entityDescriptor.getFieldDescriptorChain(field);
        if (!fdc[0]) {
            return null;
        }

        const fd = fdc[fdc.length - 1];
        let object, value, lastUpdateDate;
        object = value = lastUpdateDate = undefined;
        if (!props.showOnlyLabelColumn) {
            object = _.clone(props.entity);
            for (let index = 0; index < fdc.length - 1; index++) {
                object = object[fdc[index].getFieldName()];
                if (!object) {
                    break;
                }
            }
            value = fd.getFieldValue(object);
            if (!props.showEmpty && Utils.isNullOrEmpty(value)) {
                // ignore empty fields
                return null;
            }
            lastUpdateDate = getFieldLastUpdateDate(props.entity, field);
            if (hasFieldsLastUpdate) {
                if (props.hideFieldsWithLastUpdateDateEmpty && !lastUpdateDate) {
                    return null;
                }
            }
        }
        
        return <Table.Row key={numberOfFields}  onClick={() => this.props.onRowClick && this.props.onRowClick(field)}>
            <Table.Cell key={"name"} ><div className="flex-container-row flex-wrap">{fd.getIcon()}{props.entityDescriptor.getComposedFieldLabel(fdc)}</div></Table.Cell>
            {props.showOnlyLabelColumn ? null
                : <><Table.Cell style={fd.getFieldColors(value)} className="flex-justify-content-center" key={"value"}>{fd.renderField(object)}</Table.Cell>
                    {hasFieldsLastUpdate ? <Table.Cell textAlign='center' key={"lastUpdate"}>{lastUpdateDate ? moment(lastUpdateDate).format(Utils.dateTimeWithSecFormat) : null}</Table.Cell> : null}
                    {props.renderFunctionsForAdditionalColumns?.map(render => <Table.Cell textAlign='center' key={"additional_" + fd.name}>{render(fd)}</Table.Cell>)}</>
            }
        </Table.Row>
    }

    render() {
        const props = this.props;
        let numberOfFields = 0;
        return props.entity && <div><Table celled padded selectable compact striped {...props.tableProps}>
            <Table.Body>
                {props.fields.map((field) => {
                    return this.renderRow(props, field, numberOfFields++);
                })}
            </Table.Body>
        </Table>
            {props.maxFields && numberOfFields > props.maxFields ? <p>{_msg("general.displaying", props.maxFields + " " + _msg({pluralized: true}, "general.field").toLocaleLowerCase(), numberOfFields)}</p> : null}
        </div>;
    }
}
