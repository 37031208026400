import { FilterOperators } from "@crispico/foundation-gwt-js";
import { addEntityDescriptor, apolloClient, createSliceFoundation, DispatchersFrom, EntityDescriptor, EntityTablePage, EntityTablePageProps, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom, SliceEntityTablePage, sliceEntityTablePageOnlyForExtension, Utils } from "@crispico/foundation-react";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { addAfterStartupRunnable } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { Modal, Form, Segment, Button, CheckboxProps, Checkbox, Icon } from "semantic-ui-react";
import React, { ReactNode } from "react";
import { OverrideableElement } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import gql from "graphql-tag";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";

export let positionEntityDescriptor: EntityDescriptor;
export let gpsLocationEntityDescriptor: EntityDescriptor;

export const sliceEntityTablePagePosition = createSliceFoundation(class Ext extends SliceEntityTablePage {
    nestedSlices = {
        ...sliceEntityTablePageOnlyForExtension.nestedSlices,
    }

    initialState = {
        ...sliceEntityTablePageOnlyForExtension.initialState,
        telemetryEventMapping: [] as string[],
        markUnmappedFields: false
    }

    reducers = {
        ...sliceEntityTablePageOnlyForExtension.reducers, ...getBaseReducers<Ext>(this)
    }

    impures = {
        ...sliceEntityTablePageOnlyForExtension.impures, ...getBaseImpures<Ext>(this),
        refreshSuper: sliceEntityTablePageOnlyForExtension.impures.refresh,
        async refresh() {
            if (!(this.getState().telemetryEventMapping && this.getState().telemetryEventMapping.length > 0)) {
                this.getTelemetryEventMapping();
            }
            await this.refreshSuper();
        },
        async getTelemetryEventMapping() {
            const loadOperationName = "telemetryEventMappingService_findByFilter";
            const customFields_findByFilter = gql(`query q($params: FindByFilterParamsInput) { 
                ${loadOperationName}(params: $params) { results { mappingFrom } } }`);
            const result = (await apolloClient.query({ query: customFields_findByFilter, variables: FindByFilterParams.create() })).data[loadOperationName];
            if (result.results.length > 0) {
                this.getDispatchers().setInReduxState({ telemetryEventMapping: result.results.map((x: { mappingFrom: string }) => x.mappingFrom.toUpperCase()) })
            }
        }
    }
});

type TelemetryFieldType = FieldRendererProps & { telemetryEventMapping: string[], markUnmappedFields: boolean }
class TelemetryFieldRenderer extends React.Component<TelemetryFieldType, { modalOpen: boolean }> {

    constructor(props: TelemetryFieldType)  {
        super(props);
        this.state = { modalOpen: false };
    }

    render() {
        const text = this.props.value ? String(this.props.value) : "";
        let field = <>{text}</>;

        if (this.props.markUnmappedFields) {
            field = <>{text.split(",").map(x => {
                if (x === "") return null;
                const fieldValue = x.split(":");
                if (this.props.telemetryEventMapping.includes(fieldValue[0].toUpperCase())) {
                    return <>{x},</>;
                } else {
                    return <><span style={{color: "red"}}>{fieldValue[0]}</span>:{fieldValue[1]},</>
                }
            })}</>
        }

        return <>
            <span onDoubleClick={(evt) => { this.setState({ modalOpen: true }); evt.stopPropagation() }} >{field}</span>
            <ModalExt open={this.state.modalOpen} closeOnDocumentClick onClose={() => this.setState({ modalOpen: false })} size='tiny'>
                <Modal.Content>
                    <Form className="wh100">
                        <Segment>{field}</Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={() => this.setState({ modalOpen: false })}>Close</Button>
                </Modal.Actions>
            </ModalExt>
        </>
    }
}

addAfterStartupRunnable(() => {
    const telemetryFieldDescriptor = new class extends FieldDescriptor {
        dispatchers!: DispatchersFrom<typeof sliceEntityTablePagePosition>;

        constructor() {
            super();
            this.name = "telemetry";
            this.type = "custom";
            this.sortable = false;
        }

        protected renderFieldInternal(RendererClass: any, props: FieldRendererProps, entity: any): ReactNode {
            return <TelemetryFieldRenderer {...props} 
                        markUnmappedFields={this.dispatchers.getState().markUnmappedFields}
                        telemetryEventMapping={this.dispatchers.getState().telemetryEventMapping} />;
        }
    }();

    positionEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
        name: "Position",
        miniFields: ["id"],
        icon: "point",
        defaultFilter: Filter.createForClient("date", FilterOperators.forDate.today, ''),
        defaultSort: { field: "date", direction: "DESC" }
    })
        .removeFieldDescriptors("hasTelemetry")

        .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
        .addFieldDescriptor({ name: "date", type: FieldType.date, format: Utils.dateTimeWithSecFormat, propsForEditor: { hasTime: true, formatString: Utils.dateTimeFormat}  })
        .addFieldDescriptor({ name: "dateReceived", type: FieldType.date, format: Utils.dateTimeWithSecFormat, propsForEditor: { hasTime: true, formatString: Utils.dateTimeFormat}  })
        .addFieldDescriptor({ name: "dateProcessed", type: FieldType.date, format: Utils.dateTimeWithSecFormat, propsForEditor: { hasTime: true, formatString: Utils.dateTimeFormat}  })
        .addFieldDescriptor({ name: "gpsProvider", type: FieldType.dropdown, fieldDescriptorSettings: { fieldIntervals:
        // check PositionService.Provider from java
        [{ from: "A", label: "ASTUS" }, { from: "1", label: "CASPER" }, { from: "C", label: "C4MAX" },
        { from: "S", label: "SMARTWITNESS" }, { from: "2", label: "SENSOLUS" }, { from: "E", label: "FIXED ELA" },
        { from: "0", label: "CUMOLOCITY" }, { from: "3", label: "APUOFF" }, { from: "4", label: "POLESTAR" }, { from: "M", label: "EASY MILE" },
        { from: "W", label: "APPLIED AUTONOMY WP5"}, { from: " ", label: "UNKNOWN" }] 
        }})
        .addFieldDescriptor({ name: "plateNumber", type: FieldType.string })
        .addFieldDescriptor({ name: "longitude", type: FieldType.double })
        .addFieldDescriptor({ name: "latitude", type: FieldType.double })
        .addFieldDescriptor(telemetryFieldDescriptor)
        .addFieldDescriptor({ name: "speed", type: FieldType.number })
        .addFieldDescriptor({ name: "accuracy", type: FieldType.double })
        .addFieldDescriptor({ name: "engineState", type: FieldType.string })
        .addFieldDescriptor({ name: "odometer", type: FieldType.double })
        .addFieldDescriptor({ name: "motorHour", type: FieldType.double })
    );

    sliceEntityTablePagePosition.setEntityDescriptor(positionEntityDescriptor);
    positionEntityDescriptor.infoTable.slice = sliceEntityTablePagePosition;
    (positionEntityDescriptor.infoTable.slice as SliceEntityTablePage).setOptions({ countMode: undefined });
    
    positionEntityDescriptor.infoTable.wrappedComponentClass = class extends EntityTablePage<EntityTablePageProps & PropsFrom<typeof sliceEntityTablePagePosition>> {
        constructor(props: PropsFrom<typeof sliceEntityTablePagePosition>) {
            super(props);
            telemetryFieldDescriptor.dispatchers = this.props.dispatchers;
        }

        private onCheckboxChange = (e: any, data: CheckboxProps) => {
            this.props.dispatchers.setInReduxState({ markUnmappedFields: data.checked });
            this.props.dispatchers.refresh();
        };

        protected preRenderButtons(params: any): Array<OverrideableElement> {
            return [
                ...super.preRenderButtons(params),
                { elementType: Checkbox, props: { key: "markUnmappedFields", checked: this.props.markUnmappedFields, label: _msg("Position.markUnmappedFields"), onChange: this.onCheckboxChange } },
            ];
        }

        renderMain() {
            if (this.props.oneToManyMode?.entity && !this.props.oneToManyMode?.entity.plateNumber){ 
                return <Segment size="large" inverted color="red" style={{textAlign: 'center'}} ><Icon name="exclamation triangle"/> {_msg("HistoricalMap.invalidEqProps")}</Segment>
            }
            return super.renderMain()
        }
    };

    gpsLocationEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
        name: "GpsLocation",
        miniFields: ["id"],
        icon: "database",
        defaultFilter: Filter.createForClient("date", FilterOperators.forDate.today, ''),
        defaultSort: { field: "date", direction: "DESC" }
    })
        .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
        .addFieldDescriptor({ name: "equipmentResource", type: "EquipmentResource" })
        .addFieldDescriptor({ name: "date", type: FieldType.date, format: Utils.dateTimeWithSecFormat,  propsForEditor: { hasTime: true, formatString: Utils.dateTimeFormat}  })
        .addFieldDescriptor({ name: "creationDate", type: FieldType.date, format: Utils.dateTimeWithSecFormat,  propsForEditor: { hasTime: true, formatString: Utils.dateTimeFormat} , sortable: false })
        .addFieldDescriptor({ name: "gpsSystemName", type: FieldType.string, sortable: false })
        .addFieldDescriptor({ name: "longitude", type: FieldType.double, sortable: false })
        .addFieldDescriptor({ name: "latitude", type: FieldType.double, sortable: false })
        .addFieldDescriptor({ name: "telemetry", type: FieldType.text, sortable: false })

    );
    (gpsLocationEntityDescriptor.infoTable.slice as SliceEntityTablePage).setOptions({ countMode: undefined });
});