import React from "react";
import { Dropdown, DropdownProps, Label } from "semantic-ui-react";
import { EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { entityDescriptors, getEntityDescriptor } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";

export interface ChainItem {
    entity: string;
    field: string;
}

export enum FormType {
    FILTER,
    SORT
}

export class FieldNameContentAssistState extends State {
    chain: ChainItem[] = [];
    options: any[] = [];
    opened = false;
}

export class FieldNameContentAssistReducers<S extends FieldNameContentAssistState = FieldNameContentAssistState> extends Reducers<S> {

    addToChain(value: string[], rootEntity: string) {
        let entityDescriptor: EntityDescriptor = entityDescriptors[rootEntity];
        if (this.s.chain.length > 0) {
            entityDescriptor = entityDescriptors[this.s.chain[this.s.chain.length - 1].entity];
            entityDescriptor = entityDescriptors[entityDescriptor.fields[this.s.chain[this.s.chain.length - 1].field]?.getType()];
        }

        if (entityDescriptor) {
            this.s.chain.push({ entity: entityDescriptor.name, field: value[value.length - 1] });
        }
    }

    addAdditionalFieldAtTheEndIfNeeded(value: string[], rootEntity: string) {
        // if FieldDescriptor has a manyToMany annotation (like EntityToTag), append its value by default            
        const ed = entityDescriptors[this.s.chain[this.s.chain.length - 1].entity];
        if (!ed) {
            return;
        }
        const field = ed.fields[this.s.chain[this.s.chain.length - 1].field];
        if (field?.filterAsManyToMany) {
            value.push(field.filterAsManyToMany);
            this.addToChain(value, rootEntity);
        }
    }

    removeFromChain(index: number) {
        this.s.chain = this.s.chain.slice(0, index);
    }

    close() {
        this.s.opened = false;
    }

    addOption(value: string, text: string) {
        this.s.options.push({ key: value, text: text, value: value })
    }

    initialize(field: string, rootEntity: string) {
        this.s.chain = [];
        if (field) {
            let values = [] as any;
            const fields = field.split(".");
            fields.forEach(f => {
                values.push(f);
                this.addToChain(values, rootEntity)
            });
            this.addAdditionalFieldAtTheEndIfNeeded(values, rootEntity);

            this.s.chain.forEach((e, index) => {
                let value = e.field;
                let text = '';
                if (index === 0) {
                    text = getMessageForField(value, rootEntity);
                } else {
                    text = getMessageForField(value, this.s.chain[index].entity);
                }
                this.s.options.push({ key: value, text: text, value: value })
            });
        }
    }

    generateOptions(entityDescriptor: EntityDescriptor, rootEntity: string, includeManyToOneFields?: boolean, includeScalarFields?: boolean, formType?: FormType) {
        let options: any[] = [];
        if (entityDescriptor) {
            Object.keys(entityDescriptor.fields).forEach(field => {
                const isManyToOneField = this.s.chain.length > 0 || entityDescriptor.getField(field).typeIsEntity();
                const allowedInCurrentFormType = (formType !== FormType.FILTER || entityDescriptor.getField(field)?.filterable) && (formType !== FormType.SORT || entityDescriptor.getField(field)?.sortable)
                if (allowedInCurrentFormType && ((isManyToOneField && includeManyToOneFields) || (!isManyToOneField && includeScalarFields))) {
                    let text = getMessageForField(field, entityDescriptor.name);
                    options.push({ key: field, text: text, value: field })
                }
            });
        }

        this.s.chain.forEach((e, index) => {
            let value = e.field;
            let text = '';
            if (index === 0) {
                text = getMessageForField(value, rootEntity);
            } else {
                text = getMessageForField(value, this.s.chain[index].entity);
            }
            options.push({ key: value, text: text, value: value })
        });
        
        if (this.s.chain.length > 0 && entityDescriptor) {
            let currentExpression = this.s.chain.map((e, index) => getMessageForField(e.field, index === 0 ? rootEntity : e.entity)).join('.');
            options.unshift({ key: '', text: _msg('FieldNameContentAssist.useCurrentExp', currentExpression), value: '' });
        }
        this.s.options = options;
    }

    open(rootEntity: string, includeManyToOneFields?: boolean, includeScalarFields?: boolean, formType?: FormType) {
        this.s.opened = true;
        this.generateOptions(getEntityDescriptor(this.s.chain.map(c => c.field), rootEntity), rootEntity, includeManyToOneFields, includeScalarFields, formType);
    }

    changeChain(value: string[] | undefined, rootEntity: string, includeManyToOneFields?: boolean, includeScalarFields?: boolean, formType?: FormType, onChangeCallback?: () => void) {
        if (!value || (value.length > 0 && !value[value.length - 1])) {
            this.close();
            return;
        }
        if (value.length > this.s.chain.length) {
            this.addToChain(value, rootEntity);
            this.addAdditionalFieldAtTheEndIfNeeded(value, rootEntity);
        } else if (value.length < this.s.chain.length) {
            let last = true;
            for (let i = 0; i < value.length; i++) {
                if (value[i] !== this.s.chain[i].field) {
                    this.removeFromChain(i);
                    last = false;
                    break;
                }
            }
            if (last) {
                this.removeFromChain(this.s.chain.length - 1);
            }
        }
        const ed = getEntityDescriptor(this.s.chain.map(c => c.field), rootEntity);
        this.generateOptions(ed, rootEntity, includeManyToOneFields, includeScalarFields, formType);
        let open = this.s.chain.length > 0 && this.s.options.length === this.s.chain.length ? false : this.s.opened;
        this.s.opened = open;
        setTimeout(() => { onChangeCallback && onChangeCallback(); });
    }
    
}

type FieldNameContentAssistProps = RRCProps<FieldNameContentAssistState, FieldNameContentAssistReducers> & {
    rootEntity: string,
    field: string,
    onChangeCallback?: () => void
    includeManyToOneFields?: boolean,
    includeScalarFields?: boolean,
    formType?: FormType
};

export class FieldNameContentAssist extends React.Component<FieldNameContentAssistProps> {

    dropdownRef = React.createRef<any>();

    static defaultProps = {
        includeManyToOneFields: true,
        includeScalarFields: true
    }

    componentDidMount() {
        this.initialize(this.props.field, this.props.rootEntity);
    }

    public initialize(field: string, rootEntity: string) {
        this.props.r.initialize(field, rootEntity);
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.s.opened && this.props.s.chain.length < prevProps.s.chain.length) {
            this.dropdownRef.current?.searchRef.current?.focus();
        }
    }

    resetState = () => {
        this.props.r.setInReduxState({ chain: [], options: [], opened: false });
    }

    setChain = (entityDescriptor: string, field: string, callback?: () => void) => {
        this.initialize(field, entityDescriptor);
        setTimeout(() => {
            callback && callback();
        })
    }

    getChain = () => {
        return this.props.s.chain;
    }

    clearChain = () => {
        this.props.r.removeFromChain(0);
    }

    getOptions = () => {
        return this.props.s.options;
    }

    getOpened = () => {
        return this.props.s.opened;
    }

    setOpened = (opened: boolean) => {
        this.props.r.setInReduxState({opened: opened});
    }

    close = () => this.props.r.close();

    protected onOpen = () => this.props.r.open(this.props.rootEntity, this.props.includeManyToOneFields, this.props.includeScalarFields, this.props.formType);

    protected onChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => this.props.r.changeChain(data.value as string[], this.props.rootEntity, this.props.includeManyToOneFields, this.props.includeScalarFields, this.props.formType, this.props.onChangeCallback);

    protected onClose = () => this.props.r.close();

    render() {
        if (!this.props.s.opened) { this.dropdownRef.current?.searchRef.current?.blur() }
        let entityDescriptor = entityDescriptors[this.props.rootEntity];
        let entityDescriptorName = this.props.rootEntity;
        if (this.props.s.chain) {
            for (let i = 0; i < this.props.s.chain.length; i++) {
                if (!entityDescriptor) { break; }
                
                try {
                    entityDescriptor = entityDescriptors[entityDescriptor.getField(this.props.s.chain[i].field).getType()]
                } catch (e) {
                    this.props.r.setInReduxState({chain: []});
                    break;
                }
                
                entityDescriptorName = entityDescriptor ? entityDescriptor.name : entityDescriptorName;
            }
        }
        return (<div>
            <Dropdown
                ref={this.dropdownRef}
                header={
                    <Dropdown.Header className='flex-center'>
                        {_msg('FieldNameContentAssist.header')}
                        <Label
                            style={{ marginLeft: '5px' }}
                            horizontal color='blue'
                        >
                            {entityDescriptorName}
                        </Label>
                    </Dropdown.Header>
                }
                data-cy="dropdown"
                onClose={this.onClose} onOpen={this.onOpen} onChange={this.onChange}
                open={this.props.s.opened}
                placeholder={_msg('Filter.field.label')}
                fluid selection search multiple
                options={this.props.s.options}
                value={this.props.s.chain ? this.props.s.chain.map(i => i.field) : undefined}
                noResultsMessage={_msg("general.noResultsFound")}
            >
            </Dropdown>
        </div>)
    }
}

export function getMessageForField(filterField: string, entity: string): string {
    try {
        let fields = filterField.split('.');
        let message = '';
        let entityName = entity;

        if (fields && fields.length > 1) {
            fields.forEach((field, index) => {
                if (index === 0) {
                    message = entityDescriptors[entityName].getField(field).getLabel();
                } else {
                    if (entityName) {
                        entityName = entityDescriptors[entityName].getField(fields![index - 1]).getType();
                    }
                    message = message + '.' + (entityDescriptors[entityName].getField(field).getLabel());
                }
            });
            return message;
        }
        return entityDescriptors[entityName].getField(filterField).getLabel();
    } catch (e) { // field not found (see ed.getField)
        return filterField;
    }
}

export const FieldNameContentAssistRRC = ReduxReusableComponents.connectRRC(FieldNameContentAssistState, FieldNameContentAssistReducers, FieldNameContentAssist);