import gql from "graphql-tag";

export const GET_GANTT_ASSIGNMENT_ALGORITHMS = gql`
    query getGanttAssignmentAlgorithms {
        ganttAssignmentService_ganttAssignmentAlgorithms
    }
`;

export const GET_GANTT_ASSIGNMENT_ALGORITHM_DATE_FILTERS = gql`
    query getGanttAssignmentAlgorithmDateFilters($algorithm:String, $date: Date, $timezone: String) {
        ganttAssignmentService_algorithmDateFilters(algorithm: $algorithm, date: $date, timezone: $timezone)
    }
`;

export const RUN_GANTT_ASSIGNMENT_ALGORITHM = gql`
    mutation runGanttAssignmentAlgorithm($algorithm: String, $assignmentId: Long!) {
        ganttAssignmentService_runGanttAssignmentAlgorithm(algorithm: $algorithm, assignmentId: $assignmentId) {
            id, name, flightsDate, inputEntitiesCsv, outputEntitiesCsv
        }
    }
`;

export const CREATE_TASKS = gql`
    mutation createTasks($algorithm: String, $assignmentId: Long!) {
        ganttAssignmentService_createTasks(algorithm: $algorithm, assignmentId: $assignmentId) {
            id, name, flightsDate, inputEntitiesCsv, outputEntitiesCsv
        }
    }
`;

export const COPY_INPUT_DATA_FROM_DB = gql`
    mutation copyInputDataFromDb($assignmentId: Long!, $algorithm: String, $config: [CopyInputDataFromDbConfigInput!]!) {
        ganttAssignmentService_copyInputDataFromDb(assignmentId: $assignmentId, algorithm: $algorithm, config: $config) {
            id, name, flightsDate, inputEntitiesCsv, outputEntitiesCsv
        }
    }
`;

export const SAVE_FROM_DISK_CSV_FILES = process.env.NODE_ENV === "development" ? gql`
    mutation saveFromDiskCsvFiles($inputPaths: [String], $outputPaths: [String], $date: Date, $id: Long) {
        ganttAssignmentDevService_saveFromDiskCsvFiles(inputPaths: $inputPaths, outputPaths: $outputPaths, date: $date, id: $id)
    }
` : null;

export const COPY_GANTT_ASSIGNMENT_TO_DB = gql`
    mutation copyGanttAssignmentToDb($assignmentId: Long!) {
        ganttAssignmentService_copyGanttAssignmentToDb(assignmentId: $assignmentId)
    }
`;

export const COPY_GANTT_ASSIGNMENT_TO_DB_EM = gql`
    mutation copyGanttAssignmentToDbEm($assignmentId: Long!) {
        ganttAssignmentService_copyGanttAssignmentToDbEm(assignmentId: $assignmentId)
    }
`;

export const IMPORT_ENTITIES_FROM_CSV = gql`
    query importEntitiesFromCsv($csv: String) {
        ganttAssignmentService_importEntitiesFromCsv(csv: $csv)
    }
`;

export const GET_PATHS_GA = gql`
    mutation getPaths {
        ganttAssignmentDevService_paths
    }
`;

export const RECALCULATE_GANTT_ASSIGNMENT = gql`
    mutation recalculateGanttAssignment($algorithm: String, $assignmentId: Long!, $timezone: String) {
        ganttAssignmentService_recalculateGanttAssignment(algorithm: $algorithm, assignmentId: $assignmentId, timezone: $timezone)
    }
`;

export const RECALCULATE_FOR_TASK_TABLE = gql`
    mutation recalculateForTaskTable($timezone: String) {
        ganttAssignmentService_recalculateForTaskTable(timezone: $timezone)
    }
`;

export const SAVE_FLIGHT_APU_OFF_SHOULD_PROCESS = gql`
    mutation saveFlightApuOffShouldProcess($assignmentId: Long!) {
        ganttAssignmentServiceCustomCode_saveFlightApuOffShouldProcess(assignmentId: $assignmentId)
    }
`;
