import React from "react";
import { DispatchersFrom } from "@crispico/foundation-react/reduxHelpers";
import { sliceEntityTablePageAudit } from "../auditEntityDescriptor";
import { Popup, Segment } from "semantic-ui-react";

type AuditFieldsExtendedViewType = { content: any, openFor: string, dispatchers: DispatchersFrom<typeof sliceEntityTablePageAudit> };

export class AuditFieldsExtendedView extends React.Component<AuditFieldsExtendedViewType> {
    constructor(props: AuditFieldsExtendedViewType) {
        super(props);
        this.onPopupClose = this.onPopupClose.bind(this);
    }

    onPopupClose() {
        // @ts-ignore
        this.props.dispatchers.setInReduxState({ extendedViewForEntity: "", extendedViewEntity: null });
    }

    render() {
        if (this.props.openFor === "") {
            return null;
        }

        const element = document.getElementsByClassName(this.props.openFor)[0] as HTMLElement;

        if (!element) {
            return null;
        }

        return (
            <Popup 
                on="click" 
                position="top right" 
                wide="very" 
                content={<Segment>{this.props.content}</Segment>} 
                open={this.props.openFor !== ""} 
                context={element} 
                onClose={this.onPopupClose} 
                closeOnEscape />
        );
    }
}