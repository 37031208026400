import { apolloClient, PrivateRoute, PrivateRouteProps, Utils } from "@crispico/foundation-react";
import { DatePickerReactCalendar } from "@crispico/foundation-react/components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Form, Grid, Segment } from "semantic-ui-react";
import { dtoRootRelations, EntityRelation } from "./EntitiesRelations";
import { GanttDoublePage, GanttDoublePageRRC } from "./GanttDouble";
import { LOAD_DATA_FOR_GANTT_PAGE } from "./queries";

export class RealTimeGanttPageState extends State {
    dateForQueryAsString: string = "";
    dtoRoot: any = {};
}

export class RealTimeGanttPageReducers<S extends RealTimeGanttPageState = RealTimeGanttPageState> extends Reducers<S> {
}

export class RealTimeGanttPage extends React.Component<RRCProps<RealTimeGanttPageState, RealTimeGanttPageReducers>, {}> {
    protected ganttDoublePageRef = React.createRef<GanttDoublePage>();

    loadEntityRelation(dtoRoot: any, entityRelation: EntityRelation) {
        let newDtoRoot = _.cloneDeep(dtoRoot);
        let entities = newDtoRoot[entityRelation.to]
        let fromEntityName = entityRelation.from.charAt(0).toLowerCase() + entityRelation.from.slice(1);
        if (fromEntityName.includes("2")) {
            fromEntityName = fromEntityName.replace(/[0-9]/g, '');
        }
        for (let key of Object.keys(entities)) {
            if (entities[key][entityRelation.fromFieldName ? entityRelation.fromFieldName : fromEntityName + "Id"] !== null) {
                entities[key][entityRelation.fieldName ? entityRelation.fieldName : fromEntityName] = newDtoRoot[entityRelation.from][entities[key][entityRelation.fromFieldName ? entityRelation.fromFieldName : fromEntityName + "Id"]];
            }
        }
        newDtoRoot[entityRelation.to] = entities;
        return newDtoRoot;
    }

    async getEntities() {
        let dtoRoot = (await apolloClient.query({
            query: LOAD_DATA_FOR_GANTT_PAGE,
            variables: { date: this.props.s.dateForQueryAsString }
        })).data["diffUpdateService_ganttData"];

        // Temporary solution for a bug with missing relations between entities.
        // Should be removed (with EntitiesRelations.tsx) after the problem is solved
        for (let dtoRootRealtion of dtoRootRelations) {
            dtoRoot = this.loadEntityRelation(dtoRoot, dtoRootRealtion);
        }

        this.props.r.setInReduxState({
            dtoRoot: dtoRoot
        });

        this.ganttDoublePageRef.current?.props.r.setInReduxState({ entities: this.props.s.dtoRoot });
    }

    componentDidMount() {
        this.getEntities();
    }

    componentDidUpdate(prevProps: Readonly<RRCProps<RealTimeGanttPageState, RealTimeGanttPageReducers<RealTimeGanttPageState>>>, prevState: Readonly<{}>, snapshot?: any) {
        if (prevProps.s.dateForQueryAsString !== this.props.s.dateForQueryAsString) {
            this.getEntities();
        }
    }

    render() {
        return <>
            <Segment className="RealTimeGanttPage_bar">
                <Grid>
                    <Grid.Column width={2}>
                        <Form>
                            <Form.Field>
                                <DatePickerReactCalendar showButton allowClear format={Utils.dateFormat}
                                    value={this.props.s.dateForQueryAsString === "" ? moment(Utils.now()) : moment(moment(this.props.s.dateForQueryAsString).format(Utils.dateFormat))}
                                    onChange={(value) => this.props.r.setInReduxState({
                                        dateForQueryAsString: moment(value?.toDate()).format(Utils.dateFormat)
                                    })} />
                            </Form.Field>
                        </Form>
                    </Grid.Column>
                </Grid>
            </Segment>
            <Segment className="RealTimeGanttPage_content">
                <GanttDoublePageRRC id="GanttDoublePageRRC" ref={this.ganttDoublePageRef} />
            </Segment>
        </>;
    }
}

export const RealTimeGanttPageRRC = ReduxReusableComponents.connectRRC(RealTimeGanttPageState, RealTimeGanttPageReducers, RealTimeGanttPage);

export const realTimeGanttPageUrl = "/RealTimeGantt";
export const realTimeGanttPageRoute = (computeRoute: (props: PrivateRouteProps) => JSX.Element) =>
    <PrivateRoute key="fileBrowserPage"
        path={realTimeGanttPageUrl}
        render={(props) => <RealTimeGanttPageRRC {...props} id="realTimeGanttPage" />}
        computeRoute={computeRoute} />

export const realTimeGanttPageMenuEntry = () => {
    return {
        id: "realTimeGanttPage",
        content: _msg("RealTimeGanttPage.title"),
        to: realTimeGanttPageUrl, exact: true, icon: "chart bar outline",
    }
};