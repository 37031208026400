import gql from "graphql-tag";
import _ from "lodash";
import React from "react";
import { ActionMeta, InputActionMeta, NamedProps } from "react-select";
import { Sort } from "../apollo-gen-foundation/Sort";
import { apolloClient } from "../apolloClient";
import { Filter } from "../components/CustomQuery/Filter";
import { SelectExt, SelectExtOption } from "../components/selectExt/SelectExt";
import { Utils } from "../utils/Utils";
import { FieldEditorProps } from "./fieldRenderersEditors";
import { FindByStringParams } from "./FindByStringParams";

export interface AssociationEditorProps {
    selectedValue?: any,
    sorts?: Sort[]
    filter?: Filter,
    onChange?: (entity: any) => void,
    additionalSearchFields?: string[],
    queryLimit?: number,
}

type SelectedValue = undefined | SelectExtOption | SelectExtOption[];

export type AssociationExtraProps = NamedProps;

const QUERY_LIMIT_NUMBER = 10;

export class AssociationFieldEditor<P> extends React.Component<P & Partial<FieldEditorProps> & Pick<FieldEditorProps, "fieldDescriptor"> & AssociationEditorProps & AssociationExtraProps, {
    entities: any[],
    // This value is used to prevent SelectExt at rerender to make an unnecessary request (the query from onMenuOpen method)
    isMenuOpened: boolean
}> {
    protected selectExtRef = React.createRef<any>();

    static defaultProps = {
        queryLimit: QUERY_LIMIT_NUMBER
    };

    constructor(props: P & FieldEditorProps & AssociationEditorProps & AssociationExtraProps) {
        super(props);

        this.state = {
            entities: [],
            isMenuOpened: false
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.onMenuOpen = this.onMenuOpen.bind(this);
        this.updateCurrentOption = this.updateCurrentOption.bind(this);
        this.onMenuClose = this.onMenuClose.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<P & Partial<FieldEditorProps> & Pick<FieldEditorProps, "fieldDescriptor"> & AssociationEditorProps>, prevState: Readonly<{ entities: any[]; isMenuOpened: boolean; }>, snapshot?: any): void {
        if (!_.isEqual(prevProps.innerEntityDescriptor, this.props.innerEntityDescriptor)) {
            this.setState({ entities: [] });
            if (this.props.onChange) {
                this.props.onChange([]);
            }
            this.changeSelectedValue([]);
        }
    }

    protected createFindByStringParams(searchQuery: string): FindByStringParams {
        let p = FindByStringParams.create().string(searchQuery).pageSize(QUERY_LIMIT_NUMBER);
        p.params.sorts = this.props.sorts;
        p.params.filter = this.props.filter;
        return p;
    }

    protected createQuery(operationName?: string) {
        let name: string = operationName ? operationName : `${_.lowerFirst(this.props.innerEntityDescriptor!.name)}Service_findByString`;

        let fields: string[] = this.props.innerEntityDescriptor!.miniFields.concat(this.props.additionalSearchFields ? this.props.additionalSearchFields : []);

        let query = gql(`query q($params: FindByStringParamsInput) { 
            ${name}(params: $params) {
                ${this.props.innerEntityDescriptor!.idFields.join(" ")} ${this.props.innerEntityDescriptor!.getGraphQlFieldsToRequest(fields)}
            }
        }`);

        return {
            name: name,
            query: query
        }
    }

    protected async performQuery(searchQuery?: string, operationName?: string) {
        // In storybook, we have cases (e.g. BlocklyEditorTab) where we don't receive an innerEntityDescriptor
        if (!this.props.innerEntityDescriptor) {
            return;
        }

        let { name, query } = this.createQuery(operationName);

        let result = (await apolloClient.query({
            query: query,
            variables: this.createFindByStringParams(searchQuery ? searchQuery : ""),
            context: { showSpinner: false }
        })).data[name];

        this.setState({ entities: result });
    }

    protected onInputChange(value: string, action: InputActionMeta) {
        this.performQuery(value);
    }

    // This method will be called if the editor is inside a form.
    protected getSelectedOption(): any {
        return this.props.formikProps &&
            Utils.navigate(this.props.formikProps!.values, this.props.fieldDescriptor.name, false, ".");
    }

    protected getLabel(entity: any): string {
        return this.props.innerEntityDescriptor!.toMiniString(entity) + (this.props.additionalSearchFields ? " " + this.props.additionalSearchFields?.map((field: string) => entity[field]).join(" ") : "");
    }

    protected getSelectedValue(): SelectedValue {
        let selectedOption: any = this.getSelectedOption();
        let selectedValue: SelectedValue = undefined;
        if (selectedOption) {
            if (Array.isArray(selectedOption) && selectedOption.length > 0) {
                selectedValue = selectedOption.map((value) => ({
                    label: this.getLabel(value),
                    value: this.props.innerEntityDescriptor!.idFields.map((idField: string) => value[idField]).join(" "),
                    entity: value
                }));
            }
            else if (!Array.isArray(selectedOption)) {
                selectedValue = {
                    label: this.getLabel(selectedOption),
                    value: this.props.innerEntityDescriptor!.idFields.map((idField: string) => selectedOption[idField]).join(" "),
                    entity: selectedOption
                };
            }
        }
        return selectedValue;
    }

    // This method will be called if the editor is inside a form.
    // It can be also overrided by a component which extends this 
    protected changeSelectedValue(option: any) {
        if (this.props.formikProps) {
            this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, option);
        }
    }

    protected updateCurrentOption(value: any, action: ActionMeta<any>) {
        let option: any = null;
        if (value) {
            if (this.props.isMulti) {
                option = value.map((value: any) => value.entity);
            }
            else {
                option = value.entity;
            }
        }
        if (this.props.onChange) {
            this.props.onChange(option);
        }
        this.changeSelectedValue(option);
    }

    protected onMenuOpen() {
        if (!this.state.isMenuOpened) {
            this.performQuery();
            this.setState({
                isMenuOpened: true
            });
        }
    }

    protected onMenuClose() {
        this.setState({
            isMenuOpened: false
        });
    }

    protected checkEntityDescriptor(): boolean {
        return !this.props.innerEntityDescriptor && this.state.entities.length !== 0;
    }

    render() {
        // We have some cases where innerEntityDescriptor is changing to undefined and
        // also we have some options in state. We must ignore them because program will crash
        // at instructions like: this.props.innerEntityDescriptor!.idFields
        let options: SelectExtOption[] = this.checkEntityDescriptor() ? [] : this.state.entities!.map(entity => ({
            label: this.getLabel(entity),
            value: this.props.innerEntityDescriptor!.idFields.map((idField: string) => entity[idField]).join(" "),
            entity: entity
        }));

        // Also, we must ignore selectedValues that come from props
        let selectedValue: SelectedValue = this.checkEntityDescriptor() ? undefined : this.props.selectedValue ? {
            label: this.getLabel(this.props.selectedValue),
            value: this.props.innerEntityDescriptor!.idFields.map((idField: string) => this.props.selectedValue![idField]).join(" "),
            entity: this.props.selectedValue
        } : this.getSelectedValue();

        if (!this.props.isMulti && selectedValue && !Array.isArray(selectedValue)) {
            let currentValueId = selectedValue.value;
            if (options.filter((value) => value.value === currentValueId).length === 0) {
                options.unshift(selectedValue);
            }
        }

        if (this.props.queryLimit !== -1) {
            if (options.length >= QUERY_LIMIT_NUMBER) {
                options.splice(QUERY_LIMIT_NUMBER, options.length - QUERY_LIMIT_NUMBER);
            }
        }

        if (this.props.isMulti && !selectedValue) {
            selectedValue = []
        }

        return <SelectExt ref={this.selectExtRef} options={options} placeholder={this.props.placeholder} value={selectedValue}
            isMulti={this.props.isMulti ? this.props.isMulti : false} closeMenuOnSelect={this.props.isMulti ? false : true}
            controlShouldRenderValue={this.props.controlShouldRenderValue} onMenuOpen={this.onMenuOpen}
            onChange={this.updateCurrentOption} onMenuClose={this.onMenuClose} onInputChange={this.onInputChange}
            isDisabled={!this.props.fieldDescriptor.enabled} isClearable={this.props.isClearable !== undefined ? this.props.isClearable : true}
            header={this.props.queryLimit !== -1 && options.length >= QUERY_LIMIT_NUMBER ? _msg("AssociationFieldEditor.header", options.length) : undefined} />;
    }
}