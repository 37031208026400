import React, { ReactNode, useEffect } from "react";
import ReactDOM from "react-dom";
import { Menu, MenuProps, Icon, MenuItemProps, Tab, TabProps, Modal, Button, Breadcrumb, Divider, BreadcrumbSectionProps, SemanticShorthandCollection } from "semantic-ui-react";
import { Optional } from "@crispico/foundation-react/CompMeta";
import { TestState } from "@crispico/foundation-react/utils/TestState";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import lodash from "lodash";
import { NavLink, Prompt, Route, RouteProps, Switch, Redirect, RedirectProps } from "react-router-dom";
import { ModalExt, ModalExtProps } from "../ModalExt/ModalExt";
import { ContainerWithHeaderContext } from "../containerWithHeader/ContainerWithHeader";
import { ReactElement } from "react";

export interface TabRouterPane {
    routeProps?: RouteProps;
    menuItemProps: string | MenuItemProps;
    isTabDirty?: () => boolean;
    render?: () => React.ReactNode;

    /**
     * ## What should the implementation do?
     * 
     * If existent, should commit/combine:
     * 1) the data being edited at tab level 
     * 2) to the "main" data from parent editor/page.
     * 
     * 2) is usually stored as redux state. But 1) may be in different forms:
     * - local state (non redux); e.g. for forms
     * - impure stuff / non react; e.g. a Blockly editor, or a JS editor which holds the data inside
     * - redux state
     * 
     * The tabs may be (or contain) editors that can also be used stand alone. In this case they don't
     * know about the parent (they are independent); and pretty much all 3 bullets above apply. But there may be tabs that are
     * dependent on the parent. In which case they may even store the data directly in 1), so implementing this function is not needed.
     * 
     * ## When is it called?
     * 
     * A) When the tab is unmounted. E.g. the user navigates between the tabs. So 1) should be saved in 2) because 1) will be lost.
     * B) When the user hits "Apply", or "Save" (which performs also "apply"). All tabs are notified.
     * WARNING 1: a tab may have never been mounted. But the function is invoked anyway. So if using refs, local state or so: they may be null / not initialized.
     * WARNING 2: the current tab will have the function invoked twice. First because B). Second because A). Because of this, we had an issue #24336.
     * But currently this is not reproducible and this double invocation is difficult to avoid (while preserving generality; i.e. don't add necessary
     * steps for the user). If similar issues appear, take a look there.
     */
    commit?: () => void;
}

/**
 * See corresponding fields in `TabbedPage`.
 */
export type TabbedPageProps = {
    embeddedMode?: boolean,
    modalProps?: ModalExtProps
}

export type TabbedPageLocationState = {
    /**
     * Keeps a flag in location state in order to be used by later adds of tab panes (e.g. viewer, attached dashboards in table.editor), 
     * Redirects to the main path so that the correct 1st entry will be set as selected.
     */
    redirectToMain?: boolean
}

export type OverrideableElement<ELEMENT_TYPE = any, PROPS = any> = { element: ReactNode } | { elementType: ELEMENT_TYPE, props: PROPS } | undefined | false;

/**
 * Initially this was a functional component, using:
 * 
 * ```
 * useEffect(() => { return props.callback }, []);
 * ```
 * 
 * In theory, the code above should have been equivalent to the current component. But in practice, we
 * observed that e.g. for an hierarchy OnUnmountWatcher > A > B, the "willUnmountHandlers()" were called:
 * A, B, OnUnmountWatcher. We would have expected: OnUnmountWatcher, A, B. Hence, we transformed this in a
 * class component.
 * 
 * FYI, this order is important e.g. in the editor. When a tab is changed, "tab.commit()" will be called.
 * And we want that tab and its children to be "still alive". W/ the initial impl, this was not the case.
 * So within "commit()", refs to various components would be null, because the children would be destroyed.
 */
class OnUnmountWatcher extends React.Component<{ renderFunction: () => ReactNode, callback: () => void }> {
    componentWillUnmount() {
        this.props.callback();
    }
    
    render() {
        return this.props.renderFunction();
    }
}

/**
 * Props MAY extend TabbedPageProps.
 * 
 * Props should have extended TabbedPageProps. But there were already tens of classes that didn't do it,
 * and it was difficult to change. 
 */
export abstract class TabbedPage<P = TabbedPageProps, S = {}, SS = {}> extends React.Component<P, S, SS> {

    // these fields have a dual usage: 1) either the subclass modifies
    // directly; or 2) the user passes via props. For 1), if needed, these may be transformed
    // (for more advanced override cases) into getters or functions 

    /**
     * `true` if the page is not used as a page; but as a component, embedded in another component.
     */
    embeddedMode = false;

    /**
     * If `embeddedMode` and if this object is not `null` => popup mode. Then `modalProps.open` dictates
     * if the popup is shown or hidden.
     */
    modalProps?: ModalExtProps;

    protected shouldListenTabsUnmount = false;

    constructor(props: P) {
        super(props);
        this.onMatchChanged = this.onMatchChanged.bind(this);
        this.renderMain = this.renderMain.bind(this);
        this.commitMain = this.commitMain.bind(this);

        if (this.propsCasted.embeddedMode !== undefined) {
            this.embeddedMode = this.propsCasted.embeddedMode;
        }
        if (this.propsCasted.modalProps) {
            this.modalProps = this.propsCasted.modalProps;
        }
        // window.addEventListener("beforeunload", this.onUnload);
    }

    protected onUnload = (e: any) => {
        if (this.isDirty()) {
            this.onDirtyExitAttempted()
            e.preventDefault();
            e.returnValue = ''
        }
    }

    componentWillUnmount() {
        // on unmount, the parent (this) seems to be notified before the children (the tabs)
        // in this case, stop "listening" to unmounts, to avoid triggering a commit() when the page is
        // dead/sleeping. We had issues w/ this, but each one was solved prior to adding this flag. So
        // this line is added because we think it would help; we didn't actually observe it in action 
        // "saving the day". Not yet at least.
        this.shouldListenTabsUnmount = false;

        // window.removeEventListener("beforeunload", this.onUnload);
    }

    protected get propsCasted(): TabbedPageProps {
        return this.props as TabbedPageProps;
    }

    protected embeddedModeRenderTab(props: TabProps) {
        return React.createElement(Tab, props);
    }

    protected getTabbedPageCssClasses() {
        return "flex-container flex-grow";
    }

    render() {
        let menuProps: MenuProps;
        let content = null;
        const routes: any[] = [];
        if (!this.modalProps || this.modalProps.open) {
            // this condition disables this block when popup mode, and popup is not visible
            // so that while the popup is hidden, no rendering code is executed; because components
            // don't expect this, so maybe they have invalid data (e.g. that maybe would have arrived from server
            // on popup show)
            menuProps = this.getMenuProps();
            if (this.getMainRoutePath()) {
                // tabs mode
                const tabPanes = [];

                const panes = this.getTabPanes();
                let i = 0;
                // iterates on the panes and produces 2 sets of data:
                for (let pane of panes) {
                    const key = "route" + i++;

                    // step 1: create the menu entry
                    const menuItemProps: any = this.embeddedMode ? { key } : {
                        key, as: NavLink, to: pane.routeProps!.path,
                        exact: pane.routeProps!.exact // usually the first tab has "exact = true"; so we need to propagate to the link as well
                    };
                    if (typeof pane.menuItemProps === "string") {
                        // simple mode => provide a label
                        menuItemProps.content = pane.menuItemProps;
                    } else {
                        // disabled prop = true => deactivate NavLink
                        if (pane.menuItemProps.disabled) {
                            menuItemProps.as = Button;
                        }
                        // advanced mode => provide a render function
                        Object.assign(menuItemProps, pane.menuItemProps);
                    }

                    if (this.embeddedMode) {                        
                        tabPanes.push({ menuItem: menuItemProps, render: () => <Tab.Pane>{pane.render!()}</Tab.Pane> });
                        continue;
                    }

                    // We create the tabs only if nobody requested otherway
                    Utils.getOrCreateChildrenInProps(menuProps).push(React.createElement(Menu.Item, menuItemProps));

                    // step 2: create the route
                    (pane.routeProps as any).key = key;
                    if (pane.render) {
                        // optional because maybe the user wants to use instead "component=...", in order to gain access to match/params
                        pane.routeProps!.children = pane.render.apply(null);
                    }
                    routes.push(React.createElement(Route, pane.routeProps));
                }

                // create additional route that redirects the main path to the 1st tab path                        
                routes.push(React.createElement(Route, this.getRedirectToFirstPaneRouteProps({ to: { pathname: panes[0].routeProps?.path as string, state: { redirectToMain: true } as TabbedPageLocationState } })));

                content = this.embeddedMode
                    // "grow" because we want the pane to fill the screen; i.e. to be "pulled"; otherwise, for embedded
                    // tables (which is common), which adapt to the container (and don't "push") they won't show the rows/virtualized section

                    ? this.embeddedModeRenderTab({ className: this.getTabbedPageCssClasses(), menu: { pointing: true }, panes: tabPanes })
                    : <Switch children={routes} />;
            } else {
                // simple mode; only 1 screen; no tabs
                content = this.renderMain();
            }
        }

        if (this.embeddedMode) {
            if (this.modalProps) {              
                return (<ModalExt {...this.modalProps}>
                    <Modal.Header>{
                        // same comment as above: if popup hidden, don't invoke any render code
                        this.modalProps.open && this.renderModalHeader()
                    }</Modal.Header>
                    <Modal.Content>{content}</Modal.Content>
                </ModalExt>);
            } else {
                return <>{content}</>;
            }
        } else { 
            return (<>
                {this.getRouteHashChangedThunk() && <TestState.RouteHashChangedObserver thunk={this.getRouteHashChangedThunk()} />}
                <Utils.MatchObserver onMatchChanged={this.onMatchChanged} />
                {/* TODO by CS: deactivated because doesn't work well */}
                {/* <Prompt when={this.isDirty()}
                    message={(location) => {
                        // does not trigger on tab change
                        if (routes.find(r => location.pathname === r.props.path)) return true
                        this.onDirtyExitAttempted()
                        return _msg('Dashboard.prompt.routeChange')
                    }} /> */}
                {/* rendering the tab bar in the portal made available by the parent */}

                {/* CS: disabled when migrating to new UI */}
                {/* <ContainerWithHeaderContext.Consumer>
                    {value => value.titleAreaDiv && !value.titleAreaDivAlreadyFilledIn && // this may be missing if the page is not contained in a ContainerWithHeader which exposes this context
                        ReactDOM.createPortal(React.createElement(Menu, menuProps!), value.titleAreaDiv)}
                </ContainerWithHeaderContext.Consumer> */}

                <div className={this.getTabbedPageCssClasses()} data-cy="flexContainer">
                    {this.renderPageHeaderAndTabs(menuProps!)}
                    {content}
                </div>
            </>);
        }
    }

    protected getRedirectToFirstPaneRouteProps(redirectProps: RedirectProps): RouteProps {        
        let routeProps: RouteProps = {          
            exact: true,
            path: this.getMainRoutePath(),
            children: [<Redirect {...redirectProps} />]
        };
        (routeProps as any).key = "redirectToFirstTab";
        return routeProps;
    }

    protected getBreadcrumbSections(): SemanticShorthandCollection<BreadcrumbSectionProps> {
        return [];
    }

    protected renderPageHeaderAndTabs(menuProps: MenuProps) {
        const sections = this.getBreadcrumbSections();
        return <>
            {this.renderPageHeader()}
            {Array.isArray(menuProps.children) && menuProps.children.length > 0 && <Menu {...menuProps!}>
                <Menu.Item className="TabbedPage_breadcrumbMenuItem" key="breadcrumbMenuItem">
                    <Breadcrumb icon='right angle' size="tiny" className="TabbedPage_breadcrumb" sections={sections} />
                </Menu.Item>
                {menuProps.children}
                <Menu.Item position='right' key="rightMenuItem"/> {/** this is needed in order to align the first menu items to the left, didn't find other solution */}
            </Menu>}
        </>;
    }

    /**
     * It's not called 'renderHeader()', to avoid naming conflict, because such functions already exist.
     */
    protected renderPageHeader(): ReactElement | null {
        return null;
    }

    // TODO CS: de sters
    protected getRouteHashChangedThunk(): any {
        return null;
    }

    protected onMatchChanged(match: any) {
        // nop
    }

    protected getMenuProps(): MenuProps {
        const title = this.getTitle();
        const icon = typeof title === "string" ? "window maximize outline" : title.icon;
        const text = typeof title === "string" ? title : title.title;
        return {
            pointing: true,
            secondary: true, // CC: shows line instead of arrow to point the active tab
            className: "flex-wrap TabbedPage_menu"
            // the first tab = the title of the screen

            // CS: disabled when migrating to new UI
            // className: "TabbedPage_menu", 
            // children: [
            //     <Menu.Item key="title">
            //         {typeof icon === 'string' ? <Icon name={icon as any} /> : icon} {text}
            //     </Menu.Item>]
        }
    }

    protected getTitle(): string | { icon: JSX.Element | string, title: JSX.Element | string } {
        return "Change me (title)!";
    }

    /**
     * "" => no tabs; otherwise => tabs
     */
    protected getMainRoutePath(): string {
        return "";
    }

    protected getMainPaneSubPath() {
        return "main";
    }

    protected getTabPanes(): TabRouterPane[] {
        const mainPane = {
            routeProps: { exact: true, path: this.getMainRoutePath() + "/" + this.getMainPaneSubPath() },
            menuItemProps: this.getMainMenuItemProps(),
        } as TabRouterPane;

        const extraTabPanes = this.getExtraTabPanesInternal() as TabRouterPane[]; // cast to avoid the compiler error; we check or null below, so it's safe
        const extraTabPanesResult: TabRouterPane[] = []; // we modify the above list + elements; hence return copies
        if (!extraTabPanes) {
            this.shouldListenTabsUnmount = false;
            // simple render function; no need to listen for unmounts
            // however if we had the wrapped version, it wouldn't have harmed; but this branch is the highest traffic, so a bit
            // of economy doesn't harm
            mainPane.render = this.renderMain;

            extraTabPanesResult.push(mainPane);
        } else {
            this.shouldListenTabsUnmount = true;
            // wrapping the render function, in order to listen for unmounts
            // discussion about "this.shouldListenTabsUnmount": we want to be notified / call commitMain() or extraPane.commit() only when the user switches
            // the various tabs. We had the following scenario: chart page in edit mode w/ multiple tabs. Then open the page again, in add mode. The
            // first render would render extraPanes. But quickly it would switch to no extraPanes. Thus trigger a parasite commit that would actually
            // set in the "add" page the infos from the previous "edit". By using this flag, the callback still triggers. But it checks the flag. Which
            // has just been set to false in the latest render cycle (the one that ordered the unmount of the extra panes).
            mainPane.render = () => <OnUnmountWatcher key="main" renderFunction={this.renderMain} callback={() => this.shouldListenTabsUnmount && this.commitMain()} />

            const mainRoutePath = lodash.trimEnd(this.getMainRoutePath(), "/");
            let inserted = false;

            // look in the list; if there is a "hole" => insert the pane there
            for (let i = 0; i < extraTabPanes.length; i++) {
                let extraPane = extraTabPanes[i];

                if (!extraPane) {
                    // found a hole
                    extraPane = mainPane;
                    inserted = true;
                } else {
                    // copy because we change 2 props; one deep and one shallow
                    extraPane = lodash.cloneDeep(extraPane);
                    extraPane.routeProps && (extraPane.routeProps.path = mainRoutePath + extraPane.routeProps!.path);
                    if (extraPane.commit) {
                        const oldRender = extraPane.render!; // var needed; otherwise infinite recursion
                        extraPane.render = () => <OnUnmountWatcher key={i} renderFunction={oldRender} callback={() => this.shouldListenTabsUnmount && extraPane.commit!()} />; // I don't understand why key is needed; but it is
                    }
                }
                extraTabPanesResult.push(extraPane);
            }
            if (!inserted) {
                // arrived here, i.e. the user didn't reserve a hole for the main pane;
                // so insert it at the beginning
                extraTabPanesResult.splice(0, 0, mainPane);
            }
        }

        return extraTabPanesResult;
    }

    protected getMainMenuItemProps(): string | MenuItemProps {
        return "Change me (main tab pane menu)!";
    }

    protected renderMain(): ReactNode {
        return <p>Change me (main tab pane content)!</p>
    }

    /**
     * @see TabRouterPane#commit()
     */
    protected commitMain() {
        // nop
    }

    public triggerCommitForAll() {
        this.commitMain();
        const extraTabPanes = this.getExtraTabPanesInternal();
        if (!extraTabPanes) { return; }
        for (const tab of extraTabPanes) {
            tab?.commit?.();
        }
    }

    /**
     * Shouldn't normally be overridden.
     */
    protected getExtraTabPanesInternal() {
        return this.getExtraTabPanes();
    }

    /**
     * Meant to be overridden.
     */
    protected getExtraTabPanes(): Optional<(TabRouterPane | null)[]> {
        return null;
    }

    protected isDirty(): boolean | undefined {
        // by default: returns false => is never dirty; override this in your tabbed page
        return false;
    }

    protected async onDirtyExitAttempted() {
        // nop
        // doc: May be overridden if some actions need to be done when the "navigate away from page" prompt has just been displayed.
        // This may happen 1) the route part of the URL changes; so we are navigating from our app to our app.
        // And 2) when the whole URL changes, i.e. navigating away from the page (e.g. the user has manually changed the URL).
        // Be aware that this handler should be treated as "best effort". For the 2nd case, even if this handler is invoked (and maybe has initiated a server call),
        // the user may quickly press "OK, navigate away". And the client app halts, e.g. w/o having the opportunity of receiving the answer.
        // There is also a 3rd case when the user kills the process of the browser. So in this case, the handler is not even invoked.
    }

    protected renderModalHeader() {
        const title = this.getTitle()
        if (typeof title === "string") {
            return this.getTitle();
        } else {
            return <>{typeof title.icon === 'string' ? <Icon name={title.icon as any} size="large" /> : React.cloneElement(
                title.icon,
                { size: "large" }
            )}
            &nbsp;&nbsp;<span data-testid="TabbedPage_modalheader_title">{title.title}</span></>
        }
    }

    /**
     * The pair `elementType` + `props` are passed to `React.createElement()`. Subclasses may override this and:
     * * append new elements (e.g. buttons)
     * * insert, remove elements (by searching in the original list using `key`)
     * * modify props of existing elements (again by finding the origianl elements using `key`)
     * 
     * But a "final" class may also use `element`, because it's quicker, type safe and w/o need to worry about subclasses.
     */
    protected preRenderButtons(params: {}): Array<OverrideableElement> {
        return [];
    }

    /**
     * This can be called by subclasses. But overriding it is not recommended. `preRenderButtons()` can be overridden instead.
     */
    protected renderButtons(params: {}) {
        const list = this.preRenderButtons(params);
        return list.map((e: any, i) => { // any because I cannot properly work with the union type with disjoint fields
            if (!e) { return null; }
            if (e.element) {
                return e.element;
            }
            if (!e.props.key) {
                e.props.key = i;
            }
            return React.createElement(e.elementType, e.props);
        });
    }

}