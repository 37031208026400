import React, { ReactNode } from "react";
import { Icon, Label } from "semantic-ui-react";
import { ChartCurrentSelectionMeta } from "./ChartCurrentSelectionMeta";
import { MessageExt } from "../semanticUiReactExt";

const { testids } = ChartCurrentSelectionMeta;

type ChartCurrentSelectionProps = {
    date?: string,
    contentHeader?: ReactNode,
    contentMain?: ReactNode,
    currentPointDataCyAttribute?: string,
    contentHeaderRight?: ReactNode,
}

export class ChartCurrentSelection extends React.Component<ChartCurrentSelectionProps> {

    render() {
        return <>
            <MessageExt>
                {this.props.contentHeader}
                <span style={{ float: "left", margin: "0.3em 0.5em" }}>{_msg("ChartCurrentSelection.currentPoint")}: <Label data-testid={testids.date} basic data-cy={this.props.currentPointDataCyAttribute}><Icon name='calendar alternate outline' /> {this.props.date}</Label></span>
                <span className="float-right small-margin-right instead">{this.props.contentHeaderRight}</span>
            </MessageExt>
            {this.props.contentMain}
        </>;
    }
}