import { createSliceFoundation, EntityDescriptor, EntityTablePage, EntityTablePageProps, getBaseImpures, getBaseReducers, SliceEntityTablePage, sliceEntityTablePageOnlyForExtension, Utils } from "@crispico/foundation-react";
import { TabRouterPane } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { fieldEditors, fieldRenderers } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FieldType, fieldTypeToPrimitiveFieldTypeMapping } from "@crispico/foundation-react/entity_crud/FieldType";
import { AggregateFunctionInput, AggregateFunctionType } from "apollo-gen/globalTypes";
import { GanttTasksRRC } from "pages/gantt/GanttTasks";
import { FlightDepartureFieldEditor } from "./FlightDepartureFieldEditor";
import { FlightDepartureFieldRenderer } from "./FlightDepartureFieldRenderer";
import { FlightsAssignmentsPageRRC } from "./flightsAssignmentsPage/FlightsAssignmentsPage";

export const sliceEntityTablePageFlight = createSliceFoundation(class Ext extends SliceEntityTablePage {

    nestedSlices = {
        ...sliceEntityTablePageOnlyForExtension.nestedSlices,
    }

    initialState = {
        ...sliceEntityTablePageOnlyForExtension.initialState,
    }

    reducers = {
        ...sliceEntityTablePageOnlyForExtension.reducers, ...getBaseReducers<Ext>(this)
    }

    impures = {
        ...sliceEntityTablePageOnlyForExtension.impures, ...getBaseImpures<Ext>(this),
        getAggregateFunctions(): AggregateFunctionInput[] | null {
            return [{ field: "baggages.id", type: AggregateFunctionType.COUNT }];
        }
    }
});

export class FlightEntityDescriptor extends EntityDescriptor {

    protected customize() {
        this.addFieldDescriptor({ name: "date", type: FieldType.date, format: Utils.dateTimeWithSecFormat, propsForEditor: { hasTime: true, formatString: Utils.dateTimeFormat } })
            .addFieldDescriptor({ name: "initialDate", type: FieldType.date, format: Utils.dateTimeWithSecFormat, propsForEditor: { hasTime: true, formatString: Utils.dateTimeFormat } })
            //.addTabDescriptor({ oneToManyEntityName: "Task", oneToManyOppositeField: "taskGroup" })
            .addFieldDescriptor({ name: "name", type: FieldType.string, enabled: false })
            .addFieldDescriptor({ name: "aggFunc_count_baggages_id", type: FieldType.number, isAggregateField: true, filterable: false, sortable: false, isInDefaultColumnConfigForEditor: false })
            .addFieldDescriptor({ name: "departure", type: "departure" })
            .isInDefaultColumnConfig(true, "organization", "unit", "name", "airline", "number", "date", "initialDate", "airport", "parking", "showFlightInGantt", "origin", "destination", "departure", "planeIdentifier", "comment", "canceled", "planeType")
            .addTabDescriptor({ oneToManyEntityName: "Baggage", oneToManyOppositeField: "flight" })

        sliceEntityTablePageFlight.setEntityDescriptor(this);
        this.infoTable.slice = sliceEntityTablePageFlight;
        this.infoTable.wrappedComponentClass = class extends EntityTablePage<EntityTablePageProps> {

            protected getExtraTabPanes(): (TabRouterPane | null)[] {
                const that = this;
                let extraTabPanes = [...super.getExtraTabPanes(),
                {
                    routeProps: { path: "/flightsAssignments" },
                    menuItemProps: { icon: "plane", content: _msg("FlightsAssignmentsPage.title") },
                    render: () => <FlightsAssignmentsPageRRC id='flightsAssignments' />
                },
                {
                    routeProps: { path: "/gantt" },
                    menuItemProps: { content: _msg("Gantt.title") },
                    render: () => <GanttTasksRRC id="ganttPage" />
                }
                ];
                return extraTabPanes;
            }
        }
    }
}

fieldEditors["departure"] = FlightDepartureFieldEditor;
fieldRenderers["departure"] = FlightDepartureFieldRenderer;
fieldTypeToPrimitiveFieldTypeMapping["departure"] = FieldType.boolean;
