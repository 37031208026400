import arrayMove from 'array-move';
import _ from 'lodash';
import React from 'react';
import Select, { ActionMeta, components, KeyboardEventHandler, MenuProps, OptionTypeBase, Props as SelectProps, Styles, ValueType } from 'react-select';
import { SortableContainer, SortableContainerProps, SortableElement, SortEnd } from 'react-sortable-hoc';

export interface SelectExtOption {
    value: any,
    label: any,
    [key: string]: any
}

const defaultStyles: Partial<Styles> = {
    multiValueRemove: (provided) => {
        return { ...provided, borderRadius: '0px', paddingTop: '2px', backgroundColor: '#dddddd' }
    },
    multiValueLabel: (provided) => {
        return { ...provided, fontSize: '14px', color: 'var(--textColor)', fontWeight: 700, padding: '3px 4px', cursor: 'grab' }
    },
    multiValue: (provided) => {
        return { ...provided, backgroundColor: 'var(--background)', border: '1px solid #c4c4c4', borderRadius: '3px', zIndex: 1000 }
    },
    singleValue: (provided) => {
        return { ...provided, color: 'var(--textColor)' }
    },
    control: base => ({
        /* color to match form */
        ...base,
        borderColor: 'rgba(34,36,38,.15)',

        '&:hover': {
            borderColor: 'rgba(34,36,38,.15)'
        }
    }),
    menuPortal: base => ({
        ...base,
        zIndex: 1000,
    }),
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: 'var(--background)'
    })
};

export type SelectExtProps = SelectProps<any> & SortableContainerProps & {
    options: SelectExtOption[],
    onSelectedOptionsChange?: (selectedOptions: SelectExtOption[]) => void,
    hideMenu?: boolean,
    appendDoneMenuEntry?: boolean,
    closeOnKeys?: string[],
    customMenu?: (p: SelectExtMenuProps) => (props: MenuProps<any>) => JSX.Element,
    header?: string
};

// it is used only in FieldSelectorWithCrudViewer for a function signature that should be returned by another function
// should be used when a custom menu is needed (see FieldSelectorWithCrudViewer.tsx)
export type SelectExtMenuProps = {
    appendDoneMenuEntry?: boolean,
    onDoneMenuEntryClick?: () => void
};

export class SelectExt extends React.Component<SelectExtProps, { selectedOptions: SelectExtOption[] | undefined, opened: boolean }> {

    public static defaultProps = {
        isMulti: true,
        menuPosition: "absolute"
    };

    DONE_OPTION = { value: "DONE_OPTION", label: "" } as SelectExtOption;

    constructor(props: SelectExtProps) {
        super(props);
        this.onSortEnd = this.onSortEnd.bind(this);
        this.onChange = this.onChange.bind(this);
        this.getSelectedValues = this.getSelectedValues.bind(this);
        this.onDoneMenuEntryClick = this.onDoneMenuEntryClick.bind(this);
        this.state = { selectedOptions: props.defaultValue, opened: false };
        this.DONE_OPTION = { value: "DONE_OPTION", label: _msg("SelectExt.done") };
    }

    componentDidUpdate(prevProps: SelectExtProps) {
        if (prevProps && (!_.isEqual(prevProps.options, this.props.options) ||
            (prevProps.defaultValue && this.props.defaultValue && prevProps.defaultValue.length !== this.props.defaultValue.length))) {
            this.setState({ selectedOptions: this.props.defaultValue });
        }
    }

    protected onSortEnd(sort: SortEnd) {
        if (this.state.selectedOptions) {
            const selectedOptions = arrayMove(this.state.selectedOptions, sort.oldIndex, sort.newIndex);
            this.setState({ selectedOptions: selectedOptions });
            if (this.props.onSelectedOptionsChange) {
                this.props.onSelectedOptionsChange(selectedOptions);
            }
        }
    }

    protected onChange(selectedOptions: ValueType<OptionTypeBase>) {
        const selected = (selectedOptions ? this.props.isMulti ? selectedOptions : [selectedOptions] : []) as SelectExtOption[];
        if (selected.includes(this.DONE_OPTION)) {
            this.onDoneMenuEntryClick();
            return;
        }
        this.setState({ selectedOptions: selected });
        if (this.props.onSelectedOptionsChange) {
            this.props.onSelectedOptionsChange(selected);
        }
    }

    protected onDoneMenuEntryClick() {
        this.setState({ opened: false });
    }

    getSelectedValues() {
        return this.state.selectedOptions;
    }

    modifyOptions(appendDoneMenuEntry?: boolean, header?: string) {
        let options = this.props.options;
        if (appendDoneMenuEntry) {
            options = [this.DONE_OPTION].concat(this.props.options)
        }

        if (header) {
            return [{
                label: header,
                options: options
            }]
        }

        return options;
    }

    render() {
        let components: any = { MultiValue: SortableMultiValue as any };
        const appendDoneMenuEntry = this.state.selectedOptions && this.state.selectedOptions.length > 0 && this.props.appendDoneMenuEntry;
        if (this.props.customMenu) {
            components = { ...components, Menu: this.props.customMenu({ appendDoneMenuEntry: appendDoneMenuEntry, onDoneMenuEntryClick: this.onDoneMenuEntryClick }) };
        }
        if (this.props.hideMenu) {
            components = { ...components, Menu: () => (<></>), IndicatorsContainer: () => (<></>) };
        }
        let { selected, options, ...newProps } = this.props;
        let selectProps: SelectProps<any> & SortableContainerProps = newProps;
        return (
            <SortableSelect
                className="wh100"
                axis="xy"
                onSortEnd={this.onSortEnd}
                distance={4}
                getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                options={this.modifyOptions(appendDoneMenuEntry, this.props.header)}
                value={this.state.selectedOptions}
                components={components}
                menuIsOpen={this.state.opened} menuPlacement={"auto"}
                onFocus={() => this.setState({ opened: true })}
                closeMenuOnSelect={false}
                styles={defaultStyles}
                placeholder={_msg("SelectExt.placeholder")}
                onKeyDown={((event: React.KeyboardEvent<HTMLElement>) => {
                    this.props.closeOnKeys?.forEach(key => {
                        if (key === event.key) {
                            // this disables default functionality of the key which was pressed e.g. if we should press
                            // x to close the menu, we will not be able to write character x to search for it
                            event.preventDefault();
                            this.setState({ opened: false });
                        }
                    });
                }) as KeyboardEventHandler}
                {...selectProps}
                // The following attributes shouldn't override by the others from selectProps
                onMenuOpen={() => {
                    this.setState({ opened: true });
                    if (this.props.onMenuOpen) {
                        this.props.onMenuOpen();
                    }
                }}
                onChange={(value: ValueType<OptionTypeBase>, action: ActionMeta<any>) => {
                    this.onChange(value);
                    if (this.props.onChange) {
                        this.props.onChange(value, action);
                    }
                }}
                onMenuClose={() => {
                    this.setState({ opened: false });
                    if (this.props.onMenuClose) {
                        this.props.onMenuClose();
                    }
                }}
            />
        );
    }
}

const SortableMultiValue = SortableElement((props: any) => {
    // this prevents the menu from being opened/closed when the user clicks
    // on a value to begin dragging it. ideally, detecting a click (instead of
    // a drag) would still focus the control and toggle the menu, but that
    // requires some magic with refs that are out of scope for this example
    const onMouseDown = (e: { preventDefault: () => void; stopPropagation: () => void; }) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const innerProps = { onMouseDown };
    return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableSelect = SortableContainer(Select);