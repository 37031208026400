import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import Slider from "antd/lib/slider"
import moment from "moment";
import React from "react"
import { Button, Icon, Input, Label } from "semantic-ui-react";

export class AnimationState extends State {
    animationStep: number = 30 * 1000;
    animationInProgress: boolean = false;
    settingsModal: false | [number, number] = false;
}

export class AnimationReducers<S extends AnimationState = AnimationState> extends Reducers<S> { }

type PropsNotFromState = {
    startTime?: number,
    endTime?: number,
    padding?: string,
    currentTime?: number,
    onChange?: (reset: boolean, currentTime?: number) => void,
    onResetBtnClick?: () => void
};

type Props = RRCProps<AnimationState, AnimationReducers> & PropsNotFromState;

export class Animation extends React.Component<Props> {
    protected sliderRef = React.createRef<any>();
    protected animationTimer: number | undefined = undefined;

    constructor(props: Props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onAnimationBtnClick = this.onAnimationBtnClick.bind(this);
        this.onResetBtnClick = this.onResetBtnClick.bind(this);
    }

    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: Props) {
        this.componentDidUpdateInternal(prevProps);
    }

    private componentDidUpdateInternal(prevProps?: Props) {
        const props = this.props;
        if (!prevProps || prevProps.currentTime !== props.currentTime) {
            this.sliderRef.current?.setState({ value: props.currentTime });
        }
        if (!prevProps || (prevProps.startTime !== props.startTime || prevProps.endTime !== props.endTime)) {
            this.stopAnimationTimer();
            this.sliderRef.current?.setState({ bounds: [props.startTime, props.endTime] });
            const previousValue = this.sliderRef.current?.state.value;
            let reset = false;
            let value = previousValue;
            if (props.startTime == undefined || props.endTime == undefined || props.startTime > previousValue || props.endTime < previousValue) {
                this.sliderRef.current?.setState({ value: props.startTime });
                reset = true;
                value = props.startTime;
            }
            this.onChange(reset, value);
        }
        if (!prevProps || prevProps.s.animationInProgress !== props.s.animationInProgress) {
            if (this.props.s.animationInProgress) {
                this.startAnimationTimer();
            } else {
                this.stopAnimationTimer();
            }
        }
    }

    protected onAnimationBtnClick() {
        this.props.r.setInReduxState({ animationInProgress: !this.props.s.animationInProgress });
    }

    protected onResetBtnClick() {
        const props = this.props;
        this.onChange(false, undefined);
        props.r.setInReduxState({ animationInProgress: false });
        this.sliderRef.current?.setState({ value: props.startTime, bounds: [props.startTime, props.endTime] });
        this.props.onResetBtnClick && this.props.onResetBtnClick();
    }

    protected startAnimationTimer() {
        this.animationTimer = window.setTimeout(() => {
            let newTime;
            if (this.props.currentTime === undefined || this.props.currentTime === this.props.endTime) {
                newTime = this.props.startTime;
            } else {
                newTime = Math.min(this.props.currentTime! + this.props.s.animationStep!, this.props.endTime!);
            }
            this.onChange(false, newTime);
            this.startAnimationTimer();
        });
    }

    private stopAnimationTimer() {
        this.props.r.setInReduxState({ animationInProgress: false });
        clearTimeout(this.animationTimer);
    }

    protected onChange(reset: boolean, value: any) {
        this.props.onChange && this.props.onChange(reset, value);
    }

    render() {
        const props = this.props;
        return <div className="flex-container-row flex-center" style={{ width: "100%", padding: props.padding }}>
            <Button size='mini' icon color={props.s.animationInProgress ? "yellow" : "green"} onClick={this.onAnimationBtnClick}>
                <Icon name={props.s.animationInProgress ? "pause" : "play"} />
            </Button>
            <Button size='mini' icon color="orange" onClick={this.onResetBtnClick}>
                <Icon name="remove circle" />
            </Button>
            <Button size='mini' icon style={{ marginRight: 7 }} onClick={(e) => props.r.setInReduxState({ settingsModal: [e.clientX, e.clientY]})}>
                <Icon name="setting" />
            </Button>
            <ModalExt open={props.s.settingsModal} style={{ width: 380 }} onClose={() => props.r.setInReduxState({ settingsModal: false })}>
                <div className="less-padding">
                    <Label>{_msg("AuditGraphAnimationSlider.animationSpeed.label")}</Label>
                    <Input className="small-margin-left" size="mini" type="number" value={this.props.s.animationStep / 1000}
                        onChange={(e, data) => this.props.r.setInReduxState({animationStep: Number(data.value) * 1000})} />
                </div>
            </ModalExt>
            <Slider ref={this.sliderRef} style={{ width: "100%" }}
                trackStyle={{ backgroundColor: "#21ba45" }} handleStyle={{ borderColor: "#21ba45" }} // copied from semantic-ui -> "green" 
                step={1000} min={props.startTime} max={props.endTime}
                value={props.currentTime} tooltipVisible={props.s.animationInProgress}
                onChange={(value) => this.onChange(false, value)} tipFormatter={value => moment(value).format(Utils.dateFormatShorter + " " + Utils.timeWithSecFormat)}
            />
        </div>;
    }
}

export const AnimationRRC = ReduxReusableComponents.connectRRC(AnimationState, AnimationReducers, Animation);
