import { addEntityDescriptor, apolloClient, BigState, createSliceFoundation, EntityDescriptor, EntityTablePage, EntityTablePageProps, getBaseImpures, getBaseReducers, Optional, PropsFrom, SliceEntityTablePage, sliceEntityTablePageOnlyForExtension } from "@crispico/foundation-react";
import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { AirportForMap } from "apollo-gen/AirportForMap";
import { DrivingEventForMap } from "apollo-gen/DrivingEventForMap";
import { loadAirportsForMap, loadAirportsForMapVariables } from "apollo-gen/loadAirportsForMap";
import { loadDrivingEventsForMap, loadDrivingEventsForMapVariables } from "apollo-gen/loadDrivingEventsForMap";
import { InitializationsForClient, MapSettings } from "app";
import { HeatData, HEAT_TYPE, MapContainerLeafletRRC, MapContainerLeaflet } from "components/MapContainerLeaflet/MapContainerLeaflet";
import { DEFAULT_ZOOM_LEVEL } from "components/MapContainerLeaflet/MapLayerHelpers";
import { LOAD_AIRPORTS_FOR_MAP } from "components/realTimeMap/queries";
import { LOAD_DRIVING_EVENTS_FOR_MAP } from "pages/DrivingEvent/queries";
import React from "react";
import { Dropdown, DropdownProps, Dimmer, Loader, Popup, Button, Icon } from "semantic-ui-react";
import lodash from "lodash";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { OverrideableElement } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { airportEntityDescriptor } from "pages/EquipmentResource/equipmentResourceEntityDescriptor";
import { VideoFieldRenderer } from "./VideoFieldRenderer";
import { RealTimeUtils } from "components/realTimeMap/RealTimeUtils";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";

export const drivingEventEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
    name: "DrivingEvent",
    miniFields: ["type", "equipmentResource.identifier", "humanResource.identifier"],
    icon: "car",
    defaultFilter: Filter.createForClient("date", FilterOperators.forDate.thisWeek),
    defaultSort: { field: "date", direction: "DESC" }
})
    .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
    .addFieldDescriptor({ name: "type", type: FieldType.string })
    .addFieldDescriptor({ name: "value", type: FieldType.double })
    .addFieldDescriptor({ name: "equipmentResource", type: "EquipmentResource" })
    .addFieldDescriptor({ name: "humanResource", type: "HumanResource" })
    .addFieldDescriptor({ name: "date", type: FieldType.date })
    .addFieldDescriptor({ name: "longitude", type: FieldType.double })
    .addFieldDescriptor({ name: "latitude", type: FieldType.double })
    .addFieldDescriptor(new VideoFieldRenderer("video"))
);

export const sliceDrivingEventTablePage = drivingEventEntityDescriptor.infoTable.slice = createSliceFoundation(class Ext extends SliceEntityTablePage {

    nestedSlices = {
        ...sliceEntityTablePageOnlyForExtension.nestedSlices
    }

    initialState = {
        ...sliceEntityTablePageOnlyForExtension.initialState,
        drivingEvents: undefined as Optional<DrivingEventForMap[]>,
        airports: [] as AirportForMap[],
        isGoToAirportPopupOpen: false as boolean,
        showPointsOnMap: false as boolean
    }

    reducers = {
        ...sliceEntityTablePageOnlyForExtension.reducers, ...getBaseReducers<Ext>(this)
    }

    impures = {
        ...sliceEntityTablePageOnlyForExtension.impures, ...getBaseImpures<Ext>(this),

        async loadAirports() {
            const airports: Optional<AirportForMap[]> = (await apolloClient.query<loadAirportsForMap, loadAirportsForMapVariables>({
                query: LOAD_AIRPORTS_FOR_MAP,
                variables: FindByFilterParams.create().sorts([{ field: "code", direction: "ASC" }])
            })).data.airportService_findByFilter?.results as Optional<AirportForMap[]>;
            if (!airports || airports.length === 0) {
                return;
            }
            this.getDispatchers().setInReduxState({ airports: airports });
        },

        async loadDrivingEventsOnMap(mapContainer: Optional<MapContainerLeaflet>) {
            let filterFromCQ = this.getFilterForLoad();
            const events: Optional<DrivingEventForMap[]> = (await apolloClient.query<loadDrivingEventsForMap, loadDrivingEventsForMapVariables>({
                query: LOAD_DRIVING_EVENTS_FOR_MAP,
                variables: FindByFilterParams.create().filter(filterFromCQ)
            })).data.drivingEventService_findByFilter?.results;

            if (!events || events.length === 0) {
                this.getDispatchers().setInReduxState({ drivingEvents: [] });
                return;
            }

            this.getDispatchers().setInReduxState({ drivingEvents: events });
            this.addDrivingEventsOnMap(mapContainer);
        },

        addDrivingEventsOnMap(mapContainer: Optional<MapContainerLeaflet>) {
            let data: HeatData[] = [];
            Object.values(this.getState().drivingEvents!).forEach((event: DrivingEventForMap) => {
                if (event.longitude && event.latitude) {
                    data.push({ id: event.id, point: { longitude: event.longitude, latitude: event.latitude } });
                }
            });
            mapContainer?.addOrUpdateLayers(data, drivingEventEntityDescriptor.name);
        },

        selectAirport(id: number, mapContainer: MapContainerLeaflet) {
            const airport: Optional<AirportForMap> = this.getState().airports.find(a => a.id === id);

            if (airport?.latitude && airport?.longitude) {
                mapContainer.props.r.setInReduxState({ zoom: DEFAULT_ZOOM_LEVEL, center: [airport.latitude, airport.longitude] });
            }
        }
    }
}).setEntityDescriptor(drivingEventEntityDescriptor);

type PropsNotFromState = { mapSettings: MapSettings };
type Props = EntityTablePageProps & PropsFrom<typeof sliceDrivingEventTablePage> & PropsNotFromState;

drivingEventEntityDescriptor.infoTable.wrappedComponentClass = class extends EntityTablePage<Props> {

    mapContainerRef = React.createRef<MapContainerLeaflet>();

    constructor(props: Props) {
        super(props);

        this.props.dispatchers.loadAirports();
    }

    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdateInternal(prevProps?: Props) {
        super.componentDidUpdateInternal(prevProps);
        if (this.props.customQueryBar.customQuery?.customQueryDefinitionObject.filter !== prevProps?.customQueryBar.customQuery?.customQueryDefinitionObject.filter) {
            this.props.dispatchers.setInReduxState({ drivingEvents: undefined });
            this.props.dispatchers.tableSimple.setInReduxState({ selected: undefined });
            this.mapContainerRef.current?.clearMap();
            this.props.dispatchers.loadDrivingEventsOnMap(this.mapContainerRef.current);
        }

        // verify if [0, 0] -> default value; if not [0, 0], then it means the center was set by value from session storage, so we don't want to reset it
        if (lodash.isEqual(this.mapContainerRef?.current?.props.s.center, [0, 0]) && prevProps && prevProps.airports.length !== this.props.airports.length && this.props.mapSettings.airport !== null) {
            const airport = this.props.airports?.find(a => a.code === this.props.mapSettings.airport);
            this.props.dispatchers.selectAirport(airport?.id,  this.mapContainerRef.current!);
        }

        if (prevProps?.tableSimple.selected !== this.props.tableSimple.selected && this.props.tableSimple.selected !== this.mapContainerRef.current!.props.s.selectedLayer?.id) {
            this.mapContainerRef.current!.props.r.setInReduxState({ selectedLayer: { id: this.props.tableSimple.selected, type: drivingEventEntityDescriptor.name, additionalInfo: { flyToLayer: true } } });
        }
        
        if (this.mapContainerRef && this.props.drivingEvents && prevProps?.showPointsOnMap !== this.props.showPointsOnMap) {
            this.mapContainerRef.current?.clearMap();
            this.props.dispatchers.addDrivingEventsOnMap(this.mapContainerRef.current);
        }

        RealTimeUtils.selectAirportOnCurrentOrganizationToFilterByChange(prevProps, this.props, this.mapContainerRef.current!);
    }

    onSelectedLayerChanged() {
        this.props.dispatchers.tableSimple.setInReduxState({ selected: this.mapContainerRef.current?.props.s.selectedLayer?.id as number });
    }

    protected preRenderButtons(params: any): Array<OverrideableElement> {
        return [
            ...super.preRenderButtons(params),
            {
                element:
                    <div key="selectAirport" className="MapContainerHeader_segment">
                        <Dimmer inverted active={this.props.drivingEvents === undefined}></Dimmer>
                        <Button color={this.props.showPointsOnMap ? "orange" : "teal"} onClick={() => this.props.dispatchers.setInReduxState({ showPointsOnMap: !this.props.showPointsOnMap })}><Icon name='point' />{_msg(this.props.showPointsOnMap ? "DrivingEventTable.hidePoints" : "DrivingEventTable.showPoints")}</Button>
                        <Popup
                            open={this.props.isGoToAirportPopupOpen}
                            trigger={<Button color='olive' icon={airportEntityDescriptor.icon} content={_msg("MapRealTime.airport")} />}
                            onOpen={() => this.props.dispatchers.setInReduxState({ isGoToAirportPopupOpen: true })}
                            content={<Dropdown style={{ maxWidth: "250px", minWidth: "250px" }} data-cy={"dropdownMRT"} search selection
                                searchInput={{ autoFocus: true }}
                                options={this.props.airports.map((airport: AirportForMap) => ({ key: airport.id, value: airport.id, text: airport.code + " - " + airport.name }))}
                                onChange={(evt: any, props: DropdownProps) => {
                                    this.props.dispatchers.selectAirport(props.value as number, this.mapContainerRef.current!);
                                    this.props.dispatchers.setInReduxState({ isGoToAirportPopupOpen: false });
                                }} />}
                            on='click' position='bottom left'
                        />
                    </div>
            }
        ];
    };

    renderMain() {
        return (<>            
            <SplitPaneExt size="30%">
                {this.renderTableSimple()}
                <>
                    <Dimmer inverted active={this.props.drivingEvents === undefined}><Loader size='medium'>{_msg("general.loading")}</Loader></Dimmer>
                    <MapContainerLeafletRRC id={"mapContainerLeafletDrivingEventTable"} ref={this.mapContainerRef} mapId={"driving-event-map"} saveCenterZoomInStorage={true}
                        layers={{ [drivingEventEntityDescriptor.name]: { layerType: HEAT_TYPE, options: { hideTooltipOnHoveredLayer: true, showPoints: this.props.showPointsOnMap } } }} 
                        pruneClusterMode={false} bingAPIKey={this.props.mapSettings.bingAPIKey}  onSelectedLayerChanged={() => this.onSelectedLayerChanged()}/>
                </>
            </SplitPaneExt>
        </>)
    }
}

drivingEventEntityDescriptor.infoTable.mapBigStateToProps = (state: BigState, props: any) => {   
    props.mapSettings = (state.AppContainer.initializationsForClient as InitializationsForClient).mapSettings;
}