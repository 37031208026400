import { FilterOperators } from "@crispico/foundation-gwt-js";
import { BlocklyReadOnly } from "@crispico/foundation-react/blockly/BlocklyReadOnly";
import { BlocklyEditorTab, DEFAULT_COMPOSED_FILTER, sliceBlocklyEditorTab } from "@crispico/foundation-react/components/CustomQuery/BlocklyEditorTab";
import { createFilterBlock } from "@crispico/foundation-react/components/CustomQuery/createFilterBlock";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FilterPreview } from "@crispico/foundation-react/components/CustomQuery/FilterPreview";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { FieldEditorProps, fieldEditors, FieldRendererProps, fieldRenderers } from "../fieldRenderersEditors";
import { ConnectedComponentInSimpleComponent, ConnectedPageInfo } from "@crispico/foundation-react/reduxHelpers/ConnectedPageHelper";
import _ from "lodash";
import React from "react";
import { Button, Checkbox, Label, Modal, Popup } from "semantic-ui-react";
import { FieldType } from "../FieldType";

export enum EntityFilterMode {
    JSON, OBJECT
}

export class FilterFieldEditor extends React.Component<FieldEditorProps> {

    blocklyEditorTabRef = React.createRef<any>();
    protected ref = React.createRef<ConnectedComponentInSimpleComponent>();

    state = {
        editorOpened: false,
        entityName: undefined
    }

    componentDidUpdate(prevProps: FieldEditorProps) {
        if (prevProps.formikProps.values[this.props.fieldDescriptor.fieldForEntityName] !== this.props.formikProps.values[this.props.fieldDescriptor.fieldForEntityName]) {
            this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, undefined);
        }
    }

    closeEditor = () => this.setState({ editorOpened: false })

    openEditor = (entityName: string, filter: Filter) => {
        this.setState({ editorOpened: true });
        setTimeout(() => {
            this.ref.current?.getDispatchers<typeof sliceBlocklyEditorTab>().setInReduxState({ rootFilter: filter })
            this.ref.current?.getDispatchers<typeof sliceBlocklyEditorTab>().refreshWorkspace(this.blocklyEditorTabRef.current.workspace, filter, this.blocklyEditorTabRef, entityName);
        });
    }

    render() {
        const entityName: string = this.props.fieldDescriptor.entityDescriptor ? this.props.fieldDescriptor.entityDescriptor.name : this.props.formikProps.values[this.props.fieldDescriptor.fieldForEntityName];
        const mode: EntityFilterMode = this.props.fieldDescriptor.mode ? this.props.fieldDescriptor.mode : EntityFilterMode.JSON;
        let filter = DEFAULT_COMPOSED_FILTER;
        if (this.state.entityName !== entityName) {
            this.setState({ entityName: entityName });
        } else if (this.props.formikProps.values[this.props.fieldDescriptor.name]) {
            let f = this.props.formikProps.values[this.props.fieldDescriptor.name]!;
            if (mode === EntityFilterMode.JSON) {
                f = JSON.parse(f)!;
            }
            filter = Filter.enableAllFilters(f)!;
        }
        return (
            <>
                <div className='FilterBar_div'>
                    {filter.filters && filter.filters.length > 1 && <Label className="tiny-margin-right" size="small" content={filter.operator.toUpperCase()} color={filter.operator === FilterOperators.forComposedFilter.and.value ? "blue" : "teal"} />}
                    {filter?.filters?.map((f: Filter, index: number) => {
                        return (
                            <Label key={index} className={'OnlyFilterBar_filterDiv'}>
                                <Checkbox checked={f.enabled ? true : false} />
                                <BlocklyReadOnly key={index}>{createFilterBlock(f, { entityDescriptorName: entityName }, false, false, true)}</BlocklyReadOnly>
                            </Label>
                        );
                    })}
                    <Button type='button' compact onClick={() => this.openEditor(entityName, filter)}>...</Button>
                    {this.props.fieldDescriptor.showPreviewButton !== false ? <FilterPreview entityName={entityName} getFilter={() => filter} /> : null}
                    <ModalExt onClose={this.closeEditor} open={this.state.editorOpened} size='small' centered={false}>
                        <Modal.Header>{_msg('FilterFieldEditor.header')}</Modal.Header>
                        <Modal.Content scrolling>
                            <ConnectedComponentInSimpleComponent ref={this.ref} info={new ConnectedPageInfo(sliceBlocklyEditorTab, BlocklyEditorTab, "filterFieldEditor",
                                {
                                    closeEditor: this.closeEditor,
                                    onCancel: this.closeEditor,
                                    apply: (filter: Filter) => {
                                        const f = Filter.eliminateDisabledFilters(filter);
                                        this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, mode === EntityFilterMode.JSON ? JSON.stringify(f) : f);
                                        this.closeEditor();
                                    },
                                    entityDescriptor: entityName,
                                    ref: this.blocklyEditorTabRef,
                                    ownRef: this.blocklyEditorTabRef
                                })} />
                        </Modal.Content>
                    </ModalExt>
                </div>
            </>
        );
    }
}

fieldEditors[FieldType.filter] = FilterFieldEditor;

export const FilterFieldRenderer = (props: FieldRendererProps) => {
    const { value, entity, fieldDescriptor } = props;
    const entityName: string = props.fieldDescriptor.entityDescriptor ? props.fieldDescriptor.entityDescriptor.name : entity[fieldDescriptor.fieldForEntityName];
    let filter: Filter | undefined;
    if (fieldDescriptor.fieldForJson) {
        const f = JSON.parse(entity[fieldDescriptor.fieldForJson]);
        if (f) {
            filter = f;
        }
    } else if (value) {
        const f = JSON.parse(value);
        if (f) {
            filter = f;
        }
    }
    filter = Filter.enableAllFilters(filter);
    return <>
        {filter ? <span>
            <Popup flowing on='click' trigger={<Button compact content='...' />}>
                <div className='FilterBar_div'>
                    {filter.filters!.length > 1 && <Label compact content={filter.operator} color={filter.operator === FilterOperators.forComposedFilter.and.value ? "blue" : "teal"} />}
                    {filter.filters!.map((f: Filter, index: number) => 
                        <Label key={index} className={'OnlyFilterBar_filterDiv'}>
                            <Checkbox checked={f.enabled ? true : false} />
                            <BlocklyReadOnly key={index}>{createFilterBlock(f, { entityDescriptorName: entityName }, false, false, true)}</BlocklyReadOnly>
                        </Label>)}
                </div>
            </Popup>
            {props.fieldDescriptor.showPreviewButton !== false ? <FilterPreview entityName={entityName} getFilter={() => filter} /> : null}
        </span> : null}
    </>
}

fieldRenderers[FieldType.filter] = FilterFieldRenderer;
