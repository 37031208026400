import { ConnectedPageInfo, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, BigState } from "@crispico/foundation-react";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import React, { ReactNode } from "react";
import { Container, Header, Message, Divider } from "semantic-ui-react";
import { VisualStyleSettings, MenuEntry, HomePageSettings } from "../AppMeta";
import { Utils } from "../utils/Utils";
import { Optional } from "@crispico/foundation-react/CompMeta";
import { Messages } from "@crispico/foundation-gwt-js";
import { NodeElement } from "rc-tree/lib/interface";

export const sliceHomePage = createSliceFoundation(class SliceHomePage {
    initialState = {     
    }
    reducers = {
        ...getBaseReducers<SliceHomePage>(this),
    }
    impures = {
        ...getBaseImpures<SliceHomePage>(this),
    }
})

export type HomePageProps = PropsFrom<typeof sliceHomePage> & {
    version: string[2];
    visualStyleSettings: VisualStyleSettings;
    homePageSettings: HomePageSettings;
    menuEntries: {[key: string]: Optional<MenuEntry[]>};
};

export class HomePage<P = HomePageProps, S = {}> extends TabbedPage<P, S> {
    constructor(props:P) {
        super(props);
    }

    componentDidMount() {        
    }

    protected getTitle() {
        return { icon: "home", title: _msg("HomePage.title") };
    }

    protected renderUnderJumbotron(): ReactNode {
        return null;
    }

    protected renderFooterBar(): ReactNode {
        const footerBarText = this.castedProps.homePageSettings.footerBarText;
        if (footerBarText) {
            return <div className="footerBar">{footerBarText}</div>;
        }
        return null;
    }

    protected get castedProps(): HomePageProps {
        // "as unknown" needed because of 
        //"Conversion of type 'Readonly<P> & Readonly<{ children?: ReactNode; }>' to type 'Props' may be a mistake"
        // I don't know why in tabbebPage.propsCasted this problem doesn't appear
        return this.props as unknown as HomePageProps;
    }

    protected backgroundImageURL() {
        const props = this.castedProps;
        return props.visualStyleSettings.homeBackgroundImage && "url(" + Utils.adjustUrlToServerContext(props.visualStyleSettings.homeBackgroundImage) + ")";
    }

    protected renderMain() {
        const props = this.castedProps;
        return (<div className="HomePage" style={{ backgroundImage: this.backgroundImageURL() }}>
            <Container>
                <Message>
                    <div className="flex-container flex-center">
                        {props.visualStyleSettings.homeLogo2 ?
                            <div>
                                <img className="HomePage_clientLogo" src={Utils.adjustUrlToServerContext(props.visualStyleSettings.homeLogo2)} alt='homeLogo2' />
                                <Divider className="less-margin-top-bottom" />
                            </div>
                            : null}
                        <div>
                            <img className="HomePage_homeLogo" src={Utils.adjustUrlToServerContext(props.visualStyleSettings.homeLogo)} alt='homeLogo' />
                        </div>
                    </div>
                    <Divider className="less-margin-top-bottom mobile-hide" />
                    <Header as="h2" color="grey" className="mobile-hide">
                        <Header.Content>{Messages.getInstance().maybeTranslateByUser(props.visualStyleSettings.homeTitle)}</Header.Content>
                        <Header.Subheader>{_msg("HomePage.version", props.version[0], props.version[1])}</Header.Subheader>
                    </Header>
                </Message>
                {this.renderUnderJumbotron()}
            </Container>
            {this.renderFooterBar()}
        </div>);
    }
}

export const infoHomePage = new ConnectedPageInfo(sliceHomePage, HomePage, "HomePage");
infoHomePage.routeProps = { path: "/", exact: true, homePageType: "normal" };
infoHomePage.mapBigStateToProps = (state: BigState, props: HomePageProps) => {
    props.version = state.AppContainer.initializationsForClient.version;
    props.visualStyleSettings = state.AppContainer.initializationsForClient.visualStyleSettings;
    props.homePageSettings = state.AppContainer.initializationsForClient.homePageSettings;
    props.menuEntries = state.AppContainer.menuEntries;
}
