import gql from "graphql-tag";

export const GET_PLATE_NUMBERS_FROM_IDS = gql`
    query getPlateNumberFromIds($params: FindByFilterParamsInput) { 
        equipmentResourceService_findByFilter(params: $params) {
            results { id plateNumber }
        }
    }
`;

export const POSITION_FOR_HISTORY_GRAPH_MAP = gql`
    fragment PositionForHistoryGraphMap on Position {
        id latitude longitude date plateNumber speed gpsProvider telemetry
    }
`;

export const LOAD_POSITIONS_FOR_HISTORY_GRAPH_MAP = gql`
query loadPositionsForHistoryGraphMap($params: FindByFilterParamsInput) {
    positionService_findByFilter(params: $params) {
        results {...PositionForHistoryGraphMap}
    }  
}
${POSITION_FOR_HISTORY_GRAPH_MAP}
`;
