import { TestUtils, Utils } from "@crispico/foundation-react";
import { Reducers, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { DatePicker } from "antd";
import _, { bind } from "lodash";
import moment from "moment";
import React from "react";
import { Modal, Segment } from "semantic-ui-react";
import ReactDOM from "react-dom";
import { ModalExt, ModalExtOpen } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { Group, Item, RowLayer, Timeline, ItemRenderer, Column } from "@crispico/react-timeline-10000";

export interface GanttGroup extends Group {
    [key: string]: any
}
export interface GanttData {
    layers: RowLayer[],
    groups: GanttGroup[],
    items: Item[]
}

export class AbstractGanttState extends State {
    start: number = moment(Utils.now()).startOf('day').valueOf();
    end: number = moment(Utils.now()).endOf('day').valueOf();
    data: GanttData = { layers: [], groups: [], items: [] };
    isModalOpen: ModalExtOpen = false;
    modalContent: string | undefined;
}

export class AbstractGanttReducers<S extends AbstractGanttState = AbstractGanttState> extends Reducers<S> {
    setStartDate(start: number) {
        this.s.start = start;
    }
    setEndDate(end: number) {
        this.s.end = end;
    }
}

export type AbstractGanttProps = RRCProps<AbstractGanttState, AbstractGanttReducers> & {
    /**
     * Keeps the data in denormalization mode.
     * Shouldn't be enriched with other fields!
     */
    entities?: { [key: string]: { [key: number]: any } },
    hideDatePicker?: boolean,
    hideTopBar?: boolean,
    /**
     * Used to store the HR/ER that shouldn't be displayed 
     * (GanttResources will hide the lines, GanttTasks will calculate if task has mission based on this)
     * This should be replaced with a more general mechanism in the future!
     */
    hideResources?: { [key: string]: number[] },
    /**
     * If set, the topBar component will be displayed on this portal container.
     */
    portalContainerForTopBar?: any
};

export abstract class AbstractGantt extends React.Component<AbstractGanttProps> {

    constructor(props: AbstractGanttProps) {
        super(props);
        this.handleItemClick = this.handleItemClick.bind(this);
        this.showModal = this.showModal.bind(this);
    }

    changeStartEnd(start: number, end: number) {
        this.props.r.setStartDate(start);
        this.props.r.setEndDate(end);
    }

    showModal(x: any, y: any, content?: string) {
        this.props.r.setInReduxState({ isModalOpen: [x, y] });
        this.props.r.setInReduxState({ modalContent: content })
    }

    static findOne(entityName: string, field: string, value: any, entities: any): any {
        return this.find(entityName, field, value, entities)?.[0] || undefined;
    }

    static find(entityName: string, field: string, value: any, entities: any): any[] {
        if (entities) {
            const map = entities[entityName];
            if (map) {
                if (field === "id") { // shortcut to get it faster
                    return [map[value]];
                }
                return Object.keys(map).filter(key => {
                    const entity = map[Number(key)];                  
                    return Utils.navigate(entity, field, false, ".") === value;
                }).map(key => map[Number(key)]);
            } else {
                // for debug purposes
                // console.log("entities doesn't contain entityName = " + entityName);
            }
        }
        return [];
    }

    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: AbstractGanttProps) {
        this.componentDidUpdateInternal(prevProps);
    }

    protected entitiesChangedHandler() {
        // nop
    }

    protected startEndChangedHandler() {
        // nop
    }

    protected flightInfo(flight: any, showDate: boolean = true, showType: boolean = true) {
        if (!flight) {
            return undefined;
        }
        const planeType = AbstractGantt.findOne("PlaneType", "id", flight?.planeType?.id, this.props.entities);
        return (showType ? (flight?.departure ? "\u2191" : "\u2193") : "") +
            (flight?.airline || "") + (flight?.number || "") + " " +
            (flight?.planeIdentifier || "") + " " +
            (planeType?.name || "") + " " +
            (flight?.schengen ? "(D)" : "(I)") + " " +
            (showDate ? (flight?.date ? moment(flight?.date).format(Utils.timeFormat) : "") : "");
    }

    protected getHrName(hr: any) {
        return (hr?.identifier?.concat(" ") || "") + (hr?.firstName?.concat(" ") || "") + (hr?.lastName?.concat(" ") || "");
    }

    protected getHrAndEtFromMission(mission: any) {
        const humanResource = AbstractGantt.findOne("HumanResource", "id", mission?.humanResource?.id, this.props.entities);
        const equipmentResource = AbstractGantt.findOne("EquipmentResource", "id", mission?.equipmentResource?.id, this.props.entities);
        const equipmentType = AbstractGantt.findOne("EquipmentType", "id", mission?.equipmentType?.id, this.props.entities);
        return this.getHrName(humanResource) + (equipmentResource?.identifier?.concat(" ") || "") + (equipmentType?.name || "");
    }

    protected getFlightTooltip(flight: any) {
        const rotationFlight = AbstractGantt.findOne("Flight", "id", flight?.rotationFlight?.id, this.props.entities);
        const arvFlight = flight?.departure ? rotationFlight : flight;
        const depFlight = flight?.departure ? flight : rotationFlight;
        const arvTasks = AbstractGantt.find("Task", "taskGroup.id", arvFlight?.id, this.props.entities);
        const depTasks = AbstractGantt.find("Task", "taskGroup.id", depFlight?.id, this.props.entities);
        const arvInfo = this.flightInfo(arvFlight);
        const depInfo = this.flightInfo(depFlight);
        return (arvInfo || "") + ((arvInfo && depInfo) ? "/" : "") + (depInfo || "") + "\n" +
            arvTasks.map(task => { return this.getTaskTooltip(task, false) }).join("\n") + "\n" +
            depTasks.map(task => { return this.getTaskTooltip(task, false) }).join("\n");

    }

    protected getTaskTooltip(task: any, showFlight: boolean = true, showResource: boolean = true) {
        if (!task)
            return;
        const flight = AbstractGantt.findOne("Flight", "id", task.taskGroup?.id, this.props.entities);
        const mission = AbstractGantt.findOne("Mission2", "id", AbstractGantt.findOne("ObjectActionGroup", "object.id", task.id, this.props.entities)?.mission?.id, this.props.entities);
        const taskType = AbstractGantt.findOne("TaskType", "id", task.taskType?.id, this.props.entities);
        return (task.startTime ? moment(task.startTime).format(Utils.timeFormat).concat("-") : "") +
            (task.endTime ? moment(task.endTime).format(Utils.timeFormat).concat(" ") : "") +
            (showFlight ? (this.flightInfo(flight, false, false) || "") : "") +
            (taskType?.name?.concat(" ") || "") +
            (task?.requiredEquipmentResourceQualificationType?.concat(" ") || "") +
            (task?.equipmentResourceFillPercentage?.toString().concat(" ") || "") +            
            (showResource ? this.getHrAndEtFromMission(mission) : "");
    }

    protected getHrTooltip(hr: any) {
        if (!hr) return "";
        const qualificationTypes = AbstractGantt.find("Qualification", "humanResource.id", hr.id, this.props.entities).map(qualification => {
            return AbstractGantt.findOne("QualificationType", "id", qualification.qualificationType?.id, this.props.entities)
        });
        return this.getHrName(hr) + "\n" +
            (hr.schedules && Object.keys(hr.schedules).map(key => hr.schedules[key]).reduce((previousValue, currentData) => previousValue + (previousValue != "" ? ", " : "") + moment(currentData.startTime).format(Utils.timeFormat) + "-" + moment(currentData.endTime).format(Utils.timeFormat), "")) +
            "\n" + qualificationTypes.map(q => q.name).sort().reduce((previousValue, currentValue) => previousValue + (previousValue != "" ? ", " : "") + currentValue, "");
    }

    protected getMissionTooltip(mission: any) {
        if (!mission)
            return "";
        let tooltip = moment(mission.startTime).format(Utils.timeFormat) + "-" + moment(mission.endTime).format(Utils.timeFormat) + "\n";
        if (mission.inactivityType) {
            tooltip += AbstractGantt.findOne("InactivityType", "id", mission.inactivityType.id, this.props.entities)?.name + "\n" || "";
        }
        tooltip += this.getHrAndEtFromMission(AbstractGantt.findOne("Mission2", "id", mission?.id, this.props.entities)) + "\n\n";
       
        mission.oags?.forEach((oag: any) => {
            const task = AbstractGantt.findOne("Task", "id", oag.object?.id, this.props.entities);
            tooltip += this.getTaskTooltip(task, true, false) + "\n";
        });
        return tooltip;
    }

    protected async componentDidUpdateInternal(prevProps?: AbstractGanttProps) {
        if (TestUtils.storybookMode) {
            return;
        }
        // CC: not sure if this works well in r-t mode; should be tested!
        if (!this.props.entities && (!prevProps || prevProps.s.start !== this.props.s.start || prevProps.s.end !== this.props.s.end)) {
            this.startEndChangedHandler();
        } else if (prevProps && (!_.isEqual(this.props.entities, prevProps.entities) || !_.isEqual(this.props.hideResources, prevProps.hideResources))) {
            this.entitiesChangedHandler();
        }
    }

    protected getTableColumns(): any {
        return [];
    }

    protected renderTopBar(): React.ReactNode {
        return <></>;
    }

    protected handleItemClick(e: any, key: any) {
        const { props } = this;
        this.showModal(e.clientX, e.clientY, props.s.data.items.find(item => item.key === key)?.tooltip);
    };
   
    render() {
        const { props } = this;
        return <div className="flex-container flex-grow less-padding">
            {this.props.portalContainerForTopBar
                ? ReactDOM.createPortal(this.renderTopBar(), this.props.portalContainerForTopBar)
                : !this.props.hideTopBar ? <Segment className="less-padding flex-container-row flex-center gap5">
                    {!this.props.hideDatePicker ? <DatePicker value={moment(this.props.s.start)} format={Utils.dateFormat}
                        onChange={d => {
                            let dateAsNumber: number = d?.valueOf() as number;
                            props.r.setInReduxState({ start: moment(dateAsNumber).startOf('day').valueOf(), end: moment(dateAsNumber).endOf('day').valueOf() });
                        }} /> : null}
                    {this.renderTopBar()}
                </Segment> : null}
            <Timeline
                useMoment={false}
                startDate={moment(props.s.start).add(-9, 'hours')}
                endDate={moment(props.s.end).add(9, 'hours')}
                groups={props.s.data.groups}
                items={props.s.data.items}
                onInteraction={() => { }}
                onItemClick={this.handleItemClick}
                showCursorTime={false}                
                rowLayers={props.s.data.layers}
                itemHeight={20}
                groupOffset={200} 
                groupRenderer={(groupProps: any) => {
                    const { group } = groupProps;
                    return <div key={group.id} className={"wh100"} onClick={(e: any) => this.showModal(e.clientX, e.clientY, group.tooltip)} >{group[groupProps.labelProperty]}</div>
                }}
                tableColumns={this.getTableColumns()}
            />
            <ModalExt size="tiny" style={{ whiteSpace: "pre-line" }}
                open={this.props.s.modalContent !== undefined && this.props.s.isModalOpen}
                onClose={() => { this.props.r.setInReduxState({ isModalOpen: false }) }}>
                <Modal.Content>
                    {this.props.s.modalContent}
                </Modal.Content>
            </ModalExt>
        </div>
    }
}