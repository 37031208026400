import { createSliceFoundation, getBaseImpures, getBaseReducers, Optional, PropsFrom } from "@crispico/foundation-react";
import { ID } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { MapSettings, MarkerSettings } from "app";
import { MapContainerLeafletRRC, MapContainerLeaflet, MarkerData, MARKER_TYPE } from "components/MapContainerLeaflet/MapContainerLeaflet";
import { DEFAULT_ZOOM_LEVEL } from "components/MapContainerLeaflet/MapLayerHelpers";
import { CLUSTER_MODE, MAP_CLUSTER_MODE_KEY } from "components/realTimeMap/RealTimeMap";
import { uniqueId } from "lodash";
import { equipmentResourceEntityDescriptor } from "pages/EquipmentResource/equipmentResourceEntityDescriptor";
import { EquipmentResourceUtils } from "pages/EquipmentResource/EquipmentResourceUtils";
import React from "react";

export const sliceMapWidget = createSliceFoundation(class SliceMapWidget {

    initialState = {
    }

    reducers = {
        ...getBaseReducers<SliceMapWidget>(this)
    }

    impures = {
        ...getBaseImpures<SliceMapWidget>(this)
    }
}, true);

type PropsNotFromState = {
    mapSettings: MapSettings,
    dashboardEntity: any,
    entity: any
};
export type Props = PropsFrom<typeof sliceMapWidget> & PropsNotFromState;
export class MapWidget extends React.Component<Props> {

    private mapContainerRef = React.createRef<MapContainerLeaflet>();

    constructor(props: Props) {
        super(props);
        this.renderMarkerIcon = this.renderMarkerIcon.bind(this);
    }

    componentDidMount() {        
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: Props) {
        this.componentDidUpdateInternal(prevProps);
    }

    componentDidUpdateInternal(prevProps?: Props) {
        const { props } = this;
        if (!prevProps || prevProps.entity?.[ID] !== props.entity?.[ID]) {
            const eq = props.entity;
            this.mapContainerRef.current?.clearMap();
            if (eq && eq.identifier && eq.lastPointLongitude && eq.lastPointLatitude) {               
                this.mapContainerRef.current?.props.r.setInReduxState({ zoom: DEFAULT_ZOOM_LEVEL, center: [eq.lastPointLatitude, eq.lastPointLongitude] });
               
                const markerSettings: Optional<MarkerSettings> = EquipmentResourceUtils.getEquipmentResourceMapSettings(props.mapSettings);
                let marker: MarkerData = EquipmentResourceUtils.getMarkerFromEquipmentResource(props.rootReducerForPages!, eq, markerSettings);
                this.mapContainerRef.current?.addOrUpdateLayers([marker], equipmentResourceEntityDescriptor.name);
            } else {
                this.mapContainerRef.current?.props.r.setInReduxState({ zoom: DEFAULT_ZOOM_LEVEL, center: [0, 0] });               
            }
        }
    }

    renderMarkerIcon(markerData: MarkerData, type: string): React.ReactNode {
        const markerSettings: Optional<MarkerSettings> = EquipmentResourceUtils.getEquipmentResourceMapSettings(this.props.mapSettings);
        return EquipmentResourceUtils.renderEquipmentResourceIcon(this.props.entity, markerData, markerSettings);
    }

    render() {
        return <div className="no-padding-margin flex-container flex-grow-shrink-no-overflow MapRealTime_topParent" >
            <MapContainerLeafletRRC id={uniqueId("mapContainerLeaflet-")} ref={this.mapContainerRef}
                pruneClusterMode={localStorage.getItem(MAP_CLUSTER_MODE_KEY) === CLUSTER_MODE.PRUNE_CLUSTER}
                renderMarkerIcon={this.renderMarkerIcon}
                bingAPIKey={this.props.mapSettings.bingAPIKey} enableGestureHandling
                mapId={this.props.dashboardEntity ? String(this.props.dashboardEntity.id) : "map-widget"}
                layers={{ [equipmentResourceEntityDescriptor.name]: { layerType: MARKER_TYPE, options: { hideTooltipOnHoveredLayer: true, flyToSelectedMarker: false } } }} />
        </div>
    }
}