import { apolloClient, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, Utils } from "@crispico/foundation-react";
import { CustomQueryBar, CUSTOM_QUERY_BAR_MODE, sliceCustomQueryBar } from "@crispico/foundation-react/components/CustomQuery/CustomQueryBar";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import React from "react";
import { ReactNode } from "react";
import { Icon, Label, Loader, Segment } from "semantic-ui-react";
import lodash from 'lodash';
import { CrudGlobalSettings } from "@crispico/foundation-react/entity_crud/CrudGlobalSettings";
import gql from "graphql-tag";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { AuditGraph, sliceAuditGraph } from "@crispico/foundation-react/pages/Audit/AuditGraph";
import moment from "moment";
import { Sort } from "@crispico/foundation-react/components/CustomQuery/SortBar";
import { ClientCustomQuery } from "@crispico/foundation-react/components/CustomQuery/ClientCustomQuery";
import { FilterOperators } from "@crispico/foundation-gwt-js";

export const sliceHistoryCompare = createSliceFoundation(class SliceHistoryCompare {
    initialState = {
        entities: [] as any,
        loading: false as boolean,
    }

    nestedSlices = {
        customQueryBar: sliceCustomQueryBar,
        auditGraph: sliceAuditGraph,
    }

    reducers = {
        ...getBaseReducers<SliceHistoryCompare>(this),
    }

    impures = {
        ...getBaseImpures<SliceHistoryCompare>(this),

        async loadEntitiesToCompare(entityName: string, filter: Filter, sorts: Sort[]) {
            this.getDispatchers().setInReduxState({ entities: [], loading: true });
            const fieldsToRequestStr = CrudGlobalSettings.INSTANCE.fieldId + " " + entityDescriptors[entityName].getGraphQlFieldsToRequest(entityDescriptors[entityName].miniFields);
            const loadOperationName = `${lodash.lowerFirst(entityDescriptors[entityName].name)}Service_findByFilter`;
            const loadQuery = gql(`query q($params: FindByFilterParamsInput) { 
                ${loadOperationName}(params: $params) {
                    results { ${fieldsToRequestStr} } totalCount
                }
            }`);

            const entities: any[] = (await apolloClient.query({
                query: loadQuery,
                variables: FindByFilterParams.create().filter(Filter.eliminateDisabledFilters(filter)).sorts(sorts),
                context: { showSpinner: false }
            })).data[loadOperationName]?.results;

            this.getDispatchers().setInReduxState({ entities: entities, loading: false });
        }
    }
})

export type Props = PropsFrom<typeof sliceHistoryCompare> & {
    entityName: string;
    startDate?: string;
    endDate?: string;
    filter?: Filter;
    sorts?: Sort[];
    entityFields?: string;
    additionalElements?: JSX.Element[];
};

export class HistoryCompare extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: Props) {
        this.componentDidUpdateInternal(prevProps);
    }

    componentDidUpdateInternal(prevProps?: Props) {
        if (!lodash.isEqual(prevProps?.filter, this.props.filter) || !lodash.isEqual(prevProps?.sorts, this.props.sorts)) {
            const newCustomQuery: ClientCustomQuery = {
                id: -2,
                color: "",
                name: _msg("HistoryCompare.cq.label"),
                // done like this for editor in order to don't save in session storage the CQ for entity type
                screen: this.props.__path?.includes("editor") ? this.props.entityName + "_editor" : this.props.entityName,
                enabled: true,
                dirty: false,
                fromCrudSettings: false,
                emailScheduleRecipientList: [],
                emailScheduleCron: "",
                organization: null,
                customQueryDefinitionObject: {
                    filter: !Utils.isNullOrEmpty(this.props.filter) ? Filter.enableAllFilters(this.props.filter)! : Filter.createComposedForClient(FilterOperators.forComposedFilter.and, []),
                    sorts: !Utils.isNullOrEmpty(this.props.sorts) ? this.props.sorts! : []
                }
            }
            this.props.dispatchers.customQueryBar.updateCustomQuery(newCustomQuery);
        }

        if (!lodash.isEqual(prevProps?.entityFields, this.props.entityFields)) {
            this.props.dispatchers.auditGraph.setInReduxState({ initialFields: this.props.entityFields, fields: this.props.entityFields?.split(',') });
        }
        if (this.props.startDate && this.props.endDate && (!lodash.isEqual(prevProps?.startDate, this.props.startDate) || !lodash.isEqual(prevProps?.endDate, this.props.endDate))) {
            this.props.dispatchers.auditGraph.setInReduxState({
                startDate: moment(this.props.startDate).toDate().getTime(), endDate: moment(this.props.endDate).toDate().getTime(),
                currentStartDate: moment(this.props.startDate).toDate().getTime(), currentEndDate: moment(this.props.endDate).toDate().getTime()
            });
        }

        if (prevProps && prevProps?.customQueryBar && !lodash.isEqual(prevProps?.customQueryBar.customQuery?.customQueryDefinitionObject, this.props.customQueryBar.customQuery?.customQueryDefinitionObject)) {
            this.props.dispatchers.loadEntitiesToCompare(this.props.entityName, this.props.customQueryBar.customQuery?.customQueryDefinitionObject.filter!, this.props.customQueryBar.sortBar.sorts);
        }
    }

    renderCustomQueryBar() {
        return <Segment className="less-padding HistoryCompareTab_segment MapRealTime_topBar" id="customQueryBar">
            <div style={{ width: "100%", display: "flex" }}>
                <div className="CustomQueryBar_div">
                    <CustomQueryBar key="customQueryBar"
                        entityDescriptor={entityDescriptors[this.props.entityName].name} screen={entityDescriptors[this.props.entityName].name} {...this.props.customQueryBar}
                        dispatchers={this.props.dispatchers.customQueryBar} mode={CUSTOM_QUERY_BAR_MODE.TABLE} />
                </div>
            </div>
            <Label basic color="blue">{_msg("entityCrud.table.loadedCount") + this.props.entities.length}</Label>
            {this.props.additionalElements}
        </Segment>
    }

    renderAuditGraph() {
        return <div>
            {this.props.loading ? <Loader active size="big">{_msg("general.loading")}</Loader> : null}
            {!this.props.loading && this.props.entities.length == 0 ?
                <Segment className="HistoryCompare_noData" size="big" inverted color="orange" compact ><Icon name="exclamation triangle" /> {_msg("general.no.data")}</Segment>
                : null}
            {this.props.entities.length > 0 ? <AuditGraph {...this.props.auditGraph} dispatchers={this.props.dispatchers.auditGraph} entities={this.props.entities} entityDescriptor={entityDescriptors[this.props.entityName]} showOnlyLabelColumnInCrudViewer /> : null}
        </div>
    }

    render(): ReactNode {
        return <div>
            {this.renderCustomQueryBar()}
            {this.renderAuditGraph()}
        </div>
            
    }
}
