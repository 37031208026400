import { apolloClient, ApolloContext, CatchedGraphQLError } from "@crispico/foundation-react/apolloClient";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { ConnectedPageInfo } from "@crispico/foundation-react/reduxHelpers";
import { push } from "connected-react-router";
import React from "react";
import { Button, Form, Grid, Header, Icon, InputOnChangeData, Message } from "semantic-ui-react";
import { HomePage, infoHomePage, sliceHomePage } from "../HomePage";
import { infoLoginPage } from "../loginPage/LoginPage";
import { REQUEST_PASSWORD_RESET } from "./queries";

const TWO_MANY_FAILED_LOGINS = "twoManyFailedLogins";

export class PasswordResetInitPage extends HomePage {
    // Because the server request takes some time (because of sending the mail)
    // we need to show a loading indication in case serverRequestInProgress
    state = { email: undefined, serverRequestInProgress: false };
    constructor(props: any) {
        super(props);
        this.resetClickHandler = this.resetClickHandler.bind(this);
        this.emailInputHandler = this.emailInputHandler.bind(this);
    }

    async resetClickHandler() {
        this.setState({ serverRequestInProgress: true });
        // Call the server method userService_requestPasswordReset
        // We need reset loading state after the response comes back from server
        // If we didn't threat the errors locally the execution of this method will stop at this point in case of errors
        // So the loading state will bever be reset 
        const { errors, data } = await apolloClient.mutate({
            context: {
                [ApolloContext.ON_ERROR_HANDLER]: (e: CatchedGraphQLError) => {
                    // if ("ValidationException" === apolloGetExtensionFromError(e, GraphQLErrorExtensions.EXCEPTION_SIMPLE_NAME)) {
                    //     // TODO by CS: aici vine codul care baga in state erorile
                    // } else {
                        this.setState({ serverRequestInProgress: false });
                        return true;
                    // }
                }
            },
            mutation: REQUEST_PASSWORD_RESET,
            variables: { email: this.state.email }
        });
        this.setState({ serverRequestInProgress: false });
         // Redirect to login page
         this.props.dispatchers.dispatch(push(infoLoginPage.routeProps!.path as string));

        // On success => display a success message that a reset password mail was sent to the given address
        AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.showGlobalAlert({ message: "A mail with a reset link was sent to you", severity: Severity.INFO });

    }

    emailInputHandler(event: any, data: InputOnChangeData) {
        this.setState({ email: data.value });
    }

    render() {
        return this.renderMain();
    }

    renderUnderJumbotron() {
        const twoManyFailedLogins: boolean = this.props.match?.params.cause === TWO_MANY_FAILED_LOGINS;
        return (
            <Grid textAlign='center' verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column style={{ maxWidth: 450 }}>
                        {twoManyFailedLogins ?
                            <Message attached="top" error>
                                <Header as="h3">
                                    <Icon name='ban' />
                                    <Header.Content>
                                        {_msg("PasswordResetInitPage.tooManyFailedLoginAttempts.header")}
                                        <Header.Subheader >{_msg("PasswordResetInitPage.tooManyFailedLoginAttempts.subheader")}</Header.Subheader>
                                    </Header.Content>
                                </Header>
                            </Message> : null}
                        <Form error className="segment attached">
                            <Message warning visible>
                                {_msg("PasswordResetInitPage.noEmail")}
                        </Message>
                            <Form.Input icon='mail' iconPosition='left' placeholder={_msg("PasswordResetInitPage.yourEmail")}
                                value={this.state.email} onChange={this.emailInputHandler} />
                            <Button loading={this.state.serverRequestInProgress} primary fluid size='large' onClick={this.resetClickHandler}>{_msg("PasswordResetInitPage.resetPasssword")}</Button>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
            </Grid>);
    }
}


const passwordResetInitBaseURL: string = "/passwordResetInit/"
export const passwordResetInitPageUrl = passwordResetInitBaseURL + "default";
export const passwordResetInitPageUrl_twoManyFailedLogins = passwordResetInitBaseURL + TWO_MANY_FAILED_LOGINS;

export const infoPasswordResetInitPage = new ConnectedPageInfo(sliceHomePage, PasswordResetInitPage, "PasswordResetInitPage", undefined, false);
infoPasswordResetInitPage.routeProps = { path: passwordResetInitBaseURL + ":cause", exact: true };
infoPasswordResetInitPage.mapBigStateToProps = infoHomePage.mapBigStateToProps;