import { Optional, Utils } from '@crispico/foundation-react';
import { Pair_Double_DoubleInput } from 'apollo-gen/globalTypes';
import { ClassicClusterHelper, ClusterHelper, LayerHelper, PolygonLayerHelper, PolylineLayerHelper, DEFAULT_ZOOM_LEVEL, HeatLayerHelper, ClassicGroupHelper, DEFAULT_POLYGON_COLOR } from 'components/MapContainerLeaflet/MapLayerHelpers';

// keep L import above leaflet plugins, otherwise errors on dev
import * as L from 'leaflet';
import { DrawEvents } from 'leaflet';
import { GestureHandling } from "leaflet-gesture-handling";

import 'leaflet/dist/leaflet.css';
import 'leaflet.fullscreen/Control.FullScreen.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

import 'proj4leaflet';
import 'leaflet-bing-layer/leaflet-bing-layer';
import 'leaflet.chinatmsproviders/src/leaflet.ChineseTmsProviders';

import "components/MapContainerLeaflet/leaflet.mapCorrection";
import "components/MapContainerLeaflet/L.KML";

import 'leaflet.fullscreen';
import 'leaflet-draw';
import 'leaflet-draw-drag';

import lodash from "lodash";
import React from 'react';
import { Confirm } from 'semantic-ui-react';
import { Reducers, ReduxReusableComponents, RRCProps, State } from '@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents';

const MAP_TILE_PROVIDER_KEY = "map.tileProvider";
export enum TileProvider { OSM = 'OpenStreetMap', BING = 'Bing', BAIDU = 'Baidu', GAODE = 'GaoDe', GEOQ = 'Geoq' };

// using https the BAIDU map doesn't load (it tries downloading from https sites), here we try to restrict it to load only from http sites 
// UPDATE: found this https://github.com/maptalks/raster-collection, url for normal map was replaced with the gss... one, now it works
(L.TileLayer as any).ChinaProvider.providers = {
    ...(L.TileLayer as any).ChinaProvider.providers,
    Baidu: {
        Normal: {
            Map: 'https://gss{s}.bdstatic.com/8bo_dTSlRsgBo1vgoIiO_jowehsv/tile/?qt=tile&x={x}&y={y}&z={z}&styles=pl&scaler=1&udt=20170927'
        },
        Satellite: {
            Map: 'http://shangetu{s}.map.bdimg.com/it/u=x={x};y={y};z={z};v=009;type=sate&fm=46',
            Annotion: 'http://online{s}.map.bdimg.com/tile/?qt=tile&x={x}&y={y}&z={z}&styles=sl&v=020'
        },
        Subdomains: '0123',
        tms: true
    }
};

export const DEFAULT_BORDER_COLOR = '#3A527B';
export const TRANSPARENT_BACKGROUND = '#ffffff99';
export const HOVER_BACKGROUND = '#F0FFFF';
export const SELECTED_BACKGROUND = '#b4e6ff';

// TEMPORARY CODE, in the future this map will be provided by settings
export const IMAGE_URL_PATH = Utils.adjustUrlToServerContext("images/");
export var loadedIcons: { [key: string]: { url: string, image: HTMLImageElement } };
/*eslint-disable */

function loadImages() {
Utils.loadImages({
    "truckIcon2": IMAGE_URL_PATH + "truck2.png",
    "antennaIcon": IMAGE_URL_PATH + "antenna.png",
    "hookIcon": IMAGE_URL_PATH + "hook.png",
    "stairsIcon": IMAGE_URL_PATH + "stairs.png",
    "equipmentTypeChf": IMAGE_URL_PATH + "equipment_type_chf.png",
    "equipmentTypeCpb": IMAGE_URL_PATH + "equipment_type_cpb.png",
    "equipmentTypeCpc": IMAGE_URL_PATH + "equipment_type_cpc.png",
    "equipmentTypePushTractor": IMAGE_URL_PATH + "equipment_type_push_tractor.png",
    "equipmentTypeCargoLoader": IMAGE_URL_PATH + "equipment_type_cargo_loader.png",
    "equipmentTypeBeltLoader": IMAGE_URL_PATH + "equipment_type_belt_loader.png",
    "equipmentTypeForklift": IMAGE_URL_PATH + "equipment_type_forklift.png",
    "equipmentTypeGPU": IMAGE_URL_PATH + "equipment_type_gpu.png",
    "equipmentTypeASU": IMAGE_URL_PATH + "equipment_type_asu.png",
    "equipmentTypeACU": IMAGE_URL_PATH + "equipment_type_acu.png",
    "acu": IMAGE_URL_PATH + "equipment_types/" + "acu.png",
    "aircraft_tractor": IMAGE_URL_PATH + "equipment_types/" + "aircraft_tractor.png",
    "antenna": IMAGE_URL_PATH + "equipment_types/" + "antenna.png",
    "asu": IMAGE_URL_PATH + "equipment_types/" + "asu.png",
    "baggage_tractors": IMAGE_URL_PATH + "equipment_types/" + "baggage_tractors.png",
    "bar": IMAGE_URL_PATH + "equipment_types/" + "bar.png",
    "belt_loader": IMAGE_URL_PATH + "equipment_types/" + "belt_loader.png",
    "bus": IMAGE_URL_PATH + "equipment_types/" + "bus.png",
    "car": IMAGE_URL_PATH + "equipment_types/" + "car.png",
    "chf": IMAGE_URL_PATH + "equipment_types/" + "chf.png",
    "cooling_unit": IMAGE_URL_PATH + "equipment_types/" + "cooling_unit.png",
    "cpb": IMAGE_URL_PATH + "equipment_types/" + "cpb.png",
    "cpc": IMAGE_URL_PATH + "equipment_types/" + "cpc.png",
    "deck_loader": IMAGE_URL_PATH + "equipment_types/" + "deck_loader.png",
    "de-icing": IMAGE_URL_PATH + "equipment_types/" + "de-icing.png",
    "dolly": IMAGE_URL_PATH + "equipment_types/" + "dolly.png",
    "food_truck": IMAGE_URL_PATH + "equipment_types/" + "food_truck.png",
    "forklift": IMAGE_URL_PATH + "equipment_types/" + "forklift.png",
    "gpu": IMAGE_URL_PATH + "equipment_types/" + "gpu.png",
    "hook": IMAGE_URL_PATH + "equipment_types/" + "hook.png",
    "pallet_transporter": IMAGE_URL_PATH + "equipment_types/" + "pallet_transporter.png",
    "passenger_step": IMAGE_URL_PATH + "equipment_types/" + "passenger_step.png",
    "push_tractor": IMAGE_URL_PATH + "equipment_types/" + "push_tractor.png",
    "snow_removal_truck": IMAGE_URL_PATH + "equipment_types/" + "snow_removal_truck.png",
    "stairs": IMAGE_URL_PATH + "equipment_types/" + "stairs.png",
    "tank": IMAGE_URL_PATH + "equipment_types/" + "tank.png",
    "toilet_truck": IMAGE_URL_PATH + "equipment_types/" + "toilet_truck.png",
    "toilet_truck2": IMAGE_URL_PATH + "equipment_types/" + "toilet_truck2.png",
    "tools_truck": IMAGE_URL_PATH + "equipment_types/" + "tools_truck.png",
    "towbarless_tractor": IMAGE_URL_PATH + "equipment_types/" + "towbarless_tractor.png",
    "trailer": IMAGE_URL_PATH + "equipment_types/" + "trailer.png",
    "truck": IMAGE_URL_PATH + "equipment_types/" + "truck.png",
    "truck2": IMAGE_URL_PATH + "equipment_types/" + "truck2.png",
    "water_truck": IMAGE_URL_PATH + "equipment_types/" + "water_truck.png",

    "images/baggageTractors.svg": "images/baggageTractors.svg",
    "images/aircraftTractors.svg": "images/aircraftTractors.svg",
    "images/airStarters.svg": "images/airStarters.svg",
    "images/beltLoaders.svg": "images/beltLoaders.svg",
    "images/clim.svg": "images/clim.svg",
    "images/groundPowerUnits.svg": "images/groundPowerUnits.svg",
    "images/loaders.svg": "images/loaders.svg",
    "images/passengerSteps.svg": "images/passengerSteps.svg",
    "images/bus.svg": "images/bus.svg",
    "images/transporter.svg": "images/transporter.svg",
    "images/wc.svg": "images/wc.svg"
}, (loadedImages: { [key: string]: { url: string, image: HTMLImageElement } }) => {
    loadedIcons = loadedImages;
});
}
/*eslint-enable */

export const eqImages256x256: { [key: string]: string } = {};
["acu", "aircraft_tractor", "antenna", "asu", "baggage_tractors", "bar", "belt_loader", "bus", "car", "chf", "cooling_unit", "cpb", "cpc", "deck_loader", "de-icing", "dolly", "food_truck", "forklift", "gpu", "hook", "pallet_transporter", "passenger_step", "push_tractor", "snow_removal_truck", "stairs", "tank", "toilet_truck", "toilet_truck2", "tools_truck", "towbarless_tractor", "trailer", "truck", "truck2", "water_truck"]
    .map(i => eqImages256x256[i] = IMAGE_URL_PATH + "equipment_types/256x256/" + i + ".png");

export const getIcon = (iconKey: Optional<string>): { url: string, image: HTMLImageElement } | null => {
    return loadedIcons && iconKey ? loadedIcons[iconKey] : null;
}

/**
 * There are some problems setting icon in marker's mouseover event, so no style was added for hovered!
 */

export type ID = string | number;

export type IDData = {
    id?: ID // can be undefined if newly created (draw)
}

export type Location = {
    id?: ID // can be undefined in some cases (e.g. Marker, Territory)
    latitude: number
    longitude: number
}

export type MarkerAreaStyle = {
    color?: string
    icon?: string
}

export type MarkerData = IDData & {
    text?: string
    icon?: string | MarkerAreaStyle
    point: Location
    mainArea?: MarkerAreaStyle
    topLeftArea?: MarkerAreaStyle
    topRightArea?: MarkerAreaStyle
    bottomLeftArea?: MarkerAreaStyle
    bottomRightArea?: MarkerAreaStyle
}

export type PolygonData = IDData & {
    color?: string
    points: Location[]
    zIndex?: number,
    readableArea?: string,
    text?: string
}

export type PolylineData = IDData & {
    color?: string
    points: Location[]
    zIndex?: number
}
export type HeatData = IDData & {
    point: Location
}

export const POLYGON_TYPE: string = "polygon";
export const POLYLINE_TYPE: string = "polyline";
export const MARKER_TYPE: string = "marker";
export const HEAT_TYPE: string = "heat";

export enum EditMode { DRAW = 'draw', EDIT = 'edit', REMOVE = 'remove' };

export type SelectedLayer = { id: ID, type: string, additionalInfo?: { pointId?: ID, flyToLayer?: boolean, showPopup?: boolean } }
export type HoveredLayer = { id: ID, type: string, additionalInfo?: { pointId?: ID } }
class MapContainerLeafletState extends State {
    selectedLayer: Optional<SelectedLayer> = undefined;
    hoveredLayer: Optional<HoveredLayer> = undefined;

    editDrawEnabledOnType: Optional<string> = undefined;
    editMode: EditMode | undefined = undefined;

    center:  Optional<number[]> = undefined; // lat & lng
    zoom: Optional<number> = undefined;
    highlightedPointsOnLayer: { layerType: string, layerId: ID, pointId: ID }[] = [];
}

class MapContainerLeafletReducers<S extends MapContainerLeafletState = MapContainerLeafletState> extends Reducers<S> {
    highlightPoint(p: { layerType: string, layerId: ID, pointId: ID, reset?: boolean }) {
        if (p.reset) {
            this.s.highlightedPointsOnLayer = [];
        }
        if ( this.s.highlightedPointsOnLayer.find(point => lodash.isEqual(point, p))) {
            return;
        }
        this.s.highlightedPointsOnLayer.push({ layerType: p.layerType, layerId: p.layerId, pointId: p.pointId });
    }

    unhighlightPoint(p: { layerType?: string, layerId?: ID, pointId?: ID }) {
        const newList: { layerType: string, layerId: ID, pointId: ID }[] = [];
        this.s.highlightedPointsOnLayer.forEach((point, index) => {
            if (p.pointId ? point.pointId !== p.pointId : false &&
                p.layerId ? point.layerId !== p.layerId : false &&
                    p.layerType ? point.layerType !== p.layerType : false) {
                newList.push(point);
            }
        });
        this.s.highlightedPointsOnLayer = newList;
    }
}

export type MapContainerLeafletLayer = {
    layerType: string,
    options?: { hideStyleOnSelectedLayer?: boolean, hideStyleOnHoveredLayer?: boolean, hideTooltipOnHoveredLayer?: boolean, flyToSelectedMarker?: boolean, useCluster?: boolean, [key: string]: any }
}

type MapContainerLeafletProps = RRCProps<MapContainerLeafletState, MapContainerLeafletReducers> & {
    layers: { [dataType: string]: MapContainerLeafletLayer },
    renderPopupContent?: (layerData: any, type: string, point?: Location) => React.ReactElement;
    renderTooltipContent?: (layerData: any, type: string, additionalInfo?: { pointId?: ID }) => React.ReactElement;
    renderMarkerIcon?: (layerData: any, type: string, aditionalStyles?: { selected?: boolean, hovered?: boolean }) => React.ReactNode;

    isAllowed?: (editMode: EditMode) => boolean;
    onLayerAdded?: (layerData: any, type: string) => void;
    onLayersEdited?: (layerData: any[], type: string) => void;
    onLayersRemoved?: (layerData: any[], type: string) => void;

    onSelectLayer?: (data?: Optional<SelectedLayer>) => void;
    onHoverLayer?: (data?: Optional<HoveredLayer>) => void;
    onSelectedLayerChanged?: (prevValue?: Optional<SelectedLayer>) => void;
    onEditModeChanged?: () => void;

    pruneClusterMode: boolean;
    bingAPIKey?: string;
    mapId?: string;
    enableGestureHandling?: boolean,
    saveCenterZoomInStorage?: boolean
};

type MapContainerLeafletLocalState = { confirmRefreshWhenChangeFromToBaiduMap: boolean, currentSelectedTilesProvider: string };
export class MapContainerLeaflet extends React.Component<MapContainerLeafletProps, MapContainerLeafletLocalState> {

    private map!: L.Map;

    private layerGroupHelpers: { [type: string]: LayerHelper } = {};

    private tilesTypes: { [label: string]: L.TileLayer } = {};
    private overlayLayers: { [label: string]: L.TileLayer } = {};

    private drawHelper?: {
        drawControl: L.Control.Draw;
        editLayer: L.Layer
    }

    static defaultProps = {
        tileProvider: TileProvider.OSM
    }

    constructor(props: MapContainerLeafletProps, state: MapContainerLeafletLocalState) {
        super(props);       

        if (!loadedIcons) {
            loadImages();
        }
        this.state = { confirmRefreshWhenChangeFromToBaiduMap: false, currentSelectedTilesProvider: localStorage.getItem(MAP_TILE_PROVIDER_KEY) || _msg("Map.tile.normal", TileProvider.OSM) };

        const zoomParams = { maxNativeZoom: 18, minZoom: 2, maxZoom: 21 };

        this.tilesTypes[_msg("Map.tile.normal", TileProvider.OSM)] = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors', ...zoomParams
        });

        if (this.props.bingAPIKey) {
            this.tilesTypes[_msg("Map.tile.normal", TileProvider.BING)] = (L as any).tileLayer.bing({ bingMapsKey: this.props.bingAPIKey, imagerySet: 'Road', ...zoomParams });
            this.tilesTypes[_msg("Map.tile.satellite", TileProvider.BING)] = (L as any).tileLayer.bing({ bingMapsKey: this.props.bingAPIKey, imagerySet: 'AerialWithLabels', ...zoomParams });
        }

        zoomParams.minZoom = 3;

        this.tilesTypes[_msg("Map.tile.normal", TileProvider.GAODE)] = (L as any).tileLayer.chinaProvider('GaoDe.Normal.Map', zoomParams);
        this.tilesTypes[_msg("Map.tile.satellite", TileProvider.GAODE)] = (L as any).tileLayer.chinaProvider('GaoDe.Satellite.Map', zoomParams);
        this.overlayLayers[_msg("Map.tile.annotion", TileProvider.GAODE)] = (L as any).tileLayer.chinaProvider('GaoDe.Satellite.Annotion', zoomParams);

        this.tilesTypes[_msg("Map.tile.normal", TileProvider.GEOQ)] = (L as any).tileLayer.chinaProvider('Geoq.Normal.Map', zoomParams);

        this.tilesTypes[_msg("Map.tile.normal", TileProvider.BAIDU)] = (L as any).tileLayer.chinaProvider('Baidu.Normal.Map', { minZoom: 3 });
        // deactivated because of expired certification from their part; didn't find another one to replace this; see comment on top
        // this.tilesTypes[_msg("Map.tile.satellite", TileProvider.BAIDU)] = (L as any).tileLayer.chinaProvider('Baidu.Satellite.Map', { minZoom: 3 });
        // this.overlayLayers[_msg("Map.tile.annotion", TileProvider.BAIDU)] = (L as any).tileLayer.chinaProvider('Baidu.Satellite.Annotion', { minZoom: 3 });
    }

    componentDidMount() {
        const center = (this.props.saveCenterZoomInStorage && getCenterFromStorage()) || [0, 0];
        const zoom = (this.props.saveCenterZoomInStorage && getZoomFromStorage()) || DEFAULT_ZOOM_LEVEL;
        this.props.r.setInReduxState({ center: center, zoom: zoom });

        let selectedTileProvider = this.state.currentSelectedTilesProvider;
        if (this.tilesTypes[selectedTileProvider] === undefined) {
            selectedTileProvider = _msg("Map.tile.normal", TileProvider.OSM);
            this.setState({ currentSelectedTilesProvider: selectedTileProvider });
        }
        
        this.map = L.map(this.props.mapId ? this.props.mapId : 'map', {
            crs: this.state.currentSelectedTilesProvider.startsWith(TileProvider.BAIDU) ? (L.CRS as any).Baidu : L.CRS.EPSG3857,
            center: L.latLng(center[0], center[1]),
            zoomControl: false, zoom: zoom          
        });
        
        if (this.props.enableGestureHandling) {
            this.map.addHandler("gestureHandlingOptions", GestureHandling);
            (this.map.options as any).gestureHandlingOptions.duration = 2000; // 2 sec
        }
        this.tilesTypes[selectedTileProvider].addTo(this.map);
        L.control.layers(this.tilesTypes, this.overlayLayers).addTo(this.map);

        L.control.scale().addTo(this.map);
        L.control.zoom({
            zoomInTitle: _msg("Map.zoom.in"),
            zoomOutTitle: _msg("Map.zoom.out")
        }).addTo(this.map);

        L.control.fullscreen({
            title: _msg("Map.fullscreen.on"),
            titleCancel: _msg("Map.fullscreen.off")
        }).addTo(this.map);

        const that = this;
        this.map.on('baselayerchange', (e: L.LeafletEvent) => {
            const newTilesProvider: string = (e as any).name;
            const prevIsBaidu = that.state.currentSelectedTilesProvider.startsWith(TileProvider.BAIDU);
            const newIsBaidu = newTilesProvider.startsWith(TileProvider.BAIDU);
            if ((prevIsBaidu || newIsBaidu) && !(newIsBaidu && prevIsBaidu)) {
                that.setState({ confirmRefreshWhenChangeFromToBaiduMap: true });
            }
            localStorage.setItem(MAP_TILE_PROVIDER_KEY, newTilesProvider);
            that.setState({ currentSelectedTilesProvider: newTilesProvider });
        });

        this.map.on('click', (e: L.LeafletEvent) => {
            if (this.props.s.editMode) {
                return;
            }
            if (this.props.s.selectedLayer) {
                this.props.r.setInReduxState({ selectedLayer: undefined });
                this.props.onSelectLayer?.call(null);
            }
            if (this.props.s.hoveredLayer) {
                this.props.r.setInReduxState({ hoveredLayer: undefined });
                this.props.onHoverLayer?.call(null);
            }
        });
        this.map.on('moveend', (e: L.LeafletEvent) => {
            const center = this.map.getCenter();
            if (this.props.saveCenterZoomInStorage) {
                this.props.r.setInReduxState({ center: [center.lat, center.lng], zoom: this.map.getZoom() });
            }
        });

        this.map.on(L.Draw.Event.CREATED, (e: L.LeafletEvent) => {
            if (this.props.isAllowed === undefined || !this.props.isAllowed.call(null, EditMode.DRAW)) {
                return;
            }
            const type = (e as DrawEvents.Created).layerType;
            if (type === 'polygon' || type === 'rectangle') {
                let newPolygon: PolygonData = this.getPolygonData(e.layer as L.Polygon);
                this.props.onLayerAdded?.call(null, newPolygon, this.props.s.editDrawEnabledOnType!);
            } else if (type === 'marker') {
                let l: L.Marker = e.layer as L.Marker;
                const point = l.getLatLng();
                this.props.onLayerAdded?.call(null, { point: { longitude: point.lng, latitude: point.lat } }, this.props.s.editDrawEnabledOnType!);
            }
        });
        this.map.on(L.Draw.Event.EDITSTART, (e: L.LeafletEvent) => this.props.r.setInReduxState({ editMode: EditMode.EDIT }));
        this.map.on(L.Draw.Event.DRAWSTART, (e: L.LeafletEvent) => this.props.r.setInReduxState({ editMode: EditMode.DRAW }));
        this.map.on(L.Draw.Event.DELETESTART, (e: L.LeafletEvent) => this.props.r.setInReduxState({ editMode: EditMode.REMOVE }));
        this.map.on(L.Draw.Event.EDITSTOP, (e: L.LeafletEvent) => { this.props.r.setInReduxState({ editMode: undefined }); });
        this.map.on(L.Draw.Event.DRAWSTOP, (e: L.LeafletEvent) => { this.props.r.setInReduxState({ editMode: undefined }); });
        this.map.on(L.Draw.Event.DELETESTOP, (e: L.LeafletEvent) => { this.props.r.setInReduxState({ editMode: undefined }); });

        this.map.on(L.Draw.Event.EDITED, (e: L.LeafletEvent) => {
            if (this.props.isAllowed === undefined || !this.props.isAllowed.call(null, EditMode.EDIT)) {
                return;
            }
            let data: any[] = [];
            (e as DrawEvents.Edited).layers.eachLayer(layer => {
                if (layer instanceof L.Polygon) {
                    data.push(this.getPolygonData(layer as L.Polygon));
                } else if (layer instanceof L.Marker) {
                    data.push(this.getMarkerData(layer));
                }
            });
            this.props.onLayersEdited?.call(null, data, this.props.s.editDrawEnabledOnType!);
        });
        this.map.on(L.Draw.Event.DELETED, (e: L.LeafletEvent) => {
            if (this.props.isAllowed === undefined || !this.props.isAllowed.call(null, EditMode.REMOVE)) {
                return;
            }
            let data: any[] = [];
            (e as DrawEvents.Deleted).layers.eachLayer(layer => {
                if (layer instanceof L.Polygon) {
                    data.push(this.getPolygonData(layer));
                } else if (layer instanceof L.Marker) {
                    data.push(this.getMarkerData(layer));
                }
            });
            this.props.onLayersRemoved?.call(null, data, this.props.s.editDrawEnabledOnType!);
        });

        Object.keys(this.props.layers).forEach(dataType => {
            switch (this.props.layers[dataType].layerType) {
                case MARKER_TYPE:
                    this.addMarkerLayer(dataType, this.props.layers[dataType].options?.useCluster);
                    break;
                case POLYGON_TYPE:
                    this.addPolygonLayer(dataType);
                    break;
                case POLYLINE_TYPE:
                    this.addPolylineLayer(dataType);
                    break;
                case HEAT_TYPE:
                    this.addHeatLayer(dataType);
                    break;
            }
        });

        this.applyPropsChanges();
    }

    private getPolygonData(layer: L.Polygon) {
        let data: PolygonData = { points: [] };
        data.id = this.layerGroupHelpers[this.props.s.editDrawEnabledOnType!].getLayerData(layer)?.id;
        const points = layer.getLatLngs()[0] as L.LatLng[];
        for (let i = 0; i < points.length; i++) {
            data.points.push({ longitude: points[i].lng, latitude: points[i].lat })
        };
        return data;
    }

    private getMarkerData(layer: L.Marker) {
        const point = layer.getLatLng();
        let data: MarkerData = { point: { longitude: point.lng, latitude: point.lat } };
        data.id = this.layerGroupHelpers[this.props.s.editDrawEnabledOnType!].getLayerData(layer)?.id;
        return data;
    }

    componentDidUpdate(prevProps: MapContainerLeafletProps, prevState: MapContainerLeafletLocalState) {
        this.applyPropsChanges(prevProps, prevState);
    }

    fitBounds(type: string) {
        this.map.fitBounds(this.layerGroupHelpers[type].getLayerGroup().getBounds(), { maxZoom: DEFAULT_ZOOM_LEVEL });
    }

    private configDrawHelper(type: Optional<string>) {
        if (type !== this.props.s.editDrawEnabledOnType) {
            return;
        }
        if (this.drawHelper) {
            this.map.removeControl(this.drawHelper.drawControl);
            // this.map.removeLayer(this.drawHelper.editLayer);
            this.drawHelper = undefined;
        }
        if (!this.props.s.editDrawEnabledOnType) {
            return;
        }
        const layerHelper: LayerHelper = this.layerGroupHelpers[this.props.s.editDrawEnabledOnType];
        const editLayer: L.FeatureGroup = new L.FeatureGroup(layerHelper.getLayerGroup().getLayers());

        const drawAllowed: boolean = this.props.isAllowed === undefined || this.props.isAllowed.call(null, EditMode.DRAW);
        const options: L.Control.DrawConstructorOptions = {
            draw: {
                circle: false,
                circlemarker: false,
                marker: drawAllowed && layerHelper.getLayerGroupType() === MARKER_TYPE ? {
                    icon: (layerHelper as ClusterHelper).getMarkerIcon({ point: { latitude: 0, longitude: 0 }, text: "" }), /* dummy marker */
                    repeatMode: false
                } : false,
                polyline: false,
                rectangle: drawAllowed && layerHelper.getLayerGroupType() === POLYGON_TYPE ? {} : false,
                polygon: drawAllowed && layerHelper.getLayerGroupType() === POLYGON_TYPE ? { allowIntersection: false, showArea: true, metric: true } : false
            },
            edit: {
                edit: this.props.isAllowed === undefined || this.props.isAllowed.call(null, EditMode.EDIT) ? {} : false,
                featureGroup: editLayer,
            }
        }
        if (this.props.isAllowed && !this.props.isAllowed.call(null, EditMode.REMOVE)) {
            options.edit!.remove = false;
        }

        configDrawMessages();

        this.drawHelper = {
            editLayer: editLayer,
            drawControl: new L.Control.Draw(options)
        };
        L.EditToolbar.Delete.include({
            removeAllLayers: false
        });

        this.map.addLayer(this.drawHelper.editLayer);
        this.map.addControl(this.drawHelper.drawControl);
    }

    private applyPropsChanges(prevProps?: MapContainerLeafletProps, prevState?: MapContainerLeafletLocalState) {
        if (prevProps) {
            const { center, zoom } = this.props.s;
            const centerChanged = !lodash.isEqual(prevProps.s.center, center) && center;
            const zoomChanged = prevProps.s.zoom !== zoom && zoom;
            if (centerChanged && zoomChanged) {
                const coord = L.latLng(center![0], center![1]);
                setCenterToStorage(coord.lat, coord.lng);
                setZoomToStorage(zoom);
                this.map.setView(coord, zoom);
            } else if (centerChanged) {
                const coord = L.latLng(center![0], center![1]);
                setCenterToStorage(coord.lat, coord.lng);
                this.map.setView(coord, this.map.getZoom());
            } else if (zoomChanged) {
                setZoomToStorage(zoom!);
                if (this.map.getZoom() !== zoom) {
                    this.map.setZoom(zoom!);
                }
            }
        }

        if (!lodash.isEqual(prevProps?.s.selectedLayer, this.props.s.selectedLayer)) {
            if (prevProps?.s.selectedLayer) {
                this.layerGroupHelpers[prevProps?.s.selectedLayer.type]?.unselectLayer(prevProps?.s.selectedLayer.id);
            }
            if (this.props.s.selectedLayer) {
                this.layerGroupHelpers[this.props.s.selectedLayer.type]?.selectLayer(this.props.s.selectedLayer);
            }
            if(this.props.onSelectedLayerChanged){
                this.props.onSelectedLayerChanged(prevProps?.s.selectedLayer)
            }
        }

        if (!lodash.isEqual(prevProps?.s.hoveredLayer, this.props.s.hoveredLayer)) {
            if (prevProps?.s.hoveredLayer) {
                this.layerGroupHelpers[prevProps?.s.hoveredLayer.type]?.unhoverLayer(prevProps?.s.hoveredLayer.id);
            }
            if (this.props.s.hoveredLayer) {
                this.layerGroupHelpers[this.props.s.hoveredLayer.type]?.hoverLayer(this.props.s.hoveredLayer);
            }
        }

        if (prevProps?.s.editDrawEnabledOnType !== this.props.s.editDrawEnabledOnType) {
            this.configDrawHelper(this.props.s.editDrawEnabledOnType);
        }

        if (!lodash.isEqual(prevProps?.s.highlightedPointsOnLayer, this.props.s.highlightedPointsOnLayer)) {
            if (prevProps?.s.highlightedPointsOnLayer) {
                prevProps?.s.highlightedPointsOnLayer.forEach(pointA => {
                    if (this.props.s.highlightedPointsOnLayer.find(pointB => lodash.isEqual(pointA, pointB)) === undefined) {
                        this.layerGroupHelpers[pointA.layerType]?.unhighlightPoint(pointA.layerId, pointA.pointId);
                    }
                });
            }
            if (this.props.s.highlightedPointsOnLayer) {
                this.props.s.highlightedPointsOnLayer.forEach(pointA => {
                    if (prevProps?.s.highlightedPointsOnLayer.find(pointB => lodash.isEqual(pointA, pointB)) === undefined) {
                        this.layerGroupHelpers[pointA.layerType]?.highlightPoint(pointA.layerId, pointA.pointId);
                    }
                });
            }
        }

        if (!lodash.isEqual(this.props.s.editMode, prevProps?.s.editMode) && this.props.onEditModeChanged){
              this.props.onEditModeChanged();
        }
    }

    getMap(): L.Map {
        return this.map;
    }

    private addLayerOnMap(type: string, helper: ClusterHelper) {
        this.layerGroupHelpers[type] = helper;
        this.map.addLayer(helper.getLayerGroup());
    }

    private async addMarkerLayer(type: string, useCluster: boolean = true) {
        if (useCluster) {
            if (this.props.pruneClusterMode) {
                const PruneClusterHelper: any = (await import('components/MapContainerLeaflet/PruneClusterHelper')).PruneClusterHelper;
                this.addLayerOnMap(type, new PruneClusterHelper(type, this));
            } else {
                this.addLayerOnMap(type, new ClassicClusterHelper(type, this));
            }
        } else {
            this.addLayerOnMap(type, new ClassicGroupHelper(type, this));
        }
    }

    private addPolygonLayer(type: string): void {
        this.layerGroupHelpers[type] = new PolygonLayerHelper(type, this);
        this.map.addLayer(this.layerGroupHelpers[type].getLayerGroup());
    }

    private addPolylineLayer(type: string): void {
        this.layerGroupHelpers[type] = new PolylineLayerHelper(type, this);
        this.map.addLayer(this.layerGroupHelpers[type].getLayerGroup());
    }

    private addHeatLayer(type: string): void {
        this.layerGroupHelpers[type] = new HeatLayerHelper(type, this);
        this.map.addLayer(this.layerGroupHelpers[type].getLayerGroup());
    }

    async addOrUpdateLayers(layers: any[], type: string, addProgressively: boolean = false) {
        if (this.layerGroupHelpers[type] === undefined && this.props.layers[type] !== undefined) {
            switch (this.props.layers[type].layerType) {
                case MARKER_TYPE:
                    await this.addMarkerLayer(type, this.props.layers[type].options?.useCluster);
                    break;
                case POLYGON_TYPE:
                    this.addPolygonLayer(type);
                    break;
                case POLYLINE_TYPE:
                    this.addPolylineLayer(type);
                    break;
                case HEAT_TYPE:
                    this.addHeatLayer(type);
                    break;
            }
        }
        if (addProgressively && layers.length > 100) {
            var subLists = [];
            while (layers.length) {
                subLists.push(layers.splice(0, 100));
            }
            subLists.forEach(subList => setTimeout(() => this.layerGroupHelpers[type].addOrUpdateLayers(subList), 100));
        } else {
            this.layerGroupHelpers[type].addOrUpdateLayers(layers);
        }
        this.configDrawHelper(type);
    }

    removeLayers(type: string, layerIds?: any[]) {
        if (!layerIds) {
            if (this.props.s.hoveredLayer?.type === type) {
                this.props.r.setInReduxState({ hoveredLayer: undefined });
            }
            if (this.props.s.selectedLayer?.type === type) {
                this.props.r.setInReduxState({ selectedLayer: undefined });
            }
            this.layerGroupHelpers[type]?.removeLayers();
        } else {
            layerIds.forEach(layerId => {
                if (this.props.s.hoveredLayer?.id === layerId && this.props.s.hoveredLayer?.type === type) {
                    this.props.r.setInReduxState({ hoveredLayer: undefined });
                }
                if (this.props.s.selectedLayer?.id === layerId && this.props.s.selectedLayer?.type === type) {
                    this.props.r.setInReduxState({ selectedLayer: undefined });
                }
                this.layerGroupHelpers[type]?.removeLayer(layerId);
            });
        }
        this.configDrawHelper(type);
    }

    setZoom(zoom: number) {
        this.map.setZoom(zoom);
    }

    getCurrentBounds(): Pair_Double_DoubleInput[] {
        const bounds: L.LatLngBounds = this.map.getBounds();
        return [{ a: bounds.getNorthWest().lng, b: bounds.getNorthWest().lat },
        { a: bounds.getNorthEast().lng, b: bounds.getNorthEast().lat },
        { a: bounds.getSouthEast().lng, b: bounds.getSouthEast().lat },
        { a: bounds.getSouthWest().lng, b: bounds.getSouthWest().lat }];
    }

    areBoundsSmallerThen(bounds: Optional<Pair_Double_DoubleInput[]>, offset: number = 0): boolean {
        return bounds ? new L.LatLngBounds([
            [bounds[0].b! - offset, bounds[0].a! + offset],
            [bounds[1].b! + offset, bounds[1].a! + offset],
            [bounds[2].b! + offset, bounds[2].a! - offset],
            [bounds[3].b! - offset, bounds[3].a! - offset]])
            .contains(this.map.getBounds()) : false;
    }

    static pointInsideBounds(bounds: Optional<Pair_Double_DoubleInput[]>, point: Location) {
        return bounds ? new L.LatLngBounds([
            [bounds[0].b!, bounds[0].a!],
            [bounds[1].b!, bounds[1].a!],
            [bounds[2].b!, bounds[2].a!],
            [bounds[3].b!, bounds[3].a!]])
            .contains(new L.LatLng(point.latitude, point.longitude)) : false;
    }

    updateSize() {
        this.map.invalidateSize();
    }

    clearMap(types?: string[]) {
        for (const [type, helper] of Object.entries(this.layerGroupHelpers)) {
            if (!types || types.find(t => t === type)) {
                helper.removeLayers();
            }
        }
    }

    parseKML(kmltext: string): PolygonData[] {
        const parser = new DOMParser();
        const kml = parser.parseFromString(kmltext, 'text/xml');
        const data: L.FeatureGroup = new (L as any).KML(kml);

        var polygonData: PolygonData[] = [];
        data.getLayers().forEach((l: L.Layer) => {
            let layers: L.Layer[] = [];
            if (l instanceof L.FeatureGroup) {
                layers = l.getLayers();
            } else {
                layers = [l];
            }
            layers.forEach((l: L.Layer) => {
                if (l instanceof L.Polygon) {
                    const p: L.Polygon = l as L.Polygon;
                    let name = (p.options as any).name?.trim();
                    if (polygonData.filter(d => d.text === name).length > 0) {
                        name += " [DUPLICATE " + (polygonData.filter(d => d.text?.startsWith(name + " [DUPLICATE ")).length + 1) + "]";
                    }
                    let d: PolygonData = { color: p.options.fillColor?.padEnd(7, '0') || DEFAULT_POLYGON_COLOR, text: name, points: [] };

                    const points = p.getLatLngs()[0] as L.LatLng[];
                    for (let i = 0; i < points.length; i++) {
                        d.points.push({ longitude: points[i].lng, latitude: points[i].lat })
                    };
                    polygonData.push(d);
                }
            });
        });
        return polygonData;
    }

    render() {
        const that = this;
        return (<>
            <div className="flex-container flex-grow">
                <div id={this.props.mapId ? this.props.mapId : 'map'} className="flex-container flex-grow-shrink-no-overflow"></div>
            </div>
            <Confirm
                open={this.state.confirmRefreshWhenChangeFromToBaiduMap}
                header={_msg("general.info")}
                content={_msg("MapRealTime.confirmTileProviderChangeFromToBaidu")}
                onCancel={() => that.setState({ confirmRefreshWhenChangeFromToBaiduMap: false })}
                onConfirm={() => {
                    that.setState({ confirmRefreshWhenChangeFromToBaiduMap: false });
                    window.location.reload();
                }}
                cancelButton={_msg("general.cancel")}
                confirmButton={_msg("general.ok")}
            /></>
        )
    }
}

export const MapContainerLeafletRRC = ReduxReusableComponents.connectRRC(MapContainerLeafletState, MapContainerLeafletReducers, MapContainerLeaflet);

const configDrawMessages = () => {
    L.drawLocal.draw = {
        toolbar: {
            actions: {
                title: _msg("Map.draw.toolbar.actions.title"),
                text: _msg("Map.draw.toolbar.actions.text")
            },
            finish: {
                title: _msg("Map.draw.toolbar.finish.title"),
                text: _msg("Map.draw.toolbar.finish.text")
            },
            undo: {
                title: _msg("Map.draw.toolbar.undo.title"),
                text: _msg("Map.draw.toolbar.undo.text")
            },
            buttons: {
                polyline: _msg("Map.draw.toolbar.buttons.polyline"),
                polygon: _msg("Map.draw.toolbar.buttons.polygon"),
                rectangle: _msg("Map.draw.toolbar.buttons.rectangle"),
                circle: _msg("Map.draw.toolbar.buttons.circle"),
                marker: _msg("Map.draw.toolbar.buttons.marker"),
                circlemarker: _msg("Map.draw.toolbar.buttons.circlemarker")
            }
        },
        handlers: {
            circle: {
                tooltip: {
                    start: _msg("Map.draw.handlers.circle.tooltip.start")
                },
                radius: _msg("Map.draw.handlers.circle.radius")
            },
            circlemarker: {
                tooltip: {
                    start: _msg("Map.draw.handlers.circlemarker.tooltip.start")
                }
            },
            marker: {
                tooltip: {
                    start: _msg("Map.draw.handlers.marker.tooltip.start")
                }
            },
            polygon: {
                tooltip: {
                    start: _msg("Map.draw.handlers.polygon.tooltip.start"),
                    cont: _msg("Map.draw.handlers.polygon.tooltip.cont"),
                    end: _msg("Map.draw.handlers.polygon.tooltip.end")
                }
            },
            polyline: {
                error: _msg("Map.draw.handlers.polyline.error"),
                tooltip: {
                    start: _msg("Map.draw.handlers.polyline.tooltip.start"),
                    cont: _msg("Map.draw.handlers.polyline.tooltip.cont"),
                    end: _msg("Map.draw.handlers.polyline.tooltip.end")
                }
            },
            rectangle: {
                tooltip: {
                    start: _msg("Map.draw.handlers.rectangle.tooltip.start")
                }
            },
            simpleshape: {
                tooltip: {
                    end: _msg("Map.draw.handlers.simpleshape.tooltip.end")
                }
            }
        }
    }

    L.drawLocal.edit = {
        toolbar: {
            actions: {
                save: {
                    title: _msg("Map.edit.toolbar.actions.save.title"),
                    text: _msg("Map.edit.toolbar.actions.save.text")
                },
                cancel: {
                    title: _msg("Map.edit.toolbar.actions.cancel.title"),
                    text: _msg("Map.edit.toolbar.actions.cancel.text")
                },
                clearAll: {
                    title: _msg("Map.edit.toolbar.actions.clearAll.title"),
                    text: _msg("Map.edit.toolbar.actions.clearAll.text")
                }
            },
            buttons: {
                edit: _msg("Map.edit.toolbar.buttons.edit"),
                editDisabled: _msg("Map.edit.toolbar.buttons.editDisabled"),
                remove: _msg("Map.edit.toolbar.buttons.remove"),
                removeDisabled: _msg("Map.edit.toolbar.buttons.removeDisabled")
            }
        },
        handlers: {
            edit: {
                tooltip: {
                    text: _msg("Map.edit.handlers.edit.tooltip.text"),
                    subtext: _msg("Map.edit.handlers.edit.tooltip.subtext")
                }
            },
            remove: {
                tooltip: {
                    text: _msg("Map.edit.handlers.remove.tooltip.text")
                }
            }
        }
    }
}

function getCenterFromStorage() {
    if (sessionStorage.getItem("map.center") != null) {
        const center = sessionStorage.getItem("map.center");
        const latLng = center?.split("|");

        if (latLng?.length === 2) {
            const lat = parseFloat(latLng[0]);
            const lng = parseFloat(latLng[1]);
            return !isNaN(lat) && !isNaN(lng) ? [lat, lng] : undefined;
        }
    }
    return undefined;
}

function setCenterToStorage(lat: number, lng: number) {
    sessionStorage.setItem("map.center", lat + "|" + lng);
}

function getZoomFromStorage() {
    if (sessionStorage.getItem("map.zoom") != null) {
        const item = sessionStorage.getItem("map.zoom");
        if (item) {
            const zoom = parseFloat(item);
            return !isNaN(zoom) ? zoom : undefined;
        }
    }
    return undefined;
}

function setZoomToStorage(zoom: number) {
    sessionStorage.setItem("map.zoom", "" + zoom);
}