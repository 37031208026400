import { createSliceFoundation, EntityEditorPage, getBaseImpures, getBaseReducers, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension, StateFrom } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { CrudFormInEditor } from "@crispico/foundation-react/entity_crud/CrudFormInEditor";
import { EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { CrudEditorPageRenderHeaderParams, EditMode } from "@crispico/foundation-react/entity_crud/EntityEditorPage";
import { EntityTablePage, SliceEntityTablePage, sliceEntityTablePageOnlyForExtension } from "@crispico/foundation-react/entity_crud/EntityTablePage";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { push } from "connected-react-router";
import React from "react";
import { NavLink } from "react-router-dom";
import { Button, Icon, Menu, Modal } from "semantic-ui-react";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import { DashboardMode } from "./DashboardContants";
import { DashboardTab, sliceDashboardTab } from "./dashboardTab/DashboardTab";
import { FieldWidget } from "./dashboardTab/entity_widgets/FieldWidget/FieldWidget";
import { DashboardWidgetFactories, DashboardWidgetFactory } from "./DashboardWidgetFactory";

export type Dashboard = {
    id: number;
    name: string;
    icon: string;
    color: string;
    showInMenu: boolean;
    positionInMenu: number;
    fitHeight: boolean;
    expandOrganizations: boolean;
    configJson: string;
    filterJson: string;
    entityName: string;
    forEditor: boolean;

    // not in Java, but in config => duplicated :(
    widgetHeaderFontSize?: number;
    widgetHeaderBackgroundColor?: string;
    widgetHeaderIcon?: SemanticICONS;

    // not in Java
    filter: Filter;
    config: DashboardConfig;
}

export type DashboardConfig = {
    layouts: ReactGridLayout.Layouts;
    widgetWrapperConfigs: any;
    widgetHeaderFontSize?: number;
    widgetHeaderBackgroundColor?: string;
    widgetHeaderIcon?: SemanticICONS;
}

export class DashboardEntityDescriptor extends EntityDescriptor {

    constructor() {
        super({
            name: "Dashboard",
            miniFields: ["name"],
            icon: "dashboard",
            hasAttachedDashboards: false
        });
    }

    protected customize() {
        this.addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
            .addFieldDescriptor({ name: "name", type: FieldType.string })
            .addFieldDescriptor({ name: "icon", type: FieldType.string })
            .addFieldDescriptor({ name: "color", type: FieldType.color })
            .addFieldDescriptor({ name: "showInMenu", type: FieldType.boolean })
            .addFieldDescriptor({ name: "positionInMenu", type: FieldType.number })
            .addFieldDescriptor({ name: "fitHeight", type: FieldType.boolean })
            .addFieldDescriptor({ name: "widgetHeaderFontSize", type: FieldType.number, clientOnly: true })
            .addFieldDescriptor({ name: "widgetHeaderBackgroundColor", type: FieldType.color, clientOnly: true })
            .addFieldDescriptor({ name: "widgetHeaderIcon", type: FieldType.string, clientOnly: true })
            .addFieldDescriptor({ name: "expandOrganizations", type: FieldType.boolean })
            .addFieldDescriptor({ name: "organization", type: "Organization" })
            .addFieldDescriptor({ name: "entityName", type: FieldType.entityName })
            .addFieldDescriptor({ name: "forEditor", type: FieldType.boolean })
            .addFieldDescriptor({ name: "filterJson", type: FieldType.filter, fieldForEntityName: "entityName", filterable: false, sortable: false })

        this.infoTable.slice = sliceEntityTablePageDashboard.setEntityDescriptor(this);
        this.infoTable.wrappedComponentClass = EntityTablePageDashboard;
        this.infoEditor.slice = sliceEntityEditorPageDashboard.setEntityDescriptor(this);
        this.infoEditor.wrappedComponentClass = EntityEditorPageDashboard;
    }

    protected getInfoEditor() {
        const result = super.getInfoEditor();
        result.routeProps!.routeIsModal = false;
        return result;
    }
}

const sliceEntityTablePageDashboard = createSliceFoundation(class Ext extends SliceEntityTablePage {

    initialState = {
        ...sliceEntityTablePageOnlyForExtension.initialState,
        deleteModalOpenedFor: undefined as { name: string, id: number } | undefined
    }

    nestedSlices = {
        ...sliceEntityTablePageOnlyForExtension.nestedSlices,
    }

    reducers = {
        ...sliceEntityTablePageOnlyForExtension.reducers, ...getBaseReducers<Ext>(this),
    }

    impures = {
        ...sliceEntityTablePageOnlyForExtension.impures, ...getBaseImpures<Ext>(this),
    }
})


class EntityTablePageDashboard extends EntityTablePage<PropsFrom<typeof sliceEntityTablePageDashboard>> {

    protected renderContextMenuItems(entity: any): React.ReactNode {
        const { entityDescriptor } = this.props.dispatchers.getSlice();
        return (<>
            <Menu.Item data-cy="edit" icon="edit" content={_msg("entityCrud.table.edit")} as={NavLink} to={entityDescriptor.getEntityEditorUrl(entity.id)}
                onClick={() => this.props.dispatchers.tableSimple.closeContextMenu()} />
            <Menu.Item icon="remove" content={_msg("entityCrud.table.delete")}
                onClick={() => {
                    this.props.dispatchers.tableSimple.closeContextMenu();
                    this.props.dispatchers.setInReduxState({ deleteModalOpenedFor: { name: entityDescriptor.name, id: entity.id } });
                }} />
        </>);
    }

    renderMain() {
        return <>
            {super.renderMain()}
            <Modal open={this.props.deleteModalOpenedFor !== undefined} closeOnDocumentClick closeOnEscape
                onClose={() => this.props.dispatchers.setInReduxState({ deleteModalOpenedFor: undefined })}>
                <Modal.Header>{_msg("Dashboard.button.delete.messageHeader")}</Modal.Header>
                <Modal.Content>
                    {_msg("Dashboard.button.delete.messageContent")}
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={() => this.props.dispatchers.setInReduxState({ deleteModalOpenedFor: undefined })}>{_msg("general.no")}</Button>
                    <Button positive onClick={() => {
                        if (!this.props.deleteModalOpenedFor) {
                            return;
                        }
                        this.props.dispatchers.deleteEntity(this.props.deleteModalOpenedFor.name, this.props.deleteModalOpenedFor.id, this.props.location)
                        this.props.dispatchers.setInReduxState({ deleteModalOpenedFor: undefined });
                    }}>{_msg("general.yes")}</Button>
                </Modal.Actions>
            </Modal>
        </>
    }
}

export const sliceEntityEditorPageDashboard = createSliceFoundation(class Ext extends SliceEntityEditorPage {

    initialState = {
        ...sliceEntityEditorPageOnlyForExtension.initialState,
        dashboardMode: DashboardMode.VIEW as DashboardMode
    }

    nestedSlices = {
        ...sliceEntityEditorPageOnlyForExtension.nestedSlices,
        dashboardTab: sliceDashboardTab
    }

    reducers = {
        ...sliceEntityEditorPageOnlyForExtension.reducers, ...getBaseReducers<Ext>(this),

        clearStateBeforeAddOrEditSuper: sliceEntityEditorPageOnlyForExtension.reducers.clearStateBeforeAddOrEdit,
        clearStateBeforeAddOrEdit(state: StateFrom<Ext>) {
            this.clearStateBeforeAddOrEditSuper(state);
            state.dashboardTab.layouts = { 'lg': [] };
            state.dashboardTab.widgetWrapperConfigs = {};
            state.dashboardTab.layoutInit = null;
        },

        onModeLoadedSuper: sliceEntityEditorPageOnlyForExtension.reducers.onModeLoaded,
        onModeLoaded(state: StateFrom<Ext>, entity: any) {
            this.onModeLoadedSuper(state, entity);
            processDashboardAfterLoad(entity);
        }

    }

    impures = {
        ...sliceEntityEditorPageOnlyForExtension.impures, ...getBaseImpures<Ext>(this),

        saveSuper: sliceEntityEditorPageOnlyForExtension.impures.save,
        async save(entity: any, navigateToTable = true, customFieldsToUpdate?: string[]) {
            // the config of the dashboard is not processed in the case of duplication, or in the case we never
            // opened the dashboard tab (except when first adding a dashboard)
            if (!this.getState().duplication && (!entity.config || this.getState().dashboardTab.layoutInit !== null)) {
                const layouts = this.getState().dashboardTab.layouts
                const currentBreakpoint = this.getState().dashboardTab.currentBreakpoint
                let xs = layouts['xs']
                let lg = layouts['lg']
                if (currentBreakpoint === 'xxs') {
                    xs = layouts['xxs']
                } else if (currentBreakpoint === 'sm' || currentBreakpoint === 'md') {
                    lg = layouts[currentBreakpoint]
                }
                const config = {
                    layouts: xs ? { 'lg': lg, 'xs': xs } : { 'lg': lg },
                    widgetWrapperConfigs: this.getState().dashboardTab.widgetWrapperConfigs,
                    widgetHeaderFontSize: entity.widgetHeaderFontSize,
                    widgetHeaderBackgroundColor: entity.widgetHeaderBackgroundColor,
                    widgetHeaderIcon: entity.widgetHeaderIcon
                };
                entity = {
                    ...entity, config: config, configJson: JSON.stringify(config)
                };
            }
            this.saveSuper(entity, navigateToTable, customFieldsToUpdate);
        }
    }

    getFieldsToRequest() {
        return super.getFieldsToRequest().concat(["configJson", "filterJson"]);
    }

})

class EntityEditorPageDashboard extends EntityEditorPage<PropsFrom<typeof sliceEntityEditorPageDashboard>> {

    constructor(props: Readonly<PropsFrom<typeof sliceEntityEditorPageDashboard>>) {
        super(props);
        const that = this;
        this.editorFormSimpleClass = class extends CrudFormInEditor {
            protected isFieldEnabled(fieldDescriptor: FieldDescriptor) {
                if ("forEditor" === fieldDescriptor.name) {
                    const widgetWrapperConfigs = that.props.entity?.configJson ? JSON.parse(that.props.entity.configJson).widgetWrapperConfigs : [];
                    if (widgetWrapperConfigs && Object.keys(widgetWrapperConfigs).length > 0) {
                        return false;
                    }
                    return true;
                } else {
                    return super.isFieldEnabled(fieldDescriptor);
                }
            }
        }
    }

    protected preRenderButtons(params: CrudEditorPageRenderHeaderParams) {
        const that = this;
        if ((params as any).dashboard) {
            return [...super.preRenderButtons(params)]
        } else {
            return [
                {
                    element: <Button key='configure' disabled={that.props.mode !== EditMode.EDIT || that.props.duplication} floated='left' positive
                        onClick={() => {
                            that.props.dispatchers.setInReduxState({ dashboardMode: DashboardMode.EDIT });
                            that.props.dispatchers.dispatch(push(this.props.dispatchers.getSlice().entityDescriptor.getEntityEditorUrl(this.props.entity.id) + '/dashboard'))
                        }}>
                        <Icon name='setting' />{_msg("Dashboard.editor.configure")}
                    </Button>
                },
                ...super.preRenderButtons(params)
            ];
        }
    }

    protected async onSaveInternal() {
        this.props.dispatchers.save(this.props.entity, false, ['id', 'name', 'configJson', 'icon', 'color', 'showInMenu', 'positionInMenu', 'organization', 'forEditor', 'entityName', 'filterJson', 'fitHeight', 'expandOrganizations']);
        this.props.dispatchers.dashboardTab.setInReduxState({
            dirty: false,
            widgetHeaderFontSize: this.props.entity.widgetHeaderFontSize,
            widgetHeaderBackgroundColor: this.props.entity.widgetHeaderBackgroundColor,
            widgetHeaderIcon: this.props.entity.widgetHeaderIcon,
        });
    }

    protected async onRevert() {
        super.onRevert();
        this.props.dispatchers.dashboardTab.setInReduxState({ dirty: false });
    }

    isDirty() {
        return super.isDirty() || this.props.dashboardTab.dirty;
    }

    protected getExtraTabPanes() {
        return [
            {
                routeProps: { path: "/dashboard" },
                menuItemProps: { icon: "chart pie", content: _msg("Dashboard.label"), disabled: (this.props.entity && this.props.entity.id ? false : true) || this.props.mode !== EditMode.EDIT || this.props.duplication },
                render: () => this.props.entity && <DashboardTab {...this.props.dashboardTab} dispatchers={this.props.dispatchers.dashboardTab}
                    mode={this.props.dashboardMode} currentOrganization={this.props.currentOrganization} // currently only root Conn Comp have this; nested: not; hence this workaround to pass the info to the nested
                    currentOrganizationToFilterBy={this.props.currentOrganizationToFilterBy}
                    editorDispatchers={this.props.dispatchers} buttons={this.renderButtons({ columnConfig: false, dashboard: true } as any)} dashboardEntity={this.props.entity} />
            }
        ];
    }

}

export function processDashboardAfterLoad(entity: Dashboard) {
    entity.config = JSON.parse(entity.configJson);
    entity.widgetHeaderFontSize = entity.config.widgetHeaderFontSize;
    entity.widgetHeaderBackgroundColor = entity.config.widgetHeaderBackgroundColor;
    entity.widgetHeaderIcon = entity.config.widgetHeaderIcon;
}

export function getAdditionalFieldsToRequest(entity: Dashboard) {
    const config: DashboardConfig = JSON.parse(entity.configJson);
    return Array.prototype.concat.apply([], Object.keys(config.widgetWrapperConfigs).map(key => {
        const w = config.widgetWrapperConfigs[key];
        const factory = DashboardWidgetFactories.INSTANCE.widgets[w.type];
        return factory.getAdditionalFieldsToRequest(w.widgetConfig);
    }));
    
}
