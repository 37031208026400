import { ConsoleSqlOutlined } from "@ant-design/icons-svg";
import { EntityDescriptor, FieldDescriptor, Utils } from "@crispico/foundation-react";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { Cell, Column, Table } from "fixed-data-table-2";
import _ from "lodash";
import moment from "moment";
import React from "react";
import Measure from "react-measure";
import { Button, Icon, Segment } from "semantic-ui-react";
import { GanttAssignmentEntities } from "./GanttAssignmentEntityDescriptor";

class GanttAssignmentTablePageState extends State {
    rows: {[key: string] : any}[] = [];
    columns: {[key: string]: string[]} = {};
}

class GanttAssignmentTablePageReducers<S extends GanttAssignmentTablePageState = GanttAssignmentTablePageState> extends Reducers<S> { }

type Props = RRCProps<GanttAssignmentTablePageState, GanttAssignmentTablePageReducers> & { entities: GanttAssignmentEntities };
type LocalState = { measuredWidth: number | undefined, measuredHeight: number | undefined };

export class GanttAssignmentTablePage extends React.Component<Props, LocalState> {
    componentDidMount() {
        this.prepareData(this.props.entities);
    }

    componentDidUpdate(prevProps: Props) {
        if (!prevProps || !_.isEqual(prevProps.entities, this.props.entities)) {
            this.prepareData(this.props.entities);
        }
    }

    private prepareData(entities: GanttAssignmentEntities) {
        if (!entities || !entities["Task"]) {
            return;
        }

        entities = _.cloneDeep(entities);
        const oags = entities["ObjectActionGroup"];
        const taskToObject: {[key: number]: number} = {};
        const missionsWithTask = new Set<number>();
        oags && Object.keys(oags).forEach(key => {
            const id = Number(key);
            const taskId = oags[id]?.object?.id;
            taskToObject[taskId] = id;
            delete entities["ObjectActionGroup"][id].object;
        });

        const rows: {[key: string]: any}[] = [];
        const populatedColumns = { "Task": new Set<string>(), "ObjectActionGroup": new Set<string>() };

        const addToRow = (populatedColumns: Set<string>, row: any, entity: any, ed: EntityDescriptor, chain: string) => {
            if (!entity) {
                return;
            }
            Object.keys(entity).forEach(columnName => {
                let chainName = chain ? (chain + "." + columnName) : columnName;
                let fdc: FieldDescriptor[] = [];
                try {
                    if (chainName.startsWith("taskGroup.rotationFlight.")) {
                        fdc = ed.getFieldDescriptorChain(chainName.substring("taskGroup.rotationFlight.".length));
                    } else {
                        fdc = ed.getFieldDescriptorChain(chainName);
                    }
                } catch {}
                const fd = fdc[fdc.length - 1];
                if (!fd) {
                    return;
                }
    
                if (ed.name === "HumanResourceSchedule" && chain === "") {
                    chainName = "humanResourceSchedule" + "." + columnName;
                }

                if (chainName === "taskGroup.rotationFlight") {
                    addToRow(populatedColumns, row, entities["Flight"][entity[columnName].id], entityDescriptors["Flight"], "taskGroup.rotationFlight");
                } else if (chainName === "taskGroup.rotationFlight.rotationFlight") {
                    return;
                }
    
                if (!fd.typeIsEntity()) {
                    row[chainName] = columnName === "id" ? entity.id : entity[columnName];    
                    populatedColumns.add(chainName);
                } else if (entities[fd.type]) {
                    const id = entity[columnName]?.id;
                    addToRow(populatedColumns, row, entities[fd.type][id], ed, chainName);
                    
                    const hrs = entities["HumanResourceSchedule"];
                    if (fd.type === "HumanResource" && hrs) {
                        Object.keys(hrs).forEach(key => {
                            if (hrs[Number(key)].humanResource?.id === id) {
                                delete hrs[Number(key)].humanResource;
                                addToRow(populatedColumns, row, hrs[Number(key)], entityDescriptors["HumanResourceSchedule"], "");
                            }
                        });
                    }
                }
            });
        }

        Object.keys(entities["Task"]).forEach(key => {
            const row: any = { "Task": {}, "ObjectActionGroup": {} };
            const id = Number(key);
            addToRow(populatedColumns["Task"], row["Task"], entities["Task"][id], entityDescriptors["Task"], "");
            if (oags && taskToObject[id]) {
                const oag = entities["ObjectActionGroup"][taskToObject[id]];
                missionsWithTask.add(oag.mission.id);
                addToRow(populatedColumns["ObjectActionGroup"], row["ObjectActionGroup"], oag, entityDescriptors["ObjectActionGroup"], "");
            }
            rows.push(row);
        });

        Object.keys(entities["Mission2"]).forEach(key => {
            const id = Number(key);
            if (missionsWithTask.has(id)) {
                return;
            }
            const row: any = { "Task": {}, "ObjectActionGroup": {} };
            const oag = { mission: { id } };
            addToRow(populatedColumns["ObjectActionGroup"], row["ObjectActionGroup"], oag, entityDescriptors["ObjectActionGroup"], "");
            rows.push(row);
        });

        const taskColumns: string[] = [];
        const taskGroupColumns: string[] = [];
        Array.from(populatedColumns["Task"]).forEach(columnName => {
            if (columnName.startsWith("taskGroup")) {
                taskGroupColumns.push(columnName);
            } else {
                taskColumns.push(columnName);
            }
        });
        this.props.r.setInReduxState({ rows, columns: { "Task": taskColumns.concat(taskGroupColumns), "ObjectActionGroup": Array.from(populatedColumns["ObjectActionGroup"]) } });
    }

    private getWidth() {
        return this.state?.measuredWidth ? this.state.measuredWidth : 0;
    }

    private getHeight() {
        return this.state?.measuredHeight ? this.state.measuredHeight - 10 : 0
    }

    private getColumnFieldDescriptorChain(ed: EntityDescriptor, column: string) {
        if (column.startsWith("humanResourceSchedule.")) {
            return entityDescriptors["HumanResourceSchedule"].getFieldDescriptorChain(column.substring("humanResourceSchedule.".length));
        } else if (column.startsWith("taskGroup.rotationFlight.")) {
            return entityDescriptors["Flight"].getFieldDescriptorChain(column.substring("taskGroup.rotationFlight.".length));
        } else {
            return ed.getFieldDescriptorChain(column);
        }
    }

    private getColumnLabel(ed: EntityDescriptor, column: string) {
        const chain = this.getColumnFieldDescriptorChain(ed, column);
        let label = column;
        if (chain[0] !== undefined) {
            label = chain.map(fd => fd.getLabel()).join(".");
        }
        if (column.startsWith("humanResourceSchedule.")) {
            label = entityDescriptors["HumanResourceSchedule"].getLabel() + "." + label;
        } else if (column.startsWith("taskGroup.rotationFlight.")) {
            const fdc = entityDescriptors["Task"].getFieldDescriptorChain("taskGroup.rotationFlight");
            label = fdc[0].getLabel() + "." + fdc[1].getLabel() + "." + label;
        }
        return ed.getLabel() + "." + label;
    }

    private renderColumns(entityType: string) {
        const ed = entityDescriptors[entityType];
        return this.props.s.columns[entityType].map(column => {
            const chain = this.getColumnFieldDescriptorChain(ed, column);
            const label = this.getColumnLabel(ed, column);
            const split = column.split(".");
            const fieldName = split[split.length - 1];
            return <Column key={column} width={200}
                header={() => {
                    return <Cell style={{ wordBreak: "break-word" }}>{label}</Cell>;
                }}
                cell={props => {
                    const value = this.props.s.rows[props.rowIndex][entityType][column];
                    const obj = { [fieldName]: value };
                    if (column.startsWith("humanResourceSchedule.")) {
                        obj[column.substring("humanResourceSchedule.".length)] = value;
                    }
                    return <Cell>{chain[0] === undefined ? JSON.stringify(value) : chain[chain.length - 1].renderField(obj)}</Cell>;
                }}
            />;
        });
    }

    private getRowsForExport() {
        const rows = [];
        const fds = this.props.s.columns["Task"].map(columnName => {
            try {
                const fdc = entityDescriptors["Task"].getFieldDescriptorChain(columnName);
                return fdc[fdc.length - 1];            
            } catch { return undefined; }
        }).concat(this.props.s.columns["ObjectActionGroup"].map(columnName => {
            try {
                const fdc = columnName.startsWith("humanResourceSchedule.")
                    ? entityDescriptors["HumanResourceSchedule"].getFieldDescriptorChain(columnName.substring("humanResourceSchedule.".length))
                    : entityDescriptors["ObjectActionGroup"].getFieldDescriptorChain(columnName);
                return fdc[fdc.length - 1];    
            } catch { return undefined; }
        }));
        rows.push(this.props.s.columns["Task"].map(column => this.getColumnLabel(entityDescriptors["Task"], column)).concat(this.props.s.columns["ObjectActionGroup"].map(column => this.getColumnLabel(entityDescriptors["ObjectActionGroup"], column))));
        this.props.s.rows.forEach(row => {
            rows.push(this.props.s.columns["Task"].map(column => row["Task"][column]).concat(this.props.s.columns["ObjectActionGroup"].map(column => row["ObjectActionGroup"][column]))
                .map((value, index) => {
                    if (!value) {
                        return "";
                    } else if (fds[index]?.type === "date") {
                        return moment(value).format(fds![index]!.format);
                    } else if (typeof value === "object") {
                        return JSON.stringify(value);
                    }
                    return value;
                })
            );
        });
        return rows;
    }

    private renderBar() {
        return <Segment className="buttonBar EntityEditorFormSimple_bar">
            <Button primary onClick={() => Utils.exportToCsv("Gantt_Assignment_Table" + "_" + moment(Utils.now()).toISOString() + ".csv", this.getRowsForExport())}><Icon name="download" /> {_msg("dto_crud.export")}</Button>
        </Segment>;
    }

    private renderTable() {
        const columns = this.props.s.columns;
        if (this.props.s.rows.length === 0 || Object.keys(columns).map(entityType => columns[entityType].length).reduce((a, c) => { return a + c; }, 0) === 0) {
            return null;
        }
        return <Table width={this.getWidth()} height={this.getHeight()} rowsCount={this.props.s.rows.length} rowHeight={40} headerHeight={80}>
            {this.renderColumns("Task")}
            {this.renderColumns("ObjectActionGroup")}
        </Table>;
    }

    render() {
        return <>
            {this.renderBar()}
            <Measure bounds onResize={contentRect => this.setState({ measuredWidth: contentRect.bounds?.width, measuredHeight: contentRect.bounds?.height })}>
                {({ measureRef }) => (<div className="flex-container flex-grow" ref={measureRef}>{this.renderTable()}</div>)}
            </Measure>
        </>;
    }
}

export const GanttAssignmentTablePageRRC = ReduxReusableComponents.connectRRC(GanttAssignmentTablePageState, GanttAssignmentTablePageReducers, GanttAssignmentTablePage);
