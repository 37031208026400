import React from "react";
import moment, { Moment } from "moment";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { DatePickerReactCalendar } from "./DatePickerReactCalendar/DatePickerReactCalendar";
import { Utils } from "@crispico/foundation-react/utils/Utils";

export class DatePickerFieldEditor extends React.Component<FieldEditorProps> {

    onChange(date: Moment | null) {
        this.props.formikProps.setFieldValue(this.props.fieldDescriptor.getFieldName(), date ? date.toISOString() : null);
        this.props.fieldDescriptor.onChange && this.props.fieldDescriptor.onChange(date);
    }

    render() {
        const value = this.props.fieldDescriptor.getFieldValue((this.props as FieldEditorProps).formikProps.values);
        const defaultValue = value ? moment(value) : undefined;
        return <>
            {/* <ShortcutRefForTest objectToPublish={this} dataTestIdSuffix={this.props.fieldDescriptor.get(FieldName()} /> */}
            <DatePickerReactCalendar format={this.props.fieldDescriptor.propsForEditor?.formatString}
                fieldName={this.props.fieldDescriptor.getFieldName()} onChange={(date: Moment | null) => this.onChange(date)} value={defaultValue}
                disabledDate={this.props.fieldDescriptor.propsForEditor?.disabledDate}
                allowClear={this.props.fieldDescriptor.propsForEditor?.allowClear} />
        </>;
    }

    tadSetDate = (date: string) => {
        this.onChange(moment(date, Utils.dateTimeFormat));
    }
}