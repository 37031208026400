import { FieldNameContentAssist, ChainItem, getMessageForField, FieldNameContentAssistRRC } from "@crispico/foundation-react/components/fieldNameContentAssist/FieldNameContentAssist";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { SelectExtOption, SelectExt } from "@crispico/foundation-react/components/selectExt/SelectExt";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { EntityDescriptorForServerUtils } from "@crispico/foundation-react/flower/entityDescriptorsForServer/EntityDescriptorForServerUtils";
import { FIELDS_READ } from "@crispico/foundation-react/utils/Utils";
import { TestsAreDemoCheat } from "@famiprog-foundation/tests-are-demo";
import React from "react";
import { Button, Icon, Menu, Modal } from "semantic-ui-react";

export class EntityFieldsFieldEditor extends React.Component<FieldEditorProps> {

    fieldNameContentAssistRef = React.createRef<FieldNameContentAssist>();

    dropdownRef = React.createRef<any>();

    state = {
        contextMenuPosition: false as boolean | [number, number],
        fieldNameContentAssistOpen: false,
        selectionError: false,
        entityName: undefined as string | undefined
    }

    componentDidUpdate(prevProps: FieldEditorProps) {
        if (prevProps.formikProps.values[this.props.fieldDescriptor.fieldForEntityName] !== this.props.formikProps.values[this.props.fieldDescriptor.fieldForEntityName]) {
            this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, '')
        }
    }

    protected onChange = (selectedAsString: SelectExtOption[]) => { this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, this.getValue(selectedAsString)) }

    protected setComposedField = (composedField: string, value: any) => {
        if (!value.includes(composedField)) {
            this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, (value.length > 1 && this.props.fieldDescriptor.allowMultiple ? value + ',' : '') + composedField)
        }
    }

    protected onSubmit = (options: any[], chain: ChainItem[]) => {
        if (chain.length === 0) {
            this.setState({ selectionError: true })
        } else {
            const composedField = chain.map(c => c.field).join('.')
            this.setComposedField(composedField, this.props.fieldDescriptor.getFieldValue(this.props.formikProps.values))
            this.setState({ fieldNameContentAssistOpen: false })
        }
    }

    protected addAll = () => {
        this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, this.getValue(this.getOptions()))
    }

    removeAll = () => {
        this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, this.getValue([]))
    }

    protected getItemsFromValue(value: any): any[] {
        return value ? value.split(',') : [];
    }

    protected getValue(items: SelectExtOption[]): any {
        return items.map(i => i.value).join(',');
    }

    protected getDropdownOptionFromItem(field: any, entityName: string): SelectExtOption {
        return { value: field, label: getMessageForField(field, entityName) }
    }

    protected getEntityName() {
        return this.props.formikProps.values[this.props.fieldDescriptor.fieldForEntityName];
    }

    protected getOptions() {
        const entityName = this.getEntityName();
        const fields = entityDescriptors[entityName].getAuthorizedFields(FIELDS_READ);
        const options = [] as { value: string, label: string }[];

        for (const field of Object.keys(fields)) {
            if (this.props.fieldDescriptor.showOnlyAuditableFields && EntityDescriptorForServerUtils.getFieldId(entityName, field) === undefined) {
                continue;
            }
            if (this.props.fieldDescriptor.showOnlyComposedFields && !fields[field].typeIsEntity()) {
                continue;
            }
            options.push({ value: field, label: fields[field].getLabel() });
        }

        return options;
    }

    protected getDropdownProps() {
        const entityName = this.getEntityName();
        const entityDescriptor = entityDescriptors[entityName];
        if (this.state.entityName && this.state.entityName !== entityName) {
            // entityName has changed, but componentDidUpdate is called after this! so the reset done also here
            this.setState({ entityName: entityName });
            this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, '');
        }
        let selected: SelectExtOption[] = []
        let options: SelectExtOption[] = []
        if (entityDescriptor) {
            const fields = entityDescriptor.getAuthorizedFields(FIELDS_READ);
            options = this.getOptions()
            const value = this.props.fieldDescriptor.getFieldValue(this.props.formikProps.values)
            const items = this.getItemsFromValue(value)
            selected = value ? items.map((item: any) => {
                const option = this.getDropdownOptionFromItem(item, entityName);
                if (!fields[item]) {
                    options.push(option)
                }
                return option
            }) : []
        }
        return { entityName: entityName, selected: selected, options: options };
    }

    getMenuOptions() {
        return [
            <Menu.Item disabled={!this.props.fieldDescriptor.allowMultiple} onClick={() => this.addAll()}><Icon name='add circle' /> {_msg('ColumnConfigEntityEditorPage.addAll')}</Menu.Item>,
            <Menu.Item onClick={() => this.removeAll()}><Icon name='remove circle' /> {_msg('ColumnConfigEntityEditorPage.removeAll')}</Menu.Item>,
            <Menu.Item disabled={this.props.fieldDescriptor.noComposedFields} onClick={() => this.setState({ fieldNameContentAssistOpen: true })}><Icon name='list layout' /> {_msg('form.composedField')}</Menu.Item>
        ];
    }

    renderModals(entityName: string) {
        return <>
            <ModalExt className='EntityTableSimple_menuModal' closeIcon={false} open={this.state.contextMenuPosition} onClick={() => this.setState({ contextMenuPosition: false })} onClose={() => this.setState({ contextMenuPosition: false })}>
                {this.state.contextMenuPosition && <Menu vertical className="wh100">{this.getMenuOptions()}</Menu>}
            </ModalExt>
            <ModalExt canChangeDimension open={this.state.fieldNameContentAssistOpen} onClose={() => this.setState({ fieldNameContentAssistOpen: false })} maxWidth={580}>
                <Modal.Header>{_msg('form.composedField.selection')}</Modal.Header>
                <Modal.Content>
                    <FieldNameContentAssistRRC id='fieldNameContentAssist' ref={this.fieldNameContentAssistRef} rootEntity={entityName} includeScalarFields={false} field='' />
                    <ModalExt open={this.state.selectionError} onClose={() => this.setState({ selectionError: false })} maxWidth={400}>
                        <Modal.Header>{_msg('form.composedField.selection.error.header')}</Modal.Header>
                        <Modal.Content>
                            <p>{_msg('form.composedField.selection.error.message')}</p>
                            <Button centered onClick={() => this.setState({ selectionError: false })}>{_msg('general.ok')}</Button>
                        </Modal.Content>
                    </ModalExt>
                </Modal.Content>
                <Modal.Actions>
                    <Button className='FilterForm_applyButton' color='green' onClick={() => this.onSubmit(this.fieldNameContentAssistRef.current?.getOptions()!, this.fieldNameContentAssistRef.current?.getChain()!)}>{_msg('general.ok')}</Button>
                    <Button className='FilterForm_applyButton' color='red' onClick={() => this.setState({ fieldNameContentAssistOpen: false })}>{_msg('general.cancel')}</Button>
                </Modal.Actions>
            </ModalExt>
        </>;
    }

    renderButtons(propsForEditor: any) {
        return !propsForEditor?.hideOptions && !this.props.fieldDescriptor.showOnlyComposedFields && <Button primary icon="bars" floated='right' onClick={e => { e.preventDefault(); this.setState({ contextMenuPosition: [e.clientX, e.clientY] }) }} />;
    }

    renderMain(defaultValue: SelectExtOption[], options: SelectExtOption[], propsForEditor: any) {
        return <div className='flex-container-row flex-grow-shrink-no-overflow'>
            <div className='flex-grow-shrink-no-overflow'>
                <SelectExt options={options} defaultValue={defaultValue} onSelectedOptionsChange={this.onChange}
                    isMulti={this.props.fieldDescriptor.allowMultiple}
                    hideMenu={propsForEditor?.hideOptions} appendDoneMenuEntry={true}
                    customMenu={propsForEditor?.customMenu} closeOnKeys={this.props.fieldDescriptor.allowMultiple? ["ArrowUp", "ArrowDown"] : ["Enter", "ArrowUp", "ArrowDown"]}
                    menuPosition={propsForEditor?.menuPosition}
                    placeholder={propsForEditor?.placeholder} isSearchable={propsForEditor?.isSearchable}
                    formatOptionLabel={propsForEditor?.formatOptionLabel}
                />
            </div>
            <div style={{ flexGrow: 0 }}>{this.renderButtons(propsForEditor)}</div>
            <TestsAreDemoCheat objectToPublish={this} />
        </div>
    }

    render() {
        const { entityName, selected, options } = this.getDropdownProps();
        return <>
            {this.renderMain(selected, options, this.props.fieldDescriptor.propsForEditor)}
            {this.renderModals(entityName)}
        </>
    }

    ////////////////////////////////////////////////////////////////////////////////////////
    ////// Test functions
    ////////////////////////////////////////////////////////////////////////////////////////
    tadRemoveAll = () => {
        this.removeAll();
    }

    tadAddOption = (value: string) => {
        let selectedOption = this.getOptions().filter((option: { value: string, label: string }) => option.label.toLowerCase().includes(value.toLowerCase()));
        let previousValues = this.props.formikProps.values[this.props.fieldDescriptor.name];
        this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, { columns: [...previousValues?.columns, ...this.getValue(selectedOption)?.columns] });
    }
}