import React from "react";
import moment from "moment";
import { ChartConfig, ChartTab, ChartTabProps, ChartTabReducers, ChartTabState, getChartGenerationPeriodFromConfig } from "../ChartTab";
import { ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { HistogramWithDetailsRRC, HistogramPoint, HistogramSerie } from "@crispico/foundation-react/components/histogramWithDetails/HistogramWithDetails";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { NavLink } from "react-router-dom";


export class HistogramCountInTerritoriesTabState extends ChartTabState {
    histogramData = [] as Array<HistogramSerie>;
    initialStartDate = 0 as number;
    initialEndDate = 0 as number;
}

export class HistogramCountInTerritoriesTabReducers<S extends HistogramCountInTerritoriesTabState = HistogramCountInTerritoriesTabState> extends ChartTabReducers<S> {

    private getEntityLabel(entity: any, entityType: string) {
        return <NavLink to={entityDescriptors[entityType].getEntityEditorUrl(entity.id)}>{entityDescriptors[entityType].toMiniString(entity)}</NavLink>
    }

    private convertEntitiesToLabel(entities: any[]) {
        return entities.map(entity => this.getEntityLabel(entity, "EquipmentResource"));
    }

    prepareData(p: { savedData: string, config: ChartConfig }) {
        this.s.initialStartDate = moment(p.config.startDate).toDate().getTime();
        this.s.initialEndDate = moment(p.config.endDate).toDate().getTime();

        let result = JSON.parse(p.savedData) as Array<{ territory: any, entries: Array<{ date: string, total: Array<any>, exited: Array<any>, entered: Array<any> }> }>;

        const histogramData: Array<HistogramSerie> = [];
        let id = 0;
        result.forEach(h => {
            const points: Array<HistogramPoint> = []
            let index = 0;
            h.entries.forEach(e => {
                points.push({
                    id: id++,
                    index: index++,
                    x: moment(e.date).valueOf(),
                    y: e.total.length,
                    total: this.convertEntitiesToLabel(e.total),
                    out: this.convertEntitiesToLabel(e.exited),
                    in: this.convertEntitiesToLabel(e.entered)
                })
            })
            histogramData.push({ id: h.territory.name, label: this.getEntityLabel(h.territory, "Territory"), color: h.territory.color, data: points });
        })
        this.s.histogramData = histogramData;
    }

}

type Props = RRCProps<HistogramCountInTerritoriesTabState, HistogramCountInTerritoriesTabReducers> & ChartTabProps;
type LocalState = {}
export class HistogramCountInTerritoriesTab extends ChartTab<Props, LocalState> {


    render() {
        return <div className="flex-container flex-grow-shrink-no-overflow less-padding gap5">
            {this.renderTopBar(moment(this.props.s.initialStartDate), moment(this.props.s.initialEndDate))}
            <HistogramWithDetailsRRC id="histogramWithDetails" data={this.props.s.histogramData} startDate={this.props.s.initialStartDate} endDate={this.props.s.initialEndDate} legendY={entityDescriptors["EquipmentResource"].getLabel(true)} gridSerieLabel={entityDescriptors["Territory"].getLabel()}/>
        </div>;
    }
}

export const HistogramCountInTerritoriesTabRRC = ReduxReusableComponents.connectRRC(HistogramCountInTerritoriesTabState, HistogramCountInTerritoriesTabReducers, HistogramCountInTerritoriesTab);
