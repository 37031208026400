import gql from "graphql-tag";

export const EQUIPMENT_RESOURCE_FOR_MAP = gql`
    fragment EquipmentResourceForMap on EquipmentResource {
        astusId      
        available   
        comment
        customFields(whichFields: $whichFields)
        equipmentType {id name icon color}
        equipmentModel {id name}            
        id identifier imei
        tags {id tag {id name color colorRegistryColor }} 
        unit {id name tagsForResources} 
        updated vehicleId visible lastDetectionDate
        lastPointLatitude lastPointLongitude
        territoriesName
        territories
        enteredTerritories
        exitedTerritories
        organization {id name qualifiedName }
        plateNumber odometer engineHours closestAddress { id name } engineState status
    }
`;

export const FIND_EQUIPMENT_RESOURCE_FOR_MAP_BY_ID = gql`
    query findEquipmentResourceForMapById($id: Long, $whichFields: [String]) { 
        equipmentResourceService_findById(id: $id) {
            ...EquipmentResourceForMap
        }
    }
    ${EQUIPMENT_RESOURCE_FOR_MAP}
`;

export const LOGIN = gql`
    mutation login($loginParams: LoginParamsInput!) { 
        authService_login(loginParams: $loginParams) { status userDetails { username } messageKey messageParams token }
    }
`;

export const LOGOUT = gql`
    mutation logout($logoutParams: LogoutParamsInput!) {
        authService_logout(logoutParams: $logoutParams)
    }
`;

export const ORGANIZATION_FOR_MAP = gql`
    fragment OrganizationForMap on Organization {
        id name qualifiedName airport { id code latitude longitude }
    }   
`;

export const LOAD_ORGANIZATIONS_FOR_MAP = gql`
    query loadOrganizationsForMap($params: FindByFilterParamsInput) { 
        organizationService_findByFilter(params: $params) {
            results  { ...OrganizationForMap } 
        }
    }
    ${ORGANIZATION_FOR_MAP}
`;
