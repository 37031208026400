
import { Messages } from "@crispico/foundation-gwt-js";
import React from "react";
import { FieldRendererProps } from "../fieldRenderersEditors";
import { Label } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

export type StringFieldRendererSpecificProps = { asLabel?: boolean, asLink?: boolean, showTooltip?: boolean, showIcon?: boolean, showMeasurementUnit: boolean };
export class StringFieldRenderer<P extends FieldRendererProps, S = {}> extends React.Component<P, S> {

    protected getValue() {
        return this.props.value;
    }

    protected getUrl() {
        return this.props.fieldDescriptor.parent?.getEntityEditorUrl(this.props.entity?.id);
    }

    protected getText() {
        const { props } = this;
        const value = props.fieldDescriptor.getFieldValueConvertedToMeasurementUnit(this.getValue());
        const showMU: boolean = (props.rendererSpecificProps as StringFieldRendererSpecificProps)?.showMeasurementUnit;

        let text = '';
        if (value !== undefined && value !== null) {
            if (typeof value !== "string") {
                text = JSON.stringify(value);
            } else {
                text = Messages.getInstance().maybeTranslateByUser(value);
            }
        }
        if (props.fieldDescriptor.prepareValue) {
            text = props.fieldDescriptor.prepareValue(text);
        }
        let muLabel;
        if (showMU) {
            muLabel = props.fieldDescriptor.getMeasurementUnitLabel();
        }
        return text + (muLabel ? " " + Messages.getInstance().maybeTranslateByUser(muLabel) : "");
    }

    protected getContent() {
        const { props } = this;
        const options = props.rendererSpecificProps as StringFieldRendererSpecificProps;
        return <div data-testid="StringFieldRenderer" className="StringFieldRenderer" style={props.fieldDescriptor.getFieldColors(props.value)}
            data-tooltip={options?.showTooltip && props.fieldDescriptor.getLabel()} data-position="top center">
            {options?.showIcon && props.fieldDescriptor.getIcon()}{this.getText()}
        </div>
    }

    protected renderLabel(props: any, content: any) {
        return <Label key={props.entity?.id + "." + props.fieldDescriptor.name}
            style={props.fieldDescriptor.getFieldColors(props.value)}>
            {props.fieldDescriptor.wrapComponent(props.value, content)}
        </Label>;
    }

    protected getNavLinkExtraProps() {
        return {};
    }

    render() {
        const { props } = this;
        const options = props.rendererSpecificProps as StringFieldRendererSpecificProps;
        let content = this.getContent();
        if (options?.asLink) {
            const url = this.getUrl();
            content = <NavLink {...this.getNavLinkExtraProps()} to={url ? url : ""}>{content}</NavLink>;
        }
        if (options?.asLabel) {
            return this.renderLabel(props, content);
        }
        return props.fieldDescriptor.wrapComponent(props.value, content);
    }
}

export default StringFieldRenderer;
