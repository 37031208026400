import { apolloClientHolder, createSliceFoundation, EntityDescriptor, EntityTablePage, ENT_SAVE, PropsFrom, SliceEntityTablePage, Utils } from "@crispico/foundation-react";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { OverrideableElement } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { ganttAssignmentEntityDescriptor } from "AppEntityDescriptors";
import { RECALCULATE_FOR_TASK_TABLE, SAVE_FLIGHT_APU_OFF_SHOULD_PROCESS } from "pages/ganttAssignment/queries";
import { Button } from "semantic-ui-react";

const sliceTaskEntityTablePage = createSliceFoundation(class Ext extends SliceEntityTablePage {
});

class TaskEntityTablePage extends EntityTablePage<PropsFrom<typeof sliceTaskEntityTablePage>> {
    protected preRenderButtons(params: {}): Array<OverrideableElement> {
        return [{
            element: <Button key="recalculate" positive onClick={async () => {
                const id = (await apolloClientHolder.apolloClient.mutate({
                    mutation: RECALCULATE_FOR_TASK_TABLE, variables: { timezone: Intl.DateTimeFormat().resolvedOptions().timeZone }
                })).data["ganttAssignmentService_recalculateForTaskTable"];
                if (id) {
                    await apolloClientHolder.apolloClient.mutate({ mutation: SAVE_FLIGHT_APU_OFF_SHOULD_PROCESS, variables: { assignmentId: id } });
                }
                this.refresh();
            }} disabled={!AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_SAVE, ganttAssignmentEntityDescriptor.name]))}
            >{_msg("Task.table.recalculate")}</Button>
        }];
    }
}

export class TaskEntityDescriptor extends EntityDescriptor {
    constructor() {
        super({
            name: "Task"
        });
    }

    protected customize() {
        this.infoTable.slice = sliceTaskEntityTablePage.setEntityDescriptor(this);
        this.infoTable.wrappedComponentClass = TaskEntityTablePage;
    }
}