import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { FieldType, fieldTypeToPrimitiveFieldTypeMapping } from "@crispico/foundation-react/entity_crud/FieldType";
import { Utils } from "@crispico/foundation-react/utils/Utils";

class FilterFieldEditorFactory {

    getOperators(type: string) {
        let fieldType = fieldTypeToPrimitiveFieldTypeMapping[type];
        return FilterOperators.getOperatorsForType(fieldType ? fieldType : FilterOperators.TYPE_MANY_TO_ONE).getVisibleOperators()
    }

    renderValueEditor(op: string, type: string, props: FieldEditorProps) {
        let fieldType = fieldTypeToPrimitiveFieldTypeMapping[type];
        if (FilterOperators.noValueOperators.find(operator => operator.value === op)) {
            return undefined;
        }
        if (fieldType === FilterOperators.TYPE_NUMBER) {
            if (op === FilterOperators.forNumber.in.value || op === FilterOperators.forNumber.notIn.value || op === FilterOperators.forNumber.between.value) {
                props.fieldDescriptor.type = FieldType.string;
            } else {
                props.fieldDescriptor.type = FieldType.number;
            }
        } else if (fieldType === FilterOperators.TYPE_DATE) {
            if (FilterOperators.twoValuesOperators.find(operator => operator.value === op)) {
                props.fieldDescriptor.propsForEditor = { hasTime: true, formatString: Utils.dateTimeFormat }
                props.fieldDescriptor.type = FieldType.rangeDate;
            } else if (FilterOperators.dateOperatorsWithNumberValue.find(operator => operator.value === op)) {
                props.fieldDescriptor.type = FieldType.number;
            } else {
                if (op !== FilterOperators.forDate.dayOf.value) {
                    props.fieldDescriptor.propsForEditor = { hasTime: true, formatString: Utils.dateTimeFormat }
                }
                props.fieldDescriptor.type = FieldType.date;
            }
        } else if (!fieldType && entityDescriptors[type] && (FilterOperators.forEntityOneToMany.in.value === op || FilterOperators.forEntityOneToMany.notIn.value === op)) {
            props.fieldDescriptor.type = '[' + type + ']';
        } else {
            props.fieldDescriptor.type = type;
        }
        return props.fieldDescriptor.renderFieldEditor(props.formikProps)
    }

}

export let filterFieldEditorFactory = new FilterFieldEditorFactory();