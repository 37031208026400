import { TestsAreDemoAppWrapper } from "@famiprog-foundation/tests-are-demo";
import App from "app";
import ReactDOM from 'react-dom';
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";
import Dimmer from "semantic-ui-react/dist/commonjs/modules/Dimmer";

function renderApp() {
    ReactDOM.render(<TestsAreDemoAppWrapper app={<App />} importTestsCallback={async () => await import("./appTests")} />, document.getElementById('root'));
}

async function initApp() {
    const wrapper = await import("pages/XopsMobile/XopsMobileWrapper");  
    if (wrapper.XopsMobileWrapper.isDeviceMobileApp()) { 
        ReactDOM.render(<Dimmer active page><Loader size='large'>{_msg("general.loading")}</Loader></Dimmer>, document.getElementById('root'));
        console.log("adding cordova.js!");      
        // load cordova.js
        var cordovaScript = document.createElement('script');
        cordovaScript.setAttribute('src', 'cordova.js');
        // append the script tag to document head
        document.head.appendChild(cordovaScript);

        console.log("waiting for deviceready!");
        // Init app on device ready
        document.addEventListener("deviceready", () => {
            console.log("deviceready!");  
            // at the end, render the app
            renderApp();          
        }, false);       
    } else {        
        renderApp();
    }
}

initApp();