import { addEntityDescriptor, EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { EntityTablePage, EntityTablePageProps, SliceEntityTablePage, sliceEntityTablePageOnlyForExtension } from "@crispico/foundation-react/entity_crud/EntityTablePage";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import React from "react";
import { ChecklistReportTab, sliceChecklistReportTab } from "./ChecklistReport";
import { createSliceFoundation, PropsFrom } from "@crispico/foundation-react/reduxHelpers";
import { ChecklistEntityEditorPage, sliceChecklistEntityEditorPage } from "./ChecklistEditorPage";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";

export const checklistEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
    name: "Checklist",
    miniFields: ["template", "vehicle", "user"],
    icon: "list ol",
    defaultSort: { field: "creationDate", direction: "DESC" },
    defaultFilter: Filter.createForClient("creationDate", FilterOperators.forDate.today),
    toMiniString(entityWithMiniFields: any): string {
        return entityWithMiniFields.template + ', ' + entityDescriptors['EquipmentResource'].toMiniString(entityWithMiniFields.vehicle) + ', ' + entityDescriptors['User'].toMiniString(entityWithMiniFields.user)
    }
})
    .isInDefaultColumnConfig(true, "creationDate", "user", "vehicle", "template")
    .addFieldDescriptor({ name: "id", type: FieldType.string, enabled: false })
    // .addFieldDescriptor({ name: "mission", type: FieldType.defaultManyToOne })
);

checklistEntityDescriptor.infoEditor.slice = sliceChecklistEntityEditorPage.setEntityDescriptor(checklistEntityDescriptor)
checklistEntityDescriptor.infoEditor.wrappedComponentClass = ChecklistEntityEditorPage

export const sliceEntityTablePageChecklist = checklistEntityDescriptor.infoTable.slice = createSliceFoundation(class Ext extends SliceEntityTablePage {
    nestedSlices = {
        ...sliceEntityTablePageOnlyForExtension.nestedSlices,
        checklistReportTab: sliceChecklistReportTab
    }
}).setEntityDescriptor(checklistEntityDescriptor)

export type ChecklistEntityTablePageProps = EntityTablePageProps & PropsFrom<typeof sliceEntityTablePageChecklist>

checklistEntityDescriptor.infoTable.wrappedComponentClass = class extends EntityTablePage<ChecklistEntityTablePageProps> {    
    protected getExtraTabPanes() {
        return [{
            routeProps: { path: "/Report" },
            menuItemProps: { content: _msg("Checklist.report"), icon: "chart pie" },
            render: () => <ChecklistReportTab {...this.props.checklistReportTab} dispatchers={this.props.dispatchers.checklistReportTab} />
        }];
    }
}
