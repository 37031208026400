import { FilterOperators } from "@crispico/foundation-gwt-js";
import { apolloClientHolder, Utils } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { getHistoryGraphColor, HistoryGraphData, HistoryGraphItem, HistoryGraphItemProps, HistoryGraphItemReducers, HistoryGraphItemState } from "@crispico/foundation-react/pages/Audit/historyGraphItem/HistoryGraphItem";
import { ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { getPlateNumberFromIds_equipmentResourceService_findByFilter_results } from "apollo-gen/getPlateNumberFromIds";
import { PositionForHistoryGraphMap } from "apollo-gen/PositionForHistoryGraphMap";
import { appMeta, MapSettings } from "app";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { MapContainerLeaflet, MapContainerLeafletRRC, POLYLINE_TYPE } from "../../components/MapContainerLeaflet/MapContainerLeaflet";
import { GET_PLATE_NUMBERS_FROM_IDS, LOAD_POSITIONS_FOR_HISTORY_GRAPH_MAP } from "./queries";

const TRACK = "track";

class HistoryGraphMapItemState extends HistoryGraphItemState {
    equipmentResourceMap = {} as {[id: number]: { plateNumber?: string, positions: PositionForHistoryGraphMap[] }};
}

class HistoryGraphMapItemReducers<S extends HistoryGraphMapItemState = HistoryGraphMapItemState> extends HistoryGraphItemReducers<S> {}

type HistoryGraphMapItemProps = RRCProps<HistoryGraphMapItemState, HistoryGraphMapItemReducers> & HistoryGraphItemProps & { editor: { props: { mapSettings: MapSettings, entity: { id: number, plateNumber: string } } } };

export class HistoryGraphMapItem extends HistoryGraphItem<HistoryGraphMapItemProps> {
    private mapContainerRef = React.createRef<MapContainerLeaflet>();

    getName() {
        return _msg("HistoryGraphMapItem.name");
    }

    protected componentDidUpdateInternal(prevProps?: HistoryGraphItemProps) {
        super.componentDidUpdateInternal(prevProps);
        if (this.mapContainerRef.current && prevProps && !_.isEqual(prevProps.s.selected, this.props.s.selected)) {
            this.selectTrack(this.mapContainerRef.current, prevProps.s.selected);
        }
        if (this.mapContainerRef.current && prevProps && prevProps.showCurrentTime && !this.props.showCurrentTime) {
            this.props.r.setInReduxState({ selected: this.getSelected() });
        }
        if (prevProps && (prevProps.currentStartDate !== this.props.currentStartDate || prevProps.currentEndDate !== this.props.currentEndDate)) {
            this.addTracks(this.mapContainerRef.current!);
        }
    }

    private clearTrack(mapContainer: MapContainerLeaflet) {
        mapContainer.clearMap([TRACK]);
    }

    private addTracks(mapContainer: MapContainerLeaflet) {
        this.clearTrack(mapContainer);
        this.props.r.setInReduxState({ selected: {} });
        this.props.entities.forEach(entity => {
            this.addTrack(mapContainer, entity.id);
        });
    }

    private addTrack(mapContainer: MapContainerLeaflet, id: number) {
        let positions = this.props.s.equipmentResourceMap[id]?.positions;
        if (!positions) {
            return;
        }
        if (this.props.currentStartDate && this.props.currentEndDate) {
            positions = positions.filter(pos => this.props.currentStartDate! <= pos.date && pos.date <= this.props.currentEndDate!);
        }
        if (positions.length === 0) {
            return;
        }
        let i: number;
        this.props.entities.forEach((entity, index) => {
            if (entity.id === id) {
                i = index;
            }
        });
        mapContainer.addOrUpdateLayers([{ id: id, points: positions, color: getHistoryGraphColor(i!) }], TRACK);
    }

    private selectTrack(mapContainer: MapContainerLeaflet, prevSelected?: {[id: number]: number}) {
        Object.keys(this.props.s.equipmentResourceMap).forEach(key => {
            const id = Number(key);
            const positions = this.props.s.equipmentResourceMap[id].positions;
            const pointId = this.props.s.selected[id];
            if (prevSelected && prevSelected[id] !== undefined && prevSelected[id] !== pointId) {
                mapContainer.props.r.unhighlightPoint({ layerType: TRACK, layerId: id, pointId: prevSelected[id] });
            }
            if (pointId == undefined) {
                return;
            }
            const trackStart = positions.find(pos => pos.id === pointId);
            if (!trackStart) {
                return;
            }
            mapContainer.props.r.highlightPoint({ layerType: TRACK, layerId: id, pointId: trackStart.id });
            if (this.props.entities.length < 2) {
                mapContainer.getMap().setView({ lat: trackStart.latitude!, lng: trackStart.longitude! }, mapContainer.getMap().getZoom());
            }
        });
    }

    protected async loadPlateNumbers() {
        const equipmentResourceMap = _.cloneDeep(this.props.s.equipmentResourceMap);
        const equipmentResourcesToLoad: number[] = [];
        this.props.entities.forEach(entity => {
            if (equipmentResourceMap[entity.id]?.plateNumber) {
                return;
            }
            if (!equipmentResourceMap[entity.id]) {
                equipmentResourceMap[entity.id] = { positions: [] };
            }
            if (entity.plateNumber) {
                equipmentResourceMap[entity.id].plateNumber = entity.plateNumber;
            } else {
                equipmentResourcesToLoad.push(entity.id);
            }
        });
        if (equipmentResourcesToLoad.length === 0) {
            this.props.r.setInReduxState({ equipmentResourceMap });
            return equipmentResourceMap;
        } 
        const params = FindByFilterParams.create().filter(Filter.create("id", FilterOperators.forNumber.in, equipmentResourcesToLoad.join(",")));
        const results: getPlateNumberFromIds_equipmentResourceService_findByFilter_results[] = (await apolloClientHolder.apolloClient.query({
            query: GET_PLATE_NUMBERS_FROM_IDS, context: { showSpinner: false }, variables: params
        })).data["equipmentResourceService_findByFilter"].results;
        results.forEach(result => {
            if (!result.plateNumber) {
                return;
            }
            equipmentResourceMap[result.id].plateNumber = result.plateNumber;
            equipmentResourceMap[result.id].positions = [];
        });
        this.props.r.setInReduxState({ equipmentResourceMap });
        return equipmentResourceMap;
    }

    protected async refresh() {
        let equipmentResourceMap = await this.loadPlateNumbers();
        const plateNumberFilter = Filter.createComposed(FilterOperators.forComposedFilter.or, []);
        Object.keys(equipmentResourceMap).forEach(key => {
            const id = Number(key);
            if (!equipmentResourceMap[id].plateNumber) {
                return;
            }
            plateNumberFilter.filters?.push(Filter.create("plateNumber", FilterOperators.forString.equals, equipmentResourceMap[id].plateNumber));
        });
        
        const { startDate, endDate } = this.props;
        const params = FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.and, [
            Filter.create("date", FilterOperators.forDate.greaterThanOrEqualTo, moment(startDate).toISOString()),
            Filter.create("date", FilterOperators.forDate.lessThanOrEqualTo, moment(endDate).toISOString()),
            plateNumberFilter,
            Filter.create("latitude", FilterOperators.forNumber.isNotEmpty),
            Filter.create("longitude", FilterOperators.forNumber.isNotEmpty)
        ])).sorts([{ field: "date", direction: "ASC" }]);

        const results: PositionForHistoryGraphMap[] = (await apolloClientHolder.apolloClient.query({
            query: LOAD_POSITIONS_FOR_HISTORY_GRAPH_MAP, context: { showSpinner: false }, variables: params
        })).data["positionService_findByFilter"].results;

        if (results.length === 0) {
            return;
        }

        equipmentResourceMap = _.cloneDeep(equipmentResourceMap);
        const plateNumberToIdMap: {[key: string]: number} = {};
        Object.keys(equipmentResourceMap).forEach(key => {
            const id = Number(key);
            const plateNumber = equipmentResourceMap[id].plateNumber;
            if (plateNumber) {
                plateNumberToIdMap[plateNumber] = id;
            }
            equipmentResourceMap[id].positions = [];
        });

        results.forEach(result => {
            equipmentResourceMap[plateNumberToIdMap[result.plateNumber]].positions.push({ ...result, date: moment(result.date).toDate().getTime() });
        });

        this.props.r.setInReduxState({ equipmentResourceMap });

        if (this.mapContainerRef.current) {
            this.addTracks(this.mapContainerRef.current);
            if (this.props.entities.length === 1 && this.props.s.equipmentResourceMap[this.props.entities[0].id]) {
                const positions = this.props.s.equipmentResourceMap[this.props.entities[0].id].positions;
                if (positions.length > 0) {
                    this.mapContainerRef.current.getMap().setView({ lat: positions[0].latitude!, lng: positions[0].longitude! }, this.mapContainerRef.current.getMap().getZoom());
                }
            }
        }
    }

    protected createPositionForTable(position: PositionForHistoryGraphMap): HistoryGraphData {
        return { id: position.id, date: moment(position.date).toDate().getTime(), label: "Position", value: "[" + position.latitude + " ," + position.longitude + "]" };
    }

    protected getData() {
        const props = this.props;
        const ids = Object.keys(props.s.equipmentResourceMap).map(key => Number(key));
        const compactTable = ids.length > 1 && Object.keys(props.s.selected).length > 0 && this.isAnimationOn();
        let data: HistoryGraphData[] = [];
        ids.forEach(id => {
            const positions = props.s.equipmentResourceMap[id].positions;
            let position = undefined;
            if (compactTable && !Utils.isNullOrEmpty(props.s.selected[id])) {
                position = positions.find(pos => pos.id === props.s.selected[id]);
            }
            data = data.concat((position ? [position] : positions).map(pos => this.createPositionForTable(pos)));
        });
        return data;
    }

    protected getSelected() {
        const props = this.props;
        const selected: { [id: number]: number } = {};
        Object.keys(props.s.equipmentResourceMap).forEach(key => {
            const id = Number(key);
            const positions = props.s.equipmentResourceMap[id].positions.filter(position => position.date <= props.currentTime!);
            if (positions.length === 0) {
                return;
            }
            selected[id] = positions.reduce(function(a, b) {
                return (a.date > b.date) ? a : b;
            }).id;
        });
        return selected;
    }

    protected showError() {
        return false;
    }

    protected renderItem() {
        return <div className="no-padding-margin flex-container flex-grow-shrink-no-overflow MapRealTime_topParent" style={{ height: this.getHeight() }}><MapContainerLeafletRRC id={this.getName() + this.props.s.displayMode} ref={this.mapContainerRef} saveCenterZoomInStorage={true}
            mapId={appMeta.getMapSettings().bingAPIKey} pruneClusterMode={false}
            layers={{[TRACK]: { layerType: POLYLINE_TYPE }}}
        /></div>;
    }
}

export const HistoryGraphMapItemRRC = ReduxReusableComponents.connectRRC(HistoryGraphMapItemState, HistoryGraphMapItemReducers, HistoryGraphMapItem);
