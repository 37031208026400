import _, { isEqual } from "lodash";
import moment from "moment";
import React, { ReactNode } from "react";
import { Button, Dimmer, Header, Icon, Loader, Popup, Segment } from "semantic-ui-react";
import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { EntityTableSimple, sliceEntityTableSimple } from "@crispico/foundation-react/entity_crud/EntityTableSimple";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { FIELDS_READ, Utils } from "@crispico/foundation-react/utils/Utils";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { ConnectedComponentInSimpleComponent, ConnectedPageInfo } from "@crispico/foundation-react/reduxHelpers";
import { categoricalColorSchemes } from "@nivo/colors";

export type HistoryGraphData = {
    id: number,
    label: string,
    date: number,
    value: any
};

export enum DisplayMode { CHART, ALL };

export const HISTORY_GRAPH_ITEM_DEFAULT_HEIGHT = 400;

export function HistoryGraphItemEntityDescriptor() {
    return new EntityDescriptor({ name: "HistoryGraphItem" }, false)
    .addFieldDescriptor({ name: "label", type: FieldType.string })
    .addFieldDescriptor({ name: "date", type: FieldType.date, format: Utils.dateTimeWithSecFormat })
    .addFieldDescriptor({ name: "value", type: FieldType.string });
}

export class HistoryGraphItemState extends State {
    loading = false;
    displayMode = DisplayMode.CHART;
    selected = {} as { [id: number]: number };
}

export class HistoryGraphItemReducers<S extends HistoryGraphItemState = HistoryGraphItemState> extends Reducers<S> {
    updateSelected(id: number, value: number) {
        this.s.selected[id] = value;
    }
}

export type HistoryGraphItemProps = RRCProps<HistoryGraphItemState, HistoryGraphItemReducers> & {
    refreshUUID: string;
    startDate: number;
    endDate: number;
    entities: any[];
    showCurrentTime?: boolean;
    currentTime?: number;
    currentStartDate?: number;
    currentEndDate?: number;
    onRangeChange?: (startDate: number, endDate: number) => void;
}

export abstract class HistoryGraphItem<P extends HistoryGraphItemProps> extends React.Component<P> {
    protected abstract getName(): string;
    protected abstract renderItem(): ReactNode;
    protected abstract getData(): HistoryGraphData[];

    private tableConnectedPageInfo = new ConnectedPageInfo(sliceEntityTableSimple, EntityTableSimple, "HistoryGraphItem_EntityTableSimple_" + this.getName());

    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: Readonly<HistoryGraphItemProps>) {
        this.componentDidUpdateInternal(prevProps);
    }

    protected componentDidUpdateInternal(prevProps?: HistoryGraphItemProps) {
        const props = this.props;
        if (!prevProps || !_.isEqual(prevProps.refreshUUID, props.refreshUUID)) {
            this.refresh();
        }
        if ((prevProps?.currentTime !== props.currentTime) && props.currentTime !== undefined && this.getData().length > 0) {
            const selected = this.getSelected();
            if (!isEqual(selected, prevProps?.s.selected)) {
                props.r.setInReduxState({ selected });
            }
        }
    }

    protected async refresh() {
        this.props.r.setInReduxState({ loading: true });
        await this.refreshInternal();
        this.props.r.setInReduxState({ loading: false });
    }

    protected async refreshInternal() {
        // to be implemented by history graph item
    }

    protected renderSelectedPoint(): ReactNode | null {
        return null;
    }

    protected getSelected(): { [id: number]: number } {
        return {};
    }

    protected getMapping(): any[] {
        return [];
    }

    protected getHeight() {
        return HISTORY_GRAPH_ITEM_DEFAULT_HEIGHT;
    }

    protected getTableEntities() {
        return this.getData().filter(d => {
            return this.props.startDate <= d.date && d.date <= this.props.endDate;
        });
    }

    protected getTableSelected() {
        const selectedIds: number[] = [];
        Object.keys(this.props.s.selected).forEach(key => {
            const id = Number(key);
            selectedIds.push(this.props.s.selected[id]);
        });
        if (selectedIds.length > 1) {
            return this.getData().reduce(function(a, b) {
                return selectedIds.includes(a.id) && a.date > b.date ? a : b;
            }).id;
        } else {
            return this.getData().find(d => d.id === selectedIds[0])?.id;
        }
    }

    protected exportTable() {
        const ed = this.getTableEntityDescriptor();
        const fields: string[] = Object.keys(ed.getAuthorizedFields(FIELDS_READ));
        const rows: any[] = [fields.map(f => ed.getField(f).getLabel())];
        this.getTableEntities().forEach(entity => rows.push(fields.map(fieldName => {
            if (fieldName === "date") {
                return moment(entity[fieldName]).format(ed.getField(fieldName).format);
            }
            return (entity as any)[fieldName];
        })));
        Utils.exportToCsv("History_graph_" + this.getName() + "_" + moment(Utils.now()).toISOString() + ".csv", rows);
    }

    protected showError() {
        return this.getData().length === 0 && !this.props.s.loading;
    }

    protected renderHeader() {
        return <div className="AuditFieldGraph_field">
            <div className="flex-grow flex-center">
                <Header className="AuditFieldGraph_header" style={{ display: "inline-block" }} size="medium">{this.getName()}</Header>
                {this.renderSelectedPoint()}
            </div>
            <div className="flex-center">
                <Popup trigger={<Icon name="info circle" size="large" />}>
                    <div>{this.getMapping().map(m => {
                        return <div className="AuditFieldGraph_legend_entry">{m.legend}</div>;
                    })}</div>
                </Popup>
                <Button primary={this.props.s.displayMode === DisplayMode.ALL} onClick={() => this.props.r.setInReduxState({ displayMode: this.props.s.displayMode === DisplayMode.ALL ? DisplayMode.CHART : DisplayMode.ALL })} compact className="tiny-margin-right">{entityDescriptors["Audit"].getIcon()} {_msg("entityCrud.editor.table")}</Button>
                <a onClick={() => this.exportTable()}><Icon link name="download" /></a>
            </div>
        </div>;
    }

    protected getTableEntityDescriptor() {
        return HistoryGraphItemEntityDescriptor();
    }

    protected renderTable() {
        return <ConnectedComponentInSimpleComponent info={this.tableConnectedPageInfo} entityDescriptor={this.getTableEntityDescriptor()} entitiesAsParams={this.getTableEntities()} selectedAsParams={this.getTableSelected()} />
    }

    protected isAnimationOn() {
        if (this.props.showCurrentTime && this.props.currentTime && this.props.startDate && this.props.endDate && this.props.currentTime != this.props.startDate) {
            return true;
        }
        return false;
    }

    protected renderLoading() {
        return <Segment className="AuditGraph_loading">
            {/* zIndex = 0 bacause of the issue described here with Dimmer & active: https://github.com/Semantic-Org/Semantic-UI-React/issues/3940 */}
            <Dimmer active style={{ zIndex: 0 }}><Loader size='medium'>{_msg("general.loading")}</Loader></Dimmer>
        </Segment>;
    }

    render() {
        return <Segment className="less-margin-top-bottom wh100">
            {this.renderHeader()}
            {this.showError() ? <span>{_msg("general.no.data")}</span> : this.props.s.loading ? this.renderLoading() : 
                <SplitPaneExt size={this.props.s.displayMode === DisplayMode.CHART ? "100%" : "70%"} paneStyle={{ overflow: "hidden" }} pane2Style={{ maxHeight: this.getHeight() }}>
                    {this.renderItem()}
                    {this.renderTable()}
                </SplitPaneExt>
            }
        </Segment>;
    }
}

export const HistoryGraphItemRRC = ReduxReusableComponents.connectRRC(HistoryGraphItemState, HistoryGraphItemReducers, HistoryGraphItem);

// 42 unique colors.
const historyGraphColors = [
    ...new Set([
        ...categoricalColorSchemes["category10"],
        ...categoricalColorSchemes["dark2"],
        ...categoricalColorSchemes["paired"],
        ...categoricalColorSchemes["accent"],
        ...categoricalColorSchemes["nivo"],
    ])
]

/**
 * Gets the color at index i from our color map (historyGraphColors) which is a concatenated
 * array of colors from nivo/d3. If the index is bigger than the size of array, a rollover is used 
 * to start from the top again
 * @param index color number
 */
export function getHistoryGraphColor(index: number) {
    return historyGraphColors[index % historyGraphColors.length];
}
