import { FilterOperators } from "@crispico/foundation-gwt-js";
import { apolloClient, Utils } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { EnrichProps, ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { Item, RowLayer } from "@crispico/react-timeline-10000";
import { ApuOffStatus } from "apollo-gen/globalTypes";
import { loadFlightsForGantt, loadFlightsForGanttVariables, loadFlightsForGantt_flightService_findByFilter_results } from "apollo-gen/loadFlightsForGantt";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Icon, SemanticICONS } from "semantic-ui-react";
import { AbstractGantt, AbstractGanttReducers, AbstractGanttState, GanttData } from "./AbstractGantt";
import { LOAD_FLIGHTS_FOR_GANTT } from "./queries";

const FIXED_FLIGHT_SEGMENT_WIDTH: number = 10; // value from flex, should be configurable

type FlightArray = { [key: number]: loadFlightsForGantt_flightService_findByFilter_results };

class GanttTasksState extends AbstractGanttState {
    flights: FlightArray = {};
}
class GanttTasksReducers<S extends GanttTasksState = GanttTasksState> extends AbstractGanttReducers<S> {

}

type GanttTasksProps = RRCProps<GanttTasksState, GanttTasksReducers> & { showDatePicker?: boolean };

export class GanttTasks extends AbstractGantt {

    props!: EnrichProps<AbstractGantt, GanttTasksState, GanttTasksReducers, {}>;

    constructor(props: GanttTasksProps) {
        super(props);
    }

    protected async requestFlights(filter: Filter) {
        return (await apolloClient.query<loadFlightsForGantt, loadFlightsForGanttVariables>({
            query: LOAD_FLIGHTS_FOR_GANTT, variables: FindByFilterParams.create().filter(filter)
        })).data.flightService_findByFilter?.results;
    }

    protected async loadFlights() {
        let filter = Filter.createComposed(FilterOperators.forComposedFilter.and, [
            Filter.create("showFlightInGantt", FilterOperators.forBoolean.equals, "true"),
            Filter.create("date", FilterOperators.forDate.greaterThan, this.props.s.start.toString()),
            Filter.create("date", FilterOperators.forDate.lessThan, this.props.s.end.toString())
        ]);

        let result = await (this.requestFlights(filter));

        const flights: FlightArray = {};
        const overlapFlightIds: Number[] = [];
        if (result) {
            result.forEach(flight => {
                flights[flight.id] = flight;
                if (flight.rotationFlight !== null) {
                    overlapFlightIds.push(flight.rotationFlight.id);
                }
            });
        }

        if (overlapFlightIds.length > 0) {
            filter = Filter.create("id", FilterOperators.forNumber.in, overlapFlightIds.map(id => id.toString()).join(","))
            result = await (this.requestFlights(filter));

            if (result) {
                result.forEach(flight => flights[flight.id] = flight);
            }
        }
        await this.props.r.setInReduxState({ flights });
        this.processData();
    }

    protected getTaskItems(flight: any, index: number, missionsInfo: { [key: number]: number[] }): Item[] {
        let items: Item[] = [];
        AbstractGantt.find("Task", "taskGroup.id", flight.id, this.props.entities).forEach((task: any) => {
            if (!task.startTime && !task.endTime) {
                return;
            }
            let hasMission = false;
            AbstractGantt.find("ObjectActionGroup", "object.id", task.id, this.props.entities).find((oag: any) => {
                AbstractGantt.find("Mission2", "id", oag.mission.id, this.props.entities).find(mission => {
                    const m: any = {
                        id: mission.id,
                        startTime: mission.startTime,
                        endTime: mission.endTime
                    };

                    if ((mission.humanResource && !(this.props.hideResources?.["HumanResource"] && this.props.hideResources["HumanResource"]?.findIndex(id => id === mission.humanResource.id) !== -1))) {
                        hasMission = true;
                        AbstractGantt.find("HumanResource", "id", mission.humanResource.id, this.props.entities).find((hr: any) => {
                            m.humanResource = (hr.firstName || "") + " " + (hr.lastName || "");
                        });
                    }
                    if ((mission.equipmentResource && !(this.props.hideResources?.["EquipmentResource"] && this.props.hideResources["EquipmentResource"]?.findIndex(id => id === mission.equipmentResource.id) !== -1))) {
                        hasMission = true;
                        AbstractGantt.find("EquipmentResource", "id", mission.equipmentResource.id, this.props.entities).find((er: any) => {
                            m.equipmentResource = er?.identifier;
                        });
                    }
                    if (mission.equipmentType && !(this.props.hideResources?.["EquipmentType"] && this.props.hideResources["EquipmentType"]?.findIndex(id => id === mission.equipmentType.id) !== -1)) {
                        hasMission = true;
                        AbstractGantt.find("EquipmentType", "id", mission.equipmentType.id, this.props.entities).find((et: any) => {
                            if (et?.name) {
                                m.equipmentResource = m.equipmentResource ? (m.equipmentResource + " (" + et.name + ")") : et.name;
                            }
                        });
                    }
                    if (hasMission) {
                        if (!missionsInfo[mission.id]) {
                            m.oags = AbstractGantt.find("ObjectActionGroup", "mission.id", mission.id, this.props.entities);
                            m.tooltip = this.getMissionTooltip(m);
                            missionsInfo[mission.id] = m;
                        }
                    }
                });
            });
            const taskType = AbstractGantt.findOne("TaskType", "id", task.taskType?.id, this.props.entities);
            items.push({
                row: index,
                start: moment(task.startTime).valueOf(),
                end: moment(task.endTime).valueOf(),
                key: Number(task.id),
                color: taskType?.color ? Utils.convertColorToHex(taskType?.color) : undefined,
                tooltip: this.getTaskTooltip(task),
                style: { borderColor: hasMission ? "green" : "red", borderWidth: 2 }
            });
        });
        return items;
    }

    protected processData() {
        let flights = this.props.s.flights;
        if (this.props.entities) {
            const entities = this.props.entities;
            flights = entities["Flight"] ? _.cloneDeep(entities["Flight"]) : {};
        }
        const data: GanttData = { layers: [], items: [], groups: [] };

        if (Object.keys(flights).length === 0) {
            data.groups.push({ id: 0 });
        }

        const sortedFlights = Object.keys(flights).map(key => Number.parseFloat(key)).filter(key => flights[key].showFlightInGantt).map(key => {
            const flight = flights[key];

            let startDate: number = moment(flight.date).valueOf();
            let endDate: number = startDate;

            if (flight.rotationFlight?.id) {
                if (flights[flight.rotationFlight.id]) {
                    if (flight.departure) {
                        startDate = moment(flights[flight.rotationFlight.id].date).valueOf();
                    } else {
                        endDate = moment(flights[flight.rotationFlight.id].date).valueOf();
                    }
                } else {
                    endDate = moment(startDate).add(2, 'minute').valueOf();
                }
            } else {
                if (flight.departure) {
                    startDate = moment(startDate).add(-FIXED_FLIGHT_SEGMENT_WIDTH, 'minute').valueOf();
                } else {
                    endDate = moment(endDate).add(FIXED_FLIGHT_SEGMENT_WIDTH, 'minute').valueOf();;
                }
            }

            return { flight, startDate, endDate }
        }).sort((a, b) => a.startDate === b.startDate ? 0 : a.startDate > b.startDate ? 1 : -1);

        let groupIndex = 0;
        sortedFlights.forEach((x) => {
            const rotationFlight = flights[x.flight.rotationFlight?.id];
            const arvFlight = x.flight.departure ? rotationFlight : x.flight;
            const arvFlightName = (arvFlight?.airline || "") + (arvFlight?.number || "");
            const depFlight = x.flight.departure ? x.flight : rotationFlight;

            // filter flights
            // if arv - dep already added => don't add it again
            if (data.groups.find(group => group.data.arvFlightId === arvFlight?.id && group.data.depFlightId === depFlight?.id)) {
                return;
            }

            const arvFlightParking = AbstractGantt.findOne("Address", "id", arvFlight?.parking?.id, this.props.entities);
            const depFlightParking = AbstractGantt.findOne("Address", "id", depFlight?.parking?.id, this.props.entities);
            const i = groupIndex++;

            const missionsInfo: { [key: number]: number[] } = {};
            if (arvFlight?.showFlightInGantt) {
                data.items.push.apply(data.items, this.getTaskItems(arvFlight, i, missionsInfo));
            }
            if (depFlight?.showFlightInGantt) {
                data.items.push.apply(data.items, this.getTaskItems(depFlight, i, missionsInfo));
            }
            data.groups.push({
                id: i,
                arvFlightName: arvFlightName + (arvFlightParking?.name ? ((" (" + arvFlightParking?.name) + ")") : ""),
                depFlightName: (depFlight?.airline || "") + (depFlight?.number || "") + (depFlightParking?.name ? ((" (" + depFlightParking?.name) + ")") : ""),
                tooltip: this.getFlightTooltip(x.flight),
                data: {
                    id: x.flight.id,
                    arvFlightId: arvFlight?.id,
                    depFlightId: depFlight?.id,
                    missions: Object.values(missionsInfo)
                }
            });

            let arvColor = arvFlight?.apuOffStatus === ApuOffStatus.DONE ? 'green' : (arvFlight?.apuOffStatus === ApuOffStatus.IN_PROGRESS ? 'lightgreen' : 'darkgrey');
            let depColor = depFlight?.apuOffStatus === ApuOffStatus.DONE ? 'green' : (depFlight?.apuOffStatus === ApuOffStatus.IN_PROGRESS ? 'lightgreen' : 'darkgrey');

            let itemFlight: RowLayer = {
                rowNumber: i,
                start: x.startDate,
                end: x.endDate,
                style: { background: `linear-gradient(to right, ${arvColor} 0%, ${arvColor} 50%, ${depColor} 50%, ${depColor} 100%)` }
            };

            data.layers.push(itemFlight);
        });

        this.props.r.setInReduxState({ data });
    }

    protected entitiesChangedHandler() {
        this.processData();
    }

    protected startEndChangedHandler() {
        this.loadFlights();
    }

    protected getTableColumns(): any {
        return [
            { headerRenderer: <HeaderRenderer iconName="plane" rotation={45} />, width: 110, labelProperty: "arvFlightName" },
            { headerRenderer: <HeaderRenderer iconName="plane" rotation={-45} />, width: 110, labelProperty: "depFlightName" }
        ]
    }
}
class HeaderRenderer extends React.Component<{ iconName: SemanticICONS, rotation?: number }> {
    render() {
        return <div className="wh100 flex-center flex-justify-content-center">
            <Icon name={this.props.iconName} style={{ transform: "rotate(" + (this.props.rotation ? this.props.rotation : 0) + "deg)" }} />
        </div>;
    }
}

export const GanttTasksRRC = ReduxReusableComponents.connectRRC(GanttTasksState, GanttTasksReducers, GanttTasks);