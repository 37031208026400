import { addEntityDescriptor, BigState, EntityDescriptor } from "@crispico/foundation-react";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { AddressEntityDescriptor } from "pages/Address/AddressEntityDescriptor";
import { BlocklyScriptXopsEntityDescriptor } from "pages/blocklyScriptXops/BlocklyScriptXopsEntityDescriptor";
import { FoundationEntityDescriptors } from "@crispico/foundation-react/FoundationEntityDescriptors";
import { QualificationEntityDescriptor } from "pages/QualificationEntityDescriptor";
import { FlightEntityDescriptor } from "pages/flight/FlightEntityDescriptor";
import { Mission2EntityDescriptor } from "pages/Mission2/Mission2EntityDescriptor";
import { HumanResourceEntityDescriptor } from "pages/HumanResourceEntityDescriptor";
import { BaggageEntityDescriptor } from "pages/BaggageEntityDescriptor";
import { ChartEntityDescriptor } from "pages/Chart/chartEntityDescriptor";
import { GanttAssignmentEntityDescriptor } from "pages/ganttAssignment/GanttAssignmentEntityDescriptor";
import { GanttAssignmentParamsEntityDescriptor } from "pages/ganttAssignment/GanttAssignmentParamsEntityDescriptor";
import { TaskEntityDescriptor } from "pages/Task/TaskEntityDescriptor";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";

// TODO by CS: I think they will disappear because of the new menu system
export let addressEntityDescriptor!: EntityDescriptor;
export let blocklyScriptEntityDescriptor!: EntityDescriptor;
export let chartEntityDescriptor!: EntityDescriptor;
export let ganttAssignmentEntityDescriptor!: EntityDescriptor;
export let ganttAssignmentParamsEntityDescriptor!: EntityDescriptor;
export let humanResourceEntityDescriptor: EntityDescriptor;
export let qualificationEntityDescriptor: EntityDescriptor;
export let flightEntityDescriptor: EntityDescriptor;
export let missionEntityDescriptor: EntityDescriptor;
export let taskEntityDescriptor: EntityDescriptor;
export let baggageEntityDescriptor: EntityDescriptor;
export let interdictionEntityDescriptor: EntityDescriptor;
export let cDGPortParkingEntityDescriptor: EntityDescriptor;
export let zoneDistanceEntityDescriptor: EntityDescriptor;
export let radioEntityDescriptor: EntityDescriptor;
export let inactivityTypeEntityDescriptor: EntityDescriptor;

export class AppEntityDescriptors extends FoundationEntityDescriptors {

    init() {
        super.init();

        // please keep sorted alphabetically
        addressEntityDescriptor = addEntityDescriptor(new AddressEntityDescriptor({ name: "Address", icon: "map marker" }));
        addEntityDescriptor(new EntityDescriptor({ name: "AddressAirline", icon: "map marker" }));
        addEntityDescriptor(new EntityDescriptor({ name: "Airline", icon: 'plane', miniFields: ["code"] }));
        blocklyScriptEntityDescriptor = addEntityDescriptor(new BlocklyScriptXopsEntityDescriptor({ name: "BlocklyScriptXops" }));
        addEntityDescriptor(new EntityDescriptor({ name: "EquipmentUsageLog", icon: "clock outline" }))
            .isInDefaultColumnConfig(false, "user");

        chartEntityDescriptor = addEntityDescriptor(new ChartEntityDescriptor());

        cDGPortParkingEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
            name: "CDGPortParking",
            miniFields: ["port", "parking"],
            defaultSort: [{ field: "port", direction: "ASC" }, { field: "parking", direction: "ASC" }]
        }));

        flightEntityDescriptor = addEntityDescriptor(new FlightEntityDescriptor({
            name: "Flight", icon: "plane",
            defaultFilter: Filter.createForClient("date", FilterOperators.forDate.today),
            defaultSort: { field: "date", direction: "DESC" }
        }));

        addEntityDescriptor(new EntityDescriptor({
            name: "FlightAndObjectAction",
            icon: "plane"
        })
        );

        ganttAssignmentEntityDescriptor = addEntityDescriptor(new GanttAssignmentEntityDescriptor());

        ganttAssignmentParamsEntityDescriptor = addEntityDescriptor(new GanttAssignmentParamsEntityDescriptor());

        humanResourceEntityDescriptor = addEntityDescriptor(new HumanResourceEntityDescriptor({
            name: "HumanResource", icon: "male",
            miniFields: ["identifier", "firstName", "lastName"],
        }));

        addEntityDescriptor(new EntityDescriptor({
            name: "HumanResourceLog", icon: "male",
            miniFields: ["humanResource.identifier", "event", "date"],
            defaultSort: [{ field: "date", direction: "DESC" }],
            defaultFilter: Filter.createForClient("date", FilterOperators.forDate.today)
        }).addFieldDescriptor({
            name: "event", type: FieldType.dropdown, fieldDescriptorSettings: {
                fieldIntervals: [
                    { from: "IN", to: "IN", label: _msg("HumanResourceLog.in").toUpperCase() },
                    { from: "OUT", to: "OUT", label: _msg("HumanResourceLog.out").toUpperCase() }]
            }
        }));

        addEntityDescriptor(new EntityDescriptor({
            name: "HumanResourceShift", icon: "male",
            miniFields: ["name"],
            defaultFilter: Filter.createForClient("startTime", FilterOperators.forDate.today),
            defaultSort: { field: "startTime", direction: "DESC" }
        }));

        addEntityDescriptor(new EntityDescriptor({
            name: "HumanResourceMessage", icon: "male",
            miniFields: ["humanResource.identifier", "sentTime"],
            defaultSort: [{ field: "sentTime", direction: "DESC" }],
            defaultFilter: Filter.createForClient("sentTime", FilterOperators.forDate.today)
        }).addFieldDescriptor({ name: "message", type: FieldType.text }))
            .addFieldDescriptor({ name: "answer", type: FieldType.text })
            .isInDefaultColumnConfig(false, "xopsUser", "eventId");

        addEntityDescriptor(new EntityDescriptor({
            name: "HumanResourceSchedule", icon: "male",
            miniFields: ["humanResource.identifier", "startTime"],
            defaultSort: [{ field: "startTime", direction: "DESC" }],
            defaultFilter: Filter.createForClient("startTime", FilterOperators.forDate.today)
        })).isInDefaultColumnConfig(false, "humanResourceId", "finished", "units", "autoPauseProcessed", "equipment", "radio", "driverAssistant1Id", "driverAssistant2Id");

        interdictionEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
            name: "Interdiction",
            miniFields: ["airline", "planeTypeObservations"],
            defaultSort: [{ field: "airline", direction: "ASC" }]
        })
            .addFieldDescriptor({
                name: "planeTypeObservations", type: FieldType.dropdown, fieldDescriptorSettings: {
                    fieldIntervals: [
                        { from: "lowWing", label: _msg("planeType.observations.lowWing") },
                        { from: "highWing", label: _msg("planeType.observations.highWing") },
                        { from: "family320", label: _msg("planeType.observations.family320") }]
                }
            })
        );

        missionEntityDescriptor = addEntityDescriptor(new Mission2EntityDescriptor({
            name: "Mission2", icon: "list",  miniFields: ["id"],
            defaultFilter: Filter.createForClient("creationDate", FilterOperators.forDate.today),
            defaultSort: { field: "creationDate", direction: "DESC" }
        }));

        addEntityDescriptor(new EntityDescriptor({
            name: "MissionType",
            miniFields: ["name"],
        }).addFieldDescriptor({ name: "color", type: FieldType.color }));

        addEntityDescriptor(new EntityDescriptor({
            name: "MobileDeviceActivationHistory",
            icon: "history",
            defaultFilter: Filter.createForClient("date", FilterOperators.forDate.today),
            defaultSort: { field: "date", direction: "DESC" }
        })).addFieldDescriptor({
            name: "event", type: FieldType.dropdown, fieldDescriptorSettings: {
                fieldIntervals: [
                    { from: "IN", to: "IN", label: _msg("HumanResourceLog.in").toUpperCase() },
                    { from: "OUT", to: "OUT", label: _msg("HumanResourceLog.out").toUpperCase() }]
            }
        })

        addEntityDescriptor(new EntityDescriptor({ name: "Puck", icon: "paperclip", miniFields: ["name", "territory.name"] }));
        qualificationEntityDescriptor = addEntityDescriptor(new QualificationEntityDescriptor({ name: "Qualification", icon: "check circle outline", miniFields: ["qualificationType.name"] }));

        addEntityDescriptor(new EntityDescriptor({ name: "QualificationType", icon: "check circle outline", miniFields: ["name", "description"] })
            .isInDefaultColumnConfig(true, "name", "description")
            .addFieldDescriptor({ name: "description", type: "text" }));

        addEntityDescriptor(new EntityDescriptor({ name: "PlaneType", icon: "plane" }));

        radioEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
            name: "Radio",
            miniFields: ["identifier"],
            defaultSort: [{ field: "identifier", direction: "ASC" }]
        }));

        baggageEntityDescriptor = addEntityDescriptor(new BaggageEntityDescriptor({
            name: "Baggage",
            miniFields: ["number"],
            defaultFilter: Filter.createForClient("date", FilterOperators.forDate.today),
            defaultSort: { field: "date", direction: "DESC" }
        }));

        taskEntityDescriptor = addEntityDescriptor(new TaskEntityDescriptor());

        zoneDistanceEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
            name: "ZoneDistance",
            miniFields: ["zone1.name", "zone2.name"],
            defaultSort: [{ field: "zone1", direction: "ASC" }, { field: "zone2", direction: "ASC" }]
        }));

        inactivityTypeEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
            name: "InactivityType",
            miniFields: ["name", "duration"],
            defaultSort: [{ field: "name", direction: "ASC" }],
            icon: "clipboard list"
        })).addFieldDescriptor({ name: "color", type: FieldType.color, colorType: "number" });

        return this;
    }
}
