import Interweave from "interweave";
import React from "react";
import { Message, Dropdown, DropdownProps, Popup, Button, ButtonProps } from "semantic-ui-react";

/**
 * NOTE: on using an "id" for CSS.
 * 
 * Needed when using a dropdown in a dropdown. We need to override some styles that SUIR has, because this pattern means for
 * it a submenu; which is not the case. 
 *
 * We use ID as selector, because there are a lot of !important selectors. If we used a class selector, our style wouldn't be
 * the winner. We saw that here: http://tutorials.jenkov.com/css/precedence.html > Specificity of CSS Rule Selectors
 *
 * It would have worked also with inline styles + !important. They are not possible from React. We should have used a library
 * such as: https://stackoverflow.com/a/57293159. Or "marlanie" w/ get the DOM element + apply the style.
 *
 */
export const DropDownMenuInDropDown = (props: DropdownProps) => <Dropdown.Menu {...props} id="DropDownMenuInDropDown" />

interface MessageExtProps {
    children: any;
    className?: string
    headerClassName?: string
}

export const MessageExt = (props: MessageExtProps) => {
    let className = "MessageExt";
    if (props.className) { className += " " + props.className }
    return (
        <Message className={className} color="blue"><Message.Header className={props.headerClassName}>{props.children}</Message.Header></Message>
    );
}

interface PopupWithHelpTooltipProps {
    /**
     * Supports HTML markup via Interweave.
     */
    tooltip: string
    buttonProps?: ButtonProps,
    dataTestId?: string
}

export const PopupWithHelpTooltip = (props: PopupWithHelpTooltipProps) => <Popup popperModifiers={[{ preventOverflow: { boundariesElement: "offsetParent" } }]} 
    trigger={<Button data-testid={props.dataTestId} color='blue' size='mini' circular icon='question' {...props.buttonProps} />}
    content={<Interweave attributes={{ "data-testid": props.dataTestId + "_content" }} content={props.tooltip} />} position='top center' wide="very" />
