import { FilterOperators } from "@crispico/foundation-gwt-js";
import { EntityDescriptor, Optional, RootReducerForPages, Utils } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { Sort } from "@crispico/foundation-react/components/CustomQuery/SortBar";
import { ID } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { MapFields, MapSettings, MarkerSettings } from "app";
import { Location, MapContainerLeafletLayer, MarkerData, MARKER_TYPE } from "components/MapContainerLeaflet/MapContainerLeaflet";
import gql from "graphql-tag";
import _, { lowerFirst } from "lodash";
import { equipmentResourceEntityDescriptor } from "pages/EquipmentResource/equipmentResourceEntityDescriptor";
import { EquipmentResourceUtils } from "pages/EquipmentResource/EquipmentResourceUtils";
import { mobileDeviceEntityDescriptor } from "pages/MobileDevice/mobileDeviceEntityDescriptor";
import { ReactNode } from "react";
import { Icon } from "semantic-ui-react";
import { RealTimeUtils } from "./RealTimeUtils";

export type QueryDetails = {
    query: any,
    filters: Filter[],
    sorts: Sort[]
}

export class RealTimeMapEntityTypeFactories {
    static INSTANCE = new RealTimeMapEntityTypeFactories();

    entityTypes: { [entityType: string]: RealTimeMapEntityTypeFactory } = {};
}

export abstract class RealTimeMapEntityTypeFactory {

    abstract getEd(): EntityDescriptor

    // queries
    abstract getFiltersForFindEntity(): Filter[]
    abstract getAdditionalSearchFieldsForFindEntity(): string[]

    // renders
    abstract renderTooltipContent(entity: any, mapSettings: MapSettings, additionalInfo?: { pointId?: string | number }): JSX.Element
    abstract renderMarkerIcon(entity: any, mapSettings: MapSettings, markerData: MarkerData, additionalStyles?: {selected?: boolean, hovered?: boolean}): ReactNode
    abstract renderListRow(entity: any, mapSettings: MapSettings): JSX.Element

    // for existing realTimeEntities this fields are of date type
    abstract getListRowPopupFields(): string[]

    // common for existing realTimeEntities
    getSortField(): string {
        return "updated"
    }

    getMostRecentEntityUpdatesField(): string {
        return "updated";
    }

    getMapContainerLeafletLayer(): MapContainerLeafletLayer {
        return { layerType: MARKER_TYPE, options: { flyToSelectedMarker: false } }
    }

    getLayersForMap(entities: any[], markerSettings: Optional<MarkerSettings>): any[] {
        const updates: MarkerData[] = []
        entities.forEach((entity: any) => {
            if (entity.identifier && entity.lastPointLongitude && entity.lastPointLatitude) {
                updates.push(RealTimeUtils.getMarkerFromEntity(this.getEd().name, entity, markerSettings))
            }
        });

        return updates
    }

    getEntityPoint(entity: any): Optional<Location> {
        if (entity && entity.identifier && entity.lastPointLongitude && entity.lastPointLatitude) {
            return { longitude: entity.lastPointLongitude, latitude: entity.lastPointLatitude };
        }

        return undefined;
    }

    getMarkerSettings(mapSettings: MapSettings): Optional<MarkerSettings> {
        return mapSettings.markers.find(m => m.markerType === this.getEd().name);
    }

    getAditionalFieldsToRequestFromMapSettings(mapSettings: MapSettings, sorts: Sort[]): string[] {
        const markerSettings = this.getMarkerSettings(mapSettings);
        let fields: string[] = [];
        const colorMapFields: MapFields[] = markerSettings?.colors.map(color => ({name: color.name, fields: ""}) as MapFields) || [];
        // concat all settings from map settings of form MapFields and iterate to add fields for request
        colorMapFields.concat(markerSettings?.bigPopupFields || []).concat(markerSettings?.smallPopupFields || []).concat(markerSettings?.rowFields || []).forEach(mapField => {
            if (!Utils.isNullOrEmpty(mapField.name) && mapField.name.indexOf("=") > -1 ) {
                for (const group of mapField.name.split("||")) { 
                    fields.push(group.split("=")[0]);
                }
            }
            if (!Utils.isNullOrEmpty(mapField.fields)) {
                fields = fields.concat(mapField.fields.split(",").map((f: string) => f.trim())) || [];
            }
        })

        // add fields for which will be done sort on list
        fields = _.union(fields, sorts.map(sort => sort.field));

        // filter duplicates and remove empty fields
        return fields.filter((field, index, self) => {
            return index === self.indexOf(field);
        }).filter(field => !Utils.isNullOrEmpty(field));
    }

    getFieldsToRequest(): string[] {
        return ["identifier", "updated", "lastPointLatitude", "lastPointLongitude"];
    }

    getQuery(mapSettings: MapSettings, sorts: Sort[], findEntity?: boolean) {
        const loadOperationName = findEntity ? `${lowerFirst(this.getEd().name)}Service_findById` : `${lowerFirst(this.getEd().name)}Service_findByRectangle`;
        if (findEntity) {
            return gql(`query q($id: Long) { 
                ${loadOperationName}(id: $id) {
                    ${ID} ${this.getEd().getGraphQlFieldsToRequest(this.getFieldsToRequest())}
                }
            }`);
        }

        const additionalFieldsFromMapSettings = this.getAditionalFieldsToRequestFromMapSettings(mapSettings, sorts);
        return gql(`query q($filter: FilterInput, $sorts: [SortInput], $coordinates:[Pair_Double_DoubleInput]) { 
            ${loadOperationName}(filter: $filter, sorts: $sorts, coordinates: $coordinates) {
                results { ${ID} ${this.getEd().getGraphQlFieldsToRequest(this.getFieldsToRequest().concat(additionalFieldsFromMapSettings))}}
            }
        }`);
    }

    getLoadEntitiesQueryDetails(mapSettings: MapSettings, sorts: Sort[]): QueryDetails {
        return {
            query: this.getQuery(mapSettings, sorts),
            filters: this.getFiltersForFindEntity(),
            sorts: [{ field: this.getSortField(), direction: "DESC" }]
        }
    }

    getFindEntityQueryDetails(mapSettings: MapSettings): QueryDetails {
        return {
            query: this.getQuery(mapSettings, [], true),
            filters: [],
            sorts: []
        }
    }

    renderPopupContent(entity: any, mapSettings: MapSettings, layerData?: any, point?: Location | undefined): JSX.Element {
        return <></>
    }
}

RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[equipmentResourceEntityDescriptor.name] = new class extends RealTimeMapEntityTypeFactory {
    
    getEd(): EntityDescriptor {
        return equipmentResourceEntityDescriptor;
    }

    getFieldsToRequest(): string[] {
        return super.getFieldsToRequest().concat(["plateNumber", "equipmentType", "equipmentType.icon", "equipmentModel", "lastDetectionDate"]);
    }

    getFiltersForFindEntity(): Filter[] {
        return [
            Filter.create("visible", FilterOperators.forBoolean.equals, "true"), 
            Filter.create("updated", FilterOperators.forDate.isNotEmpty)
        ]
    }

    getAdditionalSearchFieldsForFindEntity(): string[] {
        return ["plateNumber"];
    }

    renderTooltipContent(entity: any, mapSettings: MapSettings, additionalInfo?: { pointId?: string | number | undefined; } | undefined): JSX.Element {
        return entity ? EquipmentResourceUtils.renderSmallInfoArea(entity, mapSettings) : <></>;
    }

    renderMarkerIcon(entity: any, mapSettings: MapSettings, markerData: MarkerData, additionalStyles?: {selected?: boolean, hovered?: boolean}): ReactNode {
        return EquipmentResourceUtils.renderEquipmentResourceIcon(entity, markerData, this.getMarkerSettings(mapSettings), additionalStyles);
    }

    getListRowPopupFields(): string[] {
        return ["lastDetectionDate", "updated"]
    }

    renderListRow(entity: any, mapSettings: MapSettings): JSX.Element {
        return (<>
            <div>{EquipmentResourceUtils.getImage(entity, true)}</div>
            {RealTimeUtils.renderFieldsForListRow(entity, this.getEd(), mapSettings, this.getListRowPopupFields())}
        </>
        )
    }
}();

RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[mobileDeviceEntityDescriptor.name] = new class extends RealTimeMapEntityTypeFactory {
    
    getEd(): EntityDescriptor {
        return mobileDeviceEntityDescriptor;
    }

    getFieldsToRequest(): string[] {
        return super.getFieldsToRequest().concat(["lastUpdate"]);
    }

    getFiltersForFindEntity(): Filter[] {
        return [Filter.create("updated", FilterOperators.forDate.isNotEmpty)]
    }

    getAdditionalSearchFieldsForFindEntity(): string[] {
        return [];
    }

    renderTooltipContent(entity: any, mapSettings: MapSettings, additionalInfo?: { pointId?: string | number | undefined; } | undefined): JSX.Element {
        const markerSettings: Optional<MarkerSettings> = this.getMarkerSettings(mapSettings);
        const fieldDescriptor = mobileDeviceEntityDescriptor.getFieldDescriptorChain(markerSettings?.markerIdField ? markerSettings?.markerIdField : "identifier")[0];
        return (
            <div className="flex-container">
                <div>{fieldDescriptor ? fieldDescriptor.getFieldValue(entity) : ""}</div>
            </div>
        )
    }

    renderMarkerIcon(entity: any, mapSettings: MapSettings, markerData: MarkerData, additionalStyles?: {selected?: boolean, hovered?: boolean}): ReactNode {
        return RealTimeUtils.renderEntityIcon(markerData, this.getMarkerSettings(mapSettings), 
            <Icon name="mobile alternate" className="margin-auto"/>, additionalStyles);
    }

    getListRowPopupFields(): string[] {
        return ["lastUpdate", "updated"]
    }
    
    renderListRow(entity: any, mapSettings: MapSettings): JSX.Element {
        return (<>
            <div><Icon name="mobile alternate" size="big" /></div>
            {RealTimeUtils.renderFieldsForListRow(entity, this.getEd(), mapSettings, this.getListRowPopupFields())}
        </>
        )
    }
}();  
