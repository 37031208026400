import React from "react";
import Blockly from "blockly";
import { Tab, Segment, Button, Icon, Container } from "semantic-ui-react";
import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react/reduxHelpers";
import { EDITOR_LOGIC_OPERATION_WITH_OUTPUT, EDITOR_LOGIC_OPERATION_NO_OUTPUT, FILTER_WITH_OUTPUT, NO_VALUE_FILTER_WITH_OUTPUT, LABELED_FILTER_WITH_OUTPUT } from "../../blockly/customBlocks";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import lodash from 'lodash';
import { ErrorType } from "./dataStructures";
import { sliceFilterForm, FilterForm, checkValidFilter } from "./FilterForm";
import { createFilterBlock } from "./createFilterBlock";
import { Filter } from "./Filter";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { FormType } from "../fieldNameContentAssist/FieldNameContentAssist";

export const DEFAULT_COMPOSED_FILTER: Filter = Filter.createComposedForClient(FilterOperators.forComposedFilter.and);

export const DEFAULT_BLOCKLY_SETTINGS = {
    move: {
        scrollbars: true
    },
    grid: {
        spacing: 20,
        length: 2,
        colour: '#ccc',
        snap: true
    },
    zoom: {
        controls: true,
        wheel: true,
        maxScale: 1,
        minScale: 0.5
    },
    trashcan: true,
    sounds: false
}

export const sliceBlocklyEditorTab = createSliceFoundation(class SliceBlocklyEditorTab {

    initialState = {
        rootFilter: DEFAULT_COMPOSED_FILTER,
        filterFormOpened: false as [number, number] | boolean,
        selectedFilterPath: '',
        lastAddedFilterBlockId: '',
        linearBlocks: true
    }

    nestedSlices = {
        filterForm: sliceFilterForm
    }

    reducers = {
        ...getBaseReducers<SliceBlocklyEditorTab>(this),
    }

    impures = {
        ...getBaseImpures<SliceBlocklyEditorTab>(this),

        onFilterFormApply(filter: Filter, workspace: Blockly.WorkspaceSvg, blocklyEditorTabRef: React.RefObject<any>, entityDescriptor: string, closeFilterForm?: boolean) {
            this.commitWorkFromFilterForm(filter, workspace, blocklyEditorTabRef, entityDescriptor);
            if (closeFilterForm) {
                this.getDispatchers().setInReduxState({ filterFormOpened: false })
            }
            this.getDispatchers().setInReduxState({
                filterForm: {
                    ...this.getState().filterForm,
                }
            })
        },

        commitWorkFromFilterForm(filter: Filter, workspace: Blockly.WorkspaceSvg, blocklyEditorTabRef: React.RefObject<any>, entityDescriptor: string) {
            let value: any;
            if (value && typeof value == 'object') {
                if (value.length !== undefined) {
                    value = JSON.stringify(value.map((o: any) => o.id)).replace("[", "").replace("]", "");
                } else {
                    value = value.id;
                }
            }
            if (value) {
                filter.value = value;
            }
            let state = this.getState();
            let rootFilter = lodash.cloneDeep(state.rootFilter);

            const pathToParent = Utils.substringBefore(state.selectedFilterPath, Utils.defaultIdSeparator, true);
            const parent = Utils.navigate(rootFilter, pathToParent);
            const childKey = Utils.substringAfter(state.selectedFilterPath, Utils.defaultIdSeparator, true);
            parent[childKey] = filter;

            this.getDispatchers().setInReduxState({ rootFilter: rootFilter, lastAddedFilterBlockId: '' });

            state = this.getState();
            this.refreshWorkspace(workspace, state.rootFilter, blocklyEditorTabRef, entityDescriptor);
        },

        refreshWorkspace(workspace: Blockly.WorkspaceSvg, filter: Filter, blocklyEditorTabRef: React.RefObject<any>, entityDescriptor: string) {
            let selected = this.getPathFromBlock(workspace.getBlockById(Blockly.selected?.id), blocklyEditorTabRef.current?.topBlockId);
            workspace.clear();
            let newTopBlockId = Blockly.Xml.appendDomToWorkspace(Blockly.Xml.textToDom(`<xml>${createFilterBlock(filter, { entityDescriptorName: entityDescriptor }, false, true, true)}</xml>`), workspace)[0];
            let topBlock = workspace.getBlockById(newTopBlockId);
            topBlock.setInputsInline(false);
            topBlock.setDeletable(false);
            topBlock.moveBy(20, 20);
            if (blocklyEditorTabRef.current) {
                blocklyEditorTabRef.current.topBlockId = newTopBlockId;
            }
            if (selected) {
                selected = selected.split('filters').join('childBlocks_');

                let topBlock = workspace.getBlockById(newTopBlockId);

                const pathToParent = Utils.substringBefore(selected, Utils.defaultIdSeparator, true);
                const parent = Utils.navigate(topBlock, pathToParent);
                const childKey = Utils.substringAfter(selected, Utils.defaultIdSeparator, true);
                parent[childKey].select();
            }
        },

        async beforeApply(workspace: Blockly.WorkspaceSvg, topBlockId: string, blocklyEditorTabRef: React.RefObject<any>, entityDescriptor: string, apply?: (filter: Filter) => void) {
            let topBlock = workspace.getBlockById(topBlockId);
            let filter = this.getFilterFromBlock(topBlock);

            let filterToBeValidated = this.eliminateEmptyFilters(lodash.cloneDeep(filter));
            let validated = this.validate(filterToBeValidated, '');
            validated = validated.slice(0, validated.length - Utils.defaultIdSeparator.length);

            if (validated === '') {
                await checkValidFilter(filterToBeValidated, entityDescriptor);
                apply?.(filterToBeValidated);
                this.getDispatchers().setInReduxState({
                    ...this.getSlice().initialState,
                    filterForm: {
                        ...this.getState().filterForm,
                    }
                });
                this.getDispatchers().setInReduxState({ rootFilter: filterToBeValidated });
                this.refreshWorkspace(workspace, filterToBeValidated, blocklyEditorTabRef, entityDescriptor);
            } else {
                this.getDispatchers().setInReduxState({ selectedFilterPath: validated, filterFormOpened: true, filterForm: { ...this.getState().filterForm, error: ErrorType.NONE } });
                return;
            }
        },

        eliminateEmptyFilters(filter: Filter) {
            function check(f: Filter): boolean {
                if (f.filters) {
                    if (f.filters.length === 0) {
                        return false;
                    } else {
                        return f.filters.filter(filter => { return check(filter) }).length > 0;
                    }
                }
                return true;
            }
            filter.filters = filter.filters?.filter(f => { return check(f) });
            return filter;
        },

        validate(filter: Filter, path: string): string {
            if (filter.filters) {
                if (filter.filters.length === 0) { return ''; }
                path = path + 'filters';
                for (let i = 0; i < filter.filters.length; i++) {
                    let f = filter.filters[i];
                    let validation = this.validate(f, path + Utils.defaultIdSeparator + i + Utils.defaultIdSeparator);
                    if (validation !== '') { 
                        validation = validation.slice(0, validation.length - Utils.defaultIdSeparator.length)
                        return validation; 
                    }
                }
                return '';
            }
            return FilterOperators.noValueOperators.find(op => op.value === filter.operator) ? filter.field && filter.operator ? '' : path : filter.field && filter.operator && filter.value?.toString() ? '' : path;
        },

        getPathFromBlock(block: Blockly.Block, topBlockId: string) {
            if (!block) { return ''; }
            let path: string = '';
            let blockParent = block.getParent();
            let fromRootFilter = blockParent ? blockParent.id === topBlockId : false;
            while (blockParent != null) {
                path = `filters${Utils.defaultIdSeparator}${blockParent.getChildren(false).indexOf(block)}${Utils.defaultIdSeparator}` + path;
                block = blockParent;
                blockParent = blockParent.getParent();
                if (blockParent && blockParent.id === topBlockId) {
                    fromRootFilter = true;
                }
            }
            if (!fromRootFilter) {
                return '';
            }
            path = path.slice(0, path.length - Utils.defaultIdSeparator.length);
            return path;
        },

        getBlockFromPath(path: string, workspace: Blockly.WorkspaceSvg, topBlockId: string) {
            let topBlock = workspace.getBlockById(topBlockId);
            path = path.split('filters').join('childBlocks_');
            const pathToParent = Utils.substringBefore(path, Utils.defaultIdSeparator, true);
            const parent = Utils.navigate(topBlock, pathToParent);
            const childKey = Utils.substringAfter(path, Utils.defaultIdSeparator, true);
            return parent[childKey];
        },

        addFilter(newFilter: Filter, workspace: Blockly.WorkspaceSvg, topBlockId: string, entityDescriptor: string, duplicateMode?: boolean, openFilterForm?: boolean) {
            this.addFilterInBlockly(newFilter, workspace, topBlockId, entityDescriptor, duplicateMode);
            if (openFilterForm) {
                this.getDispatchers().setInReduxState({ filterFormOpened: true });
            }
            if (!newFilter.filters) {
                if (!duplicateMode) {
                    this.getDispatchers().setInReduxState({
                        filterForm: {
                            ...this.getState().filterForm,
                        }
                    });
                }
            }

        },

        connectSimpleFilterToAdvancedFilter(childBlock: any, block: any, inputIndex?: number) {
            if (block && block.type && (block.type === EDITOR_LOGIC_OPERATION_NO_OUTPUT || block.type === EDITOR_LOGIC_OPERATION_WITH_OUTPUT)) {
                block.initSvg();
                block.render();
                childBlock.initSvg();
                childBlock.render();

                let parentConnection: any;
                let inputList = block.inputList.filter((input: any) => input.name.includes('ADD'));

                if (inputIndex !== undefined) {
                    let input = block.getInput('ADD' + inputIndex);
                    parentConnection = input.connection;
                }
                if (!parentConnection) {
                    for (let i = 0; i < inputList.length; i++) {
                        let input = block.getInput('ADD' + i);
                        if (input.connection && !input.connection.targetConnection) {
                            parentConnection = input.connection;
                            break;
                        }
                    }
                }

                if (!parentConnection) {
                    parentConnection = block.appendInput_(1, "ADD" + inputList.length).connection;
                    block.itemCount_++
                }

                var childConnection = childBlock.outputConnection;
                parentConnection.connect(childConnection);
            }
        },

        addFilterInBlockly(newFilter: Filter, workspace: Blockly.WorkspaceSvg, topBlockId: string, entityDescriptor: string, duplicateMode?: boolean, fromApplyAndAddNewSameField?: boolean, indexConnection?: number) {
            let block = workspace.getBlockById(Blockly.selected?.id);
            if (!duplicateMode && block && block.type && (block.type === FILTER_WITH_OUTPUT || block.type === NO_VALUE_FILTER_WITH_OUTPUT)) {
                block = block.getParent();
            }

            let path = this.getPathFromBlock(workspace.getBlockById(Blockly.selected?.id), topBlockId);
            if (duplicateMode && path) {
                path = Utils.substringBefore(path, Utils.defaultIdSeparator, true);
                if (path === "filters") {
                    block = workspace.getBlockById(topBlockId);
                } else {
                    block = block.getParent();
                }
            }
            if (!block) {
                block = workspace.getBlockById(topBlockId);
            }
            if (fromApplyAndAddNewSameField && (block as any).getOperator() !== FilterOperators.forComposedFilter.or.value) {
                let filterBlockToMove = this.getBlockFromPath(this.getState().selectedFilterPath, workspace, topBlockId);
                let indexOfFilterToMove = Utils.substringAfter(this.getState().selectedFilterPath, Utils.defaultIdSeparator, true);
                this.addFilterInBlockly(Filter.createComposedForClient(FilterOperators.forComposedFilter.or), workspace, topBlockId, entityDescriptor, false, false, parseInt(indexOfFilterToMove));
                let newOrBlock = this.getBlockFromPath(this.getState().selectedFilterPath, workspace, topBlockId);
                this.connectSimpleFilterToAdvancedFilter(filterBlockToMove, newOrBlock);
                newOrBlock.select();
                this.addFilter(newFilter, workspace, topBlockId, entityDescriptor);
                return
            }
            let childBlock: any = Blockly.Xml.domToBlock(Blockly.Xml.textToDom(createFilterBlock(newFilter, { entityDescriptorName: entityDescriptor }, true, true, true)), workspace);
            if (block && block.type && (block.type === EDITOR_LOGIC_OPERATION_NO_OUTPUT || block.type === EDITOR_LOGIC_OPERATION_WITH_OUTPUT)) {
                this.connectSimpleFilterToAdvancedFilter(childBlock, block, indexConnection);

                if (this.getPathFromBlock(childBlock, topBlockId)) {
                    this.getDispatchers().setInReduxState({
                        selectedFilterPath: this.getPathFromBlock(childBlock, topBlockId),
                        rootFilter: this.getFilterFromBlock(workspace.getBlockById(topBlockId)),
                    });
                    if (!newFilter.filters) {
                        this.getDispatchers().setInReduxState({ lastAddedFilterBlockId: childBlock.id });
                    }
                }
            }

        },

        getFilterFromBlock(block: Blockly.Block): Filter {
            if (!block) { return Filter.createComposed(FilterOperators.forComposedFilter.and, []); }
            const childrens = block.getChildren(false);
            let f: Filter = Filter.createForClient();
            if (block.type === EDITOR_LOGIC_OPERATION_WITH_OUTPUT || block.type === EDITOR_LOGIC_OPERATION_NO_OUTPUT) {
                f.filters = [];
                for (let i = 0; i < childrens.length; i++) {
                    let filter = this.getFilterFromBlock(childrens[i]);
                    f.filters.push(filter);
                }
                f.operator = block.getFieldValue("OP");
                f.enabled = block.getFieldValue("ENABLED") && block.getFieldValue("ENABLED").toUpperCase() === "FALSE" ? false : true;
                return f;
            } else {
                let filterData = JSON.parse(block.data);
                let filter: Filter = {
                    field: filterData.field,
                    operator: filterData.operator,
                    value: filterData.value,
                    enabled: filterData.enabled === "false" ? false : true,
                };
                if (filterData.label) {
                    filter.label = filterData.label
                }
                return filter;
            }
        },

        onWorkspaceClick(event: any, topBlockId: string, workspace: Blockly.WorkspaceSvg) {
            let block = workspace.getBlockById(event.blockId);
            if (block.id === topBlockId) {
                this.getDispatchers().setInReduxState({ selectedFilterPath: '' });
                return;
            }
            let newSelectedFilterPath: string = this.getPathFromBlock(block, topBlockId);
            if (this.getState().selectedFilterPath !== newSelectedFilterPath) {
                this.getDispatchers().setInReduxState({ selectedFilterPath: newSelectedFilterPath });
            }
        },

        onWorkspaceDoubleClick(event: any, topBlockId: string, workspace: Blockly.WorkspaceSvg, mousePosition: { x: number, y: number }) {
            if (event.type === Blockly.Events.UI && event.element === 'click') {
                this.onWorkspaceClick(event, topBlockId, workspace);
                let block = workspace.getBlockById(event.blockId);
                if (block.type !== FILTER_WITH_OUTPUT && block.type !== NO_VALUE_FILTER_WITH_OUTPUT && block.type !== LABELED_FILTER_WITH_OUTPUT) {
                    this.getDispatchers().setInReduxState({ selectedFilterPath: '' });
                    return;
                }
                if (this.getState().selectedFilterPath) {
                    this.getDispatchers().setInReduxState({ filterFormOpened: [mousePosition.x, mousePosition.y] });
                }
            }
        },

        updateFilter(workspace: Blockly.WorkspaceSvg, topBlockId: string, keepSelectedPath?: boolean) {
            let newFilter = this.getFilterFromBlock(workspace.getBlockById(topBlockId));
            if (!lodash.isEqual(JSON.stringify(newFilter), JSON.stringify(this.getState().rootFilter))) {
                this.getDispatchers().setInReduxState({
                    rootFilter: this.getFilterFromBlock(workspace.getBlockById(topBlockId)),
                    selectedFilterPath: keepSelectedPath ? this.getState().selectedFilterPath : ''
                })
            }
        },

        duplicateFilter(workspace: Blockly.WorkspaceSvg, topBlockId: string, entityDescriptor: string) {
            if (this.getState().rootFilter.filters!.length === 0 || !Blockly.selected) { return; }
            this.getDispatchers().setInReduxState({ selectedFilterPath: this.getPathFromBlock(workspace.getBlockById(Blockly.selected.id), topBlockId) });
            if (!this.getState().selectedFilterPath) { return; }
            let filter = Utils.navigate(this.getState().rootFilter, this.getState().selectedFilterPath);
            const openFilterForm = filter.filters ? false : true;
            this.addFilter(filter, workspace, topBlockId, entityDescriptor, true, openFilterForm);
        },

        editFilter(topBlockId: string, workspace: Blockly.WorkspaceSvg) {
            if (!Blockly.selected) { return; }
            this.getDispatchers().setInReduxState({ selectedFilterPath: this.getPathFromBlock(workspace.getBlockById(Blockly.selected.id), topBlockId) });
            if (!this.getState().selectedFilterPath) { return; }
            let filter = Utils.navigate(this.getState().rootFilter, this.getState().selectedFilterPath);
            if (!filter.filters) {
                this.getDispatchers().setInReduxState({ filterFormOpened: true });
            }
        },

        onFilterFormApplyAndAddNew(newFilter: Filter, workspace: Blockly.WorkspaceSvg, blocklyEditorTabRef: React.RefObject<any>, entityDescriptor: string, filterFormRef: React.RefObject<FilterForm>) {
            filterFormRef.current?.fieldNameContentAssistRef?.resetState();
            this.onFilterFormApply(newFilter, workspace, blocklyEditorTabRef, entityDescriptor);
            this.addFilterInBlockly(Filter.createForClient(), workspace, blocklyEditorTabRef.current.topBlockId, entityDescriptor);
            filterFormRef.current!.fieldNameContentAssistRef!.props.r.open(entityDescriptor, true, true, FormType.FILTER);
        },

        onFilterFormApplyAndAddNewSameField(newFilter: Filter, workspace: Blockly.WorkspaceSvg, blocklyEditorTabRef: React.RefObject<any>, entityDescriptor: string) {
            this.commitWorkFromFilterForm(newFilter, workspace, blocklyEditorTabRef, entityDescriptor);
            this.addFilterInBlockly(Filter.createForClient(newFilter.field), workspace, blocklyEditorTabRef.current.topBlockId, entityDescriptor, false, true);
        },

        removeBlock(id: any, workspace: Blockly.WorkspaceSvg, blocklyEditorTabRef: React.RefObject<any>, entityDescriptor: string) {
            workspace.getBlockById(id) && workspace.getBlockById(id).dispose(false);
            let filter = this.getFilterFromBlock(workspace.getBlockById(blocklyEditorTabRef.current.topBlockId))
            this.refreshWorkspace(workspace, filter, blocklyEditorTabRef, entityDescriptor);
        }
    }
});

interface BlocklyEditorTabProps {
    ownRef: React.RefObject<any>,
    apply?: (filter: Filter) => void,
    onCancel?: () => void,
    entityDescriptor: string,
}

export class BlocklyEditorTab extends React.Component<PropsFrom<typeof sliceBlocklyEditorTab> & BlocklyEditorTabProps> {

    primaryWorkspace!: Blockly.WorkspaceSvg;
    blocklyComponentDivRef = React.createRef<any>();
    topBlockId!: string;
    filterFormRef = React.createRef<FilterForm>();
    confirmModalRef = React.createRef<any>();
    mousePosition = { x: 0, y: 0 };
    clickCount = 0;
    singleClickTimer: any;

    constructor(props: any) {
        super(props);
        this.onFilterFormApply = this.onFilterFormApply.bind(this);
        this.onFilterFormApplyAndAddNew = this.onFilterFormApplyAndAddNew.bind(this);
        this.onFilterFormApplyAndAddNewSameField = this.onFilterFormApplyAndAddNewSameField.bind(this);
    }

    handleMouseMove = (e: any) => {
        this.mousePosition.x = e.clientX;
        this.mousePosition.y = e.clientY;
    }

    componentWillUnmount() {
        document.removeEventListener('mousemove', this.handleMouseMove);
    }

    handleBlocklyEvents(e: any) {
        if (e.newValue === 'workspace' || !this.props.ownRef.current) { return; }
        if (e.type === Blockly.Events.DELETE || (e.type === Blockly.Events.MOVE && e.newParentId)) {
            this.workspace.trashcan.emptyContents();
            this.props.dispatchers.updateFilter(this.workspace, this.topBlockId);
            return;
        } else if (e.type === Blockly.Events.CHANGE && e.element === 'field' && e.name === 'OP') {
            this.props.dispatchers.onWorkspaceClick(e, this.topBlockId, this.workspace);
            this.props.dispatchers.updateFilter(this.workspace, this.topBlockId, true);
            return;
        } else if (e.type === Blockly.Events.UI && e.element === 'click') {
            this.clickCount++;
            this.props.dispatchers.onWorkspaceClick(e, this.topBlockId, this.workspace);
            if (this.clickCount === 1) {
                this.singleClickTimer = setTimeout(() => {
                    this.clickCount = 0;
                }, 500);
            } else if (this.clickCount === 2) {
                clearTimeout(this.singleClickTimer);
                this.props.dispatchers.onWorkspaceDoubleClick(e, this.topBlockId, this.workspace, this.mousePosition);
                this.clickCount = 0;
            }
        }
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.entityDescriptor !== prevProps.entityDescriptor) {
            this.props.dispatchers.setInReduxState({ rootFilter: DEFAULT_COMPOSED_FILTER})
            this.props.dispatchers.refreshWorkspace(this.workspace, DEFAULT_COMPOSED_FILTER, this.props.ownRef, this.props.entityDescriptor);
        }
    }

    componentDidMount() {
        // TODO CS/FILT: cred ca trebuie si operatiunea inversa de scoatere la unmount? parca la readonly facusem asta?
        this.primaryWorkspace = Blockly.inject(
            this.blocklyComponentDivRef.current,
            DEFAULT_BLOCKLY_SETTINGS
        );
        this.setXml(`<xml>${createFilterBlock(this.props.rootFilter, { entityDescriptorName: this.props.entityDescriptor }, false, true, true) as string}</xml>`);
        this.workspace.addChangeListener((event: any) => this.handleBlocklyEvents(event));

        if (this.props.selectedFilterPath) {
            this.props.dispatchers.setInReduxState({ filterFormOpened: true });
        }

        if (this.confirmModalRef.current && this.confirmModalRef.current._reactInternalFiber.child.stateNode.dimmerRef.current) {
            this.confirmModalRef.current._reactInternalFiber.child.stateNode.dimmerRef.current.classList.add('SortBar_editSortModal');
        }

        document.addEventListener('mousemove', this.handleMouseMove);
    }

    get workspace() {
        return this.primaryWorkspace;
    }

    setXml(xml: string) {
        this.topBlockId = Blockly.Xml.appendDomToWorkspace(Blockly.Xml.textToDom(xml), this.workspace)[0];
        let topBlock = this.workspace.getBlockById(this.topBlockId);
        topBlock.setInputsInline(false);
        topBlock.setDeletable(false);
        topBlock.moveBy(20, 20);
    }

    addSimpleFilter = () => this.props.dispatchers.addFilter(Filter.createForClient(), this.workspace, this.topBlockId, this.props.entityDescriptor, false, true);

    addDuplicateFilter = () => this.props.dispatchers.duplicateFilter(this.workspace, this.topBlockId, this.props.entityDescriptor)

    editFilter = () => this.props.dispatchers.editFilter(this.topBlockId, this.workspace);

    addAndFilter = () => this.props.dispatchers.addFilter(Filter.createComposedForClient(FilterOperators.forComposedFilter.and), this.workspace, this.topBlockId, this.props.entityDescriptor);

    addOrFilter = () => this.props.dispatchers.addFilter(Filter.createComposedForClient(FilterOperators.forComposedFilter.or), this.workspace, this.topBlockId, this.props.entityDescriptor);

    toggleTreeView = (block: Blockly.Block, changeOnlyChilds?: boolean) => {
        !changeOnlyChilds && block.setInputsInline(!this.props.linearBlocks);
        if (block.getChildren(false).length > 0) {
            block.getChildren(false).forEach(b => this.toggleTreeView(b));
        }
    }

    onToggleTreeView = (block: Blockly.Block) => {
        this.props.dispatchers.setInReduxState({ linearBlocks: !this.props.linearBlocks })
        this.toggleTreeView(block, true)
    }

    onApply = () => {
       this.props.dispatchers.beforeApply(this.workspace, this.topBlockId, this.props.ownRef, this.props.entityDescriptor, this.props.apply);
    }

    onClose = () => {
        if (this.props.lastAddedFilterBlockId) {
            this.props.dispatchers.removeBlock(this.props.lastAddedFilterBlockId, this.workspace, this.props.ownRef, this.props.entityDescriptor);
        }
        this.props.dispatchers.setInReduxState({
            lastAddedFilterBlockId: '',
            filterFormOpened: false,
            filterForm: {
                error: ErrorType.NONE,
                initialValues: {}
            }
        })
    }

    onFilterFormApply(filter: Filter) {
        this.props.dispatchers.onFilterFormApply(filter, this.workspace, this.props.ownRef, this.props.entityDescriptor, true);
    }

    onFilterFormApplyAndAddNew(filter: Filter) {
        this.props.dispatchers.onFilterFormApplyAndAddNew(filter, this.workspace, this.props.ownRef, this.props.entityDescriptor, this.filterFormRef);
    }

    onFilterFormApplyAndAddNewSameField(filter: Filter) {
        this.props.dispatchers.onFilterFormApplyAndAddNewSameField(filter, this.workspace, this.props.ownRef, this.props.entityDescriptor);
    }

    render() {
        let filter = this.props.selectedFilterPath && this.props.rootFilter.filters!.length > 0 ? Utils.navigate(this.props.rootFilter, this.props.selectedFilterPath) : Filter.createForClient();
        let parentFilter: Filter;
        if (this.props.selectedFilterPath) {
            if (this.props.selectedFilterPath.split(Utils.defaultIdSeparator).length === 2) {
                parentFilter = lodash.cloneDeep(this.props.rootFilter);
            } else {
                let pathToParent = Utils.substringBefore(this.props.selectedFilterPath, Utils.defaultIdSeparator, true);
                pathToParent = Utils.substringBefore(pathToParent, Utils.defaultIdSeparator, true);
                const parent: Filter = Utils.navigate(this.props.rootFilter, pathToParent);
                parentFilter = lodash.cloneDeep(parent);
            }
            parentFilter.filters = parentFilter.filters?.filter((f, index) => index.toString() !== Utils.substringAfter(this.props.selectedFilterPath, Utils.defaultIdSeparator, true));
        }
        return (
            <Tab.Pane>
                <React.Fragment>
                    <Segment className='BlocklyEditorTab_allFiltersTabButtons'>
                        <Button icon='add' onClick={this.addSimpleFilter} color='olive' />
                        <Button icon='copy' onClick={this.addDuplicateFilter} color='olive' />
                        <Button icon='edit' onClick={this.editFilter} color='olive' />
                        <Button color='olive' onClick={this.addAndFilter}><Icon className='FilterEditor_addAndButtonColor' name='add' />{_msg('Filter.operator.and')}</Button>
                        <Button color='olive' onClick={this.addOrFilter}><Icon className='FilterEditor_addOrButtonColor' name='add' />{_msg('Filter.operator.or')}</Button>
                        <Button floated='right' onClick={() => this.onToggleTreeView(this.workspace.getBlockById(this.topBlockId))}><Icon name='sitemap' />{_msg('BlocklyEditorTab.treeLinear')}</Button>
                    </Segment>
                    <div className='BlocklyComponent' ref={this.blocklyComponentDivRef} />
                </React.Fragment>
                <Container>{_msg('BlocklyEditorTab.hint')}</Container>
                <div className='BlocklyEditorTab_buttons'>
                    {this.props.apply && <Button color='green' onClick={this.onApply}>{_msg('general.apply')}</Button>}
                    {this.props.onCancel && <Button color='red' onClick={this.props.onCancel}>{_msg('general.cancel')}</Button>}
                </div>
                <FilterForm parentFilter={parentFilter!} filterFormOpened={this.props.filterFormOpened} {...this.props.filterForm} onClose={this.onClose} onApplyAndAddNewSameField={this.onFilterFormApplyAndAddNewSameField} onApplyAndAddNew={this.onFilterFormApplyAndAddNew} onApply={this.onFilterFormApply} ref={this.filterFormRef} entityDescriptor={this.props.entityDescriptor} dispatchers={this.props.dispatchers.filterForm} initialFilter={filter} ></FilterForm>
            </Tab.Pane>
        );
    }
}