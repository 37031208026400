import gql from "graphql-tag";

export const FLIGHT_FOR_GANTT = gql`
    fragment FlightForGantt on Flight {
        id name date departure initialDate airline number showFlightInGantt tasks { id offset duration emrjUseRotationFlightAsReference eventMonitoringRulesJson taskType { name color } } rotationFlight { id } apuOffStatus parking { id name }
    }
`;

export const HUMAN_RESOURCE_SCHEDULE_FOR_GANTT = gql`
    fragment HumanResourceScheduleForGantt on HumanResourceSchedule {
        id startTime endTime humanResource {id firstName lastName}
    }
`;

export const LOAD_FLIGHTS_FOR_GANTT = gql`
    query loadFlightsForGantt($params: FindByFilterParamsInput) { 
        flightService_findByFilter(params: $params) {
            results  { ...FlightForGantt } 
        }
    }
    ${FLIGHT_FOR_GANTT}
`;

export const LOAD_HUMAN_RESOURCE_SCHEDULE_FOR_GANTT = gql`
    query loadHumanResourceScheduleForGantt($params: FindByFilterParamsInput) {
        humanResourceScheduleService_findByFilter(params: $params) {
            results { ...HumanResourceScheduleForGantt }
        }
    }
    ${HUMAN_RESOURCE_SCHEDULE_FOR_GANTT}
`;

export const LOAD_AUDIT_FOR_GANTT = gql`
    query LoadAuditForGantt($params: FindByFilterParamsInput) {
        auditService_findByFilter(params: $params) {
            results {
                index auditableEntity entityId date auditableField newValue oldValue action
            }
        }
    }
`;

export const OAG_FOR_GANTT = gql`
    fragment ObjectActionGroupForGantt on ObjectActionGroup {
        mission { id humanResource { id } equipmentResource { id identifier } }
        object { id startTime endTime }
    }
`;

export const LOAD_MISSION_FOR_GANTT = gql`
    query loadMissionForGantt($params: FindByFilterParamsInput) {
        objectActionGroupService_findByFilter(params: $params) {
            results { ...ObjectActionGroupForGantt }
        }
    }
    ${OAG_FOR_GANTT}
`;

// XopsDiffUpdateService.getGanttDataNew
export const LOAD_DATA_FOR_GANTT_PAGE = gql`
    query getGanttData($date: String) {
        diffUpdateService_ganttData(clientDate: $date)
    }
`;
