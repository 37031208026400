import { FilterOperators } from "@crispico/foundation-gwt-js";
import { apolloClient, ConnectedPageInfo, createSliceFoundation, DispatchersFrom, getBaseImpures, getBaseReducers, Optional, 
    PropsFrom, StateFrom, Utils } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { Sort } from "@crispico/foundation-react/components/CustomQuery/SortBar";
import { DragAndDropArea } from "@crispico/foundation-react/components/DragAndDrop/DragAndDrop";
import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { EntityDescriptorForServerUtils } from "@crispico/foundation-react/flower/entityDescriptorsForServer/EntityDescriptorForServerUtils";
import { ActionName, AuditUtils } from "@crispico/foundation-react/pages/Audit/AuditUtils";
import { DatePicker } from "antd";
import { AuditNewGantt, AuditNewGanttVariables } from "apollo-gen/AuditNewGantt";
import { loadFlightsForGantt, loadFlightsForGanttVariables, loadFlightsForGantt_flightService_findByFilter_results } from "apollo-gen/loadFlightsForGantt";
import { loadHumanResourceScheduleForGantt, loadHumanResourceScheduleForGanttVariables, 
    loadHumanResourceScheduleForGantt_humanResourceScheduleService_findByFilter_results } from "apollo-gen/loadHumanResourceScheduleForGantt";
import { GanttItemRenderer, ScheduleRenderer } from "pages/gantt/ganttItemRenderers";
import { CheckboxProps, Segment } from "semantic-ui-react";
import { LOAD_AUDIT_FOR_GANTT, LOAD_FLIGHTS_FOR_GANTT, LOAD_HUMAN_RESOURCE_SCHEDULE_FOR_GANTT } from './queries';
import { Group, Timeline } from "@crispico/react-timeline-10000";

let moment = require('moment');

export enum GANTT_ITEM_TYPES { OBJECTS, FLIGHT, TASKS }

const HUMAN_RESOURCE_SCHEDULE_ITEM_FOREGROUND_STYLE = { backgroundColor: 'orange', opacity: 1, height: '32px' };
const HUMAN_RESOURCE_SCHEDULE_ITEM_BACKGROUND_STYLE = { backgroundColor: 'blue', opacity: 0.5, height: '32px' };

export interface FlightItem {
    /* Mandatory properties */
    start: typeof moment,
    end: typeof moment,
    row: number,
    key: number,
    type: GANTT_ITEM_TYPES,
    /* Optional properties */
    data: loadFlightsForGantt_flightService_findByFilter_results
}

export interface HumanResourceScheduleItem {
    /* Mandatory properties */
    id: number,
    start: typeof moment,
    end: typeof moment,
    row: number,
    key: string,
    type: GANTT_ITEM_TYPES,
    /* Optional properties */
    data?: any | null | undefined
    style?: object,
    title: string | undefined,
    dispatchers: DispatchersFrom<any>,
    canDrop?: () => boolean
}

export interface Layer {
    /* Mandatory properties */
    start: typeof moment,
    end: typeof moment,
    rowNumber: number,
    key: number,
    /* Optional properties */
    style: object
}

const refreshRateMillis: number = 2 * 1000;

const OPT_FIXED_FLIGHT_SEGMENT_WIDTH: number = 100; // value from flex, should be configurable

export const sliceGantt = createSliceFoundation(class SliceGantt {
    initialState = {
        counter: 1,
        flights: {} as { [key: string]: loadFlightsForGantt_flightService_findByFilter_results },
        humanResourceSchedules: {} as { [key: string]: loadHumanResourceScheduleForGantt_humanResourceScheduleService_findByFilter_results },
        ganttFromDate: moment(new Date(2021, 9, 3)).startOf('day').valueOf(), // configured for test
        ganttUntilDate: moment(new Date(2021, 9, 3)).endOf('day').valueOf(), // configured for test
        mostRecentUpdate: undefined as Optional<string>,
        dropModalOpen: false,
        dropData: {} as any,
        dropChecked: [] as Array<number>
    }

    reducers = {
        ...getBaseReducers<SliceGantt>(this),

        updateFlight(state: StateFrom<SliceGantt>, flight: any) {
            state.flights[flight.id] = flight;
        },
        removeFlight(state: StateFrom<SliceGantt>, flight: any) {
            delete state.flights[flight.id];
        },
        check(state: StateFrom<SliceGantt>, p: {id: number, data: CheckboxProps}) {
            let dropCheckedTasks = state.dropChecked;
            if (p.data.checked) {
                dropCheckedTasks.push(p.id);
            } else {
                dropCheckedTasks = dropCheckedTasks.filter(c => c !== p.id);
            }
            state.dropChecked = dropCheckedTasks;
        }

    }

    impures = {
        ...getBaseImpures<SliceGantt>(this),

        async loadFlights() {
            this.getDispatchers().setInReduxState({ mostRecentUpdate: moment(Utils.now()).toISOString() });

            const filters: Filter[] = [
                Filter.create("showFlightInGantt", FilterOperators.forBoolean.equals, "true"),
                Filter.create("date", FilterOperators.forDate.greaterThan, "" + this.getState().ganttFromDate),
                Filter.create("date", FilterOperators.forDate.lessThan, "" + this.getState().ganttUntilDate)];
            const sorts: Sort[] = [{ field: "date", direction: "ASC" }];

            const result = (await apolloClient.query<loadFlightsForGantt, loadFlightsForGanttVariables>({
                query: LOAD_FLIGHTS_FOR_GANTT,
                variables: FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.and, filters)).sorts(sorts)
            })).data.flightService_findByFilter?.results;

            var newFlights = {};
            if (result) {
                result.map(flight => (newFlights as any)[flight.id] = flight);
            }
            this.getDispatchers().setInReduxState({ flights: newFlights });
        },
        
        async loadHumanResourceSchedules() {
            const filters: Filter[] = [
                Filter.create("startTime", FilterOperators.forDate.greaterThan, "" + this.getState().ganttFromDate),
                Filter.create("endTime", FilterOperators.forDate.lessThan, "" + this.getState().ganttUntilDate)];
            const sorts: Sort[] = [{ field: "startTime", direction: "ASC" }];

            // get schedule data from server
            const result = (await apolloClient.query<loadHumanResourceScheduleForGantt, loadHumanResourceScheduleForGanttVariables>({
                query: LOAD_HUMAN_RESOURCE_SCHEDULE_FOR_GANTT,
                variables: FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.and, filters)).sorts(sorts)
            })).data.humanResourceScheduleService_findByFilter?.results;

            const humanResourceSchedules = {};
            result?.forEach(hrs => {
                (humanResourceSchedules as any)[hrs.id] = hrs;
            });
            this.getDispatchers().setInReduxState({humanResourceSchedules});
        },

        async checkForFlightUpdates() {
            const result = (await apolloClient.query<AuditNewGantt, AuditNewGanttVariables>({
                query: LOAD_AUDIT_FOR_GANTT,
                variables: FindByFilterParams.create().pageSize(-1).startIndex(0).filter(
                    Filter.createComposed(FilterOperators.forComposedFilter.and, [
                        Filter.createComposed(FilterOperators.forComposedFilter.or, [
                            AuditUtils.getFilterForAuditableEntity("Flight"), 
                            AuditUtils.getFilterForAuditableEntity("TaskGroup")
                        ]),
                       Filter.create("date", FilterOperators.forDate.greaterThan, this.getState().mostRecentUpdate!) 
                    ])).sorts([{ direction: 'ASC', field: 'date' }])
            })).data.auditService_findByFilter?.results;

            if (result && result.length !== 0) {
                this.getDispatchers().setInReduxState({ mostRecentUpdate: moment(result![result!.length - 1].date).toISOString() });

                result.forEach(el => {
                    const flight = this.getState().flights[el.entityId];
                    if (el.action! === ActionName.UPDATE) {
                        const fieldName = EntityDescriptorForServerUtils.getFieldDescriptor(el.auditableEntity, el.auditableField)?.name;
                        if (flight && fieldName) {
                            let newFlight: any = Object.assign({}, flight);
                            newFlight[fieldName] = el.newValue;
                            this.getDispatchers().updateFlight(newFlight);
                        }
                    } else if (el.action! === ActionName.DELETE) {
                        if (flight) {
                            this.getDispatchers().removeFlight(flight);
                        }
                    } else if (el.action! === ActionName.ADD) {
                        var fieldsAndValues = JSON.parse(el.newValue);
                        var newFlight: any = flight ? Object.assign({}, flight) : { id: el.entityId };
                        Object.entries(fieldsAndValues).forEach(([key, value]) => {
                            newFlight[EntityDescriptorForServerUtils.getFieldDescriptor(el.auditableEntity, key)!.name] = value;
                        })
                        // CC: we must see what info should go to state, what info should be ignored
                        // in case of a flight: if showInGantt = true and the date corresponds to filters, then add, otherwize ignore
                        // IMPORTANT: because we have 2 entities TaskGroup & Flight, we have problems with this (TaskGroup has date, Flight has showInGantt -> they came separately)
                        // no filtering is done for the moment
                        this.getDispatchers().updateFlight(newFlight);
                    }
                })
            }
        }
    }
})

export class Gantt extends TabbedPage<PropsFrom<typeof sliceGantt>> {

    private timer: number | undefined = undefined;

    private getGroupsAndItems(flights: { [key: string]: loadFlightsForGantt_flightService_findByFilter_results }, humanResourceSchedules: { [key: string]: loadHumanResourceScheduleForGantt_humanResourceScheduleService_findByFilter_results }) {
        const props = this.props;
        const flightData = {groups: [] as Group[], items: [] as FlightItem[]};
        
        Object.keys(flights).forEach((key, index) => {
            const flight = flights[key];
            // for groups I used the default group renderer that uses only the id and the title
            flightData.groups.push({ id: index, title: (flight.airline || "") + (flight.number || "") });

            // calculate flight segment start date and end date depending on
            // the type of the flight: arrival or departure
            let startDate: Date = new Date(flight.date), endDate: Date = new Date(flight.date);
            let offset: number = OPT_FIXED_FLIGHT_SEGMENT_WIDTH * 60 * 1000;
            if (flight.departure) {
                startDate = new Date(new Date(flight.date).getTime() - offset);
            } else {
                endDate = new Date(new Date(flight.date).getTime() + offset);
            }

            let item: FlightItem = {
                row: index,
                start: moment(startDate),
                end: moment(endDate),
                key: Number(key),
                data: flight,
                type: GANTT_ITEM_TYPES.FLIGHT
            };

            let itemObject: FlightItem = {
                row: index,
                start: moment(endDate),
                end: moment(props.ganttUntilDate),
                key: Number(key) * (-1),
                data: flight,
                type: GANTT_ITEM_TYPES.OBJECTS
            };
            
            flightData.items.push(item);
            flightData.items.push(itemObject);
        });

        const hrsData = {groups: [] as Group[], items: [] as HumanResourceScheduleItem[]};

        let rowMap: Map<number, number> = new Map();
        Object.keys(humanResourceSchedules).forEach((key, index) => {
            const hrs = humanResourceSchedules[key];
            const title = ((hrs.humanResource?.lastName + ", ") || "") + (hrs.humanResource?.firstName || "");            
            let row = rowMap.size;
            if (rowMap.has(hrs.humanResource?.id)) {
                row = rowMap.get(hrs.humanResource?.id) as number;
            } else {
                rowMap.set(hrs.humanResource?.id, row);
                hrsData.groups.push({ id: row, title: title });
            }
            const hrsItems: HumanResourceScheduleItem[] = []; 
           
            const hrsHelpers: HumanResourceScheduleItem[] = [];
            let startTime = moment(hrs.startTime);
            const endTime = moment(hrs.endTime);
            for (let i = 0; i < hrsItems.length; i ++) {
                const hrsItem = hrsItems[i];
                if (startTime < hrsItem.start) {
                    if (startTime < endTime) {
                        hrsHelpers.push({
                            id: hrs.id,
                            row: row,
                            start: startTime,
                            end: hrsItem.start < endTime ? hrsItem.start : endTime,
                            key: hrsItem.key + '_helper',
                            type: GANTT_ITEM_TYPES.TASKS,
                            title: title,
                            dispatchers: this.props.dispatchers,
                            style: HUMAN_RESOURCE_SCHEDULE_ITEM_BACKGROUND_STYLE
                        });
                    }
                    startTime = hrsItem.end;
                }
            }
            if (startTime < endTime) {
                hrsHelpers.push({
                    id: hrs.id,
                    row: row,
                    start: startTime,
                    end: endTime,
                    key: index + '_end_helper',
                    type: GANTT_ITEM_TYPES.TASKS,
                    title: title,
                    dispatchers: this.props.dispatchers,
                    style: HUMAN_RESOURCE_SCHEDULE_ITEM_BACKGROUND_STYLE
                });
            }

            hrsData.items = hrsData.items.concat(hrsItems).concat(hrsHelpers);
        });
    
        // set state flights/hrs to new map
        return {flight: flightData, hrs: hrsData};
    }

    private startTimer() {
        this.timer = window.setTimeout(async () => {
            await this.props.dispatchers.checkForFlightUpdates();
            this.startTimer();
        }, refreshRateMillis);
    }

    private stopTimer() {
        clearTimeout(this.timer);
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    protected getTitle() {
        return { icon: "cog", title: _msg({ missingKeyStrategy: "RETURN_KEY" }, "Gantt") };
    }

    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: PropsFrom<typeof sliceGantt>) {
        this.componentDidUpdateInternal(prevProps);
    }

    async componentDidUpdateInternal(prevProps?: PropsFrom<typeof sliceGantt>) {
        if (!prevProps || prevProps.ganttFromDate !== this.props.ganttFromDate || prevProps.ganttUntilDate !== this.props.ganttUntilDate) {
            await this.props.dispatchers.loadFlights();
            await this.props.dispatchers.loadHumanResourceSchedules();
            this.startTimer();
        }
    }

    closeDropModal() {
        this.props.dispatchers.setInReduxState({dropModalOpen: false});
    }

    protected renderMain() {
        const props = this.props;
        const data = this.getGroupsAndItems(props.flights, props.humanResourceSchedules);
        return (<div><DragAndDropArea>
            <Segment><DatePicker value={moment(props.ganttFromDate)} format={Utils.dateFormat}
                onChange={d => {
                    let dateAsNumber: number = d?.valueOf() as number;
                    props.dispatchers.setInReduxState({
                        ganttFromDate: moment(dateAsNumber).startOf('day').valueOf(),
                        ganttUntilDate: moment(dateAsNumber).endOf('day').valueOf()
                    });
                }}
            />
            </Segment>
            <SplitPaneExt defaultSize={"50%"}>
                {/*Flight gantt*/}
                <Timeline
                    startDate={moment(props.ganttFromDate)}
                    endDate={moment(props.ganttUntilDate)}
                    groups={data.flight.groups}
                    items={data.flight.items}
                    onInteraction={() => { }}
                    showCursorTime={false}
                    itemRenderer={GanttItemRenderer}
                />
                {/* HR schedules gantt */}
                <Timeline
                    startDate={moment(props.ganttFromDate)}
                    endDate={moment(props.ganttUntilDate)}
                    groups={data.hrs.groups}
                    items={data.hrs.items}
                    onInteraction={() => { }}
                    showCursorTime={false}
                    itemRenderer={ScheduleRenderer}
                />
            </SplitPaneExt>
        </DragAndDropArea>      
        </div>
        );
    }
}

export const infoGantt = new ConnectedPageInfo(sliceGantt, Gantt, _msg({ missingKeyStrategy: "RETURN_KEY" }, "Gantt"));