import { getFieldLastUpdateDate, ScaleToContainer, Utils } from "@crispico/foundation-react";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { AuditEntriesPopup, sliceAuditEntriesPopup } from "@crispico/foundation-react/pages/Audit/AuditEntriesPopup";
import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react/reduxHelpers";
import moment from "moment";
import React, { RefObject } from "react";
import ReactDOM from 'react-dom';
import { Header, Icon, Popup, Segment } from "semantic-ui-react";

export interface FieldWidgetConfig {
    entityName: string,
    field: string,
    fontSize?: string,    
    titleHasFieldName?: boolean
}

export const sliceFieldWidget = createSliceFoundation(class SliceFieldWidget {
    nestedSlices = {
        auditEntriesPopup: sliceAuditEntriesPopup
    }

    reducers = {
        ...getBaseReducers<SliceFieldWidget>(this),
    }

    impures = {
        ...getBaseImpures<SliceFieldWidget>(this),
    }
})

export type FieldWidgetProps = PropsFrom<typeof sliceFieldWidget> & { config: FieldWidgetConfig, zeroTrainingMode: boolean, buttonBarRef: any, id: string, entity: any };

export class FieldWidget extends React.Component<FieldWidgetProps> {
    fieldWidgetValueRef: RefObject<any>;

    constructor(props: FieldWidgetProps) {
        super(props);
        this.fieldWidgetValueRef = React.createRef();
    }

    protected createButtonForAuditEntries(entityName: string, fieldName: string, entityId: number) {
        return <Icon link name="chart line" size="large" onClick={(e: MouseEvent) => this.props.dispatchers.auditEntriesPopup.open(entityName, fieldName, entityId, [e.clientX, e.clientY])} />;
    }

    render() {
        const props = this.props;
        try {
            let lastUpdateDate;
            let fd = null;
            let fdc = null;
            let value = null;
            if (props.entity && props.config?.entityName) {
                fdc = entityDescriptors[props.config.entityName]?.getFieldDescriptorChain(props.config.field);
                fd = fdc[fdc.length - 1];
                lastUpdateDate = getFieldLastUpdateDate(props.entity, props.config.field);
            }
            
            let result: any = _msg("general.error");
            let muLabel: string | undefined;
            let textColor: string | undefined;
            let backgroundColor: string | undefined;
            let isObject: boolean = false;
            if (fd && fdc) {
                value = props.entity;
                for (let i = 0; i < fdc.length - 1; i++) {
                    value = value[fdc[i].getFieldName()];
                    if (!value) {
                        break;
                    }
                }
                if (value) {
                    value = (fd?.renderField(value) as any).props.value;
                }
                if (value !== null && value !== undefined) {
                    result = value;
                    if (typeof value === "string") {
                        if (value.length === 0 || value === "null") {
                            result = _msg("general.notAvailable");
                        }
                    } else if (typeof value === "number") {
                        result = fd.getFieldValueConvertedToMeasurementUnit(value, 1);
                    } else if (typeof value === "object") {
                        isObject = true;
                    }
                    const fieldColors = fd.getFieldColors(value);
                    if (!fieldColors?.bulletColor) {
                        textColor = fieldColors?.color;
                        backgroundColor = fieldColors?.backgroundColor;
                    }
                } else {
                    result = _msg("general.notAvailable");
                }
                muLabel = fd.getMeasurementUnitLabel();
            }

            return <><Segment basic className="flex-container flex-grow no-padding-top-bottom" style={{ padding: '5px' }}>
                <Popup position="top center"
                    popperModifiers={[{ name: "preventOverflow", options: { boundariesElement: "offsetParent" } }]}
                    trigger={<div className="flex-container-row flex-grow FieldWidget_lineHeight" ref={this.fieldWidgetValueRef} style={{ backgroundColor: backgroundColor, alignItems: 'baseline' }}>
                        <ScaleToContainer className="FieldWidget_bigText flex-center" fixedFontSize={props.config.fontSize}><div style={{color: textColor, backgroundColor: backgroundColor}}>
                            {isObject ? fd?.renderField(props.entity) : fd ? fd.wrapComponent(value, result) : result}
                        </div></ScaleToContainer>
                        {muLabel ? <Header size="small" className="no-margin">{muLabel}</Header> : null}
                    </div>}
                    content={result + (muLabel ? (' ' + muLabel) : '')}>
                </Popup>
                {lastUpdateDate ? <Popup position="top center"
                    popperModifiers={[{ name: "preventOverflow", options: { boundariesElement: "offsetParent" } }]}
                    trigger={<Header size="tiny" className="no-margin white-space-normal" color='grey' style={{ fontWeight: 'normal' }}>{moment(lastUpdateDate).fromNow()}</Header>}
                    content={moment(lastUpdateDate).format(Utils.dateTimeFormat)}>
                </Popup> : null}
            </Segment>
                {fd && props.buttonBarRef && ReactDOM.createPortal(this.createButtonForAuditEntries(props.config.entityName, props.config.field, props.entity.id), props.buttonBarRef)}
                <AuditEntriesPopup {...props.auditEntriesPopup} dispatchers={props.dispatchers.auditEntriesPopup}></AuditEntriesPopup>
            </>
        } catch (e) {
            return <>{_msg("general.error")}</>
        }

    }
}