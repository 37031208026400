import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { CrudFormInEditor } from "@crispico/foundation-react/entity_crud/CrudFormInEditor";
import { addAfterStartupRunnable } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { EditMode, EntityEditorPage, EntityEditorPageProps, SaveParams, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension } from "@crispico/foundation-react/entity_crud/EntityEditorPage";
import { SaveFieldsParams } from "@crispico/foundation-react/entity_crud/SaveFieldsParams";
import { createSliceFoundationFromCallback, getBaseImpures, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react/reduxHelpers";
import { CUSTOM_QUERY_COLUMN_CONFIG_ALLOW_APPLY, ENT_DELETE, Utils } from "@crispico/foundation-react/utils/Utils";
import { createTestids } from "@famiprog-foundation/tests-are-demo";
import { MutationOptions } from "apollo-client";
import { FormikProps } from "formik";
import _ from "lodash";
import React from "react";
import { Button, Message } from "semantic-ui-react";
import { MIN_REFRESH_RATE } from "../RefreshButton/RefreshButton";
import { OverrideableElement } from "../TabbedPage/TabbedPage";
import { ClientColumnConfig } from "./ClientColumnConfig";
import { getColumnConfigForClient, getColumnConfigForServer } from './ColumnConfigDropdown';

export const sliceColumnConfigEntityEditorPage = createSliceFoundationFromCallback(() => class SliceColumnConfigEntityEditorPage extends SliceEntityEditorPage {

    initialState = {
        ...sliceEntityEditorPageOnlyForExtension.initialState,
    }

    reducers = {
        ...sliceEntityEditorPageOnlyForExtension.reducers,
        ...getBaseReducers<SliceColumnConfigEntityEditorPage>(this),
        
        onModeLoadedSuper: sliceEntityEditorPageOnlyForExtension.reducers.onModeLoaded,
        onModeLoaded(state: StateFrom<SliceEntityEditorPage>, entity: any) {
            this.onModeLoadedSuper(state, getColumnConfigForClient(entity));
        },
    }

    impures = {
        ...sliceEntityEditorPageOnlyForExtension.impures,
        ...getBaseImpures<SliceColumnConfigEntityEditorPage>(this),

        invokeSaveMutationSuper: sliceEntityEditorPageOnlyForExtension.impures.invokeSaveMutation,
        async invokeSaveMutation(options: MutationOptions<any, { params: SaveFieldsParams }>) {
            const fieldsAndValuesBeingSent = options.variables!.params.fieldsAndValues as ClientColumnConfig;
            options.variables!.params.fieldsAndValues = getColumnConfigForServer(fieldsAndValuesBeingSent);
            return await this.invokeSaveMutationSuper(options);
        },

        saveSuper: sliceEntityEditorPageOnlyForExtension.impures.save,
        async save(entity: any, navigateToTable = true, customFieldsToUpdate?: string[], params?: SaveParams)  {
            if (entity.autoRefreshInterval < MIN_REFRESH_RATE) {
                entity = {...entity, autoRefreshInterval: 0};
            }

            // this field is not among what's being sent, because it doesn't have a fieldDescriptor
            this.saveSuper(entity, navigateToTable, customFieldsToUpdate, {
                ...params, initialFieldsAndValues: {
                    configObject: (entity as ClientColumnConfig).configObject
                }
            })
        },
    }

    onBeforeMergeByConnectedPageHelper() {
        // nothing to do; we want to disable what happens in super
    }

    /**
     * Raising the visibility.
     */
    getFieldsToRequest() {
        return super.getFieldsToRequest();
    }

});

interface ColumnConfigEntityEditorPageProps {
    closeEditor?: () => void;
    updateColumnConfig?: (cc: ClientColumnConfig) => void;
    entityDescriptor: EntityDescriptor;
    openConfirm: (e: any, index: number, id: number) => void;
}

export const columnConfigEntityEditorPageTestids = createTestids("ColumnConfigEntityEditorPage", {
    delete: "", title: ""
});

export let ColumnConfigEntityEditorPage: new () => EntityEditorPage<PropsFrom<typeof sliceColumnConfigEntityEditorPage> & ColumnConfigEntityEditorPageProps & EntityEditorPageProps>;

addAfterStartupRunnable(() => {

    ColumnConfigEntityEditorPage = class extends EntityEditorPage<PropsFrom<typeof sliceColumnConfigEntityEditorPage> & ColumnConfigEntityEditorPageProps & EntityEditorPageProps>  {

        protected refFormSimple = React.createRef<CrudFormInEditor>();

        constructor(props: Readonly<PropsFrom<typeof sliceColumnConfigEntityEditorPage> & ColumnConfigEntityEditorPageProps>) {
            super(props);
            const that = this;
            this.editorFormSimpleClass = class extends CrudFormInEditor {
                renderFormikChild(formikProps: FormikProps<any>) {
                    return (<>
                        {this.props.entity?.id === -1 && <Message data-testId="ColumnConfigEntityEditorPage_saveDisabledMessage" header={_msg('EntityEditorPage.saveDisabled')} content={_msg('EntityEditorPage.saveDisabledMessage', _msg('ColumnConfig.label'))} />}
                        {super.renderFormikChild(formikProps)}
                    </>)
                }

                protected isFieldEnabled(fieldDescriptor: FieldDescriptor) {
                    if ("entityName" === fieldDescriptor.name && that.props.mode !== EditMode.ADD) {
                        return false;
                    } else {
                        return super.isFieldEnabled(fieldDescriptor);
                    }
                }

                renderFieldEditor(field: string, formikProps: FormikProps<any>) {
                    if ("version" === field || ("configJson" === field && that.props.embeddedMode) || ("entityName" === field && that.props.embeddedMode)
                        || ("id" === field && that.props.embeddedMode)) {
                        return null;
                    }
                    return super.renderFieldEditor(field, formikProps);
                }
            }
        }

        protected onModalClose() {
            if (AppMetaTempGlobals.appMetaInstance.hasPermission(CUSTOM_QUERY_COLUMN_CONFIG_ALLOW_APPLY)) {
                this.onApply();
            }
        }

        protected async onRevert() {
            const id = this.props.entity.id;
            if (id !== -1 && id !== -2) {
                this.props.dispatchers.load(this.props.entity.id);
            } else {
                this.props.dispatchers.onModeLoaded(getColumnConfigForServer(this.props.entityDescriptor.getDefaultColumnConfig()));
            }
        }

        protected async onSaveAs() {
            await Utils.setTimeoutAsync();            
            this.triggerCommitForAll();
            if (this.props.onSave) {
                const entity = _.cloneDeep(this.props.entity);
                // So that we don't create 2 program defaults, the unique constraint on name will fail
                // for Program default, because it is not stored in the db
                if (entity.name === _msg("entityCrud.table.cc.programDefault")) {
                    entity.name = _msg("entityCrud.table.cc.programDefault") + "-" + _msg("entityCrud.table.programDefaultCopy");
                }
                entity.id = null;
                this.props.dispatchers.save(entity);
            }
            if (this.modalProps) {
                this.props.dispatchers.setModalOpen(false);
            }
        }

        protected preRenderButtons(params: {}): Array<OverrideableElement> {
            const buttons: Array<OverrideableElement> = super.preRenderButtons(params).map((button: any) => {
                if (button && button.props.key === "apply" && !AppMetaTempGlobals.appMetaInstance.hasPermission(CUSTOM_QUERY_COLUMN_CONFIG_ALLOW_APPLY)) {
                    button.props.disabled = true;
                }
                return button;
            });
            const indexForEmbeddedButtons = buttons.findIndex(b => (b?.valueOf() as any)?.props?.key === "save") + 1;
            if (this.embeddedMode) {
                this.isDeleteEnabled() && buttons.splice(indexForEmbeddedButtons, 0, { elementType: Button, props: { "data-testid": columnConfigEntityEditorPageTestids.delete, key: "delete", color: "red", onClick: (e: any) => this.props.openConfirm(e, this.props.entity.id, this.props.entity.id), content: <>{_msg("entityCrud.table.delete")}</> } });
                super.isSaveEnabled() && buttons.splice(indexForEmbeddedButtons, 0, { elementType: Button, props: { key: "saveAs", color: "blue", onClick: () => this.onSaveAs(), content: <>{_msg("general.saveAs")}</>, "data-testid": "ColumnConfigEntityEditorPage_saveAs" } });
            }
            return buttons;
        }

        protected isDeleteEnabled(): boolean {
            // disable for default entity/shared cc;
            const entityDescriptorName: string = (this.props.dispatchers.getSlice() as SliceEntityEditorPage).entityDescriptor.name;
            const permission = Utils.pipeJoin([ENT_DELETE, entityDescriptorName]);
            return this.props.entity?.id !== -1 && this.props.entity?.id !== -2 && AppMetaTempGlobals.appMetaInstance.hasPermission(permission, false);
        }

        protected isSaveEnabled(): boolean {
            // disable for default entity/shared cc;
            return this.props.entity?.id !== -1 && this.props.entity?.id !== -2 && super.isSaveEnabled();
        }

        protected getTitle(): string | { icon: JSX.Element | string, title: JSX.Element | string } {
            if (this.props.embeddedMode) {
                return {icon: "table", title: <>{_msg("ColumnConfig.label")}: <span data-testid={columnConfigEntityEditorPageTestids.title}>{(this.props.entity ? (this.props.entity.name + (this.props.entity.dirty ? (" (" + _msg("EntityEditorPage.modified") + "*)") : "")) : "")}</span></>}
            } else {
                return super.getTitle();
            }
        }

        protected getExtraTabPanes() {
            return [];
        }

    } as any; // I don't know whay the types are incompatible; hence forcing
});
