import { Messages } from "@crispico/foundation-gwt-js";
import { NavLinkProps } from "react-router-dom";
import { ID } from "./entityCrudConstants";
import { FieldRendererProps } from "./fieldRenderersEditors";
import StringFieldRenderer, { StringFieldRendererSpecificProps } from "./fieldRenderersEditors/StringFieldRenderer";

export type AssociationFieldRendererSpecificProps = {
    url?: string,
    navLinkProps: Partial<NavLinkProps>
} & StringFieldRendererSpecificProps;

export class AssociationFieldRenderer extends StringFieldRenderer<FieldRendererProps> {
    private getFieldName(item: any) {
        return Messages.getInstance().maybeTranslateByUser(this.props.innerEntityDescriptor!.toMiniString(item));
    }

    protected getValue() {
        return this.props.innerEntityDescriptor!.toMiniString(this.props.value);
    }

    protected getSpecificProps() {
        return this.props.rendererSpecificProps as AssociationFieldRendererSpecificProps;
    }

    protected getUrl() {
        return this.getSpecificProps().url || (!this.props.value ? "" : this.props.innerEntityDescriptor!.getEntityEditorUrl(this.props.value[ID]));
    }

    protected getNavLinkExtraProps() {
        return !this.getSpecificProps().navLinkProps ? {} : this.getSpecificProps().navLinkProps;
    }

    protected getShowManyToOneCellAsLink() {
        return this.props.fieldDescriptor.showManyToOneCellAsLink;
    }

    render() {
        // For one-to-many and many-to-many
        if (Array.isArray(this.props.value)) {
            return <>
                {this.props.value.map((item: any) => {
                    return super.renderLabel(this.props, this.getFieldName(item));
                })}
            </>;
        }

        if (this.getShowManyToOneCellAsLink()) {
            return super.render();
        }
        return super.getContent();
    }
}