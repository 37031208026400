import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import { EntityDescriptor } from "./EntityDescriptor";
import { CrudSettings } from "@crispico/foundation-react/entity_crud/CrudSettings";
import { Optional } from "@crispico/foundation-react";
import { Organization } from "../AppMeta";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { Filter } from "../components/CustomQuery/Filter";

// TODO by CS: to replace w/ CrudGlobalSettings.fieldId
export const ID = "id";
export const TABLE_PAGE_ICON: SemanticICONS = "search";
export const EDITOR_PAGE_ICON: SemanticICONS = "list alternate outline";

export type EntityGen = {
    name: string,
    fields: FieldGen[]
}

export type FieldGenKind = "SCALAR" | "OBJECT" | "LIST" | "ENUM";
export type FieldGen = {
    name: string,
    type: string,
    kind: FieldGenKind
}

export const sessionGlobalCurrentOrganizationToFilterBy = 'global.currentOrganizationToFilterBy';
export const sessionGlobalReload = 'global.sessionGlobalReload';

declare global {
    var entitiesGen: { [entityName: string]: EntityGen };
    var crudSettings: Optional<CrudSettings>
    var organizations: Optional<Organization[]>;
    var currentOrganizationToFilterBy: Optional<Organization>;
    var oktaAuthenticationAvailable: boolean;
    var rememberMeEnabled: boolean;
    var fileBrowserBulkDownloadMaximumFileSize: number;
}

export const entityDescriptors: { [entityName: string]: EntityDescriptor } = {};

export const afterStartupRunnables: Array<{ runnable: () => void, orderIndex?: number }> = [];

export function getOrganizationFilter(entityDescriptor: EntityDescriptor, org: Optional<Organization>) {
    return entityDescriptor.fields["organization"] && org ? Filter.create("organization.qualifiedName", FilterOperators.forString.like, org.qualifiedName + "%") : undefined;    
}

export function getEntityDescriptor(chain: string[], rootEntity: string) {
    let entityDescriptor: EntityDescriptor = entityDescriptors[rootEntity];

    for (let i = 0; i < chain.length; i++) {
        if (!entityDescriptor) {
            break;
        }
        entityDescriptor = entityDescriptors[entityDescriptor.getField(chain[i]).getType()];
    }
    return entityDescriptor;
}

export function addAfterStartupRunnable(runnable: () => void, orderIndex?: number) {
    if (orderIndex !== undefined) { throw new Error("Implementation not finished. Please look in the code, uncomment and test") };
    afterStartupRunnables.push({ runnable, orderIndex });
}

/**
 * Hack to get away (in some places) of the circular imports issue. 
 */
export function runAfterStartupRunnables() {
    // TODO by CS: uncomment and test when needed; should work; but didn't have time to try
    // afterStartupRunnables.sort((first, second) => first < second ? -1 : first > second ? 1 : 0);
    for (const r of afterStartupRunnables) {
        r.runnable();
    }
}
