import React from "react";
import { BooleanFieldEditor } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { Icon } from "semantic-ui-react";

export class FlightDepartureFieldEditor extends BooleanFieldEditor {

    render () {
        const { formikProps, fieldDescriptor } = this.props;
        const value: boolean = fieldDescriptor.getFieldValue(formikProps.values) || false;
        const degrees = value ? -45 : 45;
        return <div className="flex-container-row">
            {super.render()}
            <Icon name="plane" style={{ transform: "rotate(" + degrees + "deg)" + (value ? "" : "translate(25%, -25%)") }} />
        </div>
    }
}
