import { FilterOperators } from "@crispico/foundation-gwt-js";
import { Organization } from "@crispico/foundation-react";
import { dashboardCalculateForRecordsValueService_findByFilter1_dashboardCalculateForRecordsValueService_findByFilter } from "@crispico/foundation-react/apollo-gen-foundation/dashboardCalculateForRecordsValueService_findByFilter1";
import { dashboardService_calculateForRecordsGetValue_dashboardService_calculateForRecordsGetValue } from "@crispico/foundation-react/apollo-gen-foundation/dashboardService_calculateForRecordsGetValue";
import { apolloClientHolder } from "@crispico/foundation-react/apolloClient";
import { Optional } from "@crispico/foundation-react/CompMeta";
import { entityDescriptors, ID } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { AbstractWidgetWithFilter, AbstractWidgetWithFilterConfig, AbstractWidgetWithFilterProps, sliceAbstractWidgetWithFilterOnlyForExtension } from "@crispico/foundation-react/pages/dashboard/AbstractWidgetWithFilter";
import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react/reduxHelpers";
import { ScaleToContainer } from "@crispico/foundation-react/utils/Utils";
import CronParser from "cron-parser";
import gql from "graphql-tag";
import Interweave from "interweave";
import lodash from "lodash";
import { Moment } from "moment";
import React from "react";
import ReactDOM from "react-dom";
import { Button, Dropdown, DropdownItemProps, DropdownProps, Icon, Label, Message, Modal, Popup, Segment } from "semantic-ui-react";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import { getColor } from "../../zeroTraining/ZeroTrainingIndexPage";
import { Filter } from "../CustomQuery/Filter";
import { ModalExt } from "../ModalExt/ModalExt";
import { CalculateForRecordsChart, sliceCalculateForRecordsChart } from "./CalculateForRecordsChart";
import { CALCULATE_CURRENT_VALUE, GET_SAVED_VALUES } from "./queries";

import * as momentForSetup from "moment"; // cf. https://stackoverflow.com/a/64461483/306143; but then the result cannot be used; hence reimporting again
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

momentDurationFormatSetup(momentForSetup);

export const COMMA_SEPARATOR = ",";
export const ORGANIZATION = "organization";
const ALL = "all";
const FROM = "from";
const TO = "to";

type WidgetValue = { [key: string]: number[] }

export const sliceCalculateForRecords = createSliceFoundation(class SliceCalculateForRecords {
    nestedSlices = {
        calculateForRecordsChart: sliceCalculateForRecordsChart
    }

    initialState = { ...sliceAbstractWidgetWithFilterOnlyForExtension.initialState, values: {} as WidgetValue, averageValues: {} as WidgetValue, savedValues: undefined as Optional<WidgetValue[]>, historyOpened: false, selectedGroupByKey: "", miniFields: {} as { [key: string]: any[] }, errorMessage: undefined as string | undefined, groupByModal: false as [number, number] | boolean, selections: undefined as string | undefined }

    reducers = {
        ...getBaseReducers<SliceCalculateForRecords>(this),
    }

    impures = {
        ...getBaseImpures<SliceCalculateForRecords>(this),

        async calculateValue(dashboardId: number, widgetIdIfReferenced: string, widgetConfig: any, valueIndex: number, selectedGroupByKey: string, averageValues: WidgetValue, currentOrganization: Optional<Organization>, filter: Filter | undefined) {
            const value: dashboardService_calculateForRecordsGetValue_dashboardService_calculateForRecordsGetValue =
                (await apolloClientHolder.apolloClient.query({ query: CALCULATE_CURRENT_VALUE, variables: { dashboardId, widgetIdIfReferenced, filter }, context: { showSpinner: false } })).data.dashboardService_calculateForRecordsGetValue
            
            if (!value) {
                return;
            }

            let savedValues = await this.getAverageValue(dashboardId, widgetIdIfReferenced, widgetConfig, filter);
            let values = value.a && JSON.parse(value.a);

            if (widgetConfig.groupBySuborganizations) {
                values = createValuesForParentOrganizations(values, widgetConfig.calculationLogic, currentOrganization);
                if (savedValues) {
                    savedValues = createSavedValuesForParentOrganizations(savedValues, widgetConfig.calculationLogic, currentOrganization);
                }
            }

            this.getDispatchers().setInReduxState({ values, savedValues });

            await this.prepareGroupByData(widgetConfig, values);

            this.calculateAverageValueForSelectedGroupBy(valueIndex, savedValues, selectedGroupByKey, averageValues);
        },

        async getAverageValue(dashboardId: number, widgetIdIfReferenced: string, widgetConfig: any, filter: Filter | undefined) {
            if (!widgetConfig || !widgetConfig.saveIntervalExpression || !widgetConfig.rollingAverageExpression) {
                return undefined;
            }

            const cronExpression = CronParser.parseExpression(widgetConfig.saveIntervalExpression)
            //get previous running date from cron expression
            const cronNextDate = cronExpression.next().toDate();
            const cronPrevDate = cronExpression.prev().toDate();
            

            //it takes half the difference in minutes (for this reason it is "/ (2 * ...") to generate the filters below.
            let dateDiff = Math.floor((cronNextDate.getTime() - cronPrevDate.getTime()) / (2 * 1000 * 60));
            dateDiff = dateDiff > 0 ? dateDiff : 1;
            const dateFilters = [];

            /*
             * Filter generation for each day, in the interval of + - 1 min from the date of the last run. 
             * The average is made on data recorded in the last X days, at the same time.
             * The second dateadd has 2 * datediff because for the first filter date subtracts a datediff, 
             * and the search range must be between [date - datediff, date + datediff]
             */
            for (var i = 0; i < widgetConfig.rollingAverageExpression; i++) {
                const date = moment(cronPrevDate).add(-1 * i, "day");
                dateFilters.push(Filter.createComposed(FilterOperators.forComposedFilter.and, [
                    Filter.create("timestamp", FilterOperators.forDate.greaterThanOrEqualTo, date.add(-1 * dateDiff, "minute").toISOString()),
                    Filter.create("timestamp", FilterOperators.forDate.lessThanOrEqualTo, date.add(2 * dateDiff, "minute").toISOString())
                ]));
            }

            const filters = [
                Filter.create("widget.dashboard.id", FilterOperators.forNumber.equals, dashboardId.toString()),
                Filter.create("widget.widgetIdIfReferenced", FilterOperators.forNumber.equals, widgetIdIfReferenced),
                Filter.createComposed(FilterOperators.forComposedFilter.or, dateFilters)
            ];

            //The previously generated filter is added
            const params = FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.and, filters));

            const result: dashboardCalculateForRecordsValueService_findByFilter1_dashboardCalculateForRecordsValueService_findByFilter
                = (await apolloClientHolder.apolloClient.query({ query: GET_SAVED_VALUES, variables: params, context: { showSpinner: false } })).data.dashboardCalculateForRecordsValueService_findByFilter;

            if (!result || !result.results || (result.results as []).length === 0) {
                return undefined;
            }

            return result.results.map(x => x.savedValues && JSON.parse(x.savedValues) as WidgetValue) as WidgetValue[];
        },
        calculateAverageValueForSelectedGroupBy(valueIndex: number, savedValues: Optional<WidgetValue[]>, selectedGroupByKey: string, averageValues: WidgetValue) {
            if (!savedValues) {
                this.getDispatchers().setInReduxState({ averageValues: Object.assign({ [selectedGroupByKey]: [0] }, averageValues) });
                return;
            }

            let sum: number = 0;
            let count: number = 0;

            for (const row of savedValues) {
                sum = sum + parseFloat(row[selectedGroupByKey]?.[valueIndex] ? row[selectedGroupByKey]?.[valueIndex].toString().replace(',', '.') : "0");
                count++;
            }

            this.getDispatchers().setInReduxState({ averageValues: Object.assign({ [selectedGroupByKey]: [Math.round(sum / (count > 0 ? count : 1) * 100) / 100] }, averageValues) });
        },
        async prepareGroupByData(widgetConfig: any, values: WidgetValue) {
            const { entityType, groupBySuborganizations, groupByFields } = widgetConfig as { entityType: string, groupBySuborganizations: boolean, groupByFields: string };
            const entityDescriptor = entityDescriptors[entityType];

            const keys = Object.keys(values);

            if (!keys || keys.length === 0) {
                return;
            }

            let startPos = 0;
            if (groupBySuborganizations) {
                startPos = 1;
            }

            const groupByEntities = groupByFields && groupByFields.split(COMMA_SEPARATOR);
            const groupByIds = {} as { [key: string]: string[] };

            if (groupBySuborganizations) {
                Object.assign(groupByIds, { [ORGANIZATION]: [] });
            }

            if (groupByEntities) {
                for (const fieldName of groupByEntities) {
                    Object.assign(groupByIds, { [fieldName]: [] });
                }
            }

            for (const key of keys) {
                const ids = key.split(COMMA_SEPARATOR);

                if (groupBySuborganizations) {
                    groupByIds[ORGANIZATION].push(ids[0]);
                }

                if (!groupByEntities || ids.length <= startPos) {
                    continue;
                }

                for (let i = startPos; i < ids.length; i++) {
                    groupByIds[groupByEntities[i - startPos]].push(ids[i]);
                }
            }

            const miniFields = {};

            for (const fieldName of Object.keys(groupByIds)) {
                Object.assign(miniFields, await this.getEntityMiniFields(fieldName, entityDescriptor.getField(fieldName).type, groupByIds[fieldName].filter(x => x !== "")));
            }

            this.getDispatchers().setInReduxState({ miniFields });
        },
        async getEntityMiniFields(fieldName: string, entityName: string, entityIds: string[]) {
            if (entityIds.length === 0) {
                return;
            }

            const entityDescriptor = entityDescriptors[entityName];
            const loadOperationName = `${lodash.lowerFirst(entityDescriptor.name)}Service_findByFilter`;
            const query = gql(`query q($params: FindByFilterParamsInput) { 
                ${loadOperationName}(params: $params) {
                    results { ${ID} ${entityDescriptor.getGraphQlFieldsToRequest(entityDescriptor.miniFields)} }
                }
            }`);
            const params = FindByFilterParams.create().filter(Filter.create(ID, FilterOperators.forNumber.in, entityIds.filter(x => x !== "").join(COMMA_SEPARATOR)));
            const result = (await apolloClientHolder.apolloClient.query({ query: query, variables: params, context: { showSpinner: false } })).data[loadOperationName];
            return { [fieldName]: result.results };
        }
    }
});
// TODO by CS: this should be split in 2: a generic class, and a "per-widget-type" one
export type CalculateForRecordsWidgetConfig = {
    title: string, parameters: any, description: string, headerFontSize: string, headerBackgroundColor: string, headerIcon: SemanticICONS
    backgroundColor: string, calculationLogic: string, saveIntervalExpression: string, rollingAverageExpression: string, showChartInWidget: boolean, fontSize: string,
    valueColor: string, intervals: any, scaleFactors: string, averageColor: string, showAverageAsMainInfo: boolean,
    upwardTrendIndicatorIsRed: boolean, groupBySuborganizations: boolean, groupByFields: string
} & AbstractWidgetWithFilterConfig;

export type CalculateForRecordsProps = PropsFrom<typeof sliceCalculateForRecords> & AbstractWidgetWithFilterProps & { widgetConfig: CalculateForRecordsWidgetConfig, id: string, dashboardEntity: any, expandedOrganization?: Organization, buttonBarRef: any };

export class CalculateForRecords extends AbstractWidgetWithFilter<CalculateForRecordsProps> {

    constructor(props: CalculateForRecordsProps) {
        super(props);
        this.createButtonForHistory = this.createButtonForHistory.bind(this);
    }

    componentDidUpdateInternal(prevProps: any) {
        super.componentDidUpdateInternal(prevProps);
        let shouldRefresh = false;
        if (this.props.dashboardEntity?.expandOrganizations && !this.props.widgetConfig.groupBySuborganizations) {
            if (!this.props.errorMessage) {
                this.props.dispatchers.setInReduxState({ errorMessage: _msg("CalculateForRecords.groupBy.error") });
            }
        } else {
            if (!prevProps || !lodash.isEqual(prevProps.widgetConfig, this.props.widgetConfig) ||
                !lodash.isEqual(prevProps.dashboardEntity, this.props.dashboardEntity)) {
                shouldRefresh = true;
            }
        }
        if (prevProps?.selections !== this.props.selections) {
            shouldRefresh = true;
        }
        if (shouldRefresh) {
            this.props.dispatchers.setInReduxState({ errorMessage: undefined });
            this.refresh();
        }
    }

    protected async refreshInternal(filter: Filter) {
        const exp = this.props.expandedOrganization;
        const orgId = !exp || exp?.id === this.props.dashboardEntity.organization?.id ? "" : exp.id.toString();
        if (this.props.selectedGroupByKey !== orgId) {
            this.props.dispatchers.setInReduxState({ selectedGroupByKey: orgId });
        }
        this.props.dispatchers.calculateForRecordsChart.setInReduxState({data: [{id: "default", data: []}]});
        if (this.props.dashboardEntity) {
            const valueIndex = this.props.widgetConfig.parameters && this.props.widgetConfig.parameters['percentageOfTotalMode'] ? 1 : 0;
            await this.props.dispatchers.calculateValue(this.props.dashboardEntity.id, this.props.id, this.props.widgetConfig, 
                valueIndex, orgId, this.props.averageValues, this.props.currentOrganization, filter);
        } else {
            this.props.dispatchers.setInReduxState(this.props.dispatchers.getSlice().initialState);
        }
    }

    protected getValue(value: Optional<number>, format: any, percentageMode: Optional<boolean>, scaleFactors: Optional<string>) {
        if (!scaleFactors) {
            return percentageMode ? value + "%" : (format ? moment.duration(value, 'milliseconds').format(format) : value);
        } else {
            return scaleFactors.split(",").map(scale => {
                const newValue = value && Math.round(Number.parseFloat(scale) * value);
                return percentageMode ? newValue + "%" : (format ? moment.duration(newValue, 'milliseconds').format(format) : newValue);
            }).join("; ");
        }
    }

    private handleDropdownChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        let value = data.value?.toString();
        if (value === ALL) {
            value = "";
        }
        this.props.dispatchers.setInReduxState({ selectedGroupByKey: value });
    };

    protected getDropdownOptions(format: any, valueIndex: number, percentageMode: Optional<boolean>) {
        const { groupBySuborganizations, groupByFields, entityType } = this.props.widgetConfig as { groupBySuborganizations: boolean, groupByFields: string, entityType: string };
        const entityDescriptor = entityDescriptors[entityType];

        const groupByFieldsArray = groupByFields && groupByFields.split(COMMA_SEPARATOR);

        let field: FieldDescriptor;
        let options: DropdownItemProps[] = [];
        Object.keys(this.props.values).sort().forEach((keys: string) => {
            const label =
                <>
                    <Label key={keys} horizontal basic circular color="black">{this.getValue(this.props.values[keys]?.[valueIndex], format, percentageMode, this.props.widgetConfig.scaleFactors)}</Label>
                    {keys.split(COMMA_SEPARATOR).map((key, i) => {
                        if (groupBySuborganizations && i === 0) {
                            field = entityDescriptor.getField(ORGANIZATION);
                        } else {
                            let incPos = 0;
                            if (groupBySuborganizations) {
                                incPos = 1;
                            }

                            if (!groupByFieldsArray || i - incPos >= groupByFieldsArray.length) {
                                return null;
                            }

                            field = entityDescriptor.getField(groupByFieldsArray[i - incPos]);
                        }
                        return <Label key={key + "_" + i} horizontal style={{ backgroundColor: getColor(field.name) }}>{key === "" ? _msg("CalculateForRecords.groupByAll", field.getLabel()) :
                            this.props.miniFields[field.name] && entityDescriptors[field.type].toMiniString(this.props.miniFields[field.name].find(x => x.id.toString() === key))}</Label>
                    })}
                </>;

            if (keys === "") {
                keys = ALL;
            }

            options.push({ key: keys, text: label, value: keys });
        });

        return options;
    }

    protected createButtonForHistory() {
        return <Icon link name="chart bar outline" size="large" onClick={() => this.props.dispatchers.setInReduxState({ historyOpened: true })} />;
    }

    protected renderMain() {
        if (this.props.errorMessage) {
            return <div className="flex-container flex-grow no-margin">
                <Message error>
                    <Message.Header>{_msg("Dashboard.error.widget")}</Message.Header>
                    <p>{this.props.errorMessage}</p>
                </Message>
            </div>;
        }

        const widgetConfig = { ...this.props.widgetConfig };

        const percentageMode = widgetConfig.parameters && widgetConfig.parameters['percentageOfTotalMode'];
        const valueIndex = percentageMode ? 1 : 0;
        const values = this.props.values[this.props.selectedGroupByKey];
        const value = values?.[valueIndex];

        if (widgetConfig.intervals && Array.isArray(widgetConfig.intervals)) {
            for (const interval of widgetConfig.intervals) {
                const keys: string[] = Object.keys(interval);
                if ((!keys.includes(FROM) && !keys.includes(TO)) || (keys.includes(FROM) && interval[FROM] > value) || (keys.includes(TO) && interval[TO] < value)) {
                    continue;
                }
                for (const key of keys.filter(x => ![FROM, TO].includes(x))) {
                    (widgetConfig as any)[key] = interval[key];
                }
                break;
            }
        }

        const { fontSize, valueColor, averageColor, showAverageAsMainInfo, showChartInWidget, upwardTrendIndicatorIsRed, description, groupBySuborganizations, groupByFields } = widgetConfig;
        const averageValue = this.props.averageValues[this.props.selectedGroupByKey]?.[0]; //this is calculated, for the moment only one value is in array, so 0 is the only index

        const upwardTrendIndicator = averageValue && value ? value > averageValue : false;
        const trendIndicatorColor = upwardTrendIndicatorIsRed ? (upwardTrendIndicator ? "red" : "green") : (upwardTrendIndicator ? "green" : "red")
        const format = widgetConfig.parameters && widgetConfig.parameters['format'];
        const cannotDisplayTrend = this.props.dataExplorerFilter && this.props.dataExplorerEntityDescriptor && isCompatibleWithDataExplorerFilter(this.props.dataExplorerEntityDescriptor, this.props.dataExplorerFilter).length > 0;
        
        const averageStyle = { color: averageColor }
        const valueStyle = { color: valueColor }
        const percentageModeDetailStyle = percentageMode && widgetConfig.parameters && { fontSize: widgetConfig.parameters['fontSize'] + "pt", color: widgetConfig.parameters['fontColor'] };

        const average = widgetConfig.rollingAverageExpression && averageValue ?
            <span>{_msg("CalculateForRecords.rollingAverage", widgetConfig.rollingAverageExpression)}
                <Label className="less-padding" horizontal style={averageStyle} content={this.getValue(averageValue, format, percentageMode, null)} />
            </span> : null;

        const dropdownOptions = this.getDropdownOptions(format, valueIndex, percentageMode);

        const chart = <CalculateForRecordsChart {...this.props.calculateForRecordsChart} dispatchers={this.props.dispatchers.calculateForRecordsChart}
            onlyChart={showChartInWidget} dashboardId={this.props.dashboardEntity?.id} widgetIdIfReferenced={this.props.id} dataExplorerFilter={this.props.dataExplorerFilter}
            widgetConfig={widgetConfig} format={format} selectedGroupByKey={this.props.selectedGroupByKey} valueIndex={valueIndex} percentageMode={percentageMode} currentOrganization={this.props.currentOrganization}
            groupByLabel={(groupBySuborganizations || groupByFields) && dropdownOptions.length > 1 && dropdownOptions.find(x => x.value === (this.props.selectedGroupByKey === "" ? ALL : this.props.selectedGroupByKey))?.text} expandedOrganization={this.props.expandedOrganization}/>


        const historyButtonPortal = this.props.buttonBarRef?.current && <>{ReactDOM.createPortal(this.createButtonForHistory(), this.props.buttonBarRef.current)}</>;

        const modalWithChart = (showChartInWidget || widgetConfig.saveIntervalExpression) &&
            <ModalExt open={this.props.historyOpened} size="fullscreen" onClose={() => this.props.dispatchers.setInReduxState({ historyOpened: false })} >
                <Modal.Header>
                    <Icon name="chart bar outline" />
                    <Interweave content={_msg("CalculateForRecords.history.title", widgetConfig.title)} />
                    &nbsp;{description && <Popup content={description} on="click" trigger={<Button basic color="orange" icon="question circle outline" content={_msg("general.description")} />} />}
                </Modal.Header>
                <Modal.Content style={{ height: "800px" }}>
                    <chart.type {...chart.props} onlyChart={false} />
                </Modal.Content>
            </ModalExt>

        if (showChartInWidget) {
            return (
                <div className="flex-grow flex-center" >
                    {historyButtonPortal}
                    {modalWithChart}
                    {chart}
                </div>
            );
        }

        const dropdownValue = this.props.selectedGroupByKey === "" ? ALL : this.props.selectedGroupByKey;
        const dropdownOptionsWithOrg = this.props.expandedOrganization ? dropdownOptions.filter(opt => opt.value?.toString().startsWith(this.props.expandedOrganization?.id)) : dropdownOptions;
        const groupByDropdown = (groupBySuborganizations || groupByFields) && !this.props.dataExplorerFilter && this.props.expandedOrganization && dropdownOptionsWithOrg.length > 1 &&
            <div className="flex-container-row flex-center">
                <Button primary fluid  size="small" onClick={(e) => this.props.dispatchers.setInReduxState({groupByModal: [e.clientX, e.clientY]})}><Icon name="configure" /> {_msg("CalculateForRecords.groupBy.change")}</Button>
                <ModalExt open={this.props.groupByModal} onClose={() => this.props.dispatchers.setInReduxState({groupByModal: false})} closeOnDimmerClick>
                    <Modal.Header>{_msg("CalculateForRecords.groupBy.change")}</Modal.Header>
                    <Modal.Content><Dropdown className="CalculateForRecords_groupByDropdown" fluid selection compact options={dropdownOptionsWithOrg} onChange={this.handleDropdownChange} value={dropdownValue} /></Modal.Content>
                </ModalExt>
            </div>;

        let val = showAverageAsMainInfo ? average : this.getValue(value, format, percentageMode, widgetConfig.scaleFactors);
        if (!val) {
            val = 0;
        }
        return (<Segment basic className="no-padding-top-bottom flex-container flex-grow">
            {widgetConfig.saveIntervalExpression && historyButtonPortal}
            {modalWithChart}
            <div className="flex-container-row flex-grow " style={{ alignItems: 'baseline' }}>
                <span className="flex-grow" />
                <ScaleToContainer className="CalculateForRecords_bigDigit flex-center" fixedFontSize={fontSize} divStyle={showAverageAsMainInfo ? averageStyle : valueStyle}>{val}</ScaleToContainer>
                {cannotDisplayTrend ?
                        <Popup
                            trigger={<Icon fitted name="warning sign" size="big" color="orange" />}
                            content={<p>{_msg("CalculateForRecords.error.trend")} {isCompatibleWithDataExplorerFilter(this.props.dataExplorerEntityDescriptor!, this.props.dataExplorerFilter!).join(", ")}.</p>}
                            hoverable position="top right"
                        />
                    : averageValue && averageValue !== value
                        ? <Icon fitted name={upwardTrendIndicator ? "arrow alternate circle up outline" : "arrow alternate circle down outline"} size="big" color={trendIndicatorColor} />
                        : null
                }
                <span className="flex-grow" />
            </div>
            <div className="flex-container CalculateForRecords_smallDigit" style={percentageModeDetailStyle}>
                {percentageMode && !showAverageAsMainInfo && values && (this.getValue(values?.[0], format, false, widgetConfig.scaleFactors) + (values.length > 2 ? " " + _msg("CalculateForRecords.ofTotal") + " " + this.getValue(values?.[2], format, false, widgetConfig.scaleFactors) : ""))}
            </div>
            <div className="flex-container CalculateForRecords_smallDigit" style={showAverageAsMainInfo ? valueStyle : averageStyle}>
                {showAverageAsMainInfo ? this.getValue(value, format, percentageMode, widgetConfig.scaleFactors) : average}
            </div>
            {groupByDropdown}
            {/* this.props.buttonBarRef && ReactDOM.createPortal(createButtonForWidget({ positive: true, icon: "refresh", className: "dashboardWidgetRefresh" }, () => this.refresh()), this.props.buttonBarRef) */}
        </Segment>);
    }

}

export function getFirstIdFromKey(key: string) {
    const firstComma = key.search(",");
    if (firstComma > 0) {
        return [key.substring(0, firstComma), key.substring(firstComma)]
    } else {
        return [key, undefined];
    }
}

export function getOrganizationsForValues(currentOrganization: Optional<Organization>) {
    const org = {
        organizationMap: new Map<number, Organization>(),
        organizationsWithChildrenToAccountFor: new Map<number, boolean>(),
        organizationsWithNoParent: Array<number>(),
        organizationsToDelete: new Set<number>(),
        organizations: Array<Organization>()
    };
    org.organizationMap = new Map();
    org.organizationsWithChildrenToAccountFor = new Map();
    org.organizationsWithNoParent = new Array();
    org.organizationsToDelete = new Set();

    const orgName = global.currentOrganizationToFilterBy ? global.currentOrganizationToFilterBy.qualifiedName : currentOrganization ? currentOrganization.qualifiedName : "";
    org.organizations = orgName !== undefined ? global.organizations ? global.organizations.filter(org => org.qualifiedName.startsWith(orgName)) : [] : [];

    // adding all organizations to the map "organizationMap" and all organizations
    // with children to the map "organizationsWithChildrenToAccountFor"
    org.organizations.forEach(organization => {
        org.organizationMap.set(organization.id, organization);
        if (organization.parent) {
            org.organizationsWithChildrenToAccountFor.set(organization.parent.id, true);
        } else {
            org.organizationsWithNoParent.push(organization.id);
        }
    });
    return org;
}

export type WidgetChartValue = {
    id: number
    timestamp: string,
    savedValues: string,
    records: string
}

export type CalculatePoint = {
    values: {
        [key: string]: Map<number, number>
    },
    count: {
        [key: string]: Map<number, number>
    },
    result: {
        [key: string]: number
    },
    timestamp: number,
    records: string
}

export function createChartValuesForParentOrganizations(values: WidgetChartValue[], calculationLogic: string, currentOrganization: Optional<Organization>) {
    const org = getOrganizationsForValues(currentOrganization);
    const initialValues = new Map<number, { savedValues: WidgetValue, records: string }>();
    const timestamps = new Set<number>();
    const keys = new Set<string>();
    values.forEach(value => {
        const timestamp = (moment(value.timestamp) as Moment).unix();
        timestamps.add(timestamp);
        const savedValues = JSON.parse(value.savedValues) as WidgetValue;
        initialValues.set(timestamp, { savedValues: savedValues, records: value.records });
        Object.keys(savedValues).forEach(key => {
            const [id, other] = getFirstIdFromKey(key);
            const o = org.organizationMap.get(Number(id));
            if (!o) {
                return;
            }
            const k = other ? other : "";
            keys.add(k);
        });
    });

    const points: CalculatePoint[] = [];

    timestamps.forEach(timestamp => {
        const point: CalculatePoint = {values: {}, count: {}, result: {}, timestamp: timestamp, records: ""};
        const initial = initialValues.get(timestamp);
        point.records = initial!.records;
        keys.forEach(key => {
            point.values[key] = new Map();
            point.count[key] = new Map();
            org.organizations.forEach(organization => {
                if (initial?.savedValues[organization.id + key]) {
                    point.values[key].set(organization.id, initial?.savedValues[organization.id + key][0]);
                    point.count[key].set(organization.id, initial?.savedValues[organization.id + key][1]);
                } else {
                    point.values[key].set(organization.id, 0);
                    point.count[key].set(organization.id, 0);
                }
            });
        });
        points.push(point);
    });

    let parentFound = true;
    while (parentFound) {
        parentFound = false;
        points.forEach((point) => {
            keys.forEach((key) => {
                const values = point.values[key];
                values.forEach((value, k) => {
                    const o = org.organizationMap.get(k);
                    if (!o) {
                        return;
                    }
                    if (!org.organizationsWithChildrenToAccountFor.has(o.id)) {
                        if (o && o.parent?.id && org.organizationMap.has(o.parent?.id)) {
                            const parentId = o.parent.id;
                            parentFound = true;
                            const count = point.count[key];
                            const parentCount = count.get(parentId)!;
                            const childCount = count.get(o.id);
                            if (calculationLogic === "averageCalculationLogic") {
                                childCount && values.set(parentId, (parentCount * values.get(parentId)! + childCount * value) / (parentCount + childCount));
                            } else if (calculationLogic === "countCalculationLogic" || calculationLogic === "durationOfFieldValueCalculationLogic") {
                                values.set(parentId, values.get(parentId)! + value);
                            }
                            childCount && count.set(parentId, parentCount + childCount);
                            org.organizationsToDelete.add(o.id);
                        }
                    }
                });
            });
        });
        
        org.organizationsToDelete.forEach((o) => {
            org.organizationMap.delete(o);
        });
        org.organizationsToDelete.clear();
        org.organizationsWithChildrenToAccountFor.clear();
        org.organizationMap.forEach((value, key) => {
            if (value.parent) {
                org.organizationsWithChildrenToAccountFor.set(value.parent.id, true);
            }
        });
    }

    // LA: If no organizations exist, we reach this point without any keys, but at least one iteration through points is needed, so it is forced.
    // This will probably not be needed in the future, when a default organization will always exist.
    if (keys.size === 0) {
        keys.add("");
    }
    keys.forEach(k => {
        points.forEach(point => {
            const values = point.values[k];
            values?.forEach((v, key) => {
                const value = Math.round(v * 100) / 100;
                point.result[String(key) + k] = value;
                if (org.organizationsWithNoParent.includes(key)) {
                    const newKey = k.substring(1, k.length);
                    if (point.result[newKey]) {
                        point.result[newKey] += value;
                    } else {
                        point.result[newKey] = value;
                    }
                }
            });
            point.result[""] = initialValues.get(point.timestamp)!.savedValues[""][0];
        });
    });

    return points;
}

export function createSavedValuesForParentOrganizations(values: WidgetValue[], calculationLogic: string, currentOrganization: Optional<Organization>) {
    return values.map(v => createValuesForParentOrganizations(v, calculationLogic, currentOrganization));    
}

export function createValuesForParentOrganizations(values: WidgetValue, calculationLogic: string, currentOrganization: Optional<Organization>) {
    const org = getOrganizationsForValues(currentOrganization);
    const organizationValues: {[key: string]: {values: Map<number, number>, count: Map<number, number>}} = {};
    const valuesKeySet = new Set<string>();
    Object.keys(values).forEach(key => {
        // parsing the keys to retreive the organization id --- "[[100]],200,300"
        const [id, other] = getFirstIdFromKey(key);
        const o = org.organizationMap.get(Number(id));
        if (!o) {
            return;
        }
        // using the remainder of the key as a "valuesKey" which is added to "valuesKeySet" --- "100[[,200,300]]"
        const valuesKey = other ? other : "";
        valuesKeySet.add(valuesKey);
        // the "organizationValues" has the keys in the "valuesKeySet";
        // a map "values" is created inside it which will store the calculation
        // result for an organization-valuesKey pair
        if (!organizationValues[valuesKey]) {
            organizationValues[valuesKey] = {values: new Map(), count: new Map()};
        }
        const value = values[key];
        organizationValues[valuesKey].values.set(o.id, value[0]);
        organizationValues[valuesKey].count.set(o.id, value[1]);
    });

    // for the remaining organizations that were not found in the values retreived
    // from the server, the values are initialized
    org.organizations.forEach(org => {
        valuesKeySet.forEach((valuesKey) => {
            const values = organizationValues[valuesKey];
            if (!values.values.has(org.id)) {
                values.values.set(org.id, 0);
                values.count.set(org.id, 0);
            }
        });
    });

    let parentFound = true;
    // while a parent received values from a child organization, the loop continues;
    // for each "valuesKey", the organizations with no children or with all children already accounted for,
    // their calculation result is added to their parent for that specific "valuesKey"
    // example:
    // ["100" => 5, "101" => 3, "100,700" => 2, "100,701" => 3, "101,700" => 0, "101,701" => 3]
    // where there are 3 orgs: 1 (parent) -> 100, 101 (children)
    // and equipment types are 700, 701
    // and there are 3 valuesKeys are "", ",700", "701"
    // at the end, includes, additionally, the following values:
    // ["1" => 8, "1,700" => 2, "1,701" => 6]
    while (parentFound) {
        parentFound = false;
        valuesKeySet.forEach((valuesKey) => {
            const values = organizationValues[valuesKey];
            values.values.forEach((value, key) => {
                const o = org.organizationMap.get(key);
                if (!o) {
                    return;
                }
                if (!org.organizationsWithChildrenToAccountFor.has(o.id)) {
                    if (o && o.parent?.id && org.organizationMap.has(o.parent?.id)) {
                        const parentId = o.parent.id;
                        parentFound = true;
                        if (org.organizations.find(o => o.id === parentId)) {
                            const parentCount = values.count.get(parentId)!;
                            const childCount = values.count.get(o.id)!;
                            if (calculationLogic === "averageCalculationLogic") {
                                childCount && values.values.set(parentId, (parentCount * values.values.get(parentId)! + childCount * value) / (parentCount + childCount));
                            } else if (calculationLogic === "countCalculationLogic" || calculationLogic === "durationOfFieldValueCalculationLogic") {
                                values.values.set(parentId, values.values.get(parentId)! + value);
                            }
                            values.count.set(parentId, parentCount + childCount);
                        }
                        org.organizationsToDelete.add(o.id)
                    }
                }
            });
        });
        // the organizations from "organizationMap" with all children accounted for that
        // were already added to a parent, are deleted, then the
        // "organizationsWithChildrenToAccountFor" map is updated
        org.organizationsToDelete.forEach((o) => {
            org.organizationMap.delete(o);
        });
        org.organizationsToDelete.clear();
        org.organizationsWithChildrenToAccountFor.clear();
        org.organizationMap.forEach((value, key) => {
            if (value.parent) {
                org.organizationsWithChildrenToAccountFor.set(value.parent.id, true);
            }
        });
    }

    const result: {[key: string]: number[]} = {};
    valuesKeySet.forEach((valueKey) => {
        const values = organizationValues[valueKey].values;
        values.forEach((value, key) => {
            result[String(key) + valueKey] = [Math.round(value * 100) / 100];
        });
        const count = organizationValues[valueKey].count;
        count.forEach((c, key) => {
            result[String(key) + valueKey][1] = c;
        });
    });
    result[""] = values[""] as number[];
    return result;
}

export function isCompatibleWithDataExplorerFilter(ed: EntityDescriptor, filter: Filter): string[] {
    if (filter.enabled) {
        if (filter.field) {
            const chain = ed.getFieldDescriptorChain(filter.field);
            if (!chain[chain.length - 1].typeIsEntity()) {
                return [chain.map(fd => fd.getLabel()).join(".")];
            }
        }
        if (filter.filters) {
            let incompatibleFields: string[] = [];
            filter.filters.map(f => isCompatibleWithDataExplorerFilter(ed, f)).forEach(f => {
                incompatibleFields = incompatibleFields.concat(f);
            });
            return incompatibleFields;
        }
    }
    return [];
}
