import { EntityDescriptor, FieldDescriptor, Optional, RootReducerForPages, TestUtils, Utils } from "@crispico/foundation-react";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { ColorRegistry } from "@crispico/foundation-react/utils/ColorRegistry";
import { AirportForMap } from "apollo-gen/AirportForMap";
import { MapFields, MapSettings, MarkerSettings } from "app";
import { DEFAULT_BORDER_COLOR, HOVER_BACKGROUND, MapContainerLeaflet, MarkerData, TRANSPARENT_BACKGROUND } from "components/MapContainerLeaflet/MapContainerLeaflet";
import moment from "moment";
import { ReactNode } from "react";
import { Icon, Label, Popup } from "semantic-ui-react";
import { RealTimeMapEntityTypeFactories } from "./RealTimeMapEntityTypeFactory";

export type MarkerColorInterval = {
    label?: string;
    from: string;
    to?: string;
    color: string;
}

export type EntityColorSettings = {
    mainColorSettings?: {
        area: string;
        field: string;
        showInFilterBar: string;
        useDurationBetweenNowAndFieldValue: boolean | undefined;
        intervals: MarkerColorInterval[];
    } | undefined,
    mainColor?: number | undefined,
    topLeftColor?: number | undefined,
    topRightColor?: number | undefined,
    bottomLeftColor?: number | undefined,
    bottomRightColor?: number | undefined
};

export class RealTimeUtils {

    static renderEntityIcon(data: MarkerData, markerSettings: Optional<MarkerSettings>, iconElement?: JSX.Element, additionalStyles?: { selected?: boolean, hovered?: boolean }): ReactNode {
        return <><span className='fa fa-stack fa-lg' style={{backgroundColor: (additionalStyles?.hovered || additionalStyles?.selected ? '' : TRANSPARENT_BACKGROUND), borderRadius: '0.6em'}}>
            <i className={'fa ' + (data.mainArea?.icon || 'fa-square-o') + ' fa-stack-2x'} style={{ color: (data.mainArea?.color || DEFAULT_BORDER_COLOR), marginTop: '1.5px'}} />
            {iconElement}
            {data.bottomRightArea ? <i className={'fa ' + (data.bottomRightArea.icon || 'fa-circle') + ' fa-stack-1x MapContainerLeaflet_cornered-br'} style={{ color: (data.bottomRightArea.color || undefined) }} /> : undefined}
            {data.bottomLeftArea ? <i className={'fa ' + (data.bottomLeftArea.icon || 'fa-circle') + ' fa-stack-1x MapContainerLeaflet_cornered-bl'} style={{ color: (data.bottomLeftArea.color || undefined) }} /> : undefined}
            {data.topRightArea ? <i className={'fa ' + (data.topRightArea.icon || 'fa-circle') + ' fa-stack-1x MapContainerLeaflet_cornered-tr'} style={{ color: (data.topRightArea.color || undefined) }} /> : undefined}
            {data.topLeftArea ? <i className={'fa ' + (data.topLeftArea.icon || 'fa-circle') + ' fa-stack-1x MapContainerLeaflet_cornered-tl'} style={{ color: (data.topLeftArea.color || undefined) }} /> : undefined}
        </span>{markerSettings?.showTextUnderIcon ? <div style={{ font: 'bold 12px Lato', whiteSpace: "nowrap", backgroundColor: (additionalStyles?.hovered || additionalStyles?.selected ? HOVER_BACKGROUND : TRANSPARENT_BACKGROUND), borderRadius: '0.3em', marginTop: '0.5px', position: 'absolute' }}>{data.text}</div> : undefined}</>;
    }

    static checkIfEntityIsInInterval(interval: MarkerColorInterval, useDurationBetweenNowAndFieldValue: boolean, value: any, fd: FieldDescriptor) {
        try {
            const from = Number(interval.from);
            if (useDurationBetweenNowAndFieldValue) {
                const diff = Utils.now().getTime() - moment(value).toDate().getTime();
                if (diff >= from && diff < Number(interval.to) || (interval.to === null && diff >= from)) {
                    return true;
                }
            } else if (interval.to) {
                if (value >= from && value < Number(interval.to)) {
                    return true;
                }
            } else {
                if (FieldType.boolean === fd.type) {
                    if (String(Boolean(value)) === interval.from) {
                        return true;
                    }
                } else if (!isNaN(from) && value >= from || value === interval.from || String(value) === interval.from) {
                    return true;
                }
            }
        } catch (e) {
            console.log("Error while RealTimeUtils.checkIfEntityIsInInterval: " + e);
        }
        return false;
    }

    static getMarkerFromEntity(entityDescriptorName: string, entity: any, markerSettings: Optional<MarkerSettings>): MarkerData {
        const colors = this.getEntityMapColors(entityDescriptorName, entity, markerSettings);
        let marker: MarkerData = { id: entity.id, text: markerSettings?.showTextUnderIcon ? entity.identifier : undefined, icon: entity.equipmentType?.icon || undefined, point: { longitude: entity.lastPointLongitude, latitude: entity.lastPointLatitude } };

        marker.mainArea = colors.mainColor && colors.mainColor !== -1 ? { color: Utils.convertColorToHex(colors.mainColor) } : undefined;
        marker.topLeftArea = colors.topLeftColor && colors.topLeftColor !== -1 ? { color: Utils.convertColorToHex(colors.topLeftColor) } : undefined;
        marker.topRightArea = colors.topRightColor && colors.topRightColor !== -1 ? { color: Utils.convertColorToHex(colors.topRightColor) } : undefined;
        marker.bottomLeftArea = colors.bottomLeftColor && colors.bottomLeftColor !== -1 ? { color: Utils.convertColorToHex(colors.bottomLeftColor) } : undefined;
        marker.bottomRightArea = colors.bottomRightColor && colors.bottomRightColor !== -1 ? { color: Utils.convertColorToHex(colors.bottomRightColor) } : undefined;

        return marker;
    }

    static getEntityMapColors(entityDescriptorName: string, entity: any, markerSettings: Optional<MarkerSettings>): EntityColorSettings {
        let settings: EntityColorSettings = {};

        if (TestUtils.storybookMode) {
            // there is an issue when accesing storybook for historicalMap because it doesn't find a mountedHelper for AppContainer
            // this is a provisory solution until we know what's missing
            return settings;
        }
        if (!markerSettings || !markerSettings.colors) {
            return settings;
        }
        const ed = entityDescriptors[entityDescriptorName];
        // get all fields for which MarkerColor has name and passed the condition
        const colorsMarkerFilters = ed.getFieldsFromSettings(markerSettings.colors, entity);
        markerSettings.colors.forEach(item => {
             // combine defaults with fields from custom templates 
            if (!colorsMarkerFilters.fields.concat(colorsMarkerFilters.defaultFields).includes(item.field)) {
                return;
            }
            let color = -1;
            try {
                const fd = ed.getField(item.field);
                const intervals = RealTimeUtils.getMarkerColorIntervals(entityDescriptorName, item);
                for (let i = 0; i < intervals.length; i++) {
                    if (RealTimeUtils.checkIfEntityIsInInterval(intervals[i], item.useDurationBetweenNowAndFieldValue, fd.getFieldValue(entity), fd)) {
                        color = ColorRegistry.INSTANCE!.get(intervals[i].color);
                        break;
                    }
                }
            } catch (e) {
                console.log("Field not found: " + item.field);
            }

            switch (item.area) {
                case "main":
                    settings.mainColor = color;
                    settings.mainColorSettings = item;
                    break;
                case "topLeft":
                    settings.topLeftColor = color;
                    break;
                case "topRight":
                    settings.topRightColor = color;
                    break;
                case "bottomRight":
                    settings.bottomRightColor = color;
                    break;
                case "bottomLeft":
                    settings.bottomLeftColor = color;
                    break;
            }
        })
        return settings;
    }

    static getMarkerColorIntervals(entityDescriptorName: string, color: EntityColorSettings["mainColorSettings"]) {
        if (color) {
            if (color.intervals?.length) {
                return color.intervals;
            }
            const fieldIntervals = crudSettings?.forEntities.find(fe => fe.entityName === entityDescriptorName)!.fieldDescriptorSettings.find(fds => fds.fieldRef === color.field)?.fieldIntervals;
            if (fieldIntervals) {
                return fieldIntervals as MarkerColorInterval[];
            }
        }
        return [];
    }

    static selectAirportOnCurrentOrganizationToFilterByChange(prevProps: any, props: any, mapContainer: MapContainerLeaflet) {
        let org = global.currentOrganizationToFilterBy;
        if (org && org.id !== prevProps?.currentOrganizationToFilterBy?.id) {
            let airport: Optional<AirportForMap>;
            while (!airport && org) {
                airport = (org as any).airport;
                org = org?.parent;
            }
            airport?.id && props.dispatchers.selectAirport(airport.id, mapContainer);
        }
    }
    
    static filterEntitiesUsingFilterBar(mapSettings: MapSettings, fitlerBarCheckboxes: {[key: string]: boolean}, entities: any[], selectedEntityType: string) {
        const realTimeEntityFactory = RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[selectedEntityType];
        const markerSettings: Optional<MarkerSettings> = realTimeEntityFactory.getMarkerSettings(mapSettings)        
        markerSettings?.colors.filter(color => color.showInFilterBar).forEach(color => {
            const intervals = RealTimeUtils.getMarkerColorIntervals(realTimeEntityFactory.getEd().name, color).filter(interval => fitlerBarCheckboxes[color.field + interval.from]);
            entities = !entities ? [] : entities.filter(entity => {
                const colorsMarkerFilters = realTimeEntityFactory.getEd().getFieldsFromSettings(markerSettings.colors, entity);
                // combine defaults with fields from custom templates and test if color needs to be applied for this entity
                if (!colorsMarkerFilters.fields.concat(colorsMarkerFilters.defaultFields).includes(color.field)) {
                    return true;
                }
                if (intervals.length === 0) {
                    return false
                }
                try {
                    const fd = realTimeEntityFactory.getEd().getField(color.field);                
                    let fieldValue = realTimeEntityFactory.getEd().getField(color.field).getFieldValue(entity);
                    for (let i = 0; i < intervals.length; i ++) {
                        if (RealTimeUtils.checkIfEntityIsInInterval(intervals[i], color.useDurationBetweenNowAndFieldValue, fieldValue, fd)) {
                            return true;
                        }
                    }
                } catch (e) {
                    console.log("Field not found: " + color.field);
                }
               
                return false;
            });
        });
        return entities;
    }

    static renderFieldsForListRow(entity: any, entityDescriptor: EntityDescriptor, mapSettings: MapSettings, listRowPopupFields: string[]) {
        const markerSetings: Optional<MarkerSettings> = RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[entityDescriptor.name].getMarkerSettings(mapSettings);
        const rowFieldsInfo = entityDescriptors["EquipmentResource"].getFieldsFromSettings(markerSetings?.rowFields, entity);
        const fields = rowFieldsInfo.fields.length > 0 ? rowFieldsInfo.fields : rowFieldsInfo.defaultFields;
        const genericRowPopupFieldMessage = entityDescriptor.name + ".?.label"
        return <div className="flex-container gap3">
            <h4 className="no-margin">{entityDescriptor.getField("identifier").renderField(entity)}</h4>
            <div className="flex-container gap3">
                <div className="flex-container-row gap3">
                    {fields.map(field => entityDescriptor.getField(field).renderField(entity, { asLabel: true, showIcon: true, showTooltip: true }))}
                </div>
                {listRowPopupFields.length > 0 ?
                    <Popup flowing position="bottom center"
                        trigger={<span style={{ whiteSpace: "nowrap" }}>{_msg(genericRowPopupFieldMessage.replace('?', listRowPopupFields[0]))}: {moment(entity[listRowPopupFields[0]]).fromNow()}</span>}>
                        <Popup.Content className="flex-container-row gap3" >
                            {listRowPopupFields.map(field =>
                                <div className="flex-container flex-center">
                                    <div><Icon name="truck" />{_msg(genericRowPopupFieldMessage.replace('?', field))}</div>
                                    <Label>{entity[field] ? moment(entity[field]).format(Utils.dateTimeWithSecFormat) : _msg("general.notAvailable")}</Label>
                                </div>
                            )}
                        </Popup.Content>
                    </Popup>
                    : null}
            </div>
        </div>
    }
}