import { addEntityDescriptor, BigState, createSliceFoundation, EntityDescriptor, EntityTablePage, EntityTablePageProps, getBaseImpures, getBaseReducers, PropsFrom, SliceEntityTablePage, sliceEntityTablePageOnlyForExtension, Utils } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { TabRouterPane } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { InitializationsForClient, MapSettings } from "app";
import { RealTimeMap, sliceRealTimeMap } from "components/realTimeMap/RealTimeMap";
import { uniqueId } from "lodash";

export const mobileDeviceEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
    name: "MobileDevice",
    defaultSort: { field: "lastUpdate", direction: "DESC" },
    miniFields: ["identifier"],
    icon: "mobile alternate",
})).addFieldDescriptor({ name: "status", icon: "signal", type: FieldType.dropdown})
   .addFieldDescriptor({ name: "updated", type: FieldType.date, format: Utils.dateTimeWithSecFormat, icon: "database", propsForEditor: { hasTime: true, formatString: Utils.dateTimeFormat } })
   .addFieldDescriptor({ name: "lastUpdate", type: FieldType.date, format: Utils.dateTimeWithSecFormat, icon: "feed", propsForEditor: { hasTime: true, formatString: Utils.dateTimeFormat } })
   .addTabDescriptor({ oneToManyEntityName: "MobileDeviceActivationHistory", oneToManyOppositeField: "mobileDevice", oneToManyEntityField: "id" })


export const sliceMobileDeviceTable = mobileDeviceEntityDescriptor.infoTable.slice = createSliceFoundation(class Ext extends SliceEntityTablePage {

    initialState = {
        ...sliceEntityTablePageOnlyForExtension.initialState,
    }

    nestedSlices = {
        ...sliceEntityTablePageOnlyForExtension.nestedSlices,
        realTimeMap: sliceRealTimeMap
    }

    reducers = {
        ...sliceEntityTablePageOnlyForExtension.reducers, ...getBaseReducers<Ext>(this)
    }

    impures = {
        ...sliceEntityTablePageOnlyForExtension.impures, ...getBaseImpures<Ext>(this),
    }
}).setEntityDescriptor(mobileDeviceEntityDescriptor);

mobileDeviceEntityDescriptor.infoTable.wrappedComponentClass = class extends EntityTablePage<EntityTablePageProps & PropsFrom<typeof sliceMobileDeviceTable> & { mapSettings: MapSettings }> {

    constructor(props: EntityTablePageProps & PropsFrom<typeof sliceMobileDeviceTable> & { mapSettings: MapSettings }) {
        super(props);
    }

    protected getExtraTabPanes(): (TabRouterPane | null)[] {
        const that = this;
        let extraTabPanes = [...super.getExtraTabPanes(), {
            routeProps: { path: "/realTimeMap" },
            menuItemProps: { icon: "map outline", content: _msg("MapRealTime") },
            render: () => (<>
                {that.renderRealTimeMap(false, true, undefined)}
            </>)
        }];
        return extraTabPanes;
    }

    protected renderRealTimeMap(showGoToTableButton: boolean = true, withCustomQueryBar: boolean = true, rootFilter: Filter | undefined) {
        return <RealTimeMap dispatchers={this.props.dispatchers.realTimeMap} {...this.props.realTimeMap} rootReducerForPages={this.props.rootReducerForPages} currentOrganizationToFilterBy={this.props.currentOrganizationToFilterBy}
            rootFilter={rootFilter} showCustomQueryBar={withCustomQueryBar} showGoToTableButton={showGoToTableButton} mapSettings={this.props.mapSettings} mapId={uniqueId("map-tab-" + mobileDeviceEntityDescriptor.name)} entityTypes={[mobileDeviceEntityDescriptor.name]}/>
    }
}

mobileDeviceEntityDescriptor.infoTable.mapBigStateToProps = (state: BigState, props: any) => {
    props.mapSettings = (state.AppContainer.initializationsForClient as InitializationsForClient).mapSettings;
}

