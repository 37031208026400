import { EntityDescriptor } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/apollo-gen-foundation/Filter";
import { Organization } from "@crispico/foundation-react/AppMeta";
import { CalculateForRecordsWidgetConfig } from "@crispico/foundation-react/components/CalculateForRecords/CalculateForRecords";
import { ErrorComponent } from "@crispico/foundation-react/components/ErrorComponent/ErrorComponent";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { ConnectedPageInfo, DispatchersFrom } from "@crispico/foundation-react/reduxHelpers";
import React, { RefObject } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { Button, Icon, Modal, Popup, Segment, SemanticICONS } from "semantic-ui-react";
import { DashboardMode } from "../DashboardContants";
import { Dashboard } from "../DashboardEntityDescriptor";
import { DashboardWidgetFactory } from "../DashboardWidgetFactory";

type WidgetWrapperProps = {
    id: string,
    sliceName: string,
    mode: DashboardMode,
    factory: DashboardWidgetFactory,
    widgetConfig: CalculateForRecordsWidgetConfig,
    expandedOrganization?: Organization,
    editorOpen: string | undefined,
    dispatchers: DispatchersFrom<any>,
    dashboardEntity: any,
    entityForAttachedDashboard: any,
    zeroTrainingMode?: boolean,
    deleteModalOpen: string | undefined,
    refresh: boolean,
    headerFontSize: number | undefined,
    headerBackgroundColor: string | undefined,
    headerIcon: SemanticICONS | undefined,
    dataExplorerFilter: Filter | undefined,
    dataExplorerEntityDescriptor: EntityDescriptor | undefined
}

export type WidgetProps = WidgetWrapperProps & {
    /**
     * Missing when "add wizard" mode.
     */
    buttonBarRef?: RefObject<HTMLElement>;
};

export class WidgetWrapper extends React.Component<WidgetWrapperProps> {

    titleBarRef: RefObject<HTMLElement>;
    buttonBarRef: RefObject<HTMLElement>;
    connectedPageInfo: ConnectedPageInfo;

    state = {
        tooltipNeeded: false
    }

    constructor(props: WidgetWrapperProps) {
        super(props)
        this.titleBarRef = React.createRef()
        this.buttonBarRef = React.createRef()
        this.connectedPageInfo = props.factory.getConnectedPageInfo(props.sliceName, props.widgetConfig)
    }

    componentDidUpdate(prevProps: WidgetWrapperProps) {
        this.componentDidUpdateInternal(prevProps);
    }

    componentDidUpdateInternal(prevProps?: WidgetWrapperProps) {
        /*
        * It was done in this manner to:
        * (1) avoid extensive modifications in each widget component and in the widget factory
        * (2) have the option to easily reactivate the refresh button in the future
        */
        if (prevProps && prevProps.refresh !== this.props.refresh && this.buttonBarRef.current?.children) {
            const children = this.buttonBarRef.current.children
            for (let i = 0; i < children.length; i++) {
                // @ts-ignore
                if (children[i]?.type === 'submit' && children[i]?.className.includes('dashboardWidgetRefresh')) {
                    // @ts-ignore
                    children[i].click()
                }
            }
        }
    }

    componentDidMount() {
        if (this.titleBarRef.current && this.titleBarRef.current.scrollWidth > this.titleBarRef.current.clientWidth) {
            this.setState({ tooltipNeeded: true })
        }
    }

    render() {
        const props = this.props;
        const visibility = props.mode === DashboardMode.VIEW ? 'hidden' : 'visible';
        const widgetConfig: CalculateForRecordsWidgetConfig = props.widgetConfig;

        const title = props.factory.renderTitle(widgetConfig);
        const headerFontSize = widgetConfig.headerFontSize ? widgetConfig.headerFontSize : props.headerFontSize;
        const headerBackgroundColor = widgetConfig.headerBackgroundColor ? widgetConfig.headerBackgroundColor : props.headerBackgroundColor;
        const headerIcon: SemanticICONS | undefined = widgetConfig.headerIcon ? widgetConfig.headerIcon : props.headerIcon;
        const isWidgetWrapperVisible = props.factory.isWidgetWrapperVisible(widgetConfig);

        let widgetHeaderStyle: any = {
            backgroundColor: headerBackgroundColor
        };
        if (title) {
            widgetHeaderStyle = {...widgetHeaderStyle, ...{
                alignItems: "center", padding: "5px 0px"
            }}
        } else if (DashboardMode.EDIT && (!title || title.length === 0)) {
            widgetHeaderStyle = {...widgetHeaderStyle, ...{
                position: "absolute", width: "100%", padding: "5px 10px", zIndex: 1000
            }}
        }

        return <>
                <Segment basic={isWidgetWrapperVisible || this.props.mode === DashboardMode.EDIT ? false : true} style={{ height: '100%', background: widgetConfig.backgroundColor }} className="flex-container flex-grow flex-shrink-auto" data-cy="Dashboard.widget" key={props.id}>
                    {props.mode === DashboardMode.EDIT || (title && title.length > 0) ?
                        <Segment basic className='flex-container-row no-margin flex-shrink-auto' style={{...widgetHeaderStyle}}>
                            <Popup wide position="bottom center"
                                content={title}
                                disabled={!this.state.tooltipNeeded}
                                trigger={<div className='white-space-normal flex-shrink-auto WidgetHeaderTitle' style={{ fontSize: headerFontSize }} ref={this.titleBarRef as RefObject<HTMLDivElement>}>
                                    {headerIcon ? <Icon name={headerIcon} /> : null} {title}
                                </div>}
                            />
                            <div className="flex-shrink-auto">
                                <span ref={this.buttonBarRef} />
                                {widgetConfig.description ? <Popup wide position="bottom center" popperModifiers={[{ name: "preventOverflow", options: { boundariesElement: "offsetParent" } }]}
                                    content={widgetConfig.description}
                                    trigger={<Icon link name="question" />}
                                /> : null}
                                {props.mode === DashboardMode.EDIT ?
                                    <>
                                        <Button size="tiny" className='DashboardWidgetButton' style={{ visibility: visibility }} icon="settings"
                                            onClick={e => props.dispatchers.setInReduxState({ editorOpen: props.id })} data-cy="Dashboard.widget.button" />
                                        <Modal open={props.deleteModalOpen === props.id} closeOnDocumentClick closeOnEscape
                                            onOpen={() => props.dispatchers.setInReduxState({ deleteModalOpen: props.id })}
                                            onClose={() => props.dispatchers.setInReduxState({ deleteModalOpen: undefined })}
                                            trigger={<Button negative size="tiny" className='DashboardWidgetButton' style={{ visibility: visibility }} icon="delete" />}>
                                            <Modal.Header>{_msg("Dashboard.widget.button.delete.messageHeader")}</Modal.Header>
                                            <Modal.Content>
                                                {_msg("Dashboard.widget.button.delete.messageContent")}
                                            </Modal.Content>
                                            <Modal.Actions>
                                                <Button negative onClick={() => props.dispatchers.setInReduxState({ deleteModalOpen: undefined })}>{_msg("general.no")}</Button>
                                                <Button positive onClick={() => {
                                                    props.dispatchers.setInReduxState({ deleteModalOpen: undefined });
                                                    props.dispatchers.removeItem(props.id);
                                                }}>{_msg("general.yes")}</Button>
                                            </Modal.Actions>
                                        </Modal>
                                    </> : null}
                            </div>
                        </Segment> : null}
                    <ErrorBoundary FallbackComponent={(props: FallbackProps) => <ErrorComponent error={props.error} resetErrorBoundary={props.resetErrorBoundary} hintFixErrorMessage={_msg('Dashboard.widget.checkConfiguration')}/>}>
                        <div className={"flex-container flex-grow" + (props.mode === DashboardMode.EDIT ? " disabledItem" : "")} style={{ overflow: "auto" }} key="widget">
                            {props.factory.hasRenderWidget2() ?
                                props.factory.renderWidget2(this.connectedPageInfo, { ...props, buttonBarRef: this.buttonBarRef }) :
                                props.factory.renderWidget(props.id, this.connectedPageInfo, widgetConfig, props.dashboardEntity, props.entityForAttachedDashboard, this.buttonBarRef.current, props.zeroTrainingMode ? props.zeroTrainingMode : false)}
                        </div>
                    </ErrorBoundary>
                </Segment>
            <ModalExt open={props.id === props.editorOpen} closeIcon={true} closeOnDimmerClick={true} onClose={() => props.dispatchers.setInReduxState({ editorOpen: undefined })} maxWidth={800}>
                <Modal.Header>{_msg('Dashboard.editor.title')}</Modal.Header>
                <Modal.Content><Modal.Description>
                    {props.factory.renderEditor(props.id, stringifyWidgetConfig(props.factory, widgetConfig, props.dashboardEntity), props.dispatchers, props.dashboardEntity)}
                </Modal.Description></Modal.Content>
            </ModalExt>
        </>
    }
}

function stringifyWidgetConfig(dashboardWidgetFactory: DashboardWidgetFactory, widgetConfig: any, dashboardEntity: Dashboard) {
    let values: any = {};
    const fields = dashboardWidgetFactory.getEntityDescriptor(widgetConfig, dashboardEntity).fields;
    Object.keys(fields).forEach(f => {
        values[f] = fields[f].json && widgetConfig[f] ? JSON.stringify(widgetConfig[f]) : widgetConfig[f]
    })
    return values
}