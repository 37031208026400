import { FilterOperators, Messages } from "@crispico/foundation-gwt-js"
import { apolloClient, ConnectedPageInfo, createSliceFoundation, EntityDescriptor, getBaseImpures, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react"
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter"
import { CenteredMetric, helper, PieDatum, ResponsivePieExt } from "@crispico/foundation-react/components/nivoExt"
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants"
import { ShareLinkLogic } from "@crispico/foundation-react/entity_crud/ShareLinkLogic"
import { categoricalColorSchemes } from "@nivo/colors"
import { push } from "connected-react-router"
import gql from "graphql-tag"
import lodash from 'lodash'
import React, { MouseEventHandler } from "react"
import { Link } from "react-router-dom"
import { Divider, Label, List } from "semantic-ui-react"
import { AbstractWidgetWithFilter, AbstractWidgetWithFilterConfig, AbstractWidgetWithFilterProps, SliceAbstractWidgetWithFilter, sliceAbstractWidgetWithFilterOnlyForExtension } from "../AbstractWidgetWithFilter"
import { WidgetProps } from "../dashboardTab/WidgetWrapper"

export interface CriteriaConfigInput extends AbstractWidgetWithFilterConfig {
    title?: string | null;
    currentDate?: string | null;
    entityType: string;
    field: any;
    pieSliceType?: string | null;
    pieSlices: any[] | null;
    excludeOthers: boolean;
    detailedTooltip?: boolean;
    fontSizeForTotal?: number;
    colors?: string;
    showLegend?: boolean;
    legendDoesNotShowValuesUnder: number;
}

export const slicePieCountByCriteriaTab = createSliceFoundation(class SlicePieCountByCriteriaTab extends SliceAbstractWidgetWithFilter {
    initialState = {
        ...sliceAbstractWidgetWithFilterOnlyForExtension.initialState,
        entity: '' as string,
        pieData: [] as PieDatum[],
        tooltipData: {} as any,
        keys: [] as string[],
        uniqueEntitiesUsed: undefined as number | undefined,
        totalFilter: undefined as Filter | undefined,
        lastClick: { id: -1, date: new Date() } as { id: any, date: Date }
    }

    reducers = {
        ...sliceAbstractWidgetWithFilterOnlyForExtension.reducers,
        ...getBaseReducers<SlicePieCountByCriteriaTab>(this),

        processData(state: StateFrom<SlicePieCountByCriteriaTab>, p: { entity: string, pieData: PieDatum[], colors?: string, tooltipData: any, uniqueEntitiesUsed: number | undefined }) {
            state.entity = p.entity;
            let index = 0;
            const colorSchemes = categoricalColorSchemes as any;
            const colorScheme = p.colors && colorSchemes[p.colors] ? colorSchemes[p.colors] : colorSchemes["category10"];
            state.pieData = p.pieData.map(p => {
                return {
                    id: p.id,
                    label: p.label,
                    value: p.value,
                    color: p.color ? p.color : colorScheme[(index++) % colorScheme.length],
                    filter: p.filter
                }
            });

            const othersIndex = state.pieData.findIndex(d => d.id === "Others");
            if (othersIndex === -1) {
                state.totalFilter = Filter.createComposed(FilterOperators.forComposedFilter.or, []);
                for (let i = 0; i < state.pieData.length; i ++) {
                    if (state.pieData[i].filter) {
                        state.totalFilter.filters!.push(state.pieData[i].filter!);
                    }
                }
            }

            state.tooltipData = p.tooltipData
            state.keys = Object.keys(state.tooltipData);
            state.uniqueEntitiesUsed = p.uniqueEntitiesUsed;
        }

    }

    impures = {
        ...getBaseImpures<SlicePieCountByCriteriaTab>(this),

        async loadData(p: { config: CriteriaConfigInput, currentDate: Date, filter: Filter }) {            
            let operationName = lodash.lowerFirst(p.config.entityType) + 'Service_criteriaChartData'
            let query = gql(`query q($config: CriteriaConfigInput!, $currentDate: Date!) { 
                ${operationName}(config: $config, currentDate: $currentDate) {
                    pieData { id, label, value, color, filter } tooltipData uniqueEntitiesUsed
                }
            }`);
            const config = {
                entity: p.config.entityType,
                field: p.config.field,
                filter: p.filter,
                pieSliceType: p.config.pieSliceType,
                pieSlices: p.config.pieSlices,
                excludeOthers: p.config.excludeOthers
            }
            config.pieSlices = p.config.pieSlices ? p.config.pieSlices.map(slice => { return { id: slice.id, name: slice.name } }) : null
            const criteriaChartData = (await apolloClient.query({ query: query, variables: { config: config, currentDate: p.currentDate }, context: { showSpinner: false } })).data[operationName];
           
            this.getDispatchers().processData({
                entity: p.config.entityType,
                pieData: criteriaChartData.pieData.map((d: any) => { d.id = Messages.getInstance().maybeTranslateByUser(d.id); d.filter = JSON.parse(d.filter); return d }),
                colors: p.config.colors,
                tooltipData: criteriaChartData.tooltipData,
                uniqueEntitiesUsed: criteriaChartData.uniqueEntitiesUsed
            });
            
        }
    }
})

export type PieCountByCriteriaProps = WidgetProps & AbstractWidgetWithFilterProps & PropsFrom<typeof slicePieCountByCriteriaTab> & { widgetConfig: CriteriaConfigInput, zeroTrainingMode: boolean, onPieClick?: MouseEventHandler<PieDatum>, buttonBarRef: any };

export class PieCountByCriteriaTab extends AbstractWidgetWithFilter<PieCountByCriteriaProps> {

    shareLinkLogic = new ShareLinkLogic();

    async refreshInternal(filter: Filter) {
        await this.props.dispatchers.loadData({ 
            config: this.props.widgetConfig,
            currentDate: this.props.widgetConfig.currentDate ? new Date(this.props.widgetConfig.currentDate) : new Date(),
            filter
        });
    }

    renderTotal(ed: EntityDescriptor, filter: Filter, total: number) {
        const occurrencesLabel = <Label circular size='mini' style={{ color: 'white', backgroundColor: 'silver', marginRight: '3px' }}>{total}</Label>;
        if (!this.props.uniqueEntitiesUsed || this.props.uniqueEntitiesUsed === 0) {
            return <Link to={this.shareLinkLogic.createLink(false, ed, Filter.enableAllFilters(filter), [], { allowEmptyFilter: true })} className="PieCountByCriteriaWidgetLegendLink flex-container-row flex-center">{[occurrencesLabel, _msg("Chart.pieCountByCriteria.total")]}</Link>
        }
        const uniqueEntitiesLabel = <Label circular size='mini' style={{ color: 'white', backgroundColor: 'grey', marginRight: '3px' }}>{this.props.uniqueEntitiesUsed}</Label>;
        return <Link to={this.shareLinkLogic.createLink(false, ed, Filter.enableAllFilters(filter), [], { allowEmptyFilter: true })} className="PieCountByCriteriaWidgetLegendLink flex-container-row flex-center"><p>
            {uniqueEntitiesLabel} {_msg("Chart.pieCountByCriteria.uniqueEntitiesUsed.0", ed.getLabel(true))} {occurrencesLabel} {_msg("Chart.pieCountByCriteria.uniqueEntitiesUsed.1")}
        </p></Link>;
    }

    renderMain() {
        const props = this.props
        const ed = entityDescriptors[props.widgetConfig.entityType];
        let additionalProps: any = {}
        additionalProps.colors = props.pieData.map(p => p.color)
        if (props.widgetConfig.colors) {
            additionalProps.colors = {scheme: props.widgetConfig.colors};
        }
        let total = 0;
        const filter = this.props.totalFilter ? this.props.totalFilter : this.createFilter();

        return (<div className="PieCountByCriteriaWidgetContainer">
            <div className="PieCountByCriteriaWidgetPie">
                <ResponsivePieExt data={props.pieData} {...additionalProps} arcLabelsTextColor="white"
                    layers={['arcs', 'arcLabels', props.widgetConfig.showLegend ? null : 'arcLinkLabels', props.widgetConfig.fontSizeForTotal !== 0 ? new CenteredMetric(props.widgetConfig.fontSizeForTotal).get() : null]}
                    tooltip={({ datum: { id, value, color } }) => (
                        <div className={'ResponsivePieChart_Tooltip'}>
                            <div style={{ backgroundColor: color, width: '12px', height: '12px', display: 'inline-block' }}></div> <strong>{Messages.getInstance().maybeTranslateByUser(id as string)}</strong>:&nbsp;
                            {_msg("Chart.pieCountByCriteria.tooltip.doubleclick")} {ed.getLabelWithIcon()}
                            {props.widgetConfig.detailedTooltip && props.tooltipData[id] && props.tooltipData[id].length > 0 ? <p>{helper(Array.from(props.tooltipData[id]).map((t: any) => t + ', '))}</p> : null}
                        </div>
                    )}
                    onClick={(data, e) => {
                        if (data.id === props.lastClick.id && ((new Date().valueOf() - props.lastClick.date.valueOf()) < 1000)) {
                            const link = data.data.filter ? this.shareLinkLogic.createLink(false, ed, Filter.enableAllFilters(data.data.filter), []) : undefined;
                            link && props.dispatchers.dispatch(push(link));
                        }
                        props.dispatchers.setInReduxState({ lastClick: { id: data.id as string, date: new Date() } })
                    }} />
            </div>
            {this.props.widgetConfig.showLegend ? <div className="PieCountByCriteriaWidgetLegend">
                <List>
                    {props.pieData.map(p => {
                        total += p.value;
                        const link = this.shareLinkLogic.createLink(false, ed, p.filter ? Filter.enableAllFilters(p.filter) : undefined, []);
                        return <List.Item key={p.id}><Link to={link} className='flex-container-row flex-center'><Label circular size="mini" style={{ color: 'white', backgroundColor: p.color, marginRight: '3px' }}>{p.value}</Label><span style={{ textOverflow: "ellipsis", whiteSpace: 'nowrap', overflow: 'hidden' }}>{p.label}</span></Link></List.Item>
                    })}
                    <Divider />
                    <List.Item>{this.renderTotal(ed, filter, total)}</List.Item>
                </List>
            </div> : null}
            {/* {props.buttonBarRef && ReactDOM.createPortal(createButtonForWidget({ positive: true, icon: "refresh", className: "dashboardWidgetRefresh" }, () => this.refresh()), props.buttonBarRef)} */}
        </div>);
    }

}

export const infoPieCountInTerritoriesFastTab = new ConnectedPageInfo(slicePieCountByCriteriaTab, PieCountByCriteriaTab, "SlicePieCountByCriteriaTab");