import { getBaseImpures, getBaseReducers, PropsFrom, StateFrom } from "../../reduxHelpers";
import React, { ReactElement } from "react";
import { Icon, Segment, SegmentGroup } from "semantic-ui-react";
import { Utils } from "../../utils/Utils";
import _ from "lodash";

export type RenderItemParams = { props: Props, linearizedItem: LinearizedItem };

export interface OnSelectParams {
    itemId: string,
    prevent: boolean
}

type PropsNotFromState = {
    renderItemFunction?: (params: RenderItemParams) => ReactElement | null;
    onSelectItem?: (params: OnSelectParams) => void;
    onHoverItem?: (params: OnSelectParams) => void;
    onExpandCollapseItem?: (params: OnSelectParams) => void;
    styleItemWrapperFunction?: (props: RenderItemParams) => any;
}

export type Props = PropsFrom<SliceTree> & PropsNotFromState;

export interface LinearizedItem {
    /**
     * Not used by the tree. But may be convenient by users to navigate up/down in the tree.
     */
    index: number;
    itemId: string;
    indent: number;
    expanded: Expanded;
}

export enum Expanded { COLLAPSED, EXPANDED, LEAF };

export class SliceTree {

    hasChildren(item: any) {
        return true;
    }

    getChildren(item: any): { localId: string, item: any }[] {
        return Object.keys(item).map(key => ({ localId: key, item: item[key] }));
    }

    shouldAddLinearizedItem(linearizedItem: LinearizedItem) {
        return true;
    }

    isExpanded(state: StateFrom<SliceTree>, linearizedItem: LinearizedItem ) {
        return state.expandedIds[linearizedItem.itemId];
    }

    getRoot(state: StateFrom<SliceTree>): any {
        return state.root;
    }

    initialState = {
        root: undefined as any,
        expandedIds: {} as { [key: string]: boolean },
        linearizedItems: [] as Array<LinearizedItem>,
        hoveredId: undefined as string | undefined,
        selectedId: undefined as string | undefined
    }

    reducers = {
        ...getBaseReducers<SliceTree>(this),

        expandCollapse(state: StateFrom<SliceTree>, p: { id: string, isExpand: boolean }) {
            if (p.isExpand) {
                state.expandedIds[p.id] = true;
            } else {
                delete state.expandedIds[p.id];
            }
            this.linearize(state);

        },

        linearize(state: StateFrom<SliceTree>) {
            state.linearizedItems = [];
            this._linearize(state, this.getSlice().getRoot(state), "", -1);

            // reset selection/hover if not valid any more on the new data
            if (state.hoveredId && !state.linearizedItems.find(item => item.itemId === state.hoveredId)) {
                state.hoveredId = undefined;
            }
            if (state.selectedId && !state.linearizedItems.find(item => item.itemId === state.selectedId)) {
                state.selectedId = undefined;
            }
        },

        _linearize(state: StateFrom<SliceTree>, currentItem: any, currentId: string, indent: number) {
            const hasChildren = this.getSlice().hasChildren(currentItem);
            if (currentId) {
                const linearizedItem: LinearizedItem = { indent, itemId: currentId, expanded: hasChildren ? Expanded.EXPANDED : Expanded.LEAF, index: state.linearizedItems!.length };
                if (!this.getSlice().shouldAddLinearizedItem(linearizedItem)) {
                    return;
                }
                state.linearizedItems!.push(linearizedItem);
                if (hasChildren && !this.getSlice().isExpanded(state, linearizedItem)) {
                    linearizedItem.expanded = Expanded.COLLAPSED;
                    return;
                }
            } // else is root

            if (!hasChildren) {
                // not usual; may happen if the root has changed and an expanded turned into a leaf
                return;
            }

            this.getSlice().getChildren(currentItem)?.forEach(itemWithLocalId => this._linearize(state, itemWithLocalId.item,
                currentId ? currentId + Utils.defaultIdSeparator + itemWithLocalId.localId : itemWithLocalId.localId, indent + 1));
        },

        selectItem(state: StateFrom<SliceTree>, p: string) {
            if (state.selectedId !== p) {
                state.selectedId = p;
            } else {
                // TODO CC: de discutat
                // LA: maybe to deselect; corresponding test (Tree.test.ts) needs to be edited accordingly
                // state.selectedId = undefined;
            }
        },

        reveal(state: StateFrom<SliceTree>, p: { ids: string[], expandIds: boolean, collapseOthers: boolean, toggle?: boolean }) {
            if (p.toggle && p.ids.length === 1 && state.expandedIds[p.ids[0]]) {
                state.expandedIds[p.ids[0]] = false;
                this.linearize(state);
                return;
            }

            if (p.collapseOthers) {
                state.expandedIds = {} as any;
            }
            for (let id of p.ids) {
                let first = true;
                while (true) {
                    if (!first || p.expandIds) {
                        state.expandedIds[id] = true;
                    }
                    const nextId = Utils.substringBefore(id, Utils.defaultIdSeparator, true);
                    if (nextId === id) {
                        break; // i.e. no more separator
                    } else {
                        id = nextId;
                    };
                    first = false;
                }
            }
            this.linearize(state);
        },

        collapseAll(state: StateFrom<SliceTree>) {
            state.expandedIds = {} as any;
            this.linearize(state);
        }
    }

    impures = {
        ...getBaseImpures<SliceTree>(this),

        navigateToItem(p: { index: number }) {
            const state = this.getState();
            const nextObjectId = state.linearizedItems[p.index]?.itemId;
            return nextObjectId && Utils.navigate(this.getSlice().getRoot(state), nextObjectId);
        }
    }
}

export class Tree<T extends Props = Props> extends React.Component<T> {

    protected rootJustChanged = false;
    protected firstRender = true;

    paddingLeftBase = 10;
    paddingLeftFactor = 15;
    hoverColor = "rgba(0, 0, 0, 0.03)";
    selectedColor = "rgba(0, 0, 0, 0.15)";

    constructor(props: T) {
        super(props);

        // we need to invoke here, because shouldComponentUpdate() is not invoked for the first render
        this.shouldComponentUpdateInternal(this.props, true);
    }

    componentDidMount() {
        // nop
        // delete this comment when/if someone actually adds code here
        // w/o this being implemented, TreeMenu should have called super.componentDidMount?.(). And this
        // optional construct currently fails in "yarn build". To avoid loosing time, I created this method 
        // to avoid the usage of ".?()"
    }

    shouldComponentUpdate(nextProps: Props) {
        return this.shouldComponentUpdateInternal(nextProps, false);
    }

    protected shouldComponentUpdateInternal(nextProps: Props, firstRender: boolean) {
        if (!firstRender) {
            if (nextProps.root === this.props.root) {
                this.rootJustChanged = false;

                return true;
            } // else => root OR treeAdapter was changed 
        }

        this.props.dispatchers.linearize();

        /** 
         * Initially we had this logic at "componentDidUpdate()"; more precisely "useEffect()" hook, as this was a functional component. BUT:
         * Right now the root is not in sync w/ linearizedItems; if a render() is attempted, ids from linearizedItems may point to
         * objects that don't exist any more. And e.g. the user provided renderItem function will fail. Hence we want to prevent
         * a render right now. The dispatch above will trigger a state change hence this connected component will be notified, and
         * this method will be called again. See also the comment in "render()".
         */
        this.rootJustChanged = !firstRender; // for firstRender, this is true

        return false;
    }

    protected renderMain(mainProps: any, mainChildren: Array<any>): ReactElement {
        return React.createElement(SegmentGroup, mainProps, mainChildren);
    }

    protected createItemWrapperProps(linearizedItem: LinearizedItem) {
        const props = this.props;

        return {
            className: linearizedItem.itemId === props.selectedId ? "selectedItem" : undefined,
            key: linearizedItem.itemId, "data-cy": linearizedItem.itemId, name: linearizedItem.itemId, "data-testid": "Tree_" + linearizedItem.itemId.replace(new RegExp(_.escapeRegExp(Utils.defaultIdSeparator), "g"), "_"),
            onClick: (e: any) => {
                if (e.target.id !== 'expandCollapseIcon' && props.selectedId !== linearizedItem.itemId) {
                    const params = { itemId: linearizedItem.itemId, prevent: false };
                    props.onSelectItem?.call(null, params);
                    if (params.prevent) {
                        return;
                    }
                    props.dispatchers.selectItem(linearizedItem.itemId);
                }
            },
            onMouseOver: () => {
                if (props.hoveredId !== linearizedItem.itemId) {
                    const params = { itemId: linearizedItem.itemId, prevent: false };
                    props.onHoverItem?.call(null, params);
                    if (params.prevent) {
                        return;
                    }
                    props.dispatchers.setInReduxState({ hoveredId: linearizedItem.itemId });
                }
            },
            style: Object.assign({
                display: "flex",
                alignItems: "center",
                paddingLeft: (this.paddingLeftBase + linearizedItem.indent * this.paddingLeftFactor) + "px",
                backgroundColor: linearizedItem.itemId === props.selectedId ? this.selectedColor : linearizedItem.itemId === props.hoveredId ? this.hoverColor : undefined
            }, props.styleItemWrapperFunction?.call(null, { props, linearizedItem }))
        }
    }

    protected getChildrenIcon(linearizedItem: LinearizedItem, collapsedIcon: string, expandedIcon: string): any {
        switch (linearizedItem.expanded) {
            case Expanded.LEAF: return null;
            case Expanded.COLLAPSED: return collapsedIcon;
            case Expanded.EXPANDED: return expandedIcon;
        }
    }

    protected renderItemWrapper(linearizedItem: LinearizedItem, itemWrapperProps: any) {
        const props = this.props;
        const icon = this.getChildrenIcon(linearizedItem, "plus square outline", "minus square outline");

        return this.renderItemWrapperInternal({ ...itemWrapperProps }, icon && <Icon link size="large" name={icon} id="expandCollapseIcon"
            onClick={() => {
                const params = { itemId: linearizedItem.itemId, prevent: false };
                props.onExpandCollapseItem?.call(null, params);
                if (params.prevent) {
                    return;
                }
                props.dispatchers.expandCollapse({ id: linearizedItem.itemId, isExpand: linearizedItem.expanded === Expanded.COLLAPSED })
            }}
        />, this.renderItem({ props, linearizedItem }));
    }

    protected renderItemWrapperInternal(props: any, ...children: any) {
        return React.createElement(Segment, props, ...children);
    }

    protected renderItem(params: RenderItemParams) {
        const result = params.props.renderItemFunction?.call(null, params);
        if (result) {
            return result;
        }
        const ids = params.linearizedItem.itemId.split(Utils.defaultIdSeparator)
        return ids[ids.length - 1];
    }

    render() {
        if (this.firstRender) {
            // first render always happens, regardless of shouldComponentUpdate(); we want to disable this first render
            // we discovered an issue w/ TreeMenu. On user change towards an user w/ different language (and "search" containing something) =>
            // the tree is first rendered with the linearizedItems saved in the state, based on the old root. The new root + search => root = []
            // and for the first render, we'll look w/ the linearized items from before in an empty root. Because the new linearized items that
            // were just calculated, were not yet dispatched as the props of this object. This will happen on the next render cycle.
            this.firstRender = false;
            return null;
        }

        if (this.rootJustChanged) {
            /** 
             * At the moment of writing, if shouldComponentUpdate() returns false => render() is not called. However the docs state that
             * in the future, the result of shouldComponentUpdate() may be taken as a hint; not as a guarantee, and hence render() may still
             * be called. And hence the code will arrive here. Note: that this is also true w/ the functional components / hooks version (i.e. React.memo()).
             * 
             * If this will happen, the only solution I see right now is to "memoize" (or simpler: just cache) the render function; i.e. if the code gets here => 
             * return the previous result.
             */
            throw new Error("An illegal state was detected. This was anticipated, so please follow the instructions and update the code.")
        }
        const props = this.props;

        const mainChildren = props.linearizedItems.map((linearizedItem) => this.renderItemWrapper(linearizedItem, this.createItemWrapperProps(linearizedItem)));

        // TODO CS: solutie pt ancore cypress
        return this.renderMain({ "data-cy": props.dataCy }, mainChildren);
        // return this.renderMain({ "data-cy": that.getKeyInState() }, mainChildren);
    }
}