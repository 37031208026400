export const DatePickerMeta = {
    scenarios: {
        rendererDatePicker: "RENDERER for a date picker",
        whenClickOnEmptyDatePicker: "GIVEN the date picker is empty, WHEN click on it, THEN the first section will be selected",
        whenTypeDate: "WHEN type a valid date, THEN the new value will be that date",
        whenClickOnSection: "GIVEN the date picker is filled, WHEN click on a section, THEN the section will be selected",
        whenArrowLeftRight: "GIVEN a section is selected, WHEN press ARROW LEFT, THEN the section to the left will be selected, AND WHEN press ARROW RIGHT, THEN the section to the right will be selected",
        whenArrowUpDown: "GIVEN a section is selected, WHEN press ARROW UP, THEN the section's value increments by 1, AND WHEN press ARROW DOWN, THEN the section's value decrements by 1",
        whenPageUpDown: "GIVEN a section is selected, WHEN press PAGE UP, THEN the section's value increments by 1, AND WHEN press PAGE DOWN, THEN the section's value decrements by 1",
        whenTypeOnSection: "GIVEN a section is selected, WHEN type a digit, THEN the section loses selection",
        whenLeaveSectionOnType: "WHEN section is left after filling it, THEN next section is selected",
        whenArrowLeftRightOnEditingSection: "WHEN press ARROW RIGHT, THEN cursor position moves to the right, AND WHEN press ARROW LEFT, THEN cursor position moves to the left",
        whenLeaveSectionWithArrow: "WHEN section is left using ARROW RIGHT/LEFT, THEN next/previous section is selected",
        whenSetDateWithArrows: "GIVEN an empty date picker, WHEN select first section, AND press ARROW UP + ARROW RIGHT until the last section, THEN the current date is set",
        whenNumpadAddOrKeyN: "WHEN press NUMPAD_ADD or KEY_N, THEN the date picker is set to now",
        whenHome: "WHEN press HOME, THEN cursor moves at the beginning of the input",
        whenEnd: "WHEN press END, THEN cursor moves at the end of the input",
        whenWrongValue: "WHEN invalid value is typed, THEN date picker's border become red",
        whenBlur: "WHEN blur, THEN date picker's value will be the last valid value inputed",
        whenPaste: "WHEN paste a value, THEN date picker will take that value as long as it is a valid date that matches the format and separators are ignored",
        whenBackspaceSelection: "GIVEN a selection is active, WHEN press BACKSPACE, THEN the selection will be deleted",
        whenBackspaceOnEditingSection: "GIVEN a current section is being edited, WHEN press BACKSPACE, THEN delete current digit.",
        differentValidFormats: "WHEN valid format given, THEN value show will update accordingly",
        differentInvalidFormats: "WHEN invalid format given, THEN default format will be used",
        hasPlaceholder: "GIVEN has placeholder set, WHEN date picker is empty, AND focus is lost, THEN placeholder will be shown",
        onAllowClear: "GIVEN allowClear property is set, WHEN datePicker is emtpy, THEN puts back the last non-null valid value.",
        whenIconClick: "WHEN click on icon, THEN popup appears with used format and a tooltip message",
        whenHasCalendarPicker: "GIVEN it has calendar picker, WHEN clicking on icon, THEN popup appears with calenar picker"
    },

    testids: {
        datePicker: "DatePicker",
        datePickerInput: "DatePickerInput",
        datePickerIcon: "DatePicker_icon",
        datePickerPopupHeader: "DatePicker_popupHeader",
        datePickerPopupHeaderFormat: "DatePicker_popupHeaderFormat",
        datePickerPopupHeaderTooltip: "DatePicker_popupHeaderTooltip",
        datePickerCalendar: "DatePicker_calendar"
    }
};