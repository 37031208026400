import { EntityDescriptor, ENT_READ, PrivateRoute, PrivateRouteProps, Utils } from "@crispico/foundation-react";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { missionEntityDescriptor } from "AppEntityDescriptors";
import { MissionsAwaitingForDriversPageHOC } from "./MissionsAwaitingForDriversPage";

export class Mission2EntityDescriptor extends EntityDescriptor {

    protected customize() {
        this.isInDefaultColumnConfig(true, "creationDate", "humanResource", "equipmentResource", "startTime", "endTime", "type", "comment", "currentState", "mobileDevice")
            .removeFieldDescriptors("humanResourceId", "equipmentResourceId", "inactivityTypeId", "mobileDeviceId", "dataString", "dataVersion", "day", "currentStopId")
            .addFieldDescriptor({ name: "creationDate", type: FieldType.date, format: Utils.dateTimeWithSecFormat, propsForEditor: { hasTime: true, formatString: Utils.dateTimeFormat } })
            .addFieldDescriptor({ name: "startTime", type: FieldType.date, format: Utils.dateTimeWithSecFormat, propsForEditor: { hasTime: true, formatString: Utils.dateTimeFormat } })
            .addFieldDescriptor({ name: "endTime", type: FieldType.date, format: Utils.dateTimeWithSecFormat, propsForEditor: { hasTime: true, formatString: Utils.dateTimeFormat } })
    }
}

export const MissionsAwaitingForDriversPageUrl = "/MissionsAwaitingForDrivers";
export const missionsAwaitingForDriversPageRoute = (computeRoute: (props: PrivateRouteProps) => JSX.Element) =>
    <PrivateRoute key="missionsAwaitingForDriversPage"
        path={MissionsAwaitingForDriversPageUrl}
        render={(props) => <MissionsAwaitingForDriversPageHOC {...props} id="missionsAwaitingForDriversPage" />}
        computeRoute={computeRoute} />

export const missionsAwaitingForDriversPageMenuEntry = () => {
    return {
        id: "missionsAwaitingForDriversPage",
        content: _msg("MissionsAwaitingForDriversPage.title"),
        to: MissionsAwaitingForDriversPageUrl, exact: true, icon: "list",
        permission: Utils.pipeJoin([ENT_READ, missionEntityDescriptor.name])
    }
};

