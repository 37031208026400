import { FilterOperators, Messages } from "@crispico/foundation-gwt-js";
import { CrudSettings } from "@crispico/foundation-react/entity_crud/CrudSettings";
import "@crispico/foundation-react/entity_crud/fieldRenderersEditors/EntityFilter";
import { roleToUserEntityDescriptor } from "@crispico/foundation-react/pages/role/roleEntityDescriptor";
import { ColorRegistry, ColorRegistryRRC } from "@crispico/foundation-react/utils/ColorRegistry";
import { configureStore, EnhancedStore } from "@reduxjs/toolkit";
import { Drawer } from "antd";
import { QueryOptions } from "apollo-client";
import { ConnectedRouter, connectRouter, push, routerMiddleware } from "connected-react-router";
import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { createHashHistory, createLocation, History, Location } from "history";
import Interweave from "interweave";
import lodash from 'lodash';
import moment from "moment-timezone";
import React, { ReactElement, ReactNode, Suspense } from 'react';
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Link, matchPath, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import { Button, Container, Dimmer, Dropdown, Header, Icon, Loader, Menu, Message, Modal, SemanticICONS, Label } from "semantic-ui-react";
import { logout } from "./apollo-gen-foundation/logout";
import { apolloClient, apolloClientHolder, initApolloClient } from "./apolloClient";
import { AppMetaTempGlobals } from "./AppMetaTempGlobals";
import { CompMeta, Optional } from "./CompMeta";
import { ColumnConfigDropdown, currentColumnConfigId, currentEditorColumnConfigId, sliceColumnConfigDropdown } from "./components/ColumnConfig/ColumnConfigDropdown";
import { columnConfigEntityDescriptor } from "./components/ColumnConfig/ColumnConfigEntityDescriptor";
import { ContainerWithHeader, ContainerWithHeaderContext } from "./components/containerWithHeader/ContainerWithHeader";
import { currentCustomQueryId } from "./components/CustomQuery/CustomQueryDropdown";
import { customQueryEntityDescriptor } from "./components/CustomQuery/CustomQueryEntityDescriptor";
import { Filter } from "./components/CustomQuery/Filter";
import { HorizontalMenu } from "./components/HorizontalMenu/HorizontalMenu";
import { ModalExt, Severity } from "./components/ModalExt/ModalExt";
import { Expanded, OnSelectParams, RenderItemParams, SliceTree, Tree } from "./components/TreeNew/Tree";
import { entityDescriptors, runAfterStartupRunnables, sessionGlobalCurrentOrganizationToFilterBy, sessionGlobalReload } from "./entity_crud/entityCrudConstants";
import { EntityDescriptor } from "./entity_crud/EntityDescriptor";
import { EntityDescriptorPopulatorFromEntityGen } from "./entity_crud/EntityDescriptorPopulatorFromEntityGen";
import { SliceEntityEditorPage } from "./entity_crud/EntityEditorPage";
import { FieldType } from "./entity_crud/FieldType";
import { FindByFilterParams } from "./entity_crud/FindByFilterParams";
import "./messages";
import { EntityDescriptorForServerUtils } from "./flower/entityDescriptorsForServer/EntityDescriptorForServerUtils";
// import * as zeroTrainingAudit from './pages/Audit/stories';
import { LOGOUT } from "./pages/loginPage/queries";
import { NotificationComponent, sliceNotificationComponent } from "./pages/notification/NotificationComponent";
import { organizationEntityDescriptor, OrganizationManyToOneEditorStandalone } from "./pages/SettingsEntity/settingsEntityDescriptor";
import { AppRouteProps, ConnectedComponentInSimpleComponent, ConnectedPageHelper, ConnectedPageInfo, createSliceFoundation, getBaseImpures, getBaseReducers, PrivateRouteProps, PropsFrom, RootReducerForPages, StateFrom } from "./reduxHelpers";
import { TestUtils } from "./utils/TestUtils";
import { ShowGlobalAlertParams, DATABASE_MENU_ENTRY, Utils, ENT_READ } from "./utils/Utils";
import { ZeroTrainingArticlesRegistry, ZeroTrainingContext } from "./zeroTraining/dataStructures";
import * as zeroTrainingZt from "./zeroTraining/stories/demoZeroTraining.stories";
import { ZeroTrainingArticlePageRoute } from "./zeroTraining/ZeroTrainingArticlePage";
import { ZeroTrainingIndexPageRoute } from "./zeroTraining/ZeroTrainingIndexPage";
import { ReduxReusableComponents, RRC_SINGLETON } from "./reduxReusableComponents/ReduxReusableComponents";
import { infoCurrentUserEditorPage } from "./pages/user/CurrentUserEditorPage";
import _ from "lodash";
import { Clock } from "./components/Clock/Clock";
import { TreeMenu, TreeMenuRRC } from "./components/treeMenu/TreeMenu";
import { TreeMenuSearch } from "./components/treeMenu/TreeMenuSearch";
import { WrapperWithRenderFunction } from "./utils/WrapperWithRenderFunction";
import "@crispico/foundation-react/pages/Audit/auditEntityDescriptor"; // out of the blue, only in prot, this was imported AFTER finalizeDescriptors() was ran => the registered afterStartupRunnable(), wouldn't be ran any more. Hence I force its inclusion here

const ACTION_RESET_STORE = "reset-store";
// session storage item; used to switch between normal and phone mode (temporary menu entry added on XOPS proj)
export const PHONE_MODE_KEY = 'app.phoneMode';

export interface BigState<I extends FoundationInitializationsForClient = FoundationInitializationsForClient> {
    AppContainer: {
        initializationsForClient: I;
        menuEntries: { [key: string]: Optional<MenuEntry[]> };
    }
}

export interface FoundationInitializationsForClient {
    version: string[2];
    currentUser: Optional<User>;
    currentRole: Optional<Role>;
    currentPermissions: Optional<any>;
    currentOrganization: Optional<Organization>;
    allOrganizationsAccess: Optional<boolean>;

    visualStyleSettings: VisualStyleSettings;
    homePageSettings: HomePageSettings;
    colorSettings: ColorSettings;
    internationalizationSettings: InternationalizationSettings;
    notificationSettings?: NotificationSettings;
    auditSettings?: AuditSettings;
    userSettings?: UserSettings;
    crudSettings?: CrudSettings;
    organizationSettings?: OrganizationSettings;
    oktaAuthenticationAvailable?: boolean;
    rememberMeEnabled?: boolean;
    ldapAvailable: boolean;
    fileBrowserBulkDownloadMaximumFileSize: number;
    tempSettings?: TempSettings;
}

export interface User {
    id: number;
    username: string;
    firstName: Optional<string>;
    lastName: Optional<string>;
    isAdmin: boolean;
    language: Optional<string>
}

export interface Role {
    id: any;
    name: string;
    permissions: String;
    isAdmin: boolean;
}

export interface Organization {
    id: any;
    name: string;
    qualifiedName: string;
    parent?: Organization;
    children?: Organization[];
}

export enum OrganizationFilter {
    NONE = "NONE", LEFT_SIDE = "LEFT_SIDE"
};
export interface OrganizationSettings {
    globalOrganizationFilter: OrganizationFilter;
}

export interface VisualStyleSettings {
    appTitle?: string;
    headerLogo?: string;
    headerLogoRight?: boolean;
    clientHeaderLogo?: string;
    whiteBackgroundHeaderLogo?: boolean;
    whiteBackgroundClientHeaderLogo?: boolean;

    homeLogo?: string;
    homeTitle?: string;
    homeBackgroundImage?: string;
    homeLogo2?: string;
}

export interface HomePageSettings {
    usePersonalHomePage: boolean;
    dashboardId: number;
    footerBarText: string;
}

export interface ColorSettings {
    data: Array<Color>
}

export interface Color {
    name: string;
    value?: number;
    thisColorIsAliasFor?: string
}

export interface NotificationSettings {
    maxNumberOfNotificationDisplayed: number;
    refreshRate: number;
}

export interface TempSettings {
    historyGraphInitialFields: string;
}

export interface AuditSettings {
    maxRowsForPopup: number;
}

export interface UserSettings {
    passwordMinLength: number;
    passwordMaxLength: number;
    passwordHasUpperCaseConstraint: boolean;
    passwordHasLowerCaseConstraint: boolean;
    passwordHasNumberConstraint: boolean;
    passwordHasSpecialCharacterConstraint: boolean;
}

export type MenuEntry = {
    id: number,
    name: string,
    icon: string,
    color: string
}

export interface InternationalizationSettings {
    showBothOriginalAndTranslated: boolean;
    translatableByUserLanguagesMap: {
        [language: string]: {
            entriesMap: { [original: string]: string }
        }
    },
    dateFormat?: string,
    timeFormat?: string,
    dateFormats?: [
        { name: string, momentJsFormat: string, momentJsFormatShorter: string }
    ]
    timeFormats?: [
        { name: string, momentJsFormat: string, momentJsFormatShorter: string }
    ],
    defaultLanguage: string
}

export interface ShowCrudButtons {
    showImportButton: boolean,
    showExportButton: boolean,
    showShareLinkButton: boolean,
    showCustomQueryButton: boolean,
    showCustomQueryEmailSchedule: boolean,
    showColumnConfigButton: boolean,
    showAuditButton: boolean,
    showExportAsCsvButton: boolean,
}

export enum EntityFormLightOpenType { MODAL, DRAWER };

const menuEntries = [] as any[];
export const searchOptions = {} as { [key: string]: string[] };

class SliceOrganizationTree extends SliceTree {

    static ORGANIZATION_TREE_ROOT_ID = "organizationsTreeRootId";

    hasChildren(item: any) {
        if (item instanceof Array) {
            return item.length > 0
        }
        return item?.children?.length > 0;
    }

    getChildren(item: any) {
        if (item instanceof Array) {
            return item.map((o: Organization) => ({ localId: "" + o.id, item: o }));
        }
        if (item.children) {
            const children: any = [];
            item.children?.sort((a: any, b: any) => a.name && b.name ? a.name.toLowerCase().localeCompare(b.name.toLowerCase()) : 0).forEach((o: Organization) => children.push({ localId: "" + o.id, item: o }));
            return children;
        }
    }
}
const sliceOrganizationsTree: SliceOrganizationTree = createSliceFoundation(SliceOrganizationTree);

/**
 * @author Cristian Spiescu
 */
export class AppMeta {

    componentCreated = false;

    routes: JSX.Element[] = [];

    entityCrudMenus: any[] | undefined;

    reducerCombination: any = {};

    showHorizontalMenu: boolean = false;

    showSearchBarOnTitleBar: boolean = false;

    showNotifications: boolean = true;

    showTimeZone: boolean = true;

    showOrganization: boolean = true;

    showMyAccountMenuEntry: boolean = true;

    showBackButton: boolean = false;
    showReloadButton: boolean = false;
    showHomeButton: boolean = true;
    showClock: boolean = false;
    showHeaderLogo: boolean = true;

    removeMenuEntryWhenNoPermission: boolean = true;

    scrollOnlyContentInEditor: boolean = false;

    showCrudButtons: ShowCrudButtons = {
        showImportButton: true,
        showExportButton: true,
        showShareLinkButton: true,
        showCustomQueryButton: true,
        showCustomQueryEmailSchedule: true,
        showColumnConfigButton: true,
        showAuditButton: true,
        showExportAsCsvButton: true,
    };

    /**
     * SH: Temporary property used to hide "View properties" tab on Proteus.
     */
    showViewPropertiesTab: boolean = true;

    entityFormLightOpenType: EntityFormLightOpenType = EntityFormLightOpenType.MODAL;
    /**
     * SH: By default, for each ED, the property 'hasAttachedDashboards' is true.
     * This property is used to define if dashboards are implemented at the project level.
     */
    dashboardsAvailable: boolean = true;

    newRootReducer = new RootReducerForPages();

    zeroTrainingArticlesRegistry = new ZeroTrainingArticlesRegistry();

    helperAppContainer!: ConnectedPageHelper<typeof sliceAppContainer>;

    globalPermissions: Set<string> = new Set();

    loadOrganizationsQuery!: DocumentNode;

    // list of EntityDescriptors used by UI (e.g. for menu entries, role config)
    protected uiEntityDescriptors: Optional<{ [entityName: string]: EntityDescriptor }>;

    get menuEntries() {
        return menuEntries;
    }

    set menuEntries(value) {
        menuEntries.splice(0, menuEntries.length);
        Array.prototype.push.apply(menuEntries, value); // this trick to pass an array into the varargs param
    }

    constructor(public metas: CompMeta<any, any>[]) {
        this.globalPermissions.add(DATABASE_MENU_ENTRY);
        if (AppMetaTempGlobals.appMetaInstance) {
            // TODO CC: this doesn't work for storybook
            // throw new Error("Illegal state: an instance of AppHelper already exists")
        }
        AppMetaTempGlobals.appMetaInstance = this;
        AppMetaTempGlobals.appMetaInstance = this;

        this.configQueries();
        if (!Utils.isTest()) {
            Utils.showGlobalAlert = params => {
                // Sometimes when developing, we may generate an error so early, that the React app is not completely setup.
                // And in this case it's shameful to generate another error while trying to display the initial error. 
                // Hence the "fallback" version below
                if (!this.helperAppContainer?.dispatchers) {
                    alert(`Global alert fallback. Severity: ${params.severity}. Title: ${params.title}\nMessage: ${params.message}`);
                    return;
                }
                this.helperAppContainer.dispatchers.showGlobalAlert(params);
            }
        }

        metas.forEach((m, i) => {
            m.contributeRootReducer(this.reducerCombination);
            this.routes.push(m.createRoute(this.computeRoute));
        });
        this.routes.push(ZeroTrainingIndexPageRoute());
        this.routes.push(ZeroTrainingArticlePageRoute());

        this.zeroTrainingArticlesRegistry.addFromModule(zeroTrainingZt);
        // this.zeroTrainingArticlesRegistry.addFromModule(zeroTrainingAudit);
        this.addEntityDescriptors([customQueryEntityDescriptor]);
        this.addEntityDescriptors([columnConfigEntityDescriptor]);
        // The current user slice extends the user slice that requires the one to many entity descriptors to be defined
        // @see EntityEditorPage.onBeforeMergeByConnectedPageHelper
        this.addEntityDescriptors([roleToUserEntityDescriptor]);

        SliceEntityEditorPage.sliceColumnConfigDropdown = sliceColumnConfigDropdown;
        SliceEntityEditorPage.ColumnConfigDropdown = ColumnConfigDropdown;
        this.getCurrentUser = this.getCurrentUser.bind(this);
        this.computeRoute = this.computeRoute.bind(this);
        // This method, besides other things, creates the routes described in each entityDescriptor.connectedPageInfo 
        // We need this to execute no matter if a user is currently logged in order for the <PrivateRoutes> to be registered 
        // so that the redirect to login mechanism (when no user logged in) to work 
        this.entityCrudMenus = AppMetaTempGlobals.appMetaInstance.finalizeEntityDescriptors();
    }

    /**
     * CC: Temporary property, call or not onLocationChanged from appContainer constructor
     * Is set to false for XopsMobile APK.
    */
    canCallOnLocationChangedFromAppContainerConstructor(): boolean {
        return true;
    }

    protected computeRouteForComponent(props: PrivateRouteProps) {
        return <Route {...props} component={props.component} />;
    }

    protected computeRoute(props: PrivateRouteProps): JSX.Element {
        if (props.privateRoute === false || !ConnectedPageHelper.tempEnableSecurity) {
            return this.computeRouteForComponent(props);
        }
        if (!this.getCurrentUser()) {
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }
        return !props.permission || this.hasPermission(props.permission) ? this.computeRouteForComponent(props) : this.getRedirectToError(props.permission, props.location);
    }

    addConnectedPageInfos(infos: ConnectedPageInfo[]) {
        for (let info of infos) {
            const helper = new ConnectedPageHelper(info);
            this.newRootReducer.add(helper);
            this.routes.push(helper.getRoute(this.computeRoute));
        }
    }

    createStore(initialState?: any, history?: History) {
        this.helperAppContainer = new ConnectedPageHelper<typeof sliceAppContainer>(infoAppContainer);
        this.newRootReducer.add(this.helperAppContainer);

        Utils.showSpinner = (spinnerText?: string, showSpinnerCloseButton: boolean = true) => this.helperAppContainer.dispatchers.setInReduxState({ showSpinner: true, spinnerText: spinnerText, showSpinnerCloseButton: showSpinnerCloseButton });
        Utils.hideSpinner = () => this.helperAppContainer.dispatchers.setInReduxState({ showSpinner: false, spinnerCloseConfirmationModalOpen: false });

        if (!this.menuEntries) {
            this.menuEntries = [];
        }

        const middleware: any = [thunk];
        let rc = this.reducerCombination;
        if (history) {
            middleware.push(routerMiddleware(history));
            rc = { ...rc, router: connectRouter(history) };
        }
        return configureStore({ reducer: this.createRootReducer(rc), middleware: middleware, preloadedState: initialState, enhancers: [ReduxReusableComponents.storeEnhancer] }) as unknown as EnhancedStore<any, any>;
    }

    createRootReducer(reducerCombination: any) {
        const combinedReducer: any = combineReducers(reducerCombination);
        return (state: any, action: any) => {
            if (action.type === ACTION_RESET_STORE) {
                // state.router is not reset in order to preserve the current route for the user
                return { router: state.router };
            }
            const newState = this.newRootReducer.reducer(state, action);
            // newState.router = rc.router(state?.router, action);

            // temp ca sa scapam de warning/eroare emis de reducerul creat de combineReducers()
            const cleanedUpOldState = !state ? undefined : Object.keys(reducerCombination).reduce((obj, field) => {
                obj[field] = state[field];
                return obj;
            }, {} as any);

            Object.assign(newState, combinedReducer(cleanedUpOldState, action));
            return newState;
        }
    }

    protected async invokeGetInitializationsForClientQuery(options: QueryOptions) {
        return await apolloClientHolder.apolloClient.query(options);
    }

    protected async loadInitializationsForClient_fromConstructor() {
        this.loadInitializationsForClient();
    }

    public async loadInitializationsForClient() {
        const query = this.getClientInitializationService_initializationsForClient_query(this.getFieldsForInitializationsForClient());
        const initializationsForClient = (await this.invokeGetInitializationsForClientQuery({ query, context: { showSpinner: false } })).data.clientInitializationService_initializationsForClient as FoundationInitializationsForClient;

        document.title = this.getAppTitle(initializationsForClient.visualStyleSettings);
        global.oktaAuthenticationAvailable = initializationsForClient.oktaAuthenticationAvailable === true;
        global.rememberMeEnabled = initializationsForClient.rememberMeEnabled === true;
        global.fileBrowserBulkDownloadMaximumFileSize = initializationsForClient.fileBrowserBulkDownloadMaximumFileSize;
        
        const dateFormat = initializationsForClient.internationalizationSettings?.dateFormats?.find(f => f.name === initializationsForClient.internationalizationSettings?.dateFormat);
        if (dateFormat) {
            Utils.dateFormat = dateFormat.momentJsFormat;
            Utils.dateFormatShorter = dateFormat.momentJsFormatShorter;
        }
        const timeFormat = initializationsForClient.internationalizationSettings?.timeFormats?.find(f => f.name === initializationsForClient.internationalizationSettings?.timeFormat);
        if (timeFormat) {
            Utils.timeFormat = timeFormat.momentJsFormatShorter;
            Utils.timeWithSecFormat = timeFormat.momentJsFormat;
        }
        Utils.dateTimeFormat = Utils.dateFormat + " " + Utils.timeFormat;
        if (initializationsForClient.colorSettings) {
            ColorRegistry.INSTANCE.init(initializationsForClient.colorSettings);
        }

        Messages.getInstance().setTranslatableByUser({
            // internationalizationSettings currently not available for prot; hence ?
            getShowBothOriginalAndTranslated: () => initializationsForClient.internationalizationSettings?.showBothOriginalAndTranslated,
            get: (language: string, original: string) => {
                const l = initializationsForClient.internationalizationSettings?.translatableByUserLanguagesMap[language];
                if (!l) { return null };
                return l.entriesMap[original];
            }
        });

        this.resetMenuEntries();

        if (initializationsForClient.currentUser?.language) {
            Messages.getInstance().setLanguage(initializationsForClient.currentUser.language);

            if (this.hasHomePageMenuEntry()) {
                this.menuEntries.push({ content: _msg("HomePage.title"), to: "/", icon: "home", exact: true });
            }

            // We update the content of the menu entries in order for their messages to be translated in the current user language
            this.entityCrudMenus = this.entityCrudMenus!.map(menuEntry => { return { ...menuEntry, content: _msg(menuEntry.entityMenuProps.labelKey) } });
            this.entityCrudMenus = this.entityCrudMenus!.sort(this.compareMenuEntries);
            this.menuEntries.push(...this.createMenuEntries(initializationsForClient, this.entityCrudMenus));

            if (initializationsForClient.currentUser?.isAdmin || AppMetaTempGlobals.appMetaInstance.hasPermission(DATABASE_MENU_ENTRY, false, initializationsForClient.currentPermissions)) {
                this.menuEntries.push({ content: _msg("Menu.database"), icon: "database", submenus: this.entityCrudMenus });
            }
        } else {
            Messages.getInstance().setLanguage(initializationsForClient.internationalizationSettings.defaultLanguage);
        }

        await this.getEntityDescriptorsForServer();
        this.addCustomFieldsToEntityDescriptors();

        if (initializationsForClient.currentUser && AppMetaTempGlobals.appMetaInstance.showOrganization) {
            await this.loadOrganizations(initializationsForClient.currentUser, initializationsForClient.currentOrganization);
        }

        this.populateDescriptorsWithSettings(initializationsForClient.crudSettings);
        this.helperAppContainer.dispatchers.setInReduxState({ initializationsForClientLoaded: true, initializationsForClient });

        // assign/switch "/" for routes: home <-> personalHomePage
        for (let i = 0; i < this.routes.length; i++) {
            const route = this.routes[i];
            let props: AppRouteProps = route.props;
            if (props.homePageType) {
                let newPath: string;
                if (props.homePageType === "normal") {
                    newPath = initializationsForClient.homePageSettings.usePersonalHomePage ? "/homeOld" : "/";
                } else {
                    // props.homePageType === "personal"
                    newPath = initializationsForClient.homePageSettings.usePersonalHomePage ? "/" : "/personalHomePage";
                }
                this.routes[i] = <route.type key={route.key} {...props} path={newPath} />
            }
        }
        if (AppMetaTempGlobals.history.location.pathname === "/") {
            // if pers home page, sometimes the normal home page is still displayed
            // a re-render (e.g. via "forceUpdate()") would suffice; but currently I cannot have a reliable
            // comp here; hence I work on the history; I observed that this action doesn't add a new 
            // history entry, and it seems to force a rerender
            AppMetaTempGlobals.history.push("/");
        }

        window.sessionStorage.removeItem(sessionGlobalReload);

        return initializationsForClient;
    }

    /**
     * Copies (merges) the FieldDescriptorSettings received from the server, into the ones that exist on the client.
     * Having FDS defined on the client is not a common practice, but it's possible.
     */
    protected populateDescriptorsWithSettings(crudSettings?: CrudSettings) {
        global.crudSettings = crudSettings;
        crudSettings?.forEntities.map(forEntity => {
            const ed = entityDescriptors[forEntity.entityName];
            if (!ed) {
                return;
            }
            ed.entityDescriptorSettings = forEntity;
            forEntity.fieldDescriptorSettings.forEach(fdSettings => {
                if (!fdSettings.fieldRef || ed?.getFieldDescriptorChain(fdSettings.fieldRef).length === 0 || ed?.getFieldDescriptorChain(fdSettings.fieldRef)[0] === undefined) {
                    return;
                }
                let fds = [ed?.getField(fdSettings.fieldRef)];
                fds.forEach(fd => {
                    if (fd) {
                        // IMPORTANT: for lists it will merge items based on their indexes!!! 
                        // For the moment we don't see a real case for it.
                        // There is also a 'mergeWith' if we need to do something else for them in the future.
                        fd.fieldDescriptorSettings = lodash.merge(fd.fieldDescriptorSettings, fdSettings);
                        if (fdSettings.icon) {
                            fd.icon = fdSettings.icon as SemanticICONS;
                        }
                    }
                });
            })
        });
    }

    public hasPermission(permission: string, showErrorMessageIfNoPermission?: boolean, permissions?: any): boolean {
        if (TestUtils.storybookMode || Utils.isTest()) {
            return true;
        }
        const value = this.helperAppContainer.dispatchers.hasPermission(permission, permissions);
        if (!value && showErrorMessageIfNoPermission) {
            this.helperAppContainer.dispatchers.showGlobalAlert({ message: _msg("error.insufficient.rights.title") + "\n" + _msg("error.insufficient.rights.details", '"' + permission + '"'), severity: Severity.ERROR });
        }
        return value;
    }

    /**
     * Meant to be overwritten
     * @author Daniela Buzatu
     */
    public getRedirectToError(permission: string, location?: any) {
        return <Redirect to={{ pathname: '/error', state: { from: location, headerMessage: _msg("error.insufficient.rights.title"), errorMessage: _msg("error.insufficient.rights.details", permission) } }} />;
    }

    protected async getEntityDescriptorsForServer() {
        const operationName = "entityDescriptorForServerService_entityDescriptors";
        const result = (await apolloClientHolder.apolloClient.query({ query: gql(`query q{ ${operationName} }`), context: { showSpinner: false } })).data[operationName];
        Object.assign(EntityDescriptorForServerUtils.entityDescriptorsForServer, result);
    }

    protected addCustomFieldsToEntityDescriptors() {
        for (const ed of Object.values(entityDescriptors)) {
            const fields = Object.values(ed.fields).filter(field => field.isCustomField === true).map(field => field.name);
            if (fields && fields.length > 0) {
                ed.removeFieldDescriptors(...fields);
            }
        }

        new Set<string>(Object.values(EntityDescriptorForServerUtils.entityDescriptorsForServer)
            .map(entity => entity.entityName))
            .forEach(entityName => {
                Object.entries(EntityDescriptorForServerUtils.entityDescriptorsForServer[entityName].fields)
                    .filter(([key, field]) => key === field.fieldName && field.customField)
                    .forEach(([key, field]) => {
                        entityDescriptors[entityName].addFieldDescriptor({
                            name: field.fieldName,
                            type: field.fieldType || FieldType.defaultScalar,
                            isCustomField: true
                        })
                    })
            });
    }

    protected getFieldsForOrg() {
        return "id name qualifiedName"
    }

    protected configQueries = () => {
        const orgFields = this.getFieldsForOrg();
        this.loadOrganizationsQuery = gql(`query userService_getOrganizations($userId: Long!) {
            userService_organizations(userId: $userId) { 
                ${orgFields} parent { ${orgFields} } children { 
                    ${orgFields} children {
                        ${orgFields} children {
                            ${orgFields} children {
                                ${orgFields} 
                        }
                        }
                    }
            }} 
          }`);
    }
    protected async loadOrganizations(user: User, currentOrg: Optional<Organization>) {
        const result = (await apolloClientHolder.apolloClient.query({ query: this.loadOrganizationsQuery, variables: { userId: user.id }, context: { showSpinner: false } })).data.userService_organizations;
        global.organizations = result;
        // get value from session if available
        const orgId = window.sessionStorage.getItem(sessionGlobalCurrentOrganizationToFilterBy);
        const orgFilterFromSession = orgId !== null && orgId !== "" ? global.organizations?.find(o => orgId === "" + o.id) : undefined;
        global.currentOrganizationToFilterBy = orgFilterFromSession?.id === currentOrg?.id ? undefined : orgFilterFromSession;
        // set tree root
        let orgTreeRoot = {
            children:
                currentOrg
                    ? result.filter((x: Organization) => x.qualifiedName === currentOrg.qualifiedName)
                    : [{ id: SliceOrganizationTree.ORGANIZATION_TREE_ROOT_ID }].concat(result.filter((x: Organization) => x.parent === null))
        }
        this.helperAppContainer.dispatchers.organizationsTree.setInReduxState({ root: orgTreeRoot });
        // select root node if no selection provided
        !global.currentOrganizationToFilterBy && this.helperAppContainer.dispatchers.organizationsTree.selectItem(currentOrg ? "" + currentOrg.id : SliceOrganizationTree.ORGANIZATION_TREE_ROOT_ID);
    }

    protected getClientInitializationService_initializationsForClient_query(fields: string) {
        return gql(`query q { clientInitializationService_initializationsForClient { ${fields} } }`);
    }

    protected getSecurityFieldsForInitializationsForClient() {
        return " currentRole {id name} currentOrganization {id qualifiedName name} currentPermissions allOrganizationsAccess ldapAvailable rememberMeEnabled";
    }

    protected getFieldsForInitializationsForClient() {
        let result = "";
        if (ConnectedPageHelper.tempEnableSecurity) {
            result += this.getSecurityFieldsForInitializationsForClient();
        }

        result += ` version 
        visualStyleSettings { appTitle headerLogo clientHeaderLogo whiteBackgroundHeaderLogo whiteBackgroundClientHeaderLogo homeLogo homeTitle homeBackgroundImage homeLogo2 headerLogoRight } 
        homePageSettings { usePersonalHomePage dashboardId footerBarText }
        internationalizationSettings { defaultLanguage showBothOriginalAndTranslated translatableByUserLanguagesMap dateFormat timeFormat dateFormats { name momentJsFormat momentJsFormatShorter } timeFormats { name momentJsFormat momentJsFormatShorter }  }
        colorSettings { data { name value thisColorIsAliasFor } } 
        notificationSettings { maxNumberOfNotificationDisplayed refreshRate }
        auditSettings { maxRowsForPopup }
        userSettings { passwordMinLength passwordMaxLength  passwordHasUpperCaseConstraint passwordHasLowerCaseConstraint passwordHasNumberConstraint passwordHasSpecialCharacterConstraint }
        crudSettings { forEntities { entityName defaultColumnConfig defaultCustomQuery externalLink fieldsInHeader { name fields } fieldDescriptorSettings { fieldRef inHeaderOrderIndex physicalQuantity measurementUnit numberOfDecimals fieldIntervals { from to enumOrderIndex color applyColorTo label } } } physicalQuantities { name defaultMeasurementUnitSymbol measurementUnits { name symbol scaleFactorTowardsDefaultMeasurementUnit translationFactorTowardsDefaultMeasurementUnit } } }
        organizationSettings { globalOrganizationFilter }
        oktaAuthenticationAvailable
        fileBrowserBulkDownloadMaximumFileSize
        tempSettings { historyGraphInitialFields }
        `;

        return result;
    }

    beforeLogout() {
        // nothing here, used by super
    }

    createLogoutParams() {
        return { oktaUserId: document.cookie.split('; ').find((row: string) => row.startsWith('oktaUserId='))?.split('=')[1] };
    }

    async logout() {
        this.beforeLogout();

        this.helperAppContainer.dispatchers.setInReduxState({ initializationsForClient: undefined, initializationsForClientLoaded: false });

        // when processing a GraphQL request server-side, we don't have a access to a HTTP response
        // to delete a cookie for the client. Server-side, we lose the token entity for this session, so,
        // even if we didn't delete it here, automatic login would not be possible.
        document.cookie = "auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

        await apolloClient.mutate<logout>({ mutation: LOGOUT, variables: { logoutParams: this.createLogoutParams() } });

        this.loadInitializationsForClient();

        if (ConnectedPageHelper.tempEnableSecurity) {
            AppMetaTempGlobals.history.push("/login");
        }
    }

    async afterLogin() {
        await AppMetaTempGlobals.appMetaInstance.loadInitializationsForClient();
    }

    getAppTitle(visualStyleSettings: Optional<VisualStyleSettings>) {
        return visualStyleSettings?.appTitle || "App title";
    }

    public getAdditionalItemsForRightMenu(): any | null {
        return null;
    }

    public getAdditionalUserMenuEntries(): any[] {
        return [];
    }

    public goBack() {
        AppMetaTempGlobals.history.goBack();
    }

    addEntityDescriptors(eds: EntityDescriptor[]) {
        // nop for the moment; at least forces the user to import the script and call this
        // in the future, this should invoke a runnable to trigger the customization
    }

    finalizeEntityDescriptors() {
        // duplicated below :(
        EntityDescriptorPopulatorFromEntityGen.INSTANCE.processRemainingEntitiesGen();
        runAfterStartupRunnables();

        // create pages and add in menu list only the ones in uiEntityDescriptors
        let eds = this.getUIEntityDescriptors();
        const entityCrudMenus = [];
        for (let ed in eds) {
            const d = eds[ed];
            this.addConnectedPageInfos(d.getHelpers());
            entityCrudMenus.push(d.getMenuEntry());
        }

        return entityCrudMenus;
    }

    getUIEntityDescriptors(): { [entityName: string]: EntityDescriptor } {
        if (this.uiEntityDescriptors === undefined) {
            this.uiEntityDescriptors = {};
            Object.values(entityDescriptors).filter(ed => ed.showInUI).map(ed => this.uiEntityDescriptors![ed.name] = ed);
        }
        return this.uiEntityDescriptors!;
    }

    getAppComponent = () => {
        if (this.componentCreated) {
            throw new Error("Illegal state; app already created");
        }

        if (TestUtils.storybookMode) {
            // duplicated from above :(
            runAfterStartupRunnables();
        }
        initApolloClient();

        const that = this;
        const history = createHashHistory();
        AppMetaTempGlobals.history = history;
        history.listen(l => {
            AppMetaTempGlobals.locationPathnamePrevious = AppMetaTempGlobals.locationPathnameCurrent;
            AppMetaTempGlobals.locationPathnameCurrent = l.pathname;
        });

        return class extends React.Component<any, { reloading: boolean }> {
            store = that.createStore(undefined, history);

            constructor(props: any) {
                super(props);
                this.softReload = this.softReload.bind(this);

                this.state = { reloading: false };
                that.loadInitializationsForClient_fromConstructor();
            }
            
            /**
             * Previously, when the user would change the organization, a full web page reload would be triggered. This was reasonable,
             * because we want all state cleared, which may be: Redux, local component state, "normal" variables/attributes. We don't want
             * an individual component or page to have an additional responsability: "please be ready to do a full reset, if the user changes
             * the org".
             * 
             * However, this is a no go when executing tests. Because the test is run within the window of this app which is just being reloaded.
             * 
             * So we introduced this function. The process is the following:
             * 1. `this.state.reloading = true`;
             * 2. on next update/render the root component will onmount everything. So all components in the hiearachy are unmounted / die. Normally
             * this would kill all kind of internal state
             * 3. on next update: schedule that on next update (when the components are unmounted), a "store empty" action is issued
             * 4. the components will render again normally, and the Redux state will begin naturally to be populated by the components that
             * are just mounted
             */
            protected softReload() { 
                this.setState({ reloading: true });
            }

            async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<{ reloading: boolean; }>): Promise<void> {
                if (prevState.reloading !== this.state.reloading && this.state.reloading) {
                    // needed because the components aren't yet unmounted; see ConnectedPageHelper.initHOCs() -> componentWillUnmount()
                    await Utils.setTimeoutAsync();

                    // Removing the items in session storage so that for an org switch => default org CC/CQ are used instead of what the user had in storage
                    // TODO: this should be replaced with a subscription mechanism
                    window.sessionStorage.removeItem(currentColumnConfigId);
                    window.sessionStorage.removeItem(currentEditorColumnConfigId);
                    window.sessionStorage.removeItem(currentCustomQueryId);
                    window.sessionStorage.removeItem(sessionGlobalCurrentOrganizationToFilterBy);
                    // reset the store
                    this.store.dispatch({ type: ACTION_RESET_STORE });
                    // rerender & reload data
                    this.setState({ reloading: false });
                    that.loadInitializationsForClient_fromConstructor();
                }
            }

            render() {
                return !this.state.reloading && <>
                    <Provider store={this.store}>
                        <ColorRegistryRRC id={RRC_SINGLETON} />
                        <RootReducerForPages.Context.Provider value={that.newRootReducer}>
                            <ZeroTrainingContext.Provider value={that.zeroTrainingArticlesRegistry}>
                                <ConnectedRouter history={history}>
                                    <that.helperAppContainer.componentClass onSoftReload={this.softReload} />
                                </ConnectedRouter>
                            </ZeroTrainingContext.Provider>
                        </RootReducerForPages.Context.Provider>
                    </Provider>
                </>;
            }
        }
    }

    protected hasHomePageMenuEntry() {
        return true;
    }

    protected createMenuEntries(data: FoundationInitializationsForClient, entityCrudMenus: any): any {
        return [];
    }

    protected resetMenuEntries() {
        this.menuEntries = [];
    }

    protected compareMenuEntries(a: any, b: any) {
        return a.content.localeCompare(b.content);
    }

    getCurrentUser(): Optional<User> {
        return this.helperAppContainer.dispatchers.getState().initializationsForClient.currentUser;
    }

    getVisualStyleSettings(): Optional<VisualStyleSettings> {
        return this.helperAppContainer.dispatchers.getState().initializationsForClient.visualStyleSettings;
    }

    enableModalRoutes() {
        return true;
    }

    getColor(colorKey: any): string {
        const colorFromRegistry = ColorRegistry.INSTANCE.get(colorKey);
        if (colorFromRegistry !== -1 && colorFromRegistry !== 0) {
            return Utils.convertColorToHex(colorFromRegistry);
        }
        if (typeof colorKey === 'number') {
            return Utils.convertColorToHex(colorKey);
        }
        if (typeof colorKey === 'string' && !colorKey.trim().startsWith("#")) {
            return Utils.DEFAULT_TEXT_COLOR;
        }
        return colorKey;
    }

    getOrganizationTreeIcon(itemId: string, object: any, currentOrg: Optional<Organization>) {
        let icon = organizationEntityDescriptor.getIcon();
        if (object.children?.length > 0 && (itemId !== SliceOrganizationTree.ORGANIZATION_TREE_ROOT_ID || currentOrg)) {
            icon = <></>;
        }
        return icon;
    }

    getUserInfo(user: User): React.ReactNode {
        return <div className="flex-container gap5 AppContainer_userInfo">
            <span className="no-wrap-no-overflow-ellipsis"><Icon name='user' className="AppContainer_menu_icon_without_label" /> {user?.username}</span>
            {this.renderAdditionalUserInfo(user)}
        </div>
    }

    getVersionInfo(version: string): React.ReactNode {
        return <span>{version}</span>;
    }

    protected renderAdditionalUserInfo(user: User): React.ReactNode {
        return <></>;
    }
}

export const sliceAppContainer = createSliceFoundation(class SliceAppContainer {
    initialState = {
        drawerOpen: false,
        searchBarHasFocus: false,
        initializationsForClientLoaded: false,
        avoidDisplayingInitializationsLoadingSpinner: false,
        /**
         * Unfortunately we didn't find a way to reuse the type of the app (which extends this class).
         * So when we access the state of this, we need to perform a cast beforehand.
         */
        initializationsForClient: undefined as any as FoundationInitializationsForClient,
        globalAlertTitle: undefined as string | undefined,
        globalAlertMessage: undefined as string | undefined,
        globalAlertShownAsMessage: false,
        menuEntries: {} as { [key: string]: Optional<MenuEntry[]> },
        globalAlertSeverity: undefined as unknown as Severity | undefined,

        // support for modal routes; if enabled, these are calculated by onLocationChanged(); and then these locations are used 
        // during render; i.e. from here/Redux state, instead of the current location of the browser
        locationForMain: undefined as Location<any> | undefined,
        locationForMainRouteEntityName: undefined as string | undefined,
        locationForDrawer: undefined as Location<any> | undefined,
        locationsForModals: [] as Location<any>[],
        currentUserModalOpen: false,
        showSpinner: false,
        spinnerText: undefined as string | undefined,
        showSpinnerCloseButton: true,
        spinnerCloseConfirmationModalOpen: false
    }

    nestedSlices = {
        notificationComponent: sliceNotificationComponent,
        organizationsTree: sliceOrganizationsTree
    }

    reducers = {
        ...getBaseReducers<SliceAppContainer>(this),

        // message = undefined => closes the modal, everything else shows the modal with the message
        // DON'T use directly; use Utils.showGlobalAlert() instead
        showGlobalAlert(state: StateFrom<SliceAppContainer>, params: ShowGlobalAlertParams) {
            state.globalAlertTitle = params.title;
            state.globalAlertShownAsMessage = false;
            state.globalAlertMessage = params.message;
            state.globalAlertSeverity = params.severity;
            // EM: trebuie revizuit acest cod, in alta limba nu o sa fie acelasi text;
            // nu ar trebui facuta parsare de text, ar trebui transmis un parametru
            if (params.message?.includes("Permission needed but not granted:")) {
                state.globalAlertShownAsMessage = true;
                state.globalAlertTitle = _msg("error.insufficient.rights.title");
                // it's ok to use ! in this case, since if the test contains "Permission .." then it should also contain the permission
                const permission = /Permission needed but not granted: ([_A-Za-z0-9|, ]*)/.exec(params.message)![1];
                state.globalAlertMessage = _msg("error.insufficient.rights.details", permission);
            }
        }
    }

    impures = {
        ...getBaseImpures<SliceAppContainer>(this),

        hasPermission(permission: string, permissions?: any): boolean {
            return Utils.hasPermission(permission, permissions ? permissions : this.getState().initializationsForClient?.currentPermissions);
        },

        async loadMenuEntries(entityName: string) { // can be used only for Dashboard or Chart (note: Chart isn't in foundation yet)
            const operationName = _.lowerFirst(entityName) + "Service_findByFilter";
            const params = FindByFilterParams.create().filter(Filter.create("showInMenu", FilterOperators.forBoolean.equals, "TRUE")).sorts([{ field: "positionInMenu", direction: "ASC" }]);
            const query = gql(`query q($params: FindByFilterParamsInput) { 
                ${operationName}(params: $params) {
                    results {
                        id, name, icon, color
                    }
                }
            }`);
            const menuEntries = (await apolloClient.query({ query: query, variables: params, context: { showSpinner: false } })).data[operationName].results;
            this.getDispatchers().setInReduxState({ menuEntries: { ...this.getState().menuEntries, ...{ [entityName]: menuEntries } } });
        }
    }
})

export type AppContainerProps = PropsFrom<typeof sliceAppContainer> & { onSoftReload: () => void; [key: string]: any };

export class AppContainer extends React.Component<AppContainerProps>{

    protected refTreeMenu = React.createRef<TreeMenu>();
    protected refTreeMenuSearchWrapper = React.createRef<WrapperWithRenderFunction>();

    constructor(props: AppContainerProps) {
        super(props);
       
        // commented because the FBA can get the option from settings and here it isn't yet loaded
        // w/o the if some additional changes will be seen in state, but they aren't used because of the other if in render()
        // if (AppMetaTempGlobals.appMetaInstance.enableModalRoutes()) {
        this.onLocationChanged = this.onLocationChanged.bind(this);
        // CS: I tried w/ props.location; I saw this field working in other places; but here not; for lack of time I turned to ...TempGlobals
        if (AppMetaTempGlobals.appMetaInstance.canCallOnLocationChangedFromAppContainerConstructor()) {
            this.onLocationChanged(AppMetaTempGlobals.history.location);
        }
        AppMetaTempGlobals.history.listen(this.onLocationChanged);
        // }

        this.renderOrganizationTreeItem = this.renderOrganizationTreeItem.bind(this);
        this.renderOrganizationTree = this.renderOrganizationTree.bind(this);
    }

    componentDidUpdate(prevProps: AppContainerProps) {
        this.componentDidUpdateInternal(prevProps);
    }

    componentDidUpdateInternal(prevProps?: AppContainerProps) {
        if (AppMetaTempGlobals.appMetaInstance.showSearchBarOnTitleBar && (!prevProps || prevProps.drawerOpen !== this.props.drawerOpen) && !this.props.drawerOpen) {
            this.props.dispatchers.setInReduxState({ searchBarHasFocus: false });
            this.refTreeMenu.current!.modifySearchExpression("");
        }
        if (!prevProps || prevProps.currentOrganizationToFilterBy !== this.props.currentOrganizationToFilterBy) {
            // select in tree
            var currentItem = this.props.currentOrganizationToFilterBy;
            let itemID = currentItem ? undefined : SliceOrganizationTree.ORGANIZATION_TREE_ROOT_ID;
            while (currentItem) {
                itemID = currentItem.id + (itemID ? Utils.defaultIdSeparator + itemID : "");
                currentItem = global.organizations?.find(p => p.id === currentItem?.parent?.id);
            }
            if (itemID !== undefined && this.props.dispatchers.organizationsTree.getState().selectedId !== itemID) {
                this.props.dispatchers.organizationsTree.reveal({ ids: [itemID], collapseOthers: false, expandIds: true });
                this.props.dispatchers.organizationsTree.selectItem(itemID);
            }
            // store in session
            window.sessionStorage.setItem(sessionGlobalCurrentOrganizationToFilterBy, this.props.currentOrganizationToFilterBy?.id || "");
        }
        if (!this.props.currentOrganizationToFilterBy && this.props.currentOrganization && this.props.organizationsTree.root?.children?.length === 1) {
            global.currentOrganizationToFilterBy = this.props.currentOrganization;
        }
    }

    /**
     * In some cases there is no entityDescriptor for routeEntityName (e.g. CurrentUser).
     * This method can be overwritten when necessary.
     */
    public createLocationFromRouteEntityName(routeEntityName: string) {
        /**
          * Suntem aici pt ca s-a cerut ruta de editor direct in browser. Deci a comandat si "pe fundal"
          * tabelul. Noi tr sa transmitem in state-ul rutei treaba asta. Pentru ca tabelul cand isi face logica
          * de redirect (atat redirectul imediat cat si cel in urma sosirii dashb) sa transmita la randul lui
          * in acea "location" catre care se face redirect o info supl: dontCloseDrawer. Care o vom trata aici. In cazul de fata in 
          * background. Mai precis informatia e "ba, mergi pe ruta asta in slotul de tabel si LASA editorul ON"
          */
        return createLocation(entityDescriptors[routeEntityName].getEntityTableUrl(), { dontCloseDrawer: true });
    }

    protected onLocationChanged(location: Location<any>) {
        const props = this.props;
        let foundRoute: Optional<ReactElement> = undefined;
        for (const child of React.Children.toArray(AppMetaTempGlobals.appMetaInstance.routes)) {
            const route: ReactElement = child as any;
            if (!route.props.path) {
                throw new Error("Current element doesn't seem to be a <Route .../>: " + Utils.consoleLogJson(route));
            }
            if (matchPath(location.pathname, route.props)) {
                foundRoute = route;
                break;
            }
        }

        if (foundRoute?.props["routeIsModal"]) {
            if (foundRoute.props["routeEntityName"]
                // e.g. A) displaying as main the table page for employees; and I want to display the editor for THAT entity type 
                && (foundRoute.props["routeEntityName"] === props.dispatchers.getState().locationForMainRouteEntityName
                    // B) or the initial display of an editor
                    || !props.dispatchers.getState().locationForMain)) {
                // => show it as drawer
                const newState: Partial<PropsFrom<typeof sliceAppContainer>> = { locationForDrawer: location, locationsForModals: [] /* cancel any other modals, if they exist */ };

                if (!props.dispatchers.getState().locationForMain) {
                    // B) locationForMain not def => entered on our site directly w/ an editor URL
                    // in this case, let's also open "underneath" the corresponding table page
                    const routeEntityName = foundRoute.props["routeEntityName"];
                    newState.locationForMain = this.createLocationFromRouteEntityName(routeEntityName);
                    newState.locationForMainRouteEntityName = routeEntityName;
                }
                props.dispatchers.setInReduxState(newState);
            } else {
                // a normal (non-editor) modal, or a editor modal and: 1) main is not a table page or 2) is a table page, but for another entity
                // so add a new modal in the stack

                // DISCUSSION: the feature was intended to allow a stack of modals; however w/o a lot of effort and checks, this is the case "better is the enemy of good"
                // #1 Conceptually. Should we check to see if route.pathname of last modal == crt one? However, in practice, is difficult to reproduce. Should have a link from own editor in editor.
                // #2 The above case has a more pragmatic side effect: 2 slices w/ same name. Possible solution: per route in stack, specify the entityName. BUT we may also
                // have the case of normal modal; w/o entity, but w/ a connected component. In this case => same error
                // SO for the moment, the stacking is disabled, and we allow only one modal
                props.dispatchers.setInReduxState({ locationsForModals: [/*...props.dispatchers.getState().locationsForModals,*/ location] });
            }
        } else {
            // dontCloseDrawer = true => entered on our site directly w/ an editor URL, 
            // we need to keep main open on background BUT also display the drawer + the route must point to the drawer location
            const dontCloseDrower = location.state?.dontCloseDrawer && props.dispatchers.getState().locationForDrawer;
            props.dispatchers.setInReduxState({
                locationForMain: location,
                locationForMainRouteEntityName: foundRoute?.props["routeEntityName"],
                locationForDrawer: dontCloseDrower ? props.dispatchers.getState().locationForDrawer : undefined,
                locationsForModals: []
            });
            if (dontCloseDrower) {
                props.dispatchers.dispatch(push({ pathname: props.dispatchers.getState().locationForDrawer!.pathname, state: props.dispatchers.getState().locationForDrawer?.state }));
            }
        }
    }

    /**
     * Meant to be overriden
     */
    renderAdditionalContent(): JSX.Element | null {
        return null;
    }

    protected getHeaderClassName() {
        return "";
    }

    renderLocationsModals(props: any) {
        return <>{props.locationsForModals.map((location: any) => (<ModalExt open={true} closeOnDimmerClick={true} closeIcon={true}
            onClose={() => { props.dispatchers.setInReduxState({ locationsForModals: props.locationsForModals.slice(0, -1) }) }}>
            <Modal.Content className="AppModal_content">
                <Switch location={location}>
                    {AppMetaTempGlobals.appMetaInstance.routes}
                </Switch>
            </Modal.Content>
        </ModalExt>))}</>
    }

    protected renderOrganizationTreeItem({ props, linearizedItem }: RenderItemParams) {
        const currentOrg = this.props.initializationsForClient.currentOrganization;
        const itemId = Utils.substringAfter(linearizedItem.itemId, Utils.defaultIdSeparator, true);
        const object = itemId === SliceOrganizationTree.ORGANIZATION_TREE_ROOT_ID ? props.root : global.organizations?.find(o => "" + o.id === itemId) as Organization;
        const label = itemId === SliceOrganizationTree.ORGANIZATION_TREE_ROOT_ID ? (currentOrg ? currentOrg.name : _msg("general.all")) : object?.name;
        return <div className="flex-container flex-center">
            {AppMetaTempGlobals.appMetaInstance.getOrganizationTreeIcon(itemId, object, currentOrg)}
            <span className="OrganizationTreeLeftArea_text">
                {label}
            </span>

        </div>;
    }

    private getOrganizationTreeItemStyleProps({ props, linearizedItem }: RenderItemParams) {
        const ids = linearizedItem.itemId.split(Utils.defaultIdSeparator);
        const style = {};
        Object.assign(style, {
            marginLeft: ids.length > 1 ? (ids.length - 1) * 10 + "px" : undefined,
            backgroundColor: 'rgba(255, 255, 255, 0.25)',
            flexDirection: "column", borderRadius: '2px 0px 0px 2px',
            marginBottom: "1px", padding: '4px', fontSize: '7pt'
        });
        if (linearizedItem.expanded === Expanded.EXPANDED) {
            Object.assign(style, {
                backgroundColor: 'rgba(255, 255, 255, 0.5)'
            });
        }
        return style;
    }

    protected renderOrganizationTree() {
        const { props } = this;
        return <div className="flex-container OrganizationTreeLeftArea" style={{ overflowX: "hidden" }}>
            <Tree {...props.organizationsTree} dispatchers={props.dispatchers.organizationsTree}
                renderItemFunction={this.renderOrganizationTreeItem}
                styleItemWrapperFunction={this.getOrganizationTreeItemStyleProps}
                onSelectItem={(params: OnSelectParams) => {
                    const itemId = Utils.substringAfter(params.itemId, Utils.defaultIdSeparator, true);
                    if (itemId === SliceOrganizationTree.ORGANIZATION_TREE_ROOT_ID) {
                        global.currentOrganizationToFilterBy = null;
                    } else {
                        global.currentOrganizationToFilterBy = global.organizations!.filter(o => "" + o.id === itemId)?.[0];
                    }

                }} />
        </div>
    }

    protected renderHeaderLogo(settings: VisualStyleSettings) {
        let headerLogoStyle = settings.whiteBackgroundHeaderLogo ? " HomePage_headerLogo_whiteBackground HomePage_headerLogo_image" : "";
        return AppMetaTempGlobals.appMetaInstance.showHeaderLogo
            ? (settings.headerLogo ?
                (<Menu.Item className={"AppHelper_menu less-padding" + headerLogoStyle} header as={Link} to="/"><img src={Utils.adjustUrlToServerContext(settings.headerLogo)} alt='headerLogo' /></Menu.Item>) :
                (<Menu.Item header as={Link} to="/"><Header as="h4">{AppMetaTempGlobals.appMetaInstance.getAppTitle(settings)}</Header></Menu.Item>))
            : null;
    }

    protected renderHomeButton() {
        return <Menu.Item icon="home" as={Link} to="/" />;
    }

    render() {
        return <>
            <Dimmer data-testid="AppMeta_dimmer" active={this.props.showSpinner} page={true}><Loader size='large'>
                <Header as="h3" inverted>{this.props.spinnerText ? this.props.spinnerText : _msg("general.loading")}</Header>
                {this.props.showSpinnerCloseButton
                    ? <>
                        <Button data-testid={"spinner-close"} negative size="tiny" icon="delete" onClick={() => this.props.dispatchers.setInReduxState({ spinnerCloseConfirmationModalOpen: true })} />
                        <ModalExt size="mini" open={this.props.spinnerCloseConfirmationModalOpen} closeIcon={true} onClose={() => this.props.dispatchers.setInReduxState({ spinnerCloseConfirmationModalOpen: false })}>
                            <Modal.Content>{_msg("AppHelper.abortGraphQLConfirmation")}</Modal.Content>
                            <Modal.Actions>
                                <Button data-testid={"spinner-abort-yes"} onClick={() => this.props.dispatchers.setInReduxState({ spinnerCloseConfirmationModalOpen: false, showSpinner: false })}>{_msg("general.yes")}</Button>
                                <Button data-testid={"spinner-abort-no"} onClick={() => this.props.dispatchers.setInReduxState({ spinnerCloseConfirmationModalOpen: false })}>{_msg("general.no")}</Button>
                            </Modal.Actions>
                        </ModalExt>
                    </>
                    : null}
            </Loader></Dimmer>
            {this.renderMain()}
        </>
    }

    renderMain() {
        const props = this.props;
        let alert: Optional<ReactElement> = undefined;
        if (props.globalAlertMessage) {
            if (props.globalAlertShownAsMessage) {
                alert = <Redirect to={{ pathname: '/error', state: { headerMessage: props.globalAlertTitle, errorMessage: props.globalAlertMessage } }} />
            } else {
                alert = <ModalExt severity={props.globalAlertSeverity}
                    open={props.globalAlertMessage !== undefined}
                    onClose={() => props.dispatchers.setInReduxState({ globalAlertMessage: undefined })}>
                    <Modal.Header data-testid="AppMeta_globalAlertHeader"><Interweave content={props.globalAlertTitle ? props.globalAlertTitle : _msg("general.info")} /></Modal.Header>
                    <Modal.Content>
                        <Modal.Description data-testid="AppMeta_globalAlertContent">
                            {/* {props.globalAlertMessage.split("\n").map((item, i) => { return <p key={i}>{item}</p> })} */}
                            <Interweave content={props.globalAlertMessage} />
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button data-testid="AppMeta_globalAlertOk" onClick={() => props.dispatchers.showGlobalAlert({ message: undefined })} primary>{_msg("general.ok")}</Button>
                    </Modal.Actions>
                </ModalExt>
            }
        }
        if (!props.initializationsForClientLoaded) {
            return (<div className="HomePage" data-testid="AppMeta_spinner">
                {props.avoidDisplayingInitializationsLoadingSpinner ? null :
                    <Container>
                        <Message floating>
                            <Header as="h4" color="grey" textAlign="center" icon>
                                <Icon name='spinner' loading />
                                <Header.Content>{_msg("AppHelper.loadingInitializationsForClient")}</Header.Content>
                            </Header>
                        </Message>
                    </Container>}
                {alert}
                {this.renderAdditionalContent()}
            </div>);
        }

        let left: ReactNode;
        let right: ReactNode;

        // TODO by CS: ? for prot, which doesn't have visualStyleSettings for the moment; remove when the case will be
        const appTitle = props.initializationsForClient.visualStyleSettings?.appTitle;

        const user = props.initializationsForClient.currentUser;
        const currentPath = AppMetaTempGlobals.history.location.pathname;
        const specialPageWithoutHeader: boolean = currentPath.startsWith("/passwordResetFinish");
        if (user && !specialPageWithoutHeader) {
            const organization = props.initializationsForClient.currentOrganization;
            const allOrganizationsAccess = props.initializationsForClient.allOrganizationsAccess;
            const settings = props.initializationsForClient.visualStyleSettings;
            let headerLogo: string | undefined = undefined;
            let headerLogoRight: boolean | undefined = undefined;
            let clientHeaderLogo: string | undefined = undefined;
            let clientHeaderLogoStyle: string | undefined = undefined;
            if (settings) {
                headerLogo = settings.headerLogo;
                headerLogoRight = settings.headerLogoRight;
                clientHeaderLogo = settings.clientHeaderLogo;
                clientHeaderLogoStyle = settings.whiteBackgroundClientHeaderLogo ? " HomePage_headerLogo_whiteBackground HomePage_headerLogo_image" : "";
            }
            left = (<Menu key="left">
                {AppMetaTempGlobals.appMetaInstance.showBackButton ? (<Menu.Item key="back" icon="arrow left" onClick={() => AppMetaTempGlobals.appMetaInstance.goBack()} />) : null}
                {AppMetaTempGlobals.appMetaInstance.showReloadButton ? (<Menu.Item key="reload" icon="redo" onClick={() => { window.sessionStorage.setItem(sessionGlobalReload, "true"); window.location.reload()} } />) : null}
                {!headerLogoRight ? this.renderHeaderLogo(settings) : null}
                {AppMetaTempGlobals.appMetaInstance.showHeaderLogo && clientHeaderLogo ? (<Menu.Item key="clientHeaderLogo" className={"AppHelper_menu less-padding" + clientHeaderLogoStyle} header as={Link} to="/"><img className="margin-auto" src={Utils.adjustUrlToServerContext(clientHeaderLogo)} alt='clientHeaderLogo' /></Menu.Item>) : null}
                {/* If the logo is on the left, show home button first. Else, show menu button first.  */}
                {AppMetaTempGlobals.appMetaInstance.showHomeButton && !headerLogoRight ? this.renderHomeButton() : null}
                <Menu.Item key="menu" icon="grid layout" as="a" onClick={() => this.props.dispatchers.setInReduxState({ drawerOpen: true })} />
                {AppMetaTempGlobals.appMetaInstance.showHomeButton && headerLogoRight ? this.renderHomeButton() : null}
            </Menu>);

            const myAccountMenuEntry: any[] = [];
            if (AppMetaTempGlobals.appMetaInstance.showMyAccountMenuEntry) {
                myAccountMenuEntry.push({ key: 'myAccount', text: _msg("login.myAccount"), icon: 'user', onClick: () => this.props.dispatchers.setInReduxState({ currentUserModalOpen: true }) });
            }
            right = (<Menu key="right">
                {AppMetaTempGlobals.appMetaInstance.showClock ? <span className='item very-small-padding'><Clock format={Utils.timeWithSecFormat} /></span> : null}
                {AppMetaTempGlobals.appMetaInstance.showTimeZone ?
                    <span data-tooltip={moment.tz.guess()} data-position="left center">
                        <span className='item'>{"GMT" + (Utils.LOCAL_TIMEZONE_OFFSET === 0 ? "" : (Utils.LOCAL_TIMEZONE_OFFSET > 0 ? "+" : "") + Utils.LOCAL_TIMEZONE_OFFSET.toString())}</span>
                    </span>
                    : null}
                {AppMetaTempGlobals.appMetaInstance.showNotifications ?
                    <NotificationComponent {...props.notificationComponent} dispatchers={props.dispatchers.notificationComponent} />
                    : null}
                {AppMetaTempGlobals.appMetaInstance.showOrganization ?
                    <span data-tooltip={_msg("Organization.currentOrganization")} data-position="left center">
                        <OrganizationManyToOneEditorStandalone value={organization} userId={user ? user.id : undefined} allOrganizationsAccess={allOrganizationsAccess} onChange={async (o: any) => {
                            const mutation = gql(`mutation q ($organization: String) {
                                authService_setOrganization(organization: $organization)
                            }`);
                            await apolloClient.mutate({ mutation: mutation, variables: { organization: o ? o.qualifiedName : null } });
                            
                            this.props.onSoftReload();
                        }} />
                    </span>
                    : null}
                <Dropdown pointing="top right" className='link item less-padding' trigger={AppMetaTempGlobals.appMetaInstance.getUserInfo(user)} options={[
                    {
                        key: 'user',
                        text: (
                            <span>
                                {_msg("login.signedInAs", user?.firstName || user?.lastName ? user?.lastName + " " + user?.firstName : user?.username)}
                            </span>
                        ),
                        disabled: true,
                    },
                    ...myAccountMenuEntry,
                    ...AppMetaTempGlobals.appMetaInstance.getAdditionalUserMenuEntries(),
                    { key: 'log-out', text: _msg("login.logout"), icon: 'log out', onClick: e => AppMetaTempGlobals.appMetaInstance.logout() }
                ]}
                />
                {AppMetaTempGlobals.appMetaInstance.getAdditionalItemsForRightMenu()}
                {headerLogoRight ? this.renderHeaderLogo(settings) : null}
            </Menu>);
        }
        const headerClassName = this.getHeaderClassName();
        return (<>
            <ContainerWithHeader childTitleAreaLeft={left} childTitleAreaRight={right} titleAreaClassName={AppMetaTempGlobals.appMetaInstance.showSearchBarOnTitleBar ? "flex-justify-content-center" : undefined}
                displayChildrenInRow={props.initializationsForClient.currentUser !== undefined && props.initializationsForClient.organizationSettings?.globalOrganizationFilter === OrganizationFilter.LEFT_SIDE}
                headerClassName={headerClassName}>
                {AppMetaTempGlobals.appMetaInstance.showHorizontalMenu && user && !specialPageWithoutHeader ?
                    <ContainerWithHeaderContext.Consumer>
                        {value => {
                            if (value.titleAreaDiv) {
                                value.titleAreaDivAlreadyFilledIn = true;
                                return ReactDOM.createPortal(React.createElement(HorizontalMenu, {
                                    menuEntries: AppMetaTempGlobals.appMetaInstance.menuEntries
                                }), value.titleAreaDiv);
                            }
                            else { return null; }
                        }}
                    </ContainerWithHeaderContext.Consumer> : null}
                {AppMetaTempGlobals.appMetaInstance.showSearchBarOnTitleBar && user && !specialPageWithoutHeader ?
                    <ContainerWithHeaderContext.Consumer>
                        {value => {
                            if (value.titleAreaDiv) {
                                value.titleAreaDivAlreadyFilledIn = true;
                                return ReactDOM.createPortal(<WrapperWithRenderFunction ref={this.refTreeMenuSearchWrapper} render={() =>
                                    <TreeMenuSearch value={this.refTreeMenu.current?.getSearchExpression()}
                                        style={{ maxWidth: '350px', width: '100%' }} focus={this.props.searchBarHasFocus}
                                        onChange={(value: any) => this.refTreeMenu.current?.modifySearchExpression(value)}
                                        onFocus={(value: any) => this.props.dispatchers.setInReduxState({ searchBarHasFocus: value, drawerOpen: value })}
                                    />} />, value.titleAreaDiv);
                            }
                            else { return null; }
                        }}
                    </ContainerWithHeaderContext.Consumer> : null}
                <Drawer destroyOnClose className="AppDrawer"
                    title={<div className="flex-container">{appTitle}{AppMetaTempGlobals.appMetaInstance.getVersionInfo(this.props.initializationsForClient.version)}</div>} placement="left" visible={this.props.drawerOpen} mask={!this.props.searchBarHasFocus}
                    onClose={() => this.props.dispatchers.setInReduxState({ drawerOpen: false })}
                    afterVisibleChange={visible => !visible}>
                    <TreeMenuRRC id="treeMenu" ref={this.refTreeMenu} root={AppMetaTempGlobals.appMetaInstance.menuEntries}
                        showSearchBar={!this.props.searchBarHasFocus} multiSearch={true}
                        onMenuItemClick={(isComposed: any) => !isComposed && this.props.dispatchers.setInReduxState({ drawerOpen: false })}
                        onSearchExpressionModified={() => this.refTreeMenuSearchWrapper.current?.forceUpdate()} />
                </Drawer>
                {props.initializationsForClient.currentUser && props.initializationsForClient.organizationSettings?.globalOrganizationFilter === OrganizationFilter.LEFT_SIDE
                    && props.organizationsTree.root && !(props.organizationsTree.root.children?.length == 1 && Utils.isNullOrEmpty(props.organizationsTree.root.children[0].children)) ? this.renderOrganizationTree() : null}
                <Suspense fallback={<div>Loading...</div>} >
                    {AppMetaTempGlobals.appMetaInstance.enableModalRoutes()
                        ? <>
                            {props.locationForMain && <Switch location={props.locationForMain}>
                                {AppMetaTempGlobals.appMetaInstance.routes}
                            </Switch>}
                            {props.locationForDrawer && <Drawer className="RouteDrawer" visible width={"80%"} closeIcon={<Button data-testid="Drawer_closeBtn" className="less-padding" icon="close" circular />}
                                onClose={() => {
                                    props.dispatchers.setInReduxState({ locationForDrawer: undefined });
                                    props.locationForMain && props.dispatchers.dispatch(push(props.locationForMain?.pathname));
                                }}>
                                <Switch location={props.locationForDrawer}>
                                    {AppMetaTempGlobals.appMetaInstance.routes}
                                </Switch>
                            </Drawer>}
                            {this.renderLocationsModals(props)}
                        </>
                        : <Switch>
                            {AppMetaTempGlobals.appMetaInstance.routes}
                        </Switch>
                    }
                </Suspense>
                {alert}
                {this.renderAdditionalContent()}
            </ContainerWithHeader>
            <ModalExt open={props.currentUserModalOpen} onClose={() => props.dispatchers.setInReduxState({ currentUserModalOpen: false })}>
                <Modal.Header>{_msg("login.myAccount")}</Modal.Header>
                <Modal.Content><ConnectedComponentInSimpleComponent info={infoCurrentUserEditorPage} closeModal={() => props.dispatchers.setInReduxState({ currentUserModalOpen: false })} /></Modal.Content>
            </ModalExt>
        </>);
    }
}

export const APP_CONTAINER = "AppContainer";
export const infoAppContainer = new ConnectedPageInfo(sliceAppContainer, AppContainer, APP_CONTAINER);