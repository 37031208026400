import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import moment from "moment";
import React from "react";
import { GanttResources, GanttResourcesRRC } from "./GanttResources";
import { GanttTasks, GanttTasksRRC } from "./GanttTasks";

class GanttDoubleState extends State {
    entities: { [entityName: string]: { [id: number]: any } } = {};
    hideResources: { [key: string]: number[] } = {};
}

class GanttDoublePageReducers<S extends GanttDoubleState = GanttDoubleState> extends Reducers<S> {

}

type Props = RRCProps<GanttDoubleState, GanttDoublePageReducers> & { flightsDate?: string, portalContainerForTopBar?: any };

export class GanttDoublePage extends React.Component<Props> {

    protected ganttResourcesRef = React.createRef<GanttResources>();
    protected ganttTasksRef = React.createRef<GanttTasks>();

    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: Props) {
        this.componentDidUpdateInternal(prevProps);
    }

    protected async componentDidUpdateInternal(prevProps?: Props) {
        if (!prevProps || prevProps.flightsDate !== this.props.flightsDate) {
            this.ganttResourcesRef.current!.changeStartEnd(moment(this.props.flightsDate).startOf("day").valueOf(), moment(this.props.flightsDate).endOf("day").valueOf());
            this.ganttTasksRef.current!.changeStartEnd(moment(this.props.flightsDate).startOf("day").valueOf(), moment(this.props.flightsDate).endOf("day").valueOf());
        }
    }

    render() {
        return <SplitPaneExt defaultSize="50%">
            <GanttTasksRRC id="GanttDoublePage_GanttTasks" ref={this.ganttTasksRef}
                entities={this.props.s.entities} hideResources={this.props.s.hideResources} hideTopBar />
            <GanttResourcesRRC id="GanttDoublePage_GanttResources" ref={this.ganttResourcesRef} portalContainerForTopBar={this.props.portalContainerForTopBar}
                entities={this.props.s.entities} hideResources={this.props.s.hideResources} hideTopBar />
        </SplitPaneExt>;
    }
}

export const GanttDoublePageRRC = ReduxReusableComponents.connectRRC(GanttDoubleState, GanttDoublePageReducers, GanttDoublePage);
