import { FilterOperators } from "@crispico/foundation-gwt-js";
import { apolloClient, createSliceFoundation, FieldDescriptor, getBaseImpures, getBaseReducers, Optional, PHONE_MODE_KEY, PropsFrom, StateFrom, Utils } from "@crispico/foundation-react";
import { CustomQueryBar, CUSTOM_QUERY_BAR_MODE, sliceCustomQueryBar } from "@crispico/foundation-react/components/CustomQuery/CustomQueryBar";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { Sort } from "@crispico/foundation-react/components/CustomQuery/SortBar";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { Drawer } from "antd";
import { AirportForMap } from "apollo-gen/AirportForMap";
import { Pair_Double_DoubleInput } from "apollo-gen/globalTypes";
import { loadAirportsForMap, loadAirportsForMapVariables } from "apollo-gen/loadAirportsForMap";
import { MapSettings, MarkerSettings } from "app";
import { ID, Location, MapContainerLeafletRRC, MapContainerLeaflet, MarkerData, PolygonData, POLYGON_TYPE, SelectedLayer } from "components/MapContainerLeaflet/MapContainerLeaflet";
import { DEFAULT_ZOOM_LEVEL } from "components/MapContainerLeaflet/MapLayerHelpers";
import { LOAD_AIRPORTS_FOR_MAP,  LOAD_TERRITORIES_FOR_REAL_TIME_MAP } from "components/realTimeMap/queries";
import { push } from "connected-react-router";
import lodash from 'lodash';
import moment from "moment";
import { airportEntityDescriptor, equipmentResourceEntityDescriptor } from "pages/EquipmentResource/equipmentResourceEntityDescriptor";
import { EquipmentResourceBigInfoArea } from "pages/EquipmentResource/EquipmentResourceUtils";
import React from "react";
import { Button, Dropdown, DropdownProps, Icon, Popup, Segment, Label, Divider, Checkbox } from "semantic-ui-react";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import { v4 as uuid } from 'uuid';
import { entityDescriptors, getOrganizationFilter } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import Measure from "react-measure";
import { List as ListVirtualized, ListRowProps } from 'react-virtualized';
import { TerritoryForMap } from "apollo-gen/TerritoryForMap";
import { territoryEntityDescriptor } from "pages/Territory/territoryEntityDescriptor";
import { FieldInterval } from "@crispico/foundation-react/entity_crud/CrudSettings";
import { getDropdownItemLabel } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/DropdownFieldRenderer";
import { ColorRegistry } from "@crispico/foundation-react/utils/ColorRegistry";
import { QueryDetails, RealTimeMapEntityTypeFactories } from "./RealTimeMapEntityTypeFactory";
import { mobileDeviceEntityDescriptor } from "pages/MobileDevice/mobileDeviceEntityDescriptor";
import { RealTimeUtils } from "./RealTimeUtils";
import { AssociationFieldEditor } from "@crispico/foundation-react/entity_crud/AssociationFieldEditor";
import _ from "lodash";

export const MAP_CLUSTER_MODE_KEY = 'map.clusterMode';
export enum CLUSTER_MODE { PRUNE_CLUSTER = 'pruneCluster', CLASSIC_CLUSTER = 'classicCluster' };

export const refreshRateMillis: number = 2 * 1000;
export const sliceRealTimeMap = createSliceFoundation(class SliceRealTimeMap {

    initialState = {
        entitiesList: {} as {[key: string]: any[]},
        entitiesToDisplayIds: {} as {[key: string]: any[]},
        mostRecentEntityUpdates: {} as {[key: string]: string},
        mostRecentUpdatedMapBounds: undefined as Optional<Pair_Double_DoubleInput[]>,
        entityToSelectAfterUpdates: {} as {[key: string]: any},
        selectedEntityType: "EquipmentResource" as string,
        airports: [] as AirportForMap[],
        territoriesForMap: [] as TerritoryForMap[],
        loadUUID: undefined as Optional<string>,
        filterBarCheckboxes: {} as {[key: string]: boolean},
    }

    nestedSlices = {
        customQueryBar: sliceCustomQueryBar,
    }

    reducers = {
        ...getBaseReducers<SliceRealTimeMap>(this),

        updateEntities(state: StateFrom<SliceRealTimeMap>, updatedEntities: any[]) {
            if (!state.entitiesList[state.selectedEntityType]){
                state.entitiesList[state.selectedEntityType] = updatedEntities;
            }

            // used to optimize Array.findIndex, don't remove this, without it, for 10000 equipments, time will increase with ~10 sec
            const eqMapIdIndex: {[key: number]: number} = {};
            state.entitiesList[state.selectedEntityType].forEach((eq, index) => eqMapIdIndex[eq.id] = index );
            updatedEntities.forEach(updatedEq => {
                const index = eqMapIdIndex[updatedEq.id];
                if (index !== null && index !== undefined) {
                    state.entitiesList[state.selectedEntityType][index] = updatedEq;
                } else {
                    state.entitiesList[state.selectedEntityType].push(updatedEq);
                }
            });
        },

        changeFilterBar(state: StateFrom<SliceRealTimeMap>, checkbox: string) {
            state.filterBarCheckboxes[checkbox] = !state.filterBarCheckboxes[checkbox];
        },

        updateEntityToSelectAfterUpdates(state: StateFrom<SliceRealTimeMap>, value: any){
            state.entityToSelectAfterUpdates[state.selectedEntityType] = value
        },

        updateMostRecentEntityUpdates(state: StateFrom<SliceRealTimeMap>, value: any){
            state.mostRecentEntityUpdates[state.selectedEntityType] = value
        },

        updateEntitiesToDisplayIds(state: StateFrom<SliceRealTimeMap>, entitiesIds: any[]) {
            state.entitiesToDisplayIds[state.selectedEntityType] = entitiesIds;
        }
    }

    impures = {
        ...getBaseImpures<SliceRealTimeMap>(this),

        async loadAirports(mapSettings: MapSettings) {
            const airports: Optional<AirportForMap[]> = (await apolloClient.query<loadAirportsForMap, loadAirportsForMapVariables>({
                query: LOAD_AIRPORTS_FOR_MAP,
                variables: FindByFilterParams.create().sorts([{ field: "code", direction: "ASC" }])
            })).data.airportService_findByFilter?.results as Optional<AirportForMap[]>;

            this.getDispatchers().setInReduxState({ airports: airports || [] });
        },

        async loadEntities(tableFilter: Filter | undefined, tableSorts: Sort[], mapContainer: MapContainerLeaflet | undefined, mapMarkSettings: MapSettings, bounds?: Pair_Double_DoubleInput[]) {    
            const selectedEntityType = this.getState().selectedEntityType;
            if (!mapContainer?.areBoundsSmallerThen(this.getState().mostRecentUpdatedMapBounds, 0.001)) {
                // an offset was added so no changes to non-unpdated markers if the map center is modified a little
                // useful when searching & selecting ERs in visible area -> they are centered, but the map bounds doesn't change so much
                this.getDispatchers().setInReduxState({ mostRecentUpdatedMapBounds: bounds });
                this.getDispatchers().updateMostRecentEntityUpdates(undefined);
            }

            const realTimeEntityFactory = RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[selectedEntityType];
            const filters: Filter[] = [];
            const sorts: Sort[] = Object.assign([], tableSorts);

            if (tableFilter) {
                filters.push(tableFilter);
            }
            
            const orgFilter = getOrganizationFilter(realTimeEntityFactory.getEd(), global.currentOrganizationToFilterBy);
            if (orgFilter) {
                filters.push(orgFilter);
            }

            const uuid: Optional<string> = this.getState().loadUUID;
            const customFields = Object.values(realTimeEntityFactory.getEd().fields).filter(x => x.isCustomField).map(x => x.name)

            const entityQueryDetails: QueryDetails = realTimeEntityFactory.getLoadEntitiesQueryDetails(mapMarkSettings, sorts);
            if (this.getState().mostRecentEntityUpdates![selectedEntityType]) {
                entityQueryDetails.filters.push(Filter.create(realTimeEntityFactory.getMostRecentEntityUpdatesField(),
                    FilterOperators.forDate.greaterThan, moment(this.getState().mostRecentEntityUpdates![selectedEntityType]).toISOString()));
            }
            
            const entities: any[] = (await apolloClient.query({
                query: entityQueryDetails.query,
                variables: { filter: Filter.createComposed(FilterOperators.forComposedFilter.and, filters.concat(entityQueryDetails.filters)), sorts: entityQueryDetails.sorts, coordinates: bounds, whichFields: customFields },
                context: { showSpinner: false }
            })).data[lodash.lowerFirst(realTimeEntityFactory.getEd().name) + "Service_findByRectangle"]?.results;
            
            if (uuid !== this.getState().loadUUID) {
                return;
            }

            if (entities.length > 0) {
                this.getDispatchers().updateEntities(entities);
                // get most recent date update from the newest entities 
                this.getDispatchers().updateMostRecentEntityUpdates(entities[0]);
                this.updateEntitiesOnMapAndInList(selectedEntityType, mapContainer, mapMarkSettings, sorts);
            } else if (realTimeEntityFactory.getMarkerSettings(mapMarkSettings)?.colors.filter(color => color.showInFilterBar && color.useDurationBetweenNowAndFieldValue)?.length) {
                // updateEntitiesOnMapAndInList accordingly to current moment if settings has these types of checkboxes
                // ex: no update for entities but just the '<15min' is checked, so need to apply updates to verify the condition with time
                this.updateEntitiesOnMapAndInList(selectedEntityType, mapContainer, mapMarkSettings, sorts);
            } 
        },

        updateEntitiesOnMapAndInList(selectedEntityType: string, mapContainer: MapContainerLeaflet | undefined, mapSettings: MapSettings, sorts: Sort[]) {
            let entitiesToAddOrUpdate: any[] = [];
            let entitiesToRemove: any[] = [];
            let entitiesToDisplayIds: any[] = [];

            // update entities for list
            if (Object.keys(this.getState().entitiesList).length > 0 && this.getState().entitiesList[selectedEntityType].length > 0) {
                entitiesToAddOrUpdate = RealTimeUtils.filterEntitiesUsingFilterBar(mapSettings, this.getState().filterBarCheckboxes, this.getState().entitiesList[selectedEntityType], selectedEntityType);
                // sort entities after filtering according to sortBar
                if (sorts.length > 0){
                    const properties: string[] = [];
                    const orders: ("asc" | "desc") [] = [];
                    sorts.forEach(sort => {
                        properties.push(sort.field);
                        orders.push(sort.direction == "ASC" ? "asc" : "desc");
                    });
                    
                    // for stopping position change in list, in case of equal to be ordered by id
                    properties.push("id");
                    orders.push("asc");
                    entitiesToAddOrUpdate = _.orderBy(entitiesToAddOrUpdate, properties, orders);
                }
                entitiesToDisplayIds = entitiesToAddOrUpdate.map(er => er.id);
                this.getDispatchers().updateEntitiesToDisplayIds(entitiesToDisplayIds);
            }

            // update entities for map if mapContainer exists
            if (!mapContainer) {
                return;
            }

            // should clear layers from map if no entity correspond with filter bar
            if (entitiesToDisplayIds.length > 0) {
                entitiesToRemove = this.getState().entitiesList[selectedEntityType].filter(entity => !entitiesToDisplayIds.includes(entity.id));
            } else {
                mapContainer.removeLayers(selectedEntityType);
                return;
            }

            if (!Utils.isNullOrEmpty(entitiesToRemove)) {
                mapContainer.removeLayers(selectedEntityType, entitiesToRemove!.map(entity => entity.id));
            }

            const markerSettings: Optional<MarkerSettings> = RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[selectedEntityType].getMarkerSettings(mapSettings);
            const updates: any[] = RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[selectedEntityType].getLayersForMap(entitiesToAddOrUpdate, markerSettings);
            if (updates.length > 0) {
                mapContainer.addOrUpdateLayers(updates, selectedEntityType, true);
            }

            if (this.getState().entityToSelectAfterUpdates && this.getState().entityToSelectAfterUpdates![selectedEntityType]) {
                mapContainer.props.r.setInReduxState({ selectedLayer: { id: this.getState().entityToSelectAfterUpdates![selectedEntityType]?.id, type: selectedEntityType } });
                this.getDispatchers().updateEntityToSelectAfterUpdates(undefined);
            }
        },

        selectEntity(item: Optional<{ id: any, type: string }>, mapContainer: MapContainerLeaflet) {
            mapContainer.props.r.setInReduxState({ selectedLayer: item ? { id: item.id, type: item.type } : undefined });
        },

        selectAirport(id: number, mapContainer: MapContainerLeaflet) {
            const airport: Optional<AirportForMap> = this.getState().airports.find(a => a.id === id);

            if (airport?.latitude && airport?.longitude) {
                mapContainer.props.r.setInReduxState({ zoom: DEFAULT_ZOOM_LEVEL, center: [airport.latitude, airport.longitude] });
            }
        },

        async getEntity(id: number, mapContainer: MapContainerLeaflet, mapSettings: MapSettings) {
            const selectedEntityType = this.getState().selectedEntityType;
            const realTimeEntityFactory = RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[selectedEntityType];
            const entity: Optional<any> = (await apolloClient.query({
                query: realTimeEntityFactory.getFindEntityQueryDetails(mapSettings).query,
                variables: { id: id }
            })).data[lodash.lowerFirst(realTimeEntityFactory.getEd().name) + "Service_findById"];

            if (!entity){
                return;
            }

            const point: Optional<Location> = realTimeEntityFactory.getEntityPoint(entity);
            if (!point) {
                return;
            }

            mapContainer.props.r.setInReduxState({ center: [point.latitude, point.longitude] });
            if (MapContainerLeaflet.pointInsideBounds(this.getState().mostRecentUpdatedMapBounds, point)) {
                // if marker is visible, select directly              
                mapContainer.props.r.setInReduxState({ selectedLayer: { id: entity.id, type: selectedEntityType } });
            } else { // marker not visible, wait until the update is done
                this.getDispatchers().updateEntityToSelectAfterUpdates(id);
            }
        },

        async loadTerritoriesForMap(currentBounds?: Pair_Double_DoubleInput[]) {  
            const territories: Optional<TerritoryForMap[]> = (await apolloClient.query({
                query: LOAD_TERRITORIES_FOR_REAL_TIME_MAP,
                variables: {coordinates: currentBounds},
                context: { showSpinner: false }
            })).data.territoryService_findByRectangle?.results;
            
            if (territories && territories.length > 0){
                this.getDispatchers().setInReduxState({ territoriesForMap: territories });
            }    
        },

        addTerritoriesOnMap(mapContainer: Optional<MapContainerLeaflet>) {
            let polygons: PolygonData[] = [];
            this.getState().territoriesForMap?.forEach((territory: TerritoryForMap) => {
                const points: Location[] = [];
                territory.coordinates?.forEach(p => {
                    points.push({ longitude: p.a, latitude: p.b });
                });
                polygons.push({ id: territory.id, points: points, color: territory.color || undefined, text: territory.name || undefined});
            });
            mapContainer?.addOrUpdateLayers(polygons, territoryEntityDescriptor.name, true);
        },
    }

}, true)

type PropsNotFromState = {
    mapSettings: MapSettings,
    entityTypes?: string[],
    rootFilter?: Filter,
    rootSort?: Sort[],
    showCustomQueryBar?: boolean,
    showGoToTableButton?: boolean,
    airport?: any,
    mapId?: string
};

type RealTimeMapLocalState = { drawerOpen: boolean, isFindEntityPopupOpen: boolean, isGoToAirportPopupOpen: boolean, showTerritories: boolean, measuredWidth: number, measuredHeight: number };
export type Props = PropsFrom<typeof sliceRealTimeMap> & PropsNotFromState;
export class RealTimeMap extends React.Component<Props, RealTimeMapLocalState> {

    private mapContainerRef = React.createRef<MapContainerLeaflet>();
    private listRef = React.createRef<ListVirtualized>();
    private airportDropdownRef = React.createRef<any>();
    public buttonBarRef: React.MutableRefObject<any> = React.createRef<any>();

    private timer: number | undefined = undefined;

    constructor(props: Props) {
        super(props);
        this.state = { drawerOpen: false, isFindEntityPopupOpen: false, isGoToAirportPopupOpen: false, showTerritories: false, measuredWidth: 0, measuredHeight: 0 };

        this.loadEntitiesOnMap = this.loadEntitiesOnMap.bind(this);
        this.renderTooltipContent = this.renderTooltipContent.bind(this);
        this.renderMarkerIcon = this.renderMarkerIcon.bind(this);
        this.renderListRow = this.renderListRow.bind(this);

        this.props.dispatchers.loadAirports(this.props.mapSettings);
    }

    protected getRootFilter(): Filter | undefined {
        let filter = this.props.showCustomQueryBar ? this.props.customQueryBar.customQuery?.customQueryDefinitionObject.filter : this.props.rootFilter
        if (filter){
            filter = Filter.eliminateDisabledFilters(filter);
        } 

        return filter;
    }

    protected getRootSort(): Sort[] {
        return this.props.showCustomQueryBar ? (this.props.customQueryBar.customQuery ? this.props.customQueryBar.customQuery!.customQueryDefinitionObject.sorts : []) : this.props.rootSort ? this.props.rootSort : [];
    }

    protected async loadEntitiesOnMap(resetAtNextUpdate?: boolean) {
        if (this.mapContainerRef.current) {
            if (resetAtNextUpdate) {
                this.props.dispatchers.setInReduxState({ entitiesList: {}, entitiesToDisplayIds: {}, mostRecentEntityUpdates: {}, mostRecentUpdatedMapBounds: undefined });
                this.mapContainerRef.current!.clearMap();
            }
            this.props.dispatchers.setInReduxState({ loadUUID: uuid() });
            await this.props.dispatchers.loadEntities(this.getRootFilter(), this.getRootSort(), this.mapContainerRef.current!, this.props.mapSettings, this.mapContainerRef.current!.getCurrentBounds());

            this.startTimer();
        }
    }
    
    protected async loadAndAddTerritoriesOnMap(){
        await this.props.dispatchers.loadTerritoriesForMap(this.mapContainerRef.current?.getCurrentBounds());
        this.props.dispatchers.addTerritoriesOnMap(this.mapContainerRef.current);
    }

    protected showTerritoriesOnMap(showTerritories: boolean){
        if (showTerritories){
            this.loadAndAddTerritoriesOnMap();
        } else {
            this.mapContainerRef.current?.removeLayers(territoryEntityDescriptor.name);
        }

        this.setState({showTerritories: showTerritories});
    }

    componentDidMount() {
        if (this.props.entityTypes && this.props.entityTypes.length == 1){
            this.props.dispatchers.setInReduxState({selectedEntityType: this.props.entityTypes[0]});
            const filterBarCheckboxes: {[key: string]: boolean} = {};
            if (this.props.mapSettings) {
                RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[this.props.entityTypes[0]]
                    .getMarkerSettings(this.props.mapSettings)?.colors.filter(color => color.showInFilterBar).forEach(color => {
                        RealTimeUtils.getMarkerColorIntervals(RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[this.props.entityTypes![0]!].getEd().name, color)
                        .forEach(interval => { filterBarCheckboxes[color.field + interval.from] = true; });
                    });
                this.props.dispatchers.setInReduxState({ filterBarCheckboxes: filterBarCheckboxes });
            }
            if (this.props.entitiesList[this.props.entityTypes[0]]?.length > 0) {
                this.props.dispatchers.updateEntitiesOnMapAndInList(this.props.selectedEntityType, this.mapContainerRef.current!, this.props.mapSettings, this.getRootSort());
            }
            this.componentDidUpdateInternal();

            this.loadEntitiesOnMap(true);
        } else {
            // multiple entities
        }
        
    }

    private startTimer() {
        this.timer = window.setTimeout(() => this.loadEntitiesOnMap(), refreshRateMillis);
    }

    private stopTimer() {
        clearTimeout(this.timer);
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    private componentDidUpdateInternal(prevProps?: Props, prevState?: RealTimeMapLocalState) {
        // verify if [0, 0] -> default value; if not [0, 0], then it means the center was set by value from session storage, so we don't want to reset it
        if (lodash.isEqual(this.mapContainerRef.current!.props.s.center, [0, 0]) && prevProps && prevProps.airports.length !== this.props.airports.length && this.props.mapSettings.airport !== null) {
            const airport = this.props.airports?.find(a => a.code === this.props.mapSettings.airport);
            this.props.dispatchers.selectAirport(airport?.id, this.mapContainerRef.current!);
        }
        if (prevProps && (prevProps.airport !== this.props.airport || prevProps.airports.length !== this.props.airports.length)) {
            if (this.props.airport?.id) {
                this.props.dispatchers.selectAirport(this.props.airport.id, this.mapContainerRef.current!);
            }
        }

        RealTimeUtils.selectAirportOnCurrentOrganizationToFilterByChange(prevProps, this.props, this.mapContainerRef.current!);

        if (prevState?.drawerOpen && !this.state.drawerOpen) {
            this.mapContainerRef.current!.props.r.setInReduxState({ selectedLayer: undefined });
        }
        
        if (prevProps && (!lodash.isEqual(prevProps?.currentOrganizationToFilterBy, this.props.currentOrganizationToFilterBy)
            || (this.props.showCustomQueryBar ? !lodash.isEqual(prevProps?.customQueryBar.customQuery?.customQueryDefinitionObject.filter, this.props.customQueryBar.customQuery?.customQueryDefinitionObject.filter)
                || lodash.differenceBy(this.props.customQueryBar.customQuery?.customQueryDefinitionObject.sorts, prevProps?.customQueryBar.customQuery?.customQueryDefinitionObject.sorts!, "field").length > 0
                : !lodash.isEqual(prevProps?.rootFilter, this.props.rootFilter) || !lodash.isEqual(prevProps?.rootSort, this.props.rootSort)))) {
            this.loadEntitiesOnMap(true);
        }

        if (!lodash.isEqual(prevProps?.mostRecentUpdatedMapBounds, this.props.mostRecentUpdatedMapBounds)) {
            if (this.state.showTerritories){
                this.loadAndAddTerritoriesOnMap();
            }
        }

        // if filter bar checkboxes were changed or existing sort fields directions were changed call updateEntitiesOnMapAndInList
        if (prevProps && this.mapContainerRef && Object.keys(this.props.entitiesList).length > 0 && ((Object.keys(prevProps.filterBarCheckboxes).length > 0 && prevProps.filterBarCheckboxes !== this.props.filterBarCheckboxes)
            || (!lodash.isEqual(this.props.customQueryBar.customQuery?.customQueryDefinitionObject.sorts, prevProps?.customQueryBar.customQuery?.customQueryDefinitionObject.sorts) && lodash.differenceBy(this.props.customQueryBar.customQuery?.customQueryDefinitionObject.sorts, prevProps?.customQueryBar.customQuery?.customQueryDefinitionObject.sorts!, "field").length == 0))) {
            this.props.dispatchers.updateEntitiesOnMapAndInList(this.props.selectedEntityType, this.mapContainerRef.current!, this.props.mapSettings, this.getRootSort());
        }
    }

    componentDidUpdate(prevProps: Props, prevState: RealTimeMapLocalState) {
        this.componentDidUpdateInternal(prevProps, prevState);
    }

    renderTooltipContent(layerData: any, type: string, additionalInfo?: { pointId?: ID }): React.ReactElement {
        const entity = this.props.entitiesList[type]?.find(entity => entity.id === layerData.id!);

        if (type == territoryEntityDescriptor.name){
            const terr = this.props.territoriesForMap?.find(terr => terr.id === layerData.id!);
            return terr ? <><div>{layerData.text}</div> {layerData.readableArea}</> : <></>;  
        } else {
            return RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[type].renderTooltipContent(entity, this.props.mapSettings);
        }
    }

    renderMarkerIcon(markerData: MarkerData, type: string, aditionalStyles?: { selected?: boolean, hovered?: boolean }): React.ReactNode {
        const entity = this.props.entitiesList[type]?.find(entity => entity.id === markerData.id!);
        return RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[type].renderMarkerIcon(entity, this.props.mapSettings, markerData, aditionalStyles);
    }

    onSelectedLayerChanged(prevValue?: Optional<SelectedLayer>){
        if (prevValue === undefined && this.mapContainerRef.current!.props.s.selectedLayer !== undefined) {
            const phoneMode:boolean = localStorage.getItem(PHONE_MODE_KEY) == "true";
            !phoneMode && this.setState({ drawerOpen: true });
        }
        
        this.listRef.current?.forceUpdateGrid();
        if (this.mapContainerRef.current!.props.s.selectedLayer) {
            this.listRef.current?.scrollToRow(this.props.entitiesToDisplayIds[this.props.selectedEntityType].findIndex(id => id == this.mapContainerRef.current!.props.s.selectedLayer?.id));
        }
    }

    renderFilterBar() {
        var markerColors = this.props.mapSettings.markers.find(m => m.markerType === this.props.selectedEntityType)?.colors.filter(color => color.showInFilterBar);
        if (!markerColors || markerColors.length == 0) {
            return null;
        }
        return <Segment style={{ padding: "0.5em 0.7em" }}>{markerColors?.map(color => {
            return RealTimeUtils.getMarkerColorIntervals(RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[this.props.selectedEntityType].getEd().name, color)
                .map(interval => {
                    const label = getDropdownItemLabel(RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[this.props.selectedEntityType].getEd().getField(color.field), interval as FieldInterval);
                    const checkbox = <Popup content={RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[this.props.selectedEntityType].getEd().getField(color.field).getLabel()}
                        trigger={<Checkbox label={label} checked={this.props.filterBarCheckboxes[color.field + interval.from]} onChange={() => this.props.dispatchers.changeFilterBar(color.field + interval.from)} />}
                        />;
                    const colorAsNumber = ColorRegistry.INSTANCE!.get(interval.color);
                    const colorAsHex = Utils.convertColorToHex(colorAsNumber);
                    const dotProps = {
                        className: "MapRealTime_filterBar_item_dot MapRealTime_filterBar_item_dot_" + color.area,
                        style: colorAsNumber ? { display: "inline-block", backgroundColor: colorAsHex } : undefined
                    };
                    if (color.area === "main") {
                        return <div className="MapRealTime_filterBar_item" style={colorAsNumber ? { borderColor: colorAsHex } : undefined}>{checkbox}</div>;
                    } else if (color.area === "topLeft" || color.area === "bottomLeft") {
                        return <>
                            <div {...dotProps} />
                            <div className="MapRealTime_filterBar_item" >{checkbox}</div>
                        </>
                    } else if (color.area === "topRight" || color.area === "bottomRight") {
                        return <>
                            <div className="MapRealTime_filterBar_item" >{checkbox}</div>
                            <div {...dotProps} />
                        </>;
                    }
                }).map(interval => <div className="MapRealTime_filterBar_item_container">{interval}</div>);
        })}</Segment>;
    }

    protected renderListRow(listRowProps: ListRowProps) {
        const { props } = this;
        const entity = props.entitiesList[this.props.selectedEntityType].find(entity => entity.id === props.entitiesToDisplayIds[this.props.selectedEntityType][listRowProps.index])!;
        return (
            <div style={listRowProps.style} key={listRowProps.key}
                onClick={() => this.mapContainerRef.current!.props.r.setInReduxState({ selectedLayer: { id: entity.id, type: this.props.selectedEntityType } })}>
                <div className="flex-container-row flex-center gap5" style={{ backgroundColor: this.mapContainerRef.current!.props.s.selectedLayer?.id === entity.id ? "lightgray" : undefined }}>
                    {RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[this.props.selectedEntityType].renderListRow(entity, this.props.mapSettings)}
                </div>
                <Divider className="less-margin-top-bottom" />
            </div>
        )
    }

    renderTopBar() {
        const phoneMode:boolean = localStorage.getItem(PHONE_MODE_KEY) == "true";
        var that = this;
        const realtimeEntityFactory = RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[this.props.selectedEntityType];
        const rootFilter = this.getRootFilter();
        
        const fieldDescriptor = new FieldDescriptor();
        fieldDescriptor.name = "myField";
        fieldDescriptor.type = realtimeEntityFactory.getEd().name;

        let filters = rootFilter ? realtimeEntityFactory.getFiltersForFindEntity().concat([rootFilter]) : realtimeEntityFactory.getFiltersForFindEntity()
        const organizationFilter = getOrganizationFilter(entityDescriptors[realtimeEntityFactory.getEd().name], global.currentOrganizationToFilterBy);
        if (organizationFilter) {
            filters.push(organizationFilter)
        }
        return (
            <Segment className="less-padding less-margin-bottom MapRealTime_topBar">
                <span ref={this.buttonBarRef} />
                <div style={{ width: "100%", display: "flex" }}>
                    {!phoneMode && this.props.showCustomQueryBar ? <div className="CustomQueryBar_div">
                        <CustomQueryBar key="customQueryBar"
                            entityDescriptor={realtimeEntityFactory.getEd().name} screen={realtimeEntityFactory.getEd().name} {...this.props.customQueryBar}
                            dispatchers={this.props.dispatchers.customQueryBar} mode={CUSTOM_QUERY_BAR_MODE.TABLE} />
                    </div> : null}
                    {this.props.showGoToTableButton ? <Button onClick={(e) => this.props.dispatchers.dispatch(push(realtimeEntityFactory.getEd().getEntityTableUrl()))}
                        key={_msg('CustomQueryBar.widget.goToTable')}><Icon name={realtimeEntityFactory.getEd().icon as SemanticICONS}></Icon>{_msg('CustomQueryBar.widget.goToTable')}</Button> : null}
                    <div style={{ marginLeft: "auto", flexShrink: 0 }}>
                        <Popup
                            open={this.state.isFindEntityPopupOpen}
                            onOpen={() => that.setState({ isFindEntityPopupOpen: true })}
                            trigger={<Button color='olive' icon={phoneMode}><Icon name={realtimeEntityFactory.getEd().icon}/>{!phoneMode && _msg("MapRealTime.equipment")}</Button>}
                            content={<div className="MapRealTime_topBar_padding MapRealTime_topRightBar_search" style={{ maxWidth: "250px", minWidth: "250px" }}>
                                <AssociationFieldEditor innerEntityDescriptor={entityDescriptors[realtimeEntityFactory.getEd().name]}
                                    fieldDescriptor={fieldDescriptor} filter={Filter.createComposed(FilterOperators.forComposedFilter.and, filters)}
                                    additionalSearchFields={realtimeEntityFactory.getAdditionalSearchFieldsForFindEntity()}
                                    controlShouldRenderValue={false} onChange={async (value) => {
                                        if (value) {
                                            await that.props.dispatchers.getEntity(value.id, this.mapContainerRef.current!, this.props.mapSettings);
                                        }
                                        that.setState({ isFindEntityPopupOpen: false });
                                    }}
                                />
                            </div>}
                            on='click' position='bottom right' />
                        <Popup
                            open={this.state.isGoToAirportPopupOpen}
                            trigger={<Button color='olive' icon={phoneMode}><Icon name={airportEntityDescriptor.icon}/>{!phoneMode && _msg("MapRealTime.airport")}</Button>}
                            onOpen={() => that.setState({ isGoToAirportPopupOpen: true })}
                            content={<Dropdown style={{ maxWidth: "250px", minWidth: "250px" }} data-cy={"dropdownMRT"} ref={this.airportDropdownRef} search selection
                                searchInput={{ autoFocus: true }}
                                options={this.props.airports.map((airport: AirportForMap) => ({ key: airport.id, value: airport.id, text: airport.code + " - " + airport.name }))}
                                onChange={(evt: any, props: DropdownProps) => {
                                    this.props.dispatchers.selectAirport(props.value as number, this.mapContainerRef.current!);
                                    that.setState({ isGoToAirportPopupOpen: false });
                                }} />}
                            on='click' position='bottom right'
                        />
                        <Button icon={phoneMode} color={this.state.showTerritories ? "orange" : "teal"} onClick={() => this.showTerritoriesOnMap(!this.state.showTerritories)}><Icon name='object ungroup outline' />{!phoneMode && _msg(this.state.showTerritories ? "MapRealTime.hideTerritories" : "MapRealTime.showTerritories")}</Button>
                    </div></div>
            </Segment>)
    }

    renderList() {
        return (
            <Measure bounds onResize={contentRect => this.setState({ measuredWidth: contentRect.bounds?.width || 0, measuredHeight: contentRect.bounds?.height || 0 })}>
                {({ measureRef }) => (<div ref={measureRef} className="flex-container flex-grow-shrink-no-overflow gap5">
                    <Label basic color="orange">{_msg("MapRealTime.noEquipmentResources", this.props.entitiesToDisplayIds[this.props.selectedEntityType]?.length)}</Label>
                    <ListVirtualized ref={this.listRef} className="wh100" style={{ padding: "5px", backgroundColor: "white" }}
                        width={this.state.measuredWidth}
                        height={this.state.measuredHeight}
                        rowCount={this.props.entitiesToDisplayIds[this.props.selectedEntityType] ? this.props.entitiesToDisplayIds[this.props.selectedEntityType].length : 0}
                        rowHeight={85} estimatedRowSize={85}
                        rowRenderer={this.renderListRow}
                    />
                </div>)}
            </Measure>
        )
    }

    renderDrawerEquipmentResource() {
        // if the ER is selected and a filter is applied and selected ER is no more visible, the drawer should be closed
        const eq = this.props.entitiesList[equipmentResourceEntityDescriptor.name]?.find(er => er.id === this.mapContainerRef?.current?.props.s.selectedLayer?.id)!
        if (!eq){
            return <></>
        }
        
        return (
            <Drawer data-cy={"drawerMRT"} className="MapRealTime_drawer" getContainer={false} closable={false}
                onClose={() => this.setState({ drawerOpen: false })}
                afterVisibleChange={visible => !visible}
                placement="right" visible={this.state.drawerOpen && this.mapContainerRef?.current?.props.s.selectedLayer?.type === equipmentResourceEntityDescriptor.name} width={this.state.drawerOpen ? 400 : 0}>
                {this.mapContainerRef?.current?.props.s.selectedLayer ?
                    <EquipmentResourceBigInfoArea eq={eq} mapMarkSettings={this.props.mapSettings} />
                    : undefined}
            </Drawer>
        )
    }

    render() {
        const phoneMode:boolean = localStorage.getItem(PHONE_MODE_KEY) == "true";
        return (<div className="flex-container flex-grow less-padding" >
            {this.renderTopBar()}
            <SplitPaneExt minSize={250} size={!phoneMode ? "25%" : "100%"}>
                {!phoneMode && this.renderList()}
                <div className="no-padding-margin flex-container flex-grow-shrink-no-overflow MapRealTime_topParent" >
                    {!phoneMode && this.renderFilterBar()}
                    {this.props.selectedEntityType == equipmentResourceEntityDescriptor.name ? this.renderDrawerEquipmentResource() : null}
                    <MapContainerLeafletRRC id={"mapContainerLeafletRealTimeMap-" + this.props.mapId} ref={this.mapContainerRef} 
                        pruneClusterMode={localStorage.getItem(MAP_CLUSTER_MODE_KEY) === CLUSTER_MODE.PRUNE_CLUSTER}
                        renderTooltipContent={this.renderTooltipContent} renderMarkerIcon={this.renderMarkerIcon} onSelectedLayerChanged={(prevValue: Optional<SelectedLayer>) => this.onSelectedLayerChanged(prevValue)}
                        bingAPIKey={this.props.mapSettings.bingAPIKey} mapId={this.props.mapId} saveCenterZoomInStorage={true}
                        layers={{ [equipmentResourceEntityDescriptor.name]: RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[equipmentResourceEntityDescriptor.name].getMapContainerLeafletLayer(), 
                        [mobileDeviceEntityDescriptor.name]: RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[mobileDeviceEntityDescriptor.name].getMapContainerLeafletLayer(),
                        [territoryEntityDescriptor.name]: { layerType: POLYGON_TYPE, options: { flyToSelectedMarker: false, hideStyleOnSelectedLayer: true, hideStyleOnHoveredLayer: true }}}} />
                </div>
            </SplitPaneExt>
        </div>
        )
    }
}