import { Optional, Utils } from "../..";
import { DatePicker } from "antd";
import moment from "moment";
import React from "react";
import { Button, ButtonGroup } from "semantic-ui-react";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "../../reduxReusableComponents/ReduxReusableComponents";
import _ from "lodash";

const { RangePicker } = DatePicker;

export enum PeriodType { DAY = "day", WEEK = "week", CUSTOM = "custom", LOADING = "loading" };

class PeriodPickerState extends State {
    periodType = PeriodType.LOADING;
    startDate = "";
    endDate = "";
}

class PeriodPickerReducers<S extends PeriodPickerState = PeriodPickerState> extends Reducers<S> { }

export type PeriodPickerProps = { onChange?: (startDate: string, endDate: string) => void, initialValues?: { startDate: string, endDate: string } } & RRCProps<PeriodPickerState, PeriodPickerReducers>;

export class PeriodPicker extends React.Component<PeriodPickerProps> {

    getStartDate() {
        return this.props.s.startDate;
    }

    getEndDate() {
        return this.props.s.endDate;
    }

    async onChangePeriodType(periodType: PeriodType, valueOrNull: Optional<moment.Moment>, value2?: Optional<moment.Moment>) {
        let value: moment.Moment | undefined;
        if (valueOrNull === null) {
            // needed, because moment(undefined) = OK; moment(null) != OK
            value = undefined;
        } else {
            value = valueOrNull;
        }

        const newState: Partial<PeriodPickerState> = { periodType };
        if (periodType === PeriodType.DAY) {
            newState.startDate = moment(value).startOf("day").toISOString();
            newState.endDate = moment(value).endOf("day").toISOString();
        } else if (periodType === PeriodType.WEEK) {
            newState.startDate = moment(value).startOf("week").toISOString();
            newState.endDate = moment(value).endOf("week").toISOString();
        } else if (periodType === PeriodType.CUSTOM) {
            if (!value) {
                // toggling buttons
                newState.startDate = moment().startOf("week").toISOString();
                newState.endDate = moment().endOf("week").toISOString();
            } else {
                // range changed
                newState.startDate = moment(value).startOf("day").toISOString();
                newState.endDate = moment(value2!).endOf("day").toISOString();
            }
        }

        this.props.r.setInReduxState(newState);
    }

    componentDidMount() {
        !this.props.initialValues && this.onChangePeriodType(PeriodType.DAY, null);
    }

    componentDidUpdate(prevProps: PeriodPickerProps) {
        if (prevProps && (prevProps.s.startDate !== this.props.s.startDate || prevProps.s.endDate !== this.props.s.endDate)) {
            this.props.onChange?.call(null, this.props.s.startDate, this.props.s.endDate);
        }
        if ((!prevProps || !_.isEqual(prevProps.initialValues, this.props.initialValues)) && this.props.initialValues) {
            const { startDate, endDate } = this.props.initialValues;
            if (startDate == this.props.s.startDate && endDate == this.props.s.endDate) {
                return;
            }
            let periodType = PeriodType.CUSTOM;
            if (moment(startDate).toDate().getTime() == moment(startDate).startOf("day").toDate().getTime() && moment(endDate).toDate().getTime() == moment(startDate).endOf("day").toDate().getTime()) {
                periodType = PeriodType.DAY;
            } else if (moment(startDate).toDate().getTime() == moment(startDate).startOf("week").toDate().getTime() && moment(endDate).toDate().getTime() == moment(startDate).endOf("week").toDate().getTime()) {
                periodType = PeriodType.WEEK;
            }
            this.props.r.setInReduxState({ startDate, endDate, periodType });
        }
    }

    protected renderPeriodButton(periodType: PeriodType) {
        return <Button active={this.props.s.periodType === periodType} onClick={() => this.onChangePeriodType(periodType, null)}>
            {_msg("PeriodPicker.periodType." + periodType)}
        </Button>
    }

    render() {
        return <>
            {_msg("general.period")}:&nbsp;<ButtonGroup basic toggle size="mini">
                {this.renderPeriodButton(PeriodType.DAY)}
                {this.renderPeriodButton(PeriodType.WEEK)}
                {this.renderPeriodButton(PeriodType.CUSTOM)}
            </ButtonGroup>
            &nbsp;
            {/* I wanted a span w/ fixed width; which didn't work; hence "display"; "inline" afaik means span like behavior */}
            <div style={{ display: "inline-block", width: "230px" }}>
                {this.props.s.periodType === PeriodType.DAY && <DatePicker format={Utils.dateFormat} value={moment(this.props.s.startDate)}
                    onChange={value => this.onChangePeriodType(this.props.s.periodType, value)} />}
                {this.props.s.periodType === PeriodType.WEEK && <DatePicker picker="week" value={moment(this.props.s.startDate)}
                    onChange={value => this.onChangePeriodType(this.props.s.periodType, value)} />}
                {this.props.s.periodType === PeriodType.CUSTOM && <RangePicker format={Utils.dateFormat} showTime={false} value={[moment(this.props.s.startDate), moment(this.props.s.endDate)]}
                    onCalendarChange={values => values?.[0] && values?.[1] && this.onChangePeriodType(this.props.s.periodType, values?.[0], values?.[1])} />}
            </div>

            {/* I didn't have time to upgrade antd which allows "format" as a function. For week mode, I want to show the user from/to because of
                        different US/EU callendar. I would have used "format"; but since not possible, I'm writing the actual values; this doesn't look so bad,
                        because even for custom mode, we see HH:mm which is ok
                    */}
            &nbsp;
            {_msg("general.from")}: <small>{moment(this.props.s.startDate).format(Utils.dateTimeFormat)}</small> &nbsp;{_msg("general.to")}: <small>{moment(this.props.s.endDate).format(Utils.dateTimeFormat)}</small>
        </>
    }
}

export const PeriodPickerRRC = ReduxReusableComponents.connectRRC(PeriodPickerState, PeriodPickerReducers, PeriodPicker);
