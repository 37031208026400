import React from "react";
import { ResponsivePie, PieSvgProps, DatumId } from "@nivo/pie";
import { ResponsiveLine, LineSvgProps, Point, PointTooltip, PointTooltipProps } from "@nivo/line";
import { Theme } from "@nivo/core";
import moment from "moment";
import { Utils } from "..";
import { Filter } from "./CustomQuery/Filter";

export type PieDatum = {
    id: DatumId;
    label: DatumId;
    value: number;
    color?: string;
    filter?: Filter;
}

export type ResponsivePieProps = Omit<PieSvgProps<PieDatum>, "width" | "height">;

export const customTheme: Theme = {
    textColor: 'var(--textColor)',
    labels: {
        text: {
            fontWeight: 900,
            fontSize: 12,
            fill: '#000'
        }
    },
    tooltip: {
        container: {
            position: 'fixed',
            overflow: 'auto',
            maxHeight: '200px'
        },
    },

}

export class CenteredMetric {
    fontSize: number = 52;

    constructor(fontSize?: number) {
        if (fontSize) {
            this.fontSize = fontSize;
        }
    }

    get() {
        return (data: { dataWithArc: PieDatum[], centerX: number, centerY: number, fontSizeForTotal?: number }) => {
            let total = 0
            data.dataWithArc.forEach(datum => {
                total += datum.value
            })

            return (
                <text
                    x={data.centerX}
                    y={data.centerY}
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{
                        fontSize: this.fontSize + 'px',
                        fontWeight: 600,
                        fill: 'var(--textColor)'
                    }}
                >
                    {total}
                </text>
            )
        }
    }
}

export const ResponsivePieExt = (props: ResponsivePieProps) => {
    return (<ResponsivePie margin={{ left: 10, right: 10, top: 10, bottom: 10 }} colors={{ scheme: 'category10' }}
        arcLinkLabelsDiagonalLength={5} arcLinkLabelsColor={{ from: 'color', modifiers: [] }} arcLabelsSkipAngle={10} arcLinkLabelsSkipAngle={5}
        innerRadius={0.5} padAngle={2} cornerRadius={0} sortByValue={true} fit={true}
        borderWidth={1} borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }} theme={customTheme}
        isInteractive={true} activeInnerRadiusOffset={0} activeOuterRadiusOffset={8}
        {...props}
    />);
}

export type ResponsiveLineExtProps = LineSvgProps & { renderTooltipContent?: (value: PointTooltipProps) => React.ReactNode, legendX?: string, legendY?: string, xScaleFormat?: string, minYScale?: number, maxYScale?: number };

export const ResponsiveLineExt = (props: ResponsiveLineExtProps) => {
    // after upgrade to nivo 0.7.2, if dataset empty => error; hence the check for data.length > 0
    // I tried to reproduce this w/ constant data in the "code sandbox" of nivo; w/o success. I think it's somehow
    // the fact that we have initial empty data + it arrives later: this causes maybe the issue
    return (!(props.data?.reduce((previousValue, currentData) => previousValue + currentData.data.length, 0)) ? null : <div id="ResponsiveLineChartContainer" className="ResponsiveLineChartContainer"><ResponsiveLine
        margin={{ top: 50, bottom: 80, right: 110, left: 60 }} useMesh={true} enableSlices={false}
        xScale={{ type: 'time', format: props.xScaleFormat ? props.xScaleFormat : "%Q", precision: 'second' }} xFormat="time:%Y-%m-%d %H:%M:%S"
        axisBottom={{ legend: props.legendX, format: '%H %M', legendOffset: 35 }}

        yScale={{ type: 'linear', stacked: false, min: props.minYScale !== undefined ? props.minYScale : "auto", max: props.maxYScale !== undefined ? props.maxYScale : "auto" }}
        axisLeft={{ legend: props.legendY, legendOffset: -40 }}

        legends={[{
            anchor: 'bottom-right', direction: 'column', translateX: 100, itemWidth: 80, itemHeight: 20, itemOpacity: 0.75, symbolSize: 12,
            symbolShape: 'circle', symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [{ on: 'hover', style: { itemBackground: 'rgba(0, 0, 0, .03)', itemOpacity: 1 } }]
        }]}

        tooltip={(value) => ResponsiveLineExtTooltipWrapper(props.renderTooltipContent?.call(null, value), value)}
        colors={{ datum: 'color' }}
        pointSize={4} pointBorderColor={{ from: 'color', modifiers: [['darker', 0.3]], }}
        {...props}
    /></div>);

}

export const legendsBottom = [
    {
        anchor: 'bottom',
        direction: 'row',
        translateY: 56,
        itemWidth: 100,
        itemHeight: 18,
        itemTextColor: '#999',
        symbolSize: 18,
        symbolShape: 'circle',
        effects: [
            {
                on: 'hover',
                style: {
                    itemTextColor: '#000'
                }
            }
        ]
    }
]

const ResponsiveLineExtTooltipWrapper = (content: React.ReactNode, value: PointTooltipProps) => {
    const point = value.point;
    return (<div style={{ transform: value.point.x <= 200 ? "translate(0, 0)" : "translate(-200px, 0)" }} >
        <div style={{ position: 'absolute', background: 'white', padding: '5px 9px', borderRadius: '2px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px' }}>
            {content ? content :
                <div style={{ display: "flex", alignItems: 'center', whiteSpace: 'pre' }} key={point.serieId + "." + point.id}>
                    <span><strong>{point.data.y}</strong></span>
                    <span> [{_msg("general.at", moment(point.data.x).format(Utils.dateTimeWithSecFormat))}]</span>
                </div>
            }
        </div>
    </div>);
};

export const helper = (list: string[]) => {
    if (list.length === 0) return ''
    list[list.length - 1] = list[list.length - 1].slice(0, list[list.length - 1].length - 2)
    return list
}