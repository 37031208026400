import { FilterOperators } from "@crispico/foundation-gwt-js";
import { apolloClient, EntityDescriptor, EntityEditorFormSimple, Optional, TestUtils, Utils } from "@crispico/foundation-react";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { SelectExtOption } from "@crispico/foundation-react/components/selectExt/SelectExt";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { Drawer } from "antd";
import { FlightsAssignmentsPage_Flight, FlightsAssignmentsPage_Flight_missionSubsets_mission_currentState } from "apollo-gen/FlightsAssignmentsPage_Flight";
import { FlightsAssignmentsPage_loadFlightsQuery, FlightsAssignmentsPage_loadFlightsQueryVariables, FlightsAssignmentsPage_loadFlightsQuery_flightService_findByFilter_results_baggages as BaggageData } from "apollo-gen/FlightsAssignmentsPage_loadFlightsQuery";
import { BaggageStatusSettings, ConveyorEjectSettings, InitializationsForClient } from "app";
import gql from "graphql-tag";
import moment from "moment";
import React from "react";
import { Button, Card, Icon, Label, Modal, Segment, Confirm, Input, Popup, Message } from "semantic-ui-react";
import { FLIGHTS_ASSIGNEMENTS_PAGE_CREATE_MISSION, FLIGHTS_ASSIGNEMENTS_PAGE_LOAD_FLIGHTS_QUERY } from "./queries";
import { entityDescriptors, ID } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { DocumentNode } from "graphql";
import { flightEntityDescriptor, missionEntityDescriptor } from "AppEntityDescriptors";
import Interweave from "interweave";
import lodash from 'lodash';
import { AdvancedSelectorHOC, AdvancedSelectorItem } from "@crispico/foundation-react/components/AdvancedSelector/AdvancedSelector";
import { createTestids } from "@famiprog-foundation/tests-are-demo";

export const flightsAssignmentsTestids = createTestids("FlightsAssignments", {
    topBar: "", refresh: "", changeStatus: "", searchInput: "", search: "", clearSearch: "",
    cardGroup: "", card: "", drawer: "",
    flightTop: "", nbScanned: "", nbTotal: "", criticality: "", messageFlightHasBaggageTags: "", messageDisplayFilteredBaggageTags: "",
    cardBtnMission: "", flightAddMission: "",
    missionInfo: "", missionText: "", missionNbScanned: "", delete: "", deleteYes: "", deleteNo: "",
    flight: "", tags: "", mission: "", tag: "", addTag: "", scanTag: "", tagNumber: "", tagDate: "", missionTags: ""
});


type MissionData = {
    id: number,
    humanResource: Optional<string>,
    equipmentResource: Optional<string>,
    scannedBaggages: number[],
    type: { color: string | null } | null
    status: string | null,
    state: FlightsAssignmentsPage_Flight_missionSubsets_mission_currentState | null,
    objectId: number,
    processedBaggages: Optional<number>
}

type FlightData = {
    conveyorEject?: ConveyorEjectSettings,
    totalBaggages: number,
    scannedBaggages: number,
    criticality: { value: string, color: string, text: string }
    baggagesNotScanned: number[]
    missions: MissionData[],
    messageFlightHasBaggageTags?: string,
    messageDisplayFilteredBaggageTags?: string
};

export class FlightsAssignmentsPageState extends State {
    conveyorEjects?: SelectExtOption[];
    currentConveyorEject?: SelectExtOption;
    displayedFlights?: number[];
    objectStartAddresses?: { id: any, name?: string | undefined }[];
    missionType?: { id: any, name?: string };
    flights: { [key: string]: FlightsAssignmentsPage_Flight } = {};
    data: { [key: string]: FlightData } = {};
    modalOpenedFor?: number;
    drawerOpenedFor?: number;
    loading?: boolean;
    confirmDeleteMissionFor?: number;
    addTagModalOpened?: boolean;
    searchBaggage?: { text: string, highlightBaggageId?: number };
    scannerModalOpenedFor?: {baggage: BaggageStatusSettings, additionalFields?: { [key: string]: any }};
    scannerInput?: any;
    textSearched?: string;
}

export class FlightsAssignmentsPageReducers<S extends FlightsAssignmentsPageState = FlightsAssignmentsPageState> extends Reducers<S> {

    update(flights: { [key: string]: FlightsAssignmentsPage_Flight }, data: { [key: string]: FlightData }) {
        this.s.flights = flights;
        this.s.data = data;
        this.changeDisplayedFlights();
        this.s.loading = false;
    }

    changeSelection(conveyorEject: SelectExtOption) {
        this.s.currentConveyorEject = conveyorEject;
        this.changeDisplayedFlights();
   }

   changeDisplayedFlights() {
       let displayedFlights: {id: number, date: any}[] = [];
       const searchedText = this.s.textSearched;
       Object.keys(this.s.flights).forEach(id => {
           const flight = this.s.flights[id];
           const data = this.s.data[id];  
           data.messageDisplayFilteredBaggageTags = undefined; 
           data.messageFlightHasBaggageTags = undefined;        
           let displayFlight = false;
           if (this.s.currentConveyorEject?.value != null ? data.conveyorEject?.conveyorEject === this.s.currentConveyorEject.value : true) {
               if (!Utils.isNullOrEmpty(searchedText)) {
                   // search in flights by name
                   if ((flight.airline! + flight.number!).toUpperCase().search(searchedText!.toUpperCase()) >= 0) {
                       displayFlight = true;
                   }
                   // search in flight displayed baggages
                   const baggages = flight.baggages?.filter(b => b.number != undefined && b.number.toUpperCase().search(searchedText!.toUpperCase()) >= 0)
                        .filter(b => data.baggagesNotScanned.find(id => id === b.id) || data.missions.find(m => m.scannedBaggages.find(id => id === b.id)));
                   if (baggages && baggages.length > 0) {
                       // if baggage found, add messages too
                       data.messageFlightHasBaggageTags = _msg("FlightsAssignmentsPage.textSearchedFlightBaggages", baggages.length, searchedText);
                       data.messageDisplayFilteredBaggageTags = _msg("FlightsAssignmentsPage.showOnlySearchedTextBaggages", searchedText);                   
                       displayFlight = true;                      
                   }
               } else {
                   displayFlight = true;                  
               }
           }
           if (displayFlight) {
               displayedFlights.push({id: Number(id), date: flight.date});
           }
       })
       this.s.displayedFlights = displayedFlights.sort((a: any, b: any) => new Date(a.date).getTime() < new Date(b.date).getTime() ? -1 : 1).map(f => f.id);
   }
}

export class FlightsAssignmentsPage extends React.Component<RRCProps<FlightsAssignmentsPageState, FlightsAssignmentsPageReducers>, {inputText: string}> {

    private timer: number | undefined = undefined;
    private static REFRESH_RATE: number = 5000; // 5s
    
    saveBaggageMutation!: DocumentNode;
    addressFindByFilter!: DocumentNode;
    baggageFindByFilter!: DocumentNode;

    constructor(props: RRCProps<FlightsAssignmentsPageState, FlightsAssignmentsPageReducers>) {
        super(props);

        this.state = { inputText: "" };

        this.onSearchKeyDown = this.onSearchKeyDown.bind(this);

        this.initQueries();
    }

    protected initQueries() {
        this.saveBaggageMutation = gql(`mutation q($params: SaveParams_LongInput) { 
            baggageService_save(params: $params) { ${ID} }
        }`);
        this.addressFindByFilter = gql(`query q($params: FindByFilterParamsInput) { 
            addressService_findByFilter(params: $params) {
                results { id name }
            }
        }`);
        this.baggageFindByFilter = gql(`query q($params: FindByFilterParamsInput) { 
            baggageService_findByFilter(params: $params) {
                results { id }
            }
        }`);
    }

    protected getConveyorEjectSettings():ConveyorEjectSettings[] {
        const { tempSettingsXops } = AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().initializationsForClient as InitializationsForClient;
        return tempSettingsXops.tempFlightConveyorEjectSettings;
    }

    protected getBaggageStatusSettings(): BaggageStatusSettings[] {
        const { tempSettingsXops } = AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().initializationsForClient as InitializationsForClient;
        return tempSettingsXops.tempBaggageStatusSettings;
    }

    protected getObjectAddresses(): number[] {
        let startAddresses:number[] = [];
        const { tempSettingsXops, currentOrganization } = AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().initializationsForClient as InitializationsForClient;
        const organizationSettings = tempSettingsXops.tempUnitSettings.find(s => s.organizationId === currentOrganization?.id);
        organizationSettings?.missionTypesToDisplay?.forEach(mtSettings => {
            tempSettingsXops.tempMissionTypeSettings?.filter(ms => ms.missionType === mtSettings.id).forEach(ms => ms.startAddresses && startAddresses.push(...ms.startAddresses.map(a => a.id)));
        });
        return startAddresses;
    }

    protected async createMission(flightId: number, humanResourceId?: number, comment?: string, objectStartAddressId?: number, missionTypeId?: number) {
        const result = (await apolloClient.mutate({ mutation: FLIGHTS_ASSIGNEMENTS_PAGE_CREATE_MISSION, variables: { 
            flightId: flightId, 
            humanResourceId: humanResourceId, 
            comment: comment,
            objectStartAddressId: objectStartAddressId,
            missionTypeId: missionTypeId }
         })).data.mission2Service_createMission as [];
        if (result.length > 0) {
            this.props.r.setInReduxState({modalOpenedFor: undefined});
            this.loadFlights();
        }
    }

    protected async addBaggage(flightId: number, number: string) {    
        // verify if baggage already exists, not sure if we need a constraint at db level because of WFS workflow
        const filters: Filter[] = [
            Filter.create("number", FilterOperators.forString.equals, number),
            Filter.create("outFlight.id", FilterOperators.forNumber.equals, "" + flightId),
        ];
        const baggages = (await apolloClient.query({
            query: this.baggageFindByFilter,
            variables: FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.and, filters))
        })).data["baggageService_findByFilter"].results;

        if (baggages.length > 0) {
            this.props.r.setInReduxState({ addTagModalOpened: undefined });
            return; // baggage already exists
        }
        const result = (await apolloClient.mutate({ mutation: this.saveBaggageMutation, variables: { params: { 
            id: undefined, 
            fieldsAndValues: { number: number, date: moment(Utils.now()).toISOString(), outFlight: {id: flightId}, location: this.props.s.objectStartAddresses?.[0].name }
         }
         }})).data?.baggageService_save;
         if (result?.id) {
             this.props.r.setInReduxState({ addTagModalOpened: undefined });
             this.loadFlights();
         }
    }

    protected async deleteMission(missionId: number) {
        this.props.r.setInReduxState({ confirmDeleteMissionFor: undefined });
        const result = (await apolloClient.mutate({
            mutation: gql(`mutation mission2Service_deleteMission($missionId:Long!) { 
                mission2Service_deleteMission(missionId:$missionId)
        }`), variables: {
                missionId: missionId,
            }
        }));        
        this.loadFlights();        
    }

    protected async loadMissionType(missionType?: { id: any, name?: string }) {
        if (!missionType || !missionType.id) {
            return;
        }
        if (!TestUtils.storybookMode) {
            missionType = (await apolloClient.query({
                query: gql(`query missionTypeService_findById {
                    missionTypeService_findById(id: ${missionType.id}) { id name }
            }`)
            })).data["missionTypeService_findById"];
        }
        this.props.r.setInReduxState({ missionType: missionType });
    }

    protected async loadObjectStartAddresses() {
        let objectStartAddresses = [];
        const objectAddresses = this.getObjectAddresses();
        if (!TestUtils.storybookMode && objectAddresses.length > 0) {
            let filter = Filter.create(ID, FilterOperators.forNumber.in, "" + objectAddresses);

            objectStartAddresses = (await apolloClient.query({
                query: this.addressFindByFilter,
                variables: FindByFilterParams.create().filter(filter)
            })).data["addressService_findByFilter"].results;
        }
        this.props.r.setInReduxState({ objectStartAddresses: objectStartAddresses });
    }

    protected async loadFlights() { 
        if (this.props.s.loading) {
            return;
        }
        this.props.r.setInReduxState({ loading: true });
        const filters: Filter[] = [
            Filter.create("date", FilterOperators.forDate.greaterThan, moment(Utils.now()).add(-1, "hours").toISOString()),
            Filter.create("date", FilterOperators.forDate.lessThan, moment(Utils.now()).add(12, "hours").toISOString()),
            Filter.create("showFlightInGantt", FilterOperators.forBoolean.equals, "true"),
            Filter.create("departure", FilterOperators.forBoolean.equals, "true")
        ];       
        const result = (await apolloClient.query<FlightsAssignmentsPage_loadFlightsQuery, FlightsAssignmentsPage_loadFlightsQueryVariables>({
            query: FLIGHTS_ASSIGNEMENTS_PAGE_LOAD_FLIGHTS_QUERY,
            variables: FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.and, filters)),
            context: { showSpinner: false }
        })).data.flightService_findByFilter?.results;

        var newFlights:{ [key: string]: FlightsAssignmentsPage_Flight } = {};
        var newData:{ [key: string]: FlightData } = {};
        if (result) {

            const conveyorEjectSettings = this.getConveyorEjectSettings();
            const baggageStatusSettings = this.getBaggageStatusSettings();
           
            result.map(flight => {               
                let missions: MissionData[] = []; 
                let scannedBaggages = 0;
                flight.missionSubsets?.forEach(ms => {
                    let baggages: number[] = [];                   
                    ms.mission?.objectActionGroups.forEach(oag => {
                        const allBaggages = flight.baggages?.filter(b => oag.object.id === b.object?.id) || [];
                        baggages = allBaggages?.filter(b => b.status === "scanned").sort((a, b) => a.number && b.number ? a.number.localeCompare(b.number) : 0).map(b => b.id);                        
                    })
                    const object = ms.mission && ms.mission.objectActionGroups.length > 0 ? ms.mission.objectActionGroups[0].object : null;
                    missions.push({
                        id: ms.mission?.id,
                        humanResource: ms.mission?.humanResource?.lastName,
                        equipmentResource: ms.mission?.equipmentResource?.identifier,
                        scannedBaggages: baggages,                      
                        type: ms.mission ? ms.mission.type : null,
                        status: ms.mission ? ms.mission.status : null,
                        state: ms.mission ? ms.mission.currentState : null,
                        objectId:object?.id,
                        processedBaggages: object?.processedBaggages
                    });
                    scannedBaggages += object?.processedBaggages ? object?.processedBaggages : 0;
                });
                const allBaggages = flight.baggages?.filter(b => this.props.s.objectStartAddresses && this.props.s.objectStartAddresses.length > 0 ? this.props.s.objectStartAddresses.find(osa => b.location === osa.name) : true).sort((a, b) => a.number && b.number ? a.number.localeCompare(b.number) : 0) || [];
                const baggagesNotScanned = allBaggages.filter(b => baggageStatusSettings.find(s => s.name === b.status) === undefined).map(b => b.id);
                
                if (allBaggages.length === 0 || baggagesNotScanned.length === 0) {
                    // don't add the flight, it's considered to be treated
                } else {
                    newFlights[flight.id] = flight;
                    newData[flight.id] = {
                        conveyorEject: conveyorEjectSettings.find(fj => fj.parkings.find(p => p.id === flight.parking?.id)),
                        criticality: this.getCriticality(flight),
                        totalBaggages: baggagesNotScanned.length + scannedBaggages,
                        baggagesNotScanned: baggagesNotScanned,
                        scannedBaggages: scannedBaggages,
                        missions: missions
                    } as FlightData;
                }               
            });
        }
        this.props.r.update(newFlights, newData);
    }

    protected async updateBaggage(id: number, fieldsAndValues: { [key: string]: any }) {
        await apolloClient.mutate({
            mutation: this.saveBaggageMutation,
            variables: { params: { id, fieldsAndValues: fieldsAndValues } }
        });
        this.props.r.setInReduxState({ scannerModalOpenedFor: undefined });
        this.loadFlights();
    }

    private getCriticality(flight: FlightsAssignmentsPage_Flight) {
        let criticality = undefined;
        const { tempSettingsXops, currentOrganization } = AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().initializationsForClient as InitializationsForClient;
        const organizationSettings = tempSettingsXops.tempUnitSettings.find(s => s.organizationId === currentOrganization?.id);
                    
        if (flight.flightType && organizationSettings?.defaultTripTimeToParking !== undefined) {
            const now = Utils.now().getTime();
            const initialDate = moment(flight.initialDate).toDate().getTime();
            const value = Math.floor((now - moment(flight.date).toDate().getTime()) / (60 * 1000)); // in min
            const offset: number | undefined =
                (flight.flightType === "L" ? organizationSettings.defaultLCOffset
                    : (flight.flightType === "M" ? organizationSettings.defaultMCOffset
                        : organizationSettings.defaultSCOffset)) || 0;
            let color = "red";
            
            if (now > initialDate) {
                color = "grey";
            } else if (now + organizationSettings.defaultTripTimeToParking  < initialDate + offset) {
                color = "green";
            }
            criticality = {
                value: (value > 0 ? "+" : "") + value,
                color: color,
                text: _msg("FlightsAssignmentsPage.criticality.details",
                    flightEntityDescriptor.getField("initialDate").getLabel(),
                    organizationSettings.defaultTripTimeToParking / 60 / 1000,
                    (offset > 0 ? "+" : "-") + " " + Math.abs(offset) / 60 / 1000,
                    flight.flightType)                            
            };
        }
        return criticality;
    }

    private startTimer() {
        this.timer = window.setTimeout(() => {
            this.loadFlights();
            this.startTimer();
        }, FlightsAssignmentsPage.REFRESH_RATE);
    }

    private stopTimer() {
        clearTimeout(this.timer);
    }

    
    private onSearchKeyDown(e: any, value: any) {
        if (e.key === 'Enter') {
            this.props.r.setInReduxState({ textSearched: this.state.inputText });
        }
    }

    componentDidMount() {
        if (TestUtils.storybookMode) {
            return;
        }
        
        const { tempSettingsXops, currentOrganization } = AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().initializationsForClient as InitializationsForClient;
        let list: SelectExtOption[] = [{ value: null, label: _msg("general.all") }];
        tempSettingsXops.tempFlightConveyorEjectSettings.forEach(fj => {
            list.push({ value: fj.conveyorEject, label: _msg("FlightsAssignmentsPage.conveyorEject") + " " + fj.conveyorEject, color: fj.color });
        });
        this.props.r.setInReduxState({ conveyorEjects: list });

        this.props.r.changeSelection(list[0]);

        const organizationSettings = tempSettingsXops.tempUnitSettings.find(s => s.organizationId === currentOrganization?.id);
        this.loadMissionType({ id: organizationSettings?.missionTypesToDisplay?.[0].id });
        this.loadObjectStartAddresses();

        this.loadFlights();
        this.startTimer();
    }

    componentDidUpdate(prevProps:RRCProps<FlightsAssignmentsPageState, FlightsAssignmentsPageReducers>) {
        this.componentDidUpdateInternal(prevProps);
    }

    componentDidUpdateInternal(prevProps?:RRCProps<FlightsAssignmentsPageState, FlightsAssignmentsPageReducers>) {
        if (!prevProps || !lodash.isEqual(this.props.s.textSearched, prevProps.s.textSearched)) {
            this.props.r.changeDisplayedFlights();
        }
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    protected renderModal() {
        const { data, flights } = this.props.s;
        const id = this.props.s.modalOpenedFor;
        const flight = id ? flights[id] : undefined;
        const ed = new EntityDescriptor({ name: "FlightsAssignmentsPage.createMission" })
            .addFieldDescriptor({ name: "missionType", type: "MissionType" })
            .addFieldDescriptor({ name: "objectStartAddress", type: "Address" })
            .addFieldDescriptor({ name: "humanResource", type: "HumanResource" })
            .addFieldDescriptor({ name: "comment", type: FieldType.text })

        return id && flight && <ModalExt open={true} onClose={() => this.props.r.setInReduxState({modalOpenedFor: undefined})}>
            <Modal.Header>{_msg("FlightsAssignmentsPage.createMission.title")}</Modal.Header>
            <Modal.Content>
                <Segment className="flex-container less-padding">
                    <Flight rawFlight={flight} dataFlight={data[id]} />
                </Segment>               
                <EntityEditorFormSimple autoFocusOnField={"comment"} onCancelHandler={() => this.props.r.setInReduxState({modalOpenedFor: undefined})}
                    entityDescriptor={ed} entity={{missionType: this.props.s.missionType, objectStartAddress: this.props.s.objectStartAddresses?.[0]}} 
                    onSubmitHandler={(entity: { humanResource?: { id: number }, comment: string, objectStartAddress?: {id: number}, missionType?: {id: number} }) => this.createMission(id, entity.humanResource?.id, entity.comment, entity.objectStartAddress?.id, entity.missionType?.id)} />
            </Modal.Content>
        </ModalExt>    
    }


    protected renderAddBaggageModal() {        
        const { data, flights, drawerOpenedFor, addTagModalOpened } = this.props.s;
        const flight = drawerOpenedFor ? flights[drawerOpenedFor] : undefined;
        const ed = new EntityDescriptor({ name: "FlightsAssignmentsPage.addBaggage" })
            .addFieldDescriptor({ name: "number", type: FieldType.string });

        return drawerOpenedFor && addTagModalOpened && flight && <ModalExt open={true} onClose={() => this.props.r.setInReduxState({addTagModalOpened: undefined})}>
            <Modal.Header>{_msg("FlightsAssignmentsPage.addBaggage.title")}</Modal.Header>
            <Modal.Content>
                <Segment className="flex-container less-padding">
                    <Flight rawFlight={flight} dataFlight={data[drawerOpenedFor]} />
                </Segment>               
                <EntityEditorFormSimple autoFocusOnField={"number"} onCancelHandler={() => this.props.r.setInReduxState({addTagModalOpened: undefined})}
                    entityDescriptor={ed} entity={{}} 
                    onSubmitHandler={(entity: { number: string }) => this.addBaggage(drawerOpenedFor, entity.number)} />
            </Modal.Content>
        </ModalExt>    
    }


    protected renderDrawer() {
        const { data, flights, textSearched } = this.props.s;
        const id = this.props.s.drawerOpenedFor;
        const dataFlight = id ? data[id] : undefined;
        const rawFlight = id ? flights[id] : undefined;
        return rawFlight && dataFlight && <Drawer data-testid={flightsAssignmentsTestids.drawer} visible={true} placement="right" width={700} closable={false} onClose={() => this.props.r.setInReduxState({ drawerOpenedFor: undefined })}>
            <Segment className="flex-container less-padding">
                <Flight rawFlight={rawFlight} dataFlight={dataFlight} missionsBar={{onAddClick: () => this.props.r.setInReduxState({modalOpenedFor: rawFlight.id}) }} />
            </Segment>
            <Segment className="flex-container-row flex-center less-padding">
                <div className="flex-container flex-grow">
                    {_msg('FlightsAssignmentsPage.details.tagsNotScanned')}
                    <div data-testid={flightsAssignmentsTestids.tags}>{dataFlight.baggagesNotScanned?.map((bId: number, index) => {
                        const baggage = rawFlight.baggages?.find(b => b.id === bId);
                        if (!baggage || textSearched && baggage.number != undefined && baggage.number.toUpperCase().search(textSearched.toUpperCase()) < 0) {
                            return null;
                        }
                        return <Baggage dataTestId={flightsAssignmentsTestids.tag + "_" + index} key={bId} data={baggage} />
                    })}</div>
                </div>                
                <Button data-testid={flightsAssignmentsTestids.addTag} onClick={() => this.props.r.setInReduxState({addTagModalOpened: true})}><Icon name="plus" />{_msg("FlightsAssignmentsPage.addBaggage.title")}</Button>
               </Segment> 
            {dataFlight.missions?.map((m: MissionData, index) => 
                <Segment data-testid={flightsAssignmentsTestids.mission + "_" + index} key={flightsAssignmentsTestids.mission + "_" + index} className="flex-container less-padding">
                    <div key="info" className="flex-container-row">
                        <div className="flex-container flex-grow"><Mission dataTestId={flightsAssignmentsTestids.missionInfo + "_" + index} {...m} /></div>
                        {this.getBaggageStatusSettings().map((b, index) =>
                            b.displayMode === 1 ? 
                                <Button key={flightsAssignmentsTestids.scanTag + "_" + index} data-testid={flightsAssignmentsTestids.scanTag + "_" + index} className="FlightsAssignmentsPage_label" color={b.color} 
                                    onClick={() => this.props.r.setInReduxState({ scannerModalOpenedFor: { baggage: b, additionalFields: { "object": { "id": m.objectId } } } })}>{b.label}</Button> : undefined
                        )}
                        <Button icon="delete" data-testid={flightsAssignmentsTestids.delete} className="FlightsAssignmentsPage_label" onClick={() => this.props.r.setInReduxState({confirmDeleteMissionFor: m.id})} />
                    </div>
                    <div key="tags" data-testid={flightsAssignmentsTestids.missionTags}>{m.scannedBaggages?.map((bId: number, index) => {
                        const baggage = rawFlight.baggages?.find(b => b.id === bId);
                        if (!baggage || textSearched && baggage.number != undefined && baggage.number.toUpperCase().search(textSearched.toUpperCase()) < 0) {
                            return null;
                        }
                        return <Baggage dataTestId={flightsAssignmentsTestids.tag + "_" + index} key={flightsAssignmentsTestids.tag + "_" + index} data={baggage} />
                    })}</div>
                </Segment>
            )}
    </Drawer>
    }

    protected renderConfirmDeleteMission() {
        const props = this.props;
        return (<>
            <ModalExt
                open={props.s.confirmDeleteMissionFor !== undefined} 
                actions={[
                    { "data-testid": flightsAssignmentsTestids.deleteYes, key: "yes", content: _msg("general.yes"), positive: true, onClick: () => this.deleteMission(props.s.confirmDeleteMissionFor!) },
                    { "data-testid": flightsAssignmentsTestids.deleteNo, key: "no", content: _msg("general.no"), onClick: () => props.r.setInReduxState({ confirmDeleteMissionFor: undefined }) }
                ]}
                header={_msg("dto_crud.deleteConfirmation.header", props.s.confirmDeleteMissionFor, entityDescriptors["Mission2"].getLabel())}
                content={_msg("dto_crud.deleteConfirmation")}               
            /></>);
    }

    protected renderScannerModal() {
        const props = this.props;
        const status = props.s.scannerModalOpenedFor;
        if (!status) {
            return;
        }
        const data: AdvancedSelectorItem[] = [];
        Object.keys(props.s.data).forEach(key => {
            if (props.s.drawerOpenedFor && key !== String(props.s.drawerOpenedFor)) {
                return;
            }
            const flight = props.s.flights[key];
            const dataFlight = props.s.data[key];
            if (dataFlight.baggagesNotScanned.length === 0 && dataFlight.missions.length === 0) {
                return;
            }
            data.push({ id: flight.id, level: 1, text: flight.airline! + flight.number!, color: "blue" });
            if (dataFlight.baggagesNotScanned.length !== 0) {
                data.push({ id: "F" + key + "noM", level: 2, text: _msg("FlightsAssignmentsPage.noMission"), color: "red" });
                dataFlight.baggagesNotScanned.forEach(baggage => {
                    const b = flight.baggages?.find(b => b.id === baggage);
                    if (b) {
                        data.push({ id: b.id, level: 3, text: String(b.number), color: b.importedBy ? "grey" : undefined });
                    }
                })
            }
            dataFlight.missions.forEach(mission => {
                if (mission.scannedBaggages.length === 0) {
                    return;
                }
                const text = mission.humanResource ? mission.humanResource + (mission.equipmentResource ? " / " + mission.equipmentResource : "") : _msg("FlightsAssignmentsPage.waitingDriver");
                const color = mission.status === "finished" ? 'green' : (mission.status === "started" ? 'orange' : 'red');
                data.push({ id: "F" + key + "M" + mission.id, level: 2, text: text, color: color });
                mission.scannedBaggages.forEach(id => {
                    const b = flight.baggages?.find(b => b.id === id);
                    if (b && b.status !== status.baggage.name) {
                        data.push({ id: b.id, level: 3, text: String(b.number), color: b.importedBy ? "grey" : undefined });
                    }
                })
            });          
        });
        return <ModalExt open={!!status} closeIcon onClose={() => this.props.r.setInReduxState({ scannerModalOpenedFor: undefined })}>
            <Modal.Header><>{_msg("BaggageScanner")}: <Label className="small-margin-left" size="large" color={status.baggage.color}>{status.baggage.label}</Label></></Modal.Header>
            <Modal.Content><div style={{ minHeight: 200 }}><AdvancedSelectorHOC id={"scanner_" + this.props.s.scannerModalOpenedFor} data={data} callback={(item) => this.updateBaggage(Number(item.id), {"status": status.baggage.name, ...status.additionalFields})} /></div></Modal.Content>
        </ModalExt>;
    }

    render() {
        const props = this.props;
        const { loading, data, flights, conveyorEjects, currentConveyorEject, displayedFlights, textSearched } = this.props.s;
        const scanButtons = this.getBaggageStatusSettings().filter(b => b.displayMode === 0);
        return (<><div className="flex-container less-padding FlightsAssignmentsPage">
            <Segment data-testid={flightsAssignmentsTestids.topBar} className="flex-container-row flex-center">
            {/* // TODO CC: keep it temporary, but it isn't used anymore; to be deleted if no specs changes from client */}	
                {/* <div data-testid="FlightsAssignmentsPage_dropdown" className="flex-center FlightsAssignmentsPage_parent_dropdown">
                    <DropdownExt selectProps={{ className: "FlightsAssignmentsPage_dropdown", isClearable: false, closeMenuOnSelect: true }}
                        allowMultiple={false} options={conveyorEjects ? conveyorEjects : []}
                        selected={!Utils.isNullOrEmpty(currentConveyorEject) ? [currentConveyorEject!] : []}
                        onChange={(selected:DropdownOption[]) => {props.r.changeSelection(selected[0])}} />                   
                </div> */}
                <Button key="refresh" data-testid={flightsAssignmentsTestids.refresh} className="small-margin-left" positive disabled={loading} icon={<Icon loading={loading} name={loading ? "spinner" : "refresh"} />} onClick={() => this.loadFlights()} />
                {scanButtons.length > 0 ? <>
                    <div className="EntityTablePage_barDivider" />
                    {scanButtons.map((b, index) =>
                        <Button key={flightsAssignmentsTestids.changeStatus + "_" + index} data-testid={flightsAssignmentsTestids.changeStatus + "_" + index} className="no-wrap-no-overflow-ellipsis" style={{ marginRight: "2px", marginLeft: "2px" }} color={b.color} onClick={() => props.r.setInReduxState({ scannerModalOpenedFor: { baggage: b } })}>{b.label}</Button>
                    )}
                </> : null}
                <div className="flex-justify-content-end flex-grow">
                    <Input data-testid={flightsAssignmentsTestids.searchInput} action placeholder={_msg("FlightsAssignmentsPage.searchBaggage")} value={this.state.inputText}
                        onKeyDown={this.onSearchKeyDown} onChange={(e) => this.setState({ inputText: e.target.value })}>
                        <input />
                        <Button data-testid={flightsAssignmentsTestids.search} icon='search' color="green" onClick={(e: any) => { this.props.r.setInReduxState({ textSearched: this.state.inputText }) }} />
                        <Button data-testid={flightsAssignmentsTestids.clearSearch} icon='delete' color="red" onClick={(e: any) => { this.setState({ inputText: '' }); this.props.r.setInReduxState({ textSearched: '' }) }} />
                    </Input>                    
                </div>
            </Segment>
            <p></p>
            {<Card.Group centered data-testid={flightsAssignmentsTestids.cardGroup}>
                {displayedFlights?.map((id, index) => 
                    <Card data-testid={flightsAssignmentsTestids.card + "_" + index} key={id} className={"FlightsAssignmentsPage_card" + (data[id].conveyorEject?.color ? " FlightsAssignmentsPage_card_border" : "")} style={{borderColor: data[id].conveyorEject?.color}}>
                        <Card.Content>
                            <Flight key={id} rawFlight={flights[id]} dataFlight={data[id]} details={{ hideMessageDisplayFilteredBaggageTags: true, onClick: () => this.props.r.setInReduxState({drawerOpenedFor: flights[id].id}) }} missionsBar={{onAddClick: () => this.props.r.setInReduxState({modalOpenedFor: flights[id].id}) }} />
                        </Card.Content>       
                    </Card>)}
            </Card.Group>}
        </div>
        {this.renderDrawer()}
        {this.renderAddBaggageModal()}
        {this.renderScannerModal()}
        {this.renderModal()}
        {this.renderConfirmDeleteMission()}
        </>);
    }
}


function Baggage(props: { data: BaggageData , highlight?: boolean } & { dataTestId?: string }) {
    return <Label data-testid={props.dataTestId} key={props.data.id} color={props.data.importedBy ? "grey" : undefined} className="FlightsAssignmentsPage_label">
        <span data-testid={flightsAssignmentsTestids.tagNumber}>{props.highlight ? <mark>{props.data.number}</mark> : props.data.number}</span><span data-testid={flightsAssignmentsTestids.tagDate} style={{color: "green"}}>{props.data.status === "scanned" && props.data.date ? " @" + moment(props.data.date).format(Utils.timeFormat) : ""}</span>
    </Label>;
}

function Mission(props: MissionData & { dataTestId?: string } ) {
    const color = props.status === "finished" ? 'green' : (props.status === "started" ? 'orange' : 'red');
    const borderColor = props.type?.color ? props.type.color : undefined;
    return <Button data-testid={props.dataTestId}
                className="less-padding FlightsAssignmentsPage_label FlightsAssignmentsPage_mission_border"
                color={color} style={{ borderColor: borderColor }}
                onClick={() => AppMetaTempGlobals.history.push(missionEntityDescriptor.getEntityEditorUrl(props.id))}
            >
        <div className={"flex-container-row flex-center gap5"}>
            <span data-testid={flightsAssignmentsTestids.missionText}>{props.humanResource ? props.humanResource + (props.equipmentResource ? " / " + props.equipmentResource : "") : _msg("FlightsAssignmentsPage.waitingDriver")}</span>
            <Label data-testid={flightsAssignmentsTestids.missionNbScanned} size="small" circular>{props.processedBaggages}</Label>
        </div>
    </Button>;
}

function Flight(props: {
    rawFlight: FlightsAssignmentsPage_Flight,
    dataFlight: FlightData,
    details?: { onClick?: () => void, hideMessageDisplayFilteredBaggageTags?: boolean }
    missionsBar?: { onAddClick: () => void }
}) {
    const { rawFlight, dataFlight, details, missionsBar } = props;
    const lineClassName = "flex-container-row flex-center";
    return <><h4 data-testid={flightsAssignmentsTestids.flight} className="flex-container">       
        <Button data-testid={flightsAssignmentsTestids.flightTop} style={{ cursor: details?.onClick ? undefined : "default" }} className="less-padding" primary onClick={details?.onClick}><div className={lineClassName + " justify-content-space-between"}>
            <span>{rawFlight.airline}{rawFlight.number}</span>
            <span>{rawFlight.galery?.name} // {rawFlight.parking?.name}</span>
            {dataFlight.criticality ? <Label horizontal size="large"><div className="flex-container-row gap3">
                <span style={{ color: dataFlight.criticality.color }} data-testid={flightsAssignmentsTestids.criticality}>{dataFlight.criticality.value}</span>
                <Popup key={rawFlight.id} wide='very'
                    popperModifiers={[{ name: "preventOverflow", options: { boundariesElement: "offsetParent" } }]}
                    trigger={<Icon name="info circle" />}
                    content={<Interweave content={dataFlight.criticality.text} />}
                />
            </div></Label> : <></>}
        </div></Button>
        <div className={lineClassName + " less-padding"}>
            <span className="small-margin-right">{rawFlight.destination}</span>
            <span>{rawFlight.planeIdentifier}</span>
        </div>
        <div className={lineClassName + " justify-content-space-between less-padding"}>
            <div className="flex-container" >
                <span>{_msg("deliveryReport.sd")} {moment(rawFlight.initialDate).format(Utils.timeFormat)}</span>
                <span>{_msg("deliveryReport.ed")} {moment(rawFlight.date).format(Utils.timeFormat)}</span>
            </div>           
            <div className={lineClassName}><Label basic size="large" circular color="orange"><span data-testid={flightsAssignmentsTestids.nbScanned}>{dataFlight.scannedBaggages}</span>/<span data-testid={flightsAssignmentsTestids.nbTotal}>{dataFlight.totalBaggages}</span></Label></div>
        </div>
        {missionsBar && <div>{dataFlight.missions?.map((m: MissionData, index) => <Mission dataTestId={flightsAssignmentsTestids.cardBtnMission + "_" + index} key={m.id} {...m} />)}
            <Button icon="plus" data-testid={flightsAssignmentsTestids.flightAddMission} className="FlightsAssignmentsPage_label" onClick={missionsBar.onAddClick} />
        </div>}
        
    </h4>
    {dataFlight.messageFlightHasBaggageTags && <Message data-testid={flightsAssignmentsTestids.messageFlightHasBaggageTags} key="1" className="less-padding no-margin small-margin-top" color='green'>{dataFlight.messageFlightHasBaggageTags}</Message>}
    {!details?.hideMessageDisplayFilteredBaggageTags && dataFlight.messageDisplayFilteredBaggageTags && <Message data-testid={flightsAssignmentsTestids.messageDisplayFilteredBaggageTags} key="2" className="less-padding no-margin small-margin-top" color='green'>{dataFlight.messageDisplayFilteredBaggageTags}</Message>}
    </>
}

export const FlightsAssignmentsPageUrl = "/flightsAssignments";
export const FlightsAssignmentsPageRRC = ReduxReusableComponents.connectRRC(FlightsAssignmentsPageState, FlightsAssignmentsPageReducers, FlightsAssignmentsPage);
