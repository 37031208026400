import { FilterOperators } from "@crispico/foundation-gwt-js";
import { apolloClient, Optional, TestUtils, Utils } from "@crispico/foundation-react";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { Sort } from "@crispico/foundation-react/components/CustomQuery/SortBar";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { InitializationsForClient, MissionsAwaitingForDriversPageRendererMode } from "app";
import gql from "graphql-tag";
import moment from "moment";
import React from "react";
import { Button, Header, Icon, Label, Message, Segment } from "semantic-ui-react";
import { MISSIONS_AWAITING_FOR_DRIVERS_PAGE_LOAD_MISSIONS_QUERY } from "../flight/flightsAssignmentsPage/queries";
import { MissionsAwaitingForDriversPage_Mission } from "apollo-gen/MissionsAwaitingForDriversPage_Mission";
import { MissionsAwaitingForDriversPage_loadMissionsQuery, MissionsAwaitingForDriversPage_loadMissionsQueryVariables } from "apollo-gen/MissionsAwaitingForDriversPage_loadMissionsQuery";
import { missionsAwaitingForDriversPageMenuEntry } from "./Mission2EntityDescriptor";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";

type MissionData = {
    headerInfo: string;
    headerFlightsInfo: FlightInfo[];
    displayWaitingInfo?: boolean;
}

type FlightInfo = {
    departure: Optional<boolean>;
    headerInfo: string;
}

export class MissionsAwaitingForDriversPageState extends State {
    missions: { [key: string]: MissionsAwaitingForDriversPage_Mission } = {};
    data: { [key: string]: MissionData } = {};
}

export class MissionsAwaitingForDriversPageReducers<S extends MissionsAwaitingForDriversPageState = MissionsAwaitingForDriversPageState> extends Reducers<S> {

}

export class MissionsAwaitingForDriversPage extends React.Component<RRCProps<MissionsAwaitingForDriversPageState, MissionsAwaitingForDriversPageReducers>> {

    private timer: number | undefined = undefined;
    private static REFRESH_RATE: number = 5000; // 5s

    protected async loadMissions() {
        const { tempSettingsXops, currentOrganization } = AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().initializationsForClient as InitializationsForClient;
        const organizationSettings = tempSettingsXops.tempUnitSettings.find(s => s.organizationId === currentOrganization?.id);
        const missionTypes = organizationSettings?.missionTypesToDisplay;
        const filters: Filter[] = [
            Filter.create("status", FilterOperators.forString.equals, "new"),          
            Filter.create("creationDate", FilterOperators.forDate.greaterThan, moment(Utils.now()).add(-12, "hours").toISOString()),
            Filter.create("creationDate", FilterOperators.forDate.lessThan, moment(Utils.now()).add(12, "hours").toISOString()),
            Filter.create("humanResource", FilterOperators.forEntityManyToOne.isEmpty, "")
        ];
        if (missionTypes && missionTypes.length > 0) {
            let missionTypesFilter = Filter.createComposed(FilterOperators.forComposedFilter.or, []);
            for (let i = 0; i < missionTypes.length; i++) {
                missionTypesFilter.filters?.push(Filter.create("type", FilterOperators.forEntityManyToOne.equals, missionTypes[i].id.toString()));
            }
        }
        const sorts: Sort[] = [{ field: "creationDate", direction: "ASC" }];

        const result = (await apolloClient.query<MissionsAwaitingForDriversPage_loadMissionsQuery, MissionsAwaitingForDriversPage_loadMissionsQueryVariables>({
            query: MISSIONS_AWAITING_FOR_DRIVERS_PAGE_LOAD_MISSIONS_QUERY,
            variables: FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.and, filters)).sorts(sorts)
        })).data.mission2Service_findByFilter?.results;

        var newMissions: { [key: string]: MissionsAwaitingForDriversPage_Mission } = {};
        var newData: { [key: string]: MissionData } = {};
        if (result) {
            result.forEach(m => {
                newMissions[m.id] = m;
                let rendererMode = MissionsAwaitingForDriversPageRendererMode.DEFAULT;
                if (m.type?.id) {
                    const mTypeSettings = tempSettingsXops.tempMissionTypeSettings.find(s => s.missionType === m.type?.id);
                    if (mTypeSettings) {
                        rendererMode = mTypeSettings.missionsAwaitingForDriversPageRendererMode;
                    }
                }

                let headerInfo = "";
                let headerFlightsInfo: FlightInfo[] = [];
                if (rendererMode === MissionsAwaitingForDriversPageRendererMode.BAGGAGE_LAST_MINUTE) {
                    let conveyorEjects: string[] = [];
                    const { tempSettingsXops } = AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().initializationsForClient as InitializationsForClient;
                    m.missionSubsets?.forEach(ms => {
                        const fj = tempSettingsXops.tempFlightConveyorEjectSettings.find(fj => fj.parkings.find(p => p.id === ms?.flight?.parking?.id));
                        if (fj) {
                            conveyorEjects.push(fj.conveyorEject);
                        }
                    });
                    headerInfo = _msg("FlightsAssignmentsPage.conveyorEject") + " " + (!Utils.isNullOrEmpty(conveyorEjects) ? conveyorEjects?.join(" ") : _msg("general.unknown"));
                } else {
                    // CC: we should have some type of mission informations to display; not with all these ifs & strings
                    // maybe using the FD renderers it will look better?
                    if (!Utils.isNullOrEmpty(m.missionSubsets)) {
                        m.missionSubsets!.forEach(missionSubset => {
                            if (missionSubset) {
                                headerFlightsInfo.push({
                                    departure: missionSubset.flight?.departure,
                                    headerInfo: "[" + (missionSubset.flight?.departure ? _msg("Flight.departure.true.label") : _msg("Flight.departure.false.label")) + "] " + missionSubset.flight?.name + " "
                                    + (missionSubset.flight?.date ? moment(missionSubset.flight?.date).format(Utils.timeFormat) : '') + " " + missionSubset.flight?.parking?.name + " " + missionSubset.flight?.planeIdentifier + " "
                                });
                            }
                        });
                    }
                    let startAddress, endAddress;
                    if (m.isSpecial) {
                        startAddress = m.startAddress?.name || "";
                        endAddress = m.endAddress?.name || "";
                    } else {
                        startAddress = m.objectActionGroups.map(oag => oag.object.startAddress?.name || "").join(" ");
                        endAddress = m.objectActionGroups.map(oag => oag.object.endAddress?.name || "").join(" ");
                    }
                    if (m.type?.name) {
                        headerInfo = m.type?.name + " ";
                    }
                    headerInfo += "[";
                    if (startAddress) {
                        headerInfo += startAddress + " " + _msg("general.at", moment(m.startTime).format(Utils.timeFormat));
                    } else {
                        headerInfo += moment(m.startTime).format(Utils.timeFormat);
                    }
                    headerInfo += " - ";
                    if (endAddress) {
                        headerInfo += endAddress + " " + _msg("general.at", moment(m.endTime).format(Utils.timeFormat));
                    } else {
                        headerInfo += moment(m.endTime).format(Utils.timeFormat);
                    }
                    headerInfo += "]";
                }
                newData[m.id] = {
                    headerInfo: headerInfo,
                    headerFlightsInfo: headerFlightsInfo,
                    displayWaitingInfo: rendererMode === MissionsAwaitingForDriversPageRendererMode.BAGGAGE_LAST_MINUTE
                }
            })
        }
        this.props.r.setInReduxState({ missions: newMissions, data: newData });
    }

    protected async assignHumanResourceToMission(missionId: number, humanResourceId?: number) {
        (await apolloClient.mutate({
            mutation: gql(`mutation mission2Service_assignHumanResourceToMission($missionId: Long, $humanResourceId: Long) {
                mission2Service_assignHumanResourceToMission(missionId: $missionId, humanResourceId: $humanResourceId)
        }`), variables: { missionId: missionId, humanResourceId: humanResourceId }
        }));
        if ((AppMetaTempGlobals.appMetaInstance as any).getCurrentHumanResource()) {
            AppMetaTempGlobals.history.push("/xops-mobile/missions");
        } else {
            this.loadMissions();
        }
    }

    private startTimer() {
        this.timer = window.setTimeout(() => {
            this.loadMissions();
            this.startTimer();
        }, MissionsAwaitingForDriversPage.REFRESH_RATE);
    }

    private stopTimer() {
        clearTimeout(this.timer);
    }

    componentDidMount() {
        if (TestUtils.storybookMode) {
            return;
        }
        this.loadMissions();
        this.startTimer();
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    protected renderMission(id: any) {
        const rawMission = this.props.s.missions[id];
        const dataMission = this.props.s.data[id];
        const headerInfoSplit = dataMission.headerInfo.split("[");
        const headerInfo = headerInfoSplit[0].trim();
        const missionInfo = headerInfoSplit[1] ? "[" + headerInfoSplit[1] : undefined;
        const missionColor = rawMission.type?.color && !dataMission.displayWaitingInfo ? rawMission.type?.color : undefined;
        var missionInfoElements: JSX.Element[] = Utils.isNullOrEmpty(dataMission.headerFlightsInfo) ? [<Header>{missionInfo}</Header>] : [];
        dataMission.headerFlightsInfo?.forEach((flightInfo: any) => {
            const missionInfoElement = missionInfo ? <div className="flex-container-row flex-center small-margin-bottom" >
                <Icon className="tiny-margin-right" size="large" name="plane" style={{ transform: "rotate(" + (flightInfo.departure ? -45 : 45) + "deg)" + (flightInfo.departure ? "" : "translate(25%, -25%)")}} />
                <Header> {flightInfo.headerInfo + missionInfo}</Header>
            </div> : <></>
            missionInfoElements.push(missionInfoElement);
        });
        
        const duration = moment.duration(moment(rawMission.creationDate).diff(Utils.now()));
        return <Message key={id} color="red" className="less-margin-top-bottom MissionsAwaitingForDriversPage_message">
            <Message.Header>
                <div className="flex-container-row flex-center flex-grow small-margin-bottom">
                    <div className="flex-container-row flex-center flex-grow">
                        {headerInfo ? <div className="flex-container-row flex-center MissionsAwaitingForDriversPage_header" style={{ color: missionColor, backgroundColor: !dataMission.displayWaitingInfo ? 'lightgray' :  undefined}}>
                        {!dataMission.displayWaitingInfo  ? <Icon size="large" name="exclamation circle" /> : null}
                            <Header style={{ color: missionColor }}>{headerInfo}</Header>
                        </div> : missionInfoElements}
                    </div>
                    {dataMission.displayWaitingInfo ? <div className="flex-container float-right MissionsAwaitingForDriversPage_message_right">
                        <Header as='h6' style={{ color: "var(--textColor)" }}>{_msg("MissionsAwaitingForDriversPage.waitingFor")}</Header>
                        <span>{duration.humanize()}</span>
                        <Header as='h6' style={{ color: "var(--textColor)" }}>{_msg("MissionsAwaitingForDriversPage.from", moment(rawMission.creationDate).format(Utils.timeFormat))}</Header>
                    </div> : null}
                    <Button primary onClick={() => this.assignHumanResourceToMission(id, undefined)}>{_msg("general.activate")}</Button>
                </div>
                {headerInfo ? missionInfoElements : null}
                
            </Message.Header>
            {rawMission.comment && <Message.List>
                <Message.Item>{rawMission.comment}</Message.Item>
            </Message.List>}
        </Message>
    }

    render() {
        return <div className="flex-container flex-grow less-padding" >
            <Header as="h3" className="small-margin-top no-margin"><Icon name={missionsAwaitingForDriversPageMenuEntry().icon as SemanticICONS} /> {missionsAwaitingForDriversPageMenuEntry().content}</Header>
            <Segment className="flex-container flex-grow less-margin-top-bottom flex-center">                    
                {Object.keys(this.props.s.missions).map(id => this.renderMission(id))}           
            </Segment>
            </div>;
    }
}

export const MissionsAwaitingForDriversPageHOC = ReduxReusableComponents.connectRRC(MissionsAwaitingForDriversPageState, MissionsAwaitingForDriversPageReducers, MissionsAwaitingForDriversPage);


