import { configureStore, EnhancedStore } from "@reduxjs/toolkit";
import _ from "lodash";
import React, { Children, ComponentType, ReactElement, ReactNode } from "react";
import { connect, MapStateToProps, Provider, ReactReduxContext, ReactReduxContextValue } from "react-redux";
import { Action, Reducer, Store, StoreEnhancerStoreCreator } from "redux";
import { Class, ClassToNameRegistry } from "../utils/ClassToNameRegistry";
import { Utils } from "../utils/Utils";
import { createDispatchersFromReducersClass, createReducerFromReducersClass, ParentLongIdContext, withParentLongId } from "./ReduxReusableComponentsUtils";

export const RRC_ACTION_PREFIX = "rrc::";
export const RRC_ACTION_CREATED_SUFFIX = "/created!";
export const RRC_ACTION_DESTROYED_SUFFIX = "/destroyed!";
export const RRC_SINGLETON = "RRC_SINGLETON";

/**
 * A state class should extend this (directly or indirectly). 
 * Should include only attributes (w/ initializers). This class is constructed and all its
 * props are copied to a new object that's the default Redux state.
 */
export class State { }

/**
 * A reducers class should extend this. Directly or indirectly.
 * On each reducer invocation, a new instance of this class is created, having the curent "immer" powered state.
 * Hence "mutative" logic is allowed, exactly like in `createReducer()` or `createSlice()` from Redux Toolkit.
 * 
 * Don't store any info as attributes. It's contrary to the "state" as unique source of truth. And the instance dies
 * anyway after the invocation of the function.
 * 
 * Please prefix private or protected functions w/ "_". E.g. "_myFunction". They won't appear as a case reducer.
 */
export class Reducers<S extends State = State> {

    constructor(public s: S) {
    }

    /**
     * We observed that in many cases, the reducers don't have other logic than to just store a value in the state.
     * Getting tired of writing a lot of trivial reducer functions that just store a value in the state, we wrote this
     * function. 
     * 
     * However, use it w/ caution. E.g. if you want to do simple things, such as modifying a flag or so from the state, from
     * an inline handler, then this function is the way to go. If, on the other side, you have a small logic / algorithm, then
     * try to write it entirely as a reducer function. So avoid having big pieces of code that have a lot of `setInReduxState()`.
     * 
     * And don't forget: if you call this function, the modification is propagated immediately in the state. But it's not propagated
     * immediately to the props of your component. This will happen on the next render cycle.
     */
    setInReduxState(modificationsToApply: Partial<S>) {
        // TODO: recursive impl copied from the other setInReduxState(); and tests
        Object.assign(this.s, modificationsToApply);
    }
}

export interface RRCProps<S extends State, R extends Reducers> {
    s: S;
    r: R;
}

/**
 * The original reducer passed to the store is wrapped by a new one, with the follwing logic:
 * 
 * * If the action comes from a RRC (e.g. is of form `RRC_ACTION_PREFIX + "myPage/myComponent/mySubComponent"`), then it is dispatched ONLY to
 * the reducer that matches that path. The "normal" state + the state of other RRCs is copied from the previous state.
 * * Otherwise, if it's a "normal" / non RRC action, the original logic is invoked. So the dispatchers of RRCs are not invoked, and the old state
 * for all RRCs is copied from the previous state.
 * * For the special RRC action that ends with RRC_ACTION_DESTROYED_SUFFIX => the corresponding key is removed from the new state.
 */
export interface StoreForRRCs<S = any, A extends Action = any> extends Store<S, A> {

    /**
     * A RRC, on mount (actually sooner, on construction), registers its reducer. Uses the RRC path as key, e.g. `myPage/myComponent/mySubComponent`.
     * On unmount, the RRC removes the reducer from this map.
     */
    rrcReducers: { [path: string]: Reducer };
}

export const connectRRCOptionsDefaults = {
    invokeConnect: (mapStateToProps: MapStateToProps<any, any>) => {
        return connect(mapStateToProps, null, null, { forwardRef: true });
    }
}

export type ConnectRRCOptions = Partial<typeof connectRRCOptionsDefaults>;

export class ReduxReusableComponents {

    /**
     * Enhances the store, transforming it into a `StoreForRRCs`. See its doc.
     */
    static storeEnhancer(createStore: StoreEnhancerStoreCreator) {
        return function (reducer: Reducer<any, any>, preloadedState?: any) {
            const newReducer = function (state: any, action: Action<string>) {
                if (action.type.startsWith(RRC_ACTION_PREFIX)) {
                    const result = { ...state };
                    result.rrc = { ...state?.rrc }; // doesn't mind if state.rrc is undefined
                    const actionWoPrefix = Utils.substringAfter(action.type, RRC_ACTION_PREFIX);
                    if (actionWoPrefix.endsWith(RRC_ACTION_DESTROYED_SUFFIX)) {
                        delete result.rrc[Utils.substringBefore(actionWoPrefix, RRC_ACTION_DESTROYED_SUFFIX)];
                        return result;
                    }
                    for (let rrcPath in store.rrcReducers) {
                        if (!actionWoPrefix.startsWith(rrcPath)) {
                            continue;
                        }
                        const previousState = result.rrc[rrcPath];
                        result.rrc[rrcPath] = store.rrcReducers[rrcPath](previousState, action);
                    }
                    return result;
                } else {
                    // normal action
                    const result = reducer(state, action);
                    if (result) {
                        result.rrc = state?.rrc || {};
                    }
                    return result;
                }
            }
            const store: StoreForRRCs = createStore(newReducer, preloadedState) as StoreForRRCs;
            store.rrcReducers = {};
            return store;
        };
    }

    /**
     * Creates a HOC that links the `component` to the `stateClass` and `reducersClass`. Regarding the naming convention, we recommend:
     * ```
     * const MyComponentHOC = connectRRC(MyComponentState, MyComponentReducers, MyComponent);
     * ```
     */
    // TODO I want to force the component to have among the props: id, s, r.
    // I asked here: https://stackoverflow.com/questions/70183835/in-react-typescript-variable-enforce-some-attributes-in-the-props-of-a-comp
    // and here: https://stackoverflow.com/questions/70214702/passing-a-react-component-as-arg-to-a-typescript-function-impose-a-minimal-shap
    // Cf. second question, we almost found a solution, which doesn't work always, hence not usable. More precisely, it doesn't seem to work for extended components, e.g. where we
    // have "class MyExtendedComponent ..." AND "interface MyExtendedComponent ..."
    // static connectRRC<S, R extends Reducers<S>, P extends RRCProps<S, R>>(stateClass: new () => S, reducersClass: new (s: S) => R, component: ComponentType<P>, options?: ConnectRRCOptions): ComponentType<Omit<P, "s" | "r"> & { id: string, ref?: React.Ref<any> }> {
    static connectRRC<S extends State, R extends Reducers<S>, P>(stateClass: new () => S, reducersClass: new (s: S) => R, component: P, options?: ConnectRRCOptions): ComponentType<Omit<GetPropsFromClassCompOrFunctionComp<P>, "s" | "r"> & { id: string, ref?: React.Ref<any> }> {
        if (!options) { options = {}; }
        _.defaults(options, connectRRCOptionsDefaults)

        function mapStateToProps(state: any, ownProps: any) {
            return { s: state.rrc[ownProps.longId] };
        }

        const Hoc1 = options.invokeConnect!(mapStateToProps)(component as any);

        const Hoc2 = class extends React.Component<any> {

            static contextType = ReactReduxContext;

            protected r: Reducers = {} as any;

            protected longId: string;

            protected ref: any;

            constructor(props: any, context: ReactReduxContextValue) {
                super(props);

                const storeForRRCs = context.store as StoreForRRCs;
                if (!storeForRRCs.rrcReducers) {
                    throw new Error("The store wasn't enhanced, so it doesn't support RRCs. Please use 'ReduxReusableComponents.storeEnhancer'.");
                }

                if (!props.id) {
                    throw new Error("The RRC hasn't got an 'id' property.");
                }

                this.longId = props.parentLongId ? props.parentLongId + "/" + props.id : props.id;

                if (props.id === RRC_SINGLETON) {
                    this.longId = ReduxReusableComponents.getLongIdForSingleton(component as any);
                }

                if (storeForRRCs.rrcReducers[this.longId]) {
                    if (props.id === RRC_SINGLETON) {
                        throw new Error("You already declared this component as singleton. You cannot declare it as singleton several times.");
                    }
                    throw new Error("The ID of a RRC should be unique. There exists already a RRC with the long ID = " + this.longId);
                }

                const r = createReducerFromReducersClass(stateClass, reducersClass, this.longId);
                storeForRRCs.rrcReducers[this.longId] = r;

                this.r = createDispatchersFromReducersClass(reducersClass, this.longId, context.store.dispatch)

                this.ref = props.forwardedRef || React.createRef();

                // dispatching a dummy action, to force the invocation of the reducer,
                // which will "bootstrap" the corresponding state
                context.store.dispatch({ type: RRC_ACTION_PREFIX + this.longId + RRC_ACTION_CREATED_SUFFIX });
            }

            componentDidMount(): void {
                if (this.props.id === RRC_SINGLETON) {
                    // @ts-ignore
                    component["INSTANCE"] = this.ref.current;
                }                
            }

            componentWillUnmount() {
                delete (this.context.store as StoreForRRCs).rrcReducers[this.longId];
                
                if (this.props.id === RRC_SINGLETON) {
                    // @ts-ignore
                    delete component["INSTANCE"];
                }   

                // will clear the portion of state corresponding to this component
                this.context.store.dispatch({ type: RRC_ACTION_PREFIX + this.longId + RRC_ACTION_DESTROYED_SUFFIX });
            }

            render() {
                // use a provider to publish the current longId, which will be consumed by children
                // via context. Child components will have this injected into their props under the name "parentLongId" thanks to withParentLongId() HOC
                return <ParentLongIdContext.Provider value={this.longId}>
                    <Hoc1 {...this.props} ref={this.ref} r={this.r} longId={this.longId} />
                </ParentLongIdContext.Provider>
            }

        }

        const Hoc3 = withParentLongId(Hoc2);
        return Hoc3 as any;
    }

    static getLongIdForSingleton(clazz: Class<any>) {
        return ClassToNameRegistry.INSTANCE.getClassName(clazz) + "." + RRC_SINGLETON;
    }

    static WrapWithEnhancedStore = class extends React.Component<WrapWithEnhancedStoreProps> {

        store: EnhancedStore;

        /**
         * Normally the value in here is the same as the one above. However, maybe there will be cases/tests
         * that will use in parallel 2 such components, and we don't want to cause issues.
         */
        static CURRENT_STORE: EnhancedStore;

        constructor(props: WrapWithEnhancedStoreProps) {
            super(props);
            if (props.store) {
                this.store = props.store;
            } else {
                this.store = configureStore({
                    reducer: (state, action) => state, enhancers: [ReduxReusableComponents.storeEnhancer],
                    preloadedState: props.preloadedStateRRC && { rrc: props.preloadedStateRRC }
                });
            }
            ReduxReusableComponents.WrapWithEnhancedStore.CURRENT_STORE = this.store;
        }

        render() {
            return <Provider store={this.store}>
                <ReduxReusableComponents.MountSingletonsFirst>
                    {this.props.children}
                </ReduxReusableComponents.MountSingletonsFirst>
            </Provider>
        }

    }

    static getStateSliceFromLastWrapWithEnhancedStore(key: string) {
        return ReduxReusableComponents.WrapWithEnhancedStore.CURRENT_STORE.getState().rrc[key];
    }

    /**
     * Renders in 2 phases. 1) Only the RRCs that are singletons. 2) The other components. 
     * This is needed when the code in 2) needs (early) the INSTANCEs populated in 1).
     * 
     * NOTE: The children are treated like a list. No in-depth processing is made.
     */
    static MountSingletonsFirst = class extends React.Component<{ children: ReactNode | ReactNode[] }, MountSingletonsFirstState> {
        
        componentDidMount() {
            this.setState({ singletonsMounted: true });
        }
        
        render() {
            const children: any[] = Array.isArray(this.props.children) ? this.props.children : [this.props.children];
            if (this.state?.singletonsMounted) {
                return this.props.children;
            } else {
                return children.filter(child => child?.props?.id === RRC_SINGLETON);
            }
        }
    }

}

interface WrapWithEnhancedStoreProps {

    /**
     * If given, then it will be used. This may be needed in special cases, such as tests that want to manipulate the store.
     * Otherwise (i.e. the general case) a new store will be created.
     */
    store?: EnhancedStore;

    /**
     * The preloaded state that will be used when creating the state. Actually, the preloaded state will be
     * `{ rrc: preloadedStateRRC }`, hence the **RRC** suffix.
     */
    preloadedStateRRC?: Object;
}

interface MountSingletonsFirstState {
    singletonsMounted: boolean
}

/**
 * Extracts the type of props, given a type of class component.
 * Initially I was using GetProps from react-redux. But I had issues, and this simpler version seems to work better in my case.
 */
type GetPropsSimple<C> = C extends { props: infer P } ? P : never

/**
 * Extract the type of class component, given its class.
 */
type GetInstance<C> = C extends new (...args: any) => infer P ? P : never;

/**
 * For a function comp => the type of its first/"props" param. For a class (not instance!) of class comp => the type of its props.
 */
type GetPropsFromClassCompOrFunctionComp<C> = C extends (props: infer P) => any ? P : GetPropsSimple<GetInstance<C>>;

/**
 * Returns the props type of the given class component & new props.
 */
export type EnrichProps<PARENT_CLASS_COMPONENT, STATE, REDUCERS, NEW_PROPS> = GetPropsSimple<PARENT_CLASS_COMPONENT> & { s: STATE, r: REDUCERS } & NEW_PROPS;
