export interface EntityRelation {
    from: string,
    to: string,
    fromFieldName?: string,
    fieldName?: string
}

export const humanResourceToHumanResourceSchedule : EntityRelation = {
    from: "HumanResource",
    to: "HumanResourceSchedule"
}

export const humanResourceToMission2 : EntityRelation = {
    from: "HumanResource",
    to: "Mission2"
}

export const flightToTask : EntityRelation = {
    from: "Flight",
    to: "Task",
    fieldName: "taskGroup"
}

export const taskToObjectActionGroup : EntityRelation = {
    from: "Task",
    to: "ObjectActionGroup",
    fromFieldName: "objectId",
    fieldName: "object"
}

export const mission2ToObjectActionGroup : EntityRelation = {
    from: "Mission2",
    to: "ObjectActionGroup"
}

export const dtoRootRelations = [
    humanResourceToHumanResourceSchedule,
    // humanResourceToMission2,
    // flightToTask,
    // taskToObjectActionGroup,
    // mission2ToObjectActionGroup
];