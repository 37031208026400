import { Optional } from "@crispico/foundation-react";
import { FieldInterval } from "@crispico/foundation-react/entity_crud/CrudSettings";
import { createTestids } from "@famiprog-foundation/tests-are-demo";
import { Cell, CellProps, Column, ColumnHeaderProps, Table } from "fixed-data-table-2";
import 'fixed-data-table-2/dist/fixed-data-table.css';
import _ from "lodash";
import React, { ReactNode } from "react";
import Measure from "react-measure";
import { NavLink } from "react-router-dom";
import { Button, Card, Divider, Header, Icon, Menu, SemanticSIZES } from "semantic-ui-react";
import { AppMetaTempGlobals } from "../AppMetaTempGlobals";
import { Filter } from "../components/CustomQuery/Filter";
import { FilterForm } from "../components/CustomQuery/FilterForm";
import { Sort } from "../components/CustomQuery/SortBar";
import { ModalExt, Severity } from "../components/ModalExt/ModalExt";
import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, StateFrom } from "../reduxHelpers";
import { FIELDS_READ, Utils } from "../utils/Utils";
import { CrudViewer } from "./CrudViewer";
import { EntityDescriptor, FieldDescriptor } from "./EntityDescriptor";
import { EntityNumberDisplayed } from "./EntityNumberDisplayed";
import { EntityTableMode, EntityTablePageProps } from "./EntityTablePage";

export interface ColumnConfigConfig {
    columns: ColumnDefinition[] | null;
}

export interface ColumnDefinition {
    width: number;
    name: string;
}

export type EntityTableCommonProps = {
    onSelectItem?: (entityId: any) => void
}

const CARDS_MAX_FIELDS = 10;
export const CONTENT_MENU_COLUMN_WIDTH = 60;

export const sliceEntityTableSimple = createSliceFoundation(class SliceEntityTableSimple {

    initialState = {
        entities: [] as any[],

        // TODO for GS: de redenumit in "selected"
        /**
         * @see EntityTableSimpleProps.selectedIsRowIndex
         */
        selected: undefined as unknown as number,
        contextMenuPosition: false as [number, number] | boolean,
        contextMenuRowIndex: -1
    }

    reducers = {
        ...getBaseReducers<SliceEntityTableSimple>(this),

        closeContextMenu(state: StateFrom<SliceEntityTableSimple>) {
            state.contextMenuPosition = false;
            state.contextMenuRowIndex = -1;
        }
    }

    impures = {
        ...getBaseImpures<SliceEntityTableSimple>(this),
    }

});

export type EntityTableSimpleProps = EntityTableCommonProps & PropsFrom<typeof sliceEntityTableSimple> & {
    entityDescriptor: EntityDescriptor,
    columns?: ColumnDefinition[],
    onDoubleClickItem?: (entity: any, rowIndex: number) => void,
    renderContextMenuItems?: (entity: any, rowIndex: number) => ReactNode,
    renderFooter?: () => ReactNode,

    /**
     * If present => inside ...Page; else => embedded mode
     */
    parentProps?: EntityTablePageProps;
    screen?: string;
    onColumnMoved?: (event: any) => void;
    onColumnResized?: (width: number, name: string) => void;

    /**
     * By default, `selected` (in the Redux state) means the ID of the selected element. 
     * This has usually advantages. However, there are cases where it's more convenient to 
     * remember the selection not by ID but by row index. E.g. if we add multiple (new) elements,
     * ID is not usable, because it's 0 for all new elements.
     */
    selectedIsRowIndex?: boolean,
    mode?: EntityTableMode,
    compactBar?: ReactNode

    "data-testid"?: string;

    /**
     * When used as ConnectedComponentInSimpleComponent,
     * needed to provide entities + params.
     */
    entitiesAsParams?: any[];
    selectedAsParams?: number;
};

type LocalState = {
    measuredWidth: number | undefined,
    measuredHeight: number | undefined,
}

export const entityTableSimpleTestids = createTestids("EntityTableSimple", {
	headerCell: "",
});

export class EntityTableSimple<P extends EntityTableSimpleProps = EntityTableSimpleProps> extends React.Component<P, LocalState> {
    /**
     * Maybe people need to override this. Hence as a public field. If more customization is needed, should be 
     * tranfsformed into a function receiving params.
     */
    cellComponentType: new (props: any) => Cell = Cell;

    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: Readonly<EntityTableSimpleProps>) {
        this.componentDidUpdateInternal(prevProps);
    }

    protected componentDidUpdateInternal(prevProps?: EntityTableSimpleProps) {
        if (this.props.entitiesAsParams && (!prevProps || !_.isEqual(prevProps.entitiesAsParams, this.props.entitiesAsParams))) {
            this.props.dispatchers.setInReduxState({ entities: this.props.entitiesAsParams });
        }
        if (this.props.selectedAsParams !== undefined && prevProps && prevProps.selectedAsParams !== this.props.selectedAsParams) {
            this.props.dispatchers.setInReduxState({ selected: this.props.selectedAsParams });
        }
    }

    /**
     * @param fieldDescriptorChain Usually we receive only one `FieldDescriptor`. But the user may have chosen a composed/sub field,
     * e.g. for `equipmentResource`: `type.name`. In this case we receive 2 descriptors:
     * * `EquipmentResource.type`
     * * `EquipmentResourceType.name`
     * 
     * We need both of them, in order to properly display the label
     */

    filterFormRef = React.createRef<FilterForm>();
    renderHeaderCell(props: ColumnHeaderProps, fieldDescriptorChain: FieldDescriptor[], index: number) {
        const simpleField = fieldDescriptorChain.length === 1 ? fieldDescriptorChain[0] : undefined;
        let composedFieldLabel = '';
        let composedFieldName = '';
        const icon = fieldDescriptorChain[fieldDescriptorChain.length - 1].getIcon();
        if (fieldDescriptorChain.length > 1) {
            for (const fd of fieldDescriptorChain) {
                const l = fd.getLabel();
                composedFieldLabel += l + '.';
                composedFieldName += fd.name + '.';
            }
            composedFieldLabel = composedFieldLabel.substring(0, composedFieldLabel.length - 1);
            composedFieldName = composedFieldName.substring(0, composedFieldName.length - 1);
        }

        const sort = this.props.parentProps?.customQueryBar.sortBar.sorts.find((s: Sort) => {
            return s.field === (simpleField ? simpleField?.name : composedFieldName);
        });
        const filter = this.props.parentProps?.customQueryBar.customQuery?.customQueryDefinitionObject.filter.filters?.find((f: Filter) =>
            f.enabled && f.field === (simpleField ? (simpleField!.name + (simpleField!.filterAsManyToMany ? "." + simpleField!.filterAsManyToMany : "")) : composedFieldName));

        let titleColor = '';
        if (filter) {
            titleColor = 'ColumnHeader_hasFilter';
        } else if (sort) {
            titleColor = 'ColumnHeader_hasSort';
        }

        let label: string;
        if (!simpleField) { label = composedFieldLabel; }
        else {
            label = simpleField.getLabel();
        }
        return <this.cellComponentType data-testid={entityTableSimpleTestids.headerCell + "_" + index} {...props} onClick={(e) => this.handleHeaderClicks(e, simpleField?.name, composedFieldName)}><div className='flex-center'><label className={titleColor}>{icon}{label}</label> {sort ? <Icon name={sort.direction === 'ASC' ? 'sort up' : 'sort down'} /> : null} </div></this.cellComponentType>;
    }

    /**
     * May be overridden e.g. to add props directly to `<Cell>`, e.g. a style, etc.
     */
    protected renderCell(props: CellProps, fieldDescriptorChain?: FieldDescriptor[]) {
        const cellContent = this.getCellContent(props, fieldDescriptorChain);
        const fd = cellContent?.fieldDescriptor;
        return (<this.cellComponentType {...props}
            className={this.props.selected !== undefined && (this.props.selected === this.getEntity(props.rowIndex!)?.id || (this.props.selectedIsRowIndex && this.props.selected === props.rowIndex)) ? "selectedItem" : undefined}
            style={{ backgroundColor: fd?.getFieldColors(fd.getFieldValue(cellContent?.entity)).backgroundColor, ...props.style }}
            data-testid={`col-${props.columnKey}-row-${props.rowIndex}`}>
            {cellContent?.content}
        </this.cellComponentType>);
    }

    protected getCellContent({ rowIndex, columnKey }: CellProps, fieldDescriptorChain?: FieldDescriptor[]): Optional<{ fieldDescriptor?: Optional<FieldDescriptor>, content: any, entity?: any }> {
        let rowObject = this.getEntity(rowIndex!);
        if (!rowObject) {
            return { content: _msg("entityCrud.table.pending") };
        } else if (fieldDescriptorChain) {
            for (let index = 0; index < fieldDescriptorChain.length - 1; index++) {
                rowObject = rowObject[fieldDescriptorChain[index].getFieldName()]
                if (!rowObject) {
                    break;
                }
            }
            const fd = fieldDescriptorChain[fieldDescriptorChain.length - 1];
            return { fieldDescriptor: fd, entity: rowObject, content: fd.renderField(rowObject) };
        }
        const fd = this.props.entityDescriptor.getField(columnKey as string);
        return { fieldDescriptor: fd, entity: rowObject, content: fd.renderField(rowObject) };
    }

    onSelectItem(itemId: any) {
        this.props.onSelectItem?.call(null, itemId);
    }

    render() {
        return this.renderNewest()
    }

    // CS: :( attributes directly in class; fake double click
    clickCount = 0;
    singleClickTimer: any;
    field = '';

    openFilterFormWithField(entityDescriptor: EntityDescriptor, field: string, position: boolean | [number, number], filterFormRef: React.RefObject<FilterForm>) {
        // CS: :( unfortunately we do here the job of filterBar; we should simply called some function in the filter bar that would 
        // do all the necessary steps
        const filterBar = this.props.parentProps!.dispatchers.customQueryBar.filterBar;
        filterBar.openFilterFormWithField({ entityDescriptor: entityDescriptor.name, field: field, rootFilter: this.props.parentProps!.customQueryBar.customQuery?.customQueryDefinitionObject.filter!, position: position })
    }

    //CZ: I didn't use Cell's handlers because on double click -> onClick handle is called twice and after the double click handler once.
    //That's why i used this approach (setTimeout/clearTimeout)
    handleHeaderClicks(e: any, field: string | undefined, composedFieldName: string) {   
        if (!this.props.parentProps || (!field && composedFieldName === "")) {
            return;
        }

        this.clickCount++;

        if (this.clickCount === 1) {
            this.field = field ? field : composedFieldName;
            this.singleClickTimer = setTimeout(() => {
                if (this.field) {
                    if (this.props.entityDescriptor.getField(this.field).sortable) {
                        this.props.parentProps!.dispatchers.customQueryBar.addSortFromColumnHeader(this.field)
                    } else {
                        Utils.showGlobalAlert({ message: _msg('dto_crud.error.notSortable'), severity: Severity.INFO });
                    }
                }
                this.clickCount = 0;
            }, 400);
        } else if (this.clickCount === 2) {
            if (this.field === field ? field : composedFieldName) {
                clearTimeout(this.singleClickTimer);
                if (!field || this.props.entityDescriptor.getField(field).filterable) {
                    let filterIndex = this.props.parentProps?.customQueryBar.customQuery?.customQueryDefinitionObject.filter.filters?.findIndex((f: Filter) => f.field === (field ? field : composedFieldName));
                    if (filterIndex !== undefined && filterIndex >= 0) {
                        this.props.parentProps!.dispatchers.customQueryBar.filterBar.openFilterForm({ rootFilter: this.props.parentProps?.customQueryBar.customQuery?.customQueryDefinitionObject.filter!, index: filterIndex, position: [e.clientX, e.clientY] });
                    } else {
                        this.openFilterFormWithField(this.props.entityDescriptor, field ? field : composedFieldName, [e.clientX, e.clientY], this.filterFormRef);
                    }
                } else {
                    Utils.showGlobalAlert({ message: _msg('dto_crud.error.notFilterable'), severity: Severity.INFO });
                }
                this.clickCount = 0;
            } else {
                this.clickCount = 1;
                this.singleClickTimer = setTimeout(() => {
                    if (field) {
                        if (this.props.entityDescriptor.getField(field).sortable) {
                            this.props.parentProps!.dispatchers.customQueryBar.addSortFromColumnHeader(field)
                        } else {
                            Utils.showGlobalAlert({ message: _msg('dto_crud.error.notSortable'), severity: Severity.INFO });
                        }
                    }
                    this.clickCount = 0;
                }, 400);
            }
        }
    }

    private getRowCount(): number {
        const { parentProps } = this.props;
        if (parentProps) {
            const newRowCount: number = parentProps.loaded !== -1 ? parentProps.loaded + parentProps.dispatchers.getSlice().options.bulkSize : 0;
            return parentProps.totalCount !== -1 ? Math.min(newRowCount, parentProps.totalCount) : newRowCount;
        }
        return this.props.entities.length;
    }

    protected getHeight(): number {        
        return this.state.measuredHeight ? this.state.measuredHeight - (this.props.renderFooter ?  50 : 0) : 0;
    }

    protected getEntity(rowIndex: number): any | undefined {
        return this.props.parentProps?.dispatchers.getEntityAt(rowIndex) || this.props.entities[rowIndex];
    }

    protected getContextMenuColumn(): ReactNode {
        return <Column width={CONTENT_MENU_COLUMN_WIDTH} key="menu" cell={props => {
            const { rowIndex } = props;
            return <Cell key={rowIndex}>{this.renderContextMenu(rowIndex)}</Cell>
        }
        } />;
    }

    protected renderContextMenu(index: number, size?: SemanticSIZES) {
        // Type="button" is needed in case the table is inside a form, to overpass the following problem: 
        // 'You submitted a Formik form using a button with an unspecified type attribute. If this is not a submit button, please add type="button"'
        return <Button data-testid={this.props["data-testid"] + "_deleteTableEntryButton" + index} primary icon="bars" type="button" size={size} onClick={e => this.props.dispatchers.setInReduxState({ contextMenuPosition: [e.clientX, e.clientY], contextMenuRowIndex: index })} />;
    }

    protected renderColumns() {
        const entityDescriptor = this.props.entityDescriptor;
        let columns: ReactNode[] = [];
        if (this.props.renderContextMenuItems) {
            columns.push(this.getContextMenuColumn());
        }

        const defaultCC = entityDescriptor.getDefaultColumnConfig();
        let propsColumns = !Utils.isNullOrEmpty(this.props.columns) ? this.props.columns! : defaultCC.configObject.columns!;
        const authorizedFields = entityDescriptor.getAuthorizedFields(FIELDS_READ, propsColumns.map((column) => column.name))
        propsColumns = propsColumns.filter(column => authorizedFields[column.name]);

        propsColumns.forEach((column, index) => {
            const fieldDescriptorChain = entityDescriptor.getFieldDescriptorChain(column.name);
            if (fieldDescriptorChain.length > 0) {
                columns.push(<Column key={column.name} columnKey={column.name}
                    allowCellsRecycling width={column.width}
                    isResizable={this.props.onColumnResized ? true : false}
                    isReorderable={this.props.onColumnMoved ? true : false}
                    flexGrow={index === propsColumns.length - 1 ? 1 : undefined}
                    header={props => this.renderHeaderCell(props, fieldDescriptorChain, index)}
                    cell={props => this.renderCell(props, fieldDescriptorChain)} />)
            }
        });
        return columns;
    }

    protected getNumberOfEntities(): number {
        const { parentProps } = this.props;
        if (parentProps) {
            const loaded: number = parentProps.loaded !== -1 ? parentProps.loaded : 0;
            return parentProps.totalCount !== -1 ? Math.max(loaded, parentProps.totalCount) : loaded;
        }
        return this.props.entities.length;
    }

    protected renderCard(index: number) {
        const props = this.props;
        const entity = props.entities[index];
        return entity ? <Card className="EntityTablePage_card">
            <Card.Content>
                <Card.Header>
                    <span className="flex-container-row">
                        <span className="flex-grow-shrink-no-overflow"><Header size="small" as={NavLink} to={props.entityDescriptor.getEntityEditorUrl(entity.id)}>{props.entityDescriptor.toMiniString(entity)}</Header></span>
                        <span>{this.renderContextMenu(index, "mini")}</span>
                    </span>
                </Card.Header>
                <Card.Description>
                    <CrudViewer entityDescriptor={props.entityDescriptor} maxFields={CARDS_MAX_FIELDS} showEmpty={true}
                        entity={entity} fields={props.columns?.filter(c => c?.name !== undefined).map(c => c.name) as string[]} />
                </Card.Description>
            </Card.Content>
        </Card> : null;
    }

    protected renderCards() {
        const props = this.props;
        const size = props.entities.length;
        return <div className="flex-container flex-grow">
            {this.props.compactBar ? this.props.compactBar : null}
            <Card.Group centered data-testid="EntityTablePage_cards">
                {size > 0 ? [...Array(size).keys()].map(index => this.renderCard(index)) : null}
            </Card.Group>
            <Divider />
            <EntityNumberDisplayed ed={props.entityDescriptor} displayed={size} total={this.getNumberOfEntities()} />
            {size !== this.getNumberOfEntities() ? <Button icon="angle double down" content={_msg("general.loadMore")} onClick={() => props.parentProps?.dispatchers.getNextEntities(props.entities[size - 1])} /> : null}
        </div>;
    }

    protected renderTable(columns: ReactNode[]) {
        return <div className="flex-container flex-grow">
            {this.props.compactBar ? this.props.compactBar : null}
            <Measure bounds onResize={contentRect => this.setState({ measuredWidth: contentRect.bounds?.width, measuredHeight: contentRect.bounds?.height })}>
                {({ measureRef }) => (<div className="flex-container flex-grow" ref={measureRef}>
                    {this.state?.measuredWidth === undefined ? <p>Measuring...</p> : // happens only during the first render; so this text is practically not visible
                        <Table rowsCount={this.getRowCount()} rowHeight={50} width={this.state.measuredWidth} maxHeight={this.getHeight()} headerHeight={50} touchScrollEnabled
                            isColumnResizing={false} onColumnResizeEndCallback={(newColumnWidth, columnKey) => this.props.onColumnResized?.(newColumnWidth, columnKey)}
                            isColumnReordering={false} onColumnReorderEndCallback={event => this.props.onColumnMoved?.(event)}
                            onRowClick={(event, rowIndex) => {
                                let selectedItem = this.props.selectedIsRowIndex ? rowIndex : this.getEntity(rowIndex).id;
                                if (selectedItem !== this.props.selected) {
                                    this.props.dispatchers.setInReduxState({ selected: selectedItem });
                                    this.onSelectItem(selectedItem);
                                }
                            }}
                            onRowDoubleClick={(event, rowIndex) => this.props.onDoubleClickItem?.(this.getEntity(rowIndex), rowIndex)}
                            rowClassNameGetter={index => "row" + index} 
                            // @ts-ignore
                            rowAttributesGetter={index => {return { "data-testid": "EntityTableSimple_row" + index, "role": "EntityTableSimple_row"}} }>
                            {columns}
                        </Table>}
                    {this.props.renderFooter ? <div className="EntityTableSimple_footer">{this.props.renderFooter?.()}</div> : null}
                </div>)}
            </Measure>
        </div>;
    }

    renderNewest() {
        const columns = this.renderColumns();
        return (<>
            {this.props.mode === EntityTableMode.CARDS ? this.renderCards() : this.renderTable(columns)}
            <ModalExt className='EntityTableSimple_menuModal' closeIcon={false} open={this.props.contextMenuPosition} onClick={this.props.dispatchers.closeContextMenu} onClose={this.props.dispatchers.closeContextMenu}>
                {this.props.contextMenuPosition && <Menu vertical className="wh100">
                    {this.props.renderContextMenuItems!(this.props.mode === EntityTableMode.CARDS ? this.props.entities[this.props.contextMenuRowIndex] : this.getEntity(this.props.contextMenuRowIndex), this.props.contextMenuRowIndex)}
                </Menu>}
            </ModalExt>
        </>);
    }
}