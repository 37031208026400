import React, { RefObject } from "react";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { GanttDoublePage, GanttDoublePageRRC } from "pages/gantt/GanttDouble";
import { FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { Button, Dropdown, Icon, Input, Message, Popup, Segment } from "semantic-ui-react";
import { apolloClientHolder } from "@crispico/foundation-react/apolloClient";
import { COPY_GANTT_ASSIGNMENT_TO_DB, COPY_GANTT_ASSIGNMENT_TO_DB_EM, COPY_INPUT_DATA_FROM_DB, CREATE_TASKS, GET_GANTT_ASSIGNMENT_ALGORITHMS, RECALCULATE_GANTT_ASSIGNMENT, RUN_GANTT_ASSIGNMENT_ALGORITHM, SAVE_FLIGHT_APU_OFF_SHOULD_PROCESS } from "./queries";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import _ from "lodash";
import { CopyInputDataFromDbConfigInput } from "apollo-gen/globalTypes";
import { GanttAssignmentCopyInputDataButtonRRC, GanttAssignmentEntityRequirement } from "./ganttButtons/GanttAssignmentCopyInputData";
import { GanttAssignmentDeleteDataButtonRRC } from "./ganttButtons/GanttAssignmentDeleteData";
import { ganttAssignmentEntityDescriptor } from "AppEntityDescriptors";
import { GanttAssignmentEntities, ResourcesData } from "./GanttAssignmentEntityDescriptor";

const GANTT_ASSIGNMENT_ALGORITHM_SELECTED = "ganttAssignmentAlgorithmSelected";

class GanttAssignmentPageState extends State {
    copyFlightsModal = false;
    deleteDataFor = "";
    warningFor = "";
    initialValues = {};
    currentlyUsedResources = 0;
    algorithms: { [key: string]: { entityRequirements: GanttAssignmentEntityRequirement[] } } = {};
    selectedAlgorithm = "";
}

class GanttAssignmentPageReducers<S extends GanttAssignmentPageState = GanttAssignmentPageState> extends Reducers<S> { }

type Props = RRCProps<GanttAssignmentPageState, GanttAssignmentPageReducers> & { entity: any, entities: GanttAssignmentEntities, resourcesData: ResourcesData, saveEntity?: (entity: any) => void };

export class GanttAssignmentPage extends React.Component<Props> {

    protected ganttDoublePageRef = React.createRef<GanttDoublePage>();
    protected copyFlightsFromDbFilter?: Filter = undefined;
    protected tobBarRef: RefObject<HTMLDivElement> = React.createRef();

    constructor(props: Props) {
        super(props);
        this.updateInputOutputCsv = this.updateInputOutputCsv.bind(this);
        this.copyInputDataFromDb = this.copyInputDataFromDb.bind(this);
    }

    componentDidMount() {
        this.getGanttAssignmentAlgorithms();
        if (this.props.s.selectedAlgorithm === "") {
            const selectedAlgorithm = localStorage.getItem(GANTT_ASSIGNMENT_ALGORITHM_SELECTED);
            if (selectedAlgorithm) {
                this.props.r.setInReduxState({ selectedAlgorithm });
            }
        }
        this.onChangeEntities();
        this.onChangeResourcesData();
    }

    componentDidUpdate(prevProps: Props) {
        if (!prevProps || !_.isEqual(prevProps.entities, this.props.entities)) {
            this.onChangeEntities();
        }
        if (!prevProps || !_.isEqual(prevProps.resourcesData, this.props.resourcesData)) {
            this.onChangeResourcesData();
        }
    }

    private onChangeEntities() {
        this.ganttDoublePageRef.current?.props.r.setInReduxState({ entities: this.props.entities });
    }

    private onChangeResourcesData() {
        this.props.r.setInReduxState({ currentlyUsedResources: this.props.resourcesData.currentlyUsed });
        this.ganttDoublePageRef.current?.props.r.setInReduxState({ hideResources: this.props.resourcesData.hide });
    }

    protected async runGanttAssignmentAlgorithm() {
        if (!this.props.entity || !this.props.saveEntity) {
            return;
        }
        if (!Object.keys(this.props.s.algorithms).includes(this.props.s.selectedAlgorithm)) {
            Utils.showGlobalAlert({ title: _msg("GanttAssignment.noAlgorithmSelected.title"), message: _msg("GanttAssignment.noAlgorithmSelected.message"), severity: Severity.WARNING });
            return;
        }
        const entity = (await apolloClientHolder.apolloClient.query({ query: RUN_GANTT_ASSIGNMENT_ALGORITHM, variables: { algorithm: this.props.s.selectedAlgorithm, assignmentId: this.props.entity.id } })).data["ganttAssignmentService_runGanttAssignmentAlgorithm"];
        this.props.saveEntity(entity);
    }

    protected async getGanttAssignmentAlgorithms() {
        const algorithms: { [key: string]: { entityRequirements: GanttAssignmentEntityRequirement[] } } = 
            (await apolloClientHolder.apolloClient.query({ query: GET_GANTT_ASSIGNMENT_ALGORITHMS })).data["ganttAssignmentService_ganttAssignmentAlgorithms"];
        
        Object.values(algorithms).forEach(algorithm => algorithm.entityRequirements
            .forEach(entityRequirement => entityRequirement.defaultAdditionalFilter = Filter.enableAllFilters(entityRequirement.defaultAdditionalFilter)));

        this.props.r.setInReduxState({ algorithms });
    }

    protected async createTasks() {
        if (!this.props.entity || !this.props.saveEntity) {
            return;
        }
        if (!this.ganttDoublePageRef.current?.props.s.entities["Flight"]) {
            Utils.showGlobalAlert({ title: _msg("GanttAssignment.emptyInput.title"), message: _msg("GanttAssignment.emptyInput.message"), severity: Severity.WARNING });
            return;
        }
        const entity = (await apolloClientHolder.apolloClient.query({ query: CREATE_TASKS, variables: { algorithm: this.props.s.selectedAlgorithm, assignmentId: this.props.entity.id } })).data["ganttAssignmentService_createTasks"];
        this.props.saveEntity(entity);
    }

    protected async recalculateGanttAssignment() {
        const id = (await apolloClientHolder.apolloClient.mutate({ mutation: RECALCULATE_GANTT_ASSIGNMENT, variables: { algorithm: this.props.s.selectedAlgorithm, assignmentId: this.props.entity.id, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone } })).data["ganttAssignmentService_recalculateGanttAssignment"];
        window.open("#" + ganttAssignmentEntityDescriptor.getEntityEditorUrl(id) + "/gantt");
    }

    async copyGanttAssignmentToDb() {
        if (!this.props.entity) {
            return;
        }
        await apolloClientHolder.apolloClient.mutate({ mutation: COPY_GANTT_ASSIGNMENT_TO_DB, variables: { assignmentId: this.props.entity?.id } });
    }

    async copyGanttAssignmentToDbEm() {
        if (!this.props.entity) {
            return;
        }
        await apolloClientHolder.apolloClient.mutate({ mutation: COPY_GANTT_ASSIGNMENT_TO_DB_EM, variables: { assignmentId: this.props.entity?.id } });
    }

    async copyShouldProcess() {
        if (!this.props.entity) {
            return;
        }
        await apolloClientHolder.apolloClient.mutate({ mutation: SAVE_FLIGHT_APU_OFF_SHOULD_PROCESS, variables: { assignmentId: this.props.entity?.id } });
    }

    async copyInputDataFromDb(config: CopyInputDataFromDbConfigInput[]) {
        if (!this.props.entity || !this.props.saveEntity) {
            return;
        }
        const entity = (await apolloClientHolder.apolloClient.query({ query: COPY_INPUT_DATA_FROM_DB, variables: { assignmentId: this.props.entity.id, algorithm: this.props.s.selectedAlgorithm, config } })).data["ganttAssignmentService_copyInputDataFromDb"];
        this.props.saveEntity(entity);
    }

    protected updateInputOutputCsv(input: string, output: string) {
        if (!this.props.entity || !this.props.saveEntity) {
            return;
        }
        this.props.saveEntity({ ...this.props.entity, inputEntitiesCsv: input, outputEntitiesCsv: output });
    }

    protected changeInputCurrentlyUsedResources(currentlyUsedResources: number) {
        const resourcesData = this.props.resourcesData;
        if (resourcesData.available === undefined || resourcesData.needed === undefined) {
            return;
        }
        if (currentlyUsedResources < 0) {
            currentlyUsedResources = 0;
        } else if (currentlyUsedResources > resourcesData.available && currentlyUsedResources > resourcesData.needed) {
            currentlyUsedResources = resourcesData.needed > resourcesData.available ? resourcesData.needed : resourcesData.available;
        }

        this.updateCurrentlyUsedEquipmentResources(currentlyUsedResources);
    }

    protected updateCurrentlyUsedEquipmentResources(currentlyUsedResources: number) {
        if (!this.ganttDoublePageRef.current?.props.s.entities["EquipmentResource"]) {
            return;
        }

        const entities = _.cloneDeep(this.ganttDoublePageRef.current?.props.s.entities);
        let hideResources: { [key: string]: number[] } = {};
        Object.keys(entities["EquipmentResource"]).forEach((id: string, index: number) => {
            if (!(index < currentlyUsedResources)) {
                if (!hideResources["EquipmentResource"]) {
                    hideResources["EquipmentResource"] = [];
                }
                hideResources["EquipmentResource"].push(Number(id));
            }
        });

        this.props.r.setInReduxState({ currentlyUsedResources });
        this.ganttDoublePageRef.current?.props.r.setInReduxState({ entities, hideResources });
    }

    protected renderTabBar() {
        const dummyFd = new FieldDescriptor();
        dummyFd.name = "filter";
        dummyFd.entityDescriptor = entityDescriptors["Flight"]
        dummyFd.showPreviewButton = false;
        return <Segment className="buttonBar EntityEditorFormSimple_bar less-padding less-margin-top-bottom">
            <div>{_msg("GanttAssignmentEntityEditor.assignmentAlgorithm")}:</div>
            <Dropdown className="small-margin-left" selection placeholder="..." value={this.props.s.selectedAlgorithm} options={Object.keys(this.props.s.algorithms).map(a => { return { text: _.startCase(_.camelCase(a)), value: a } })} onChange={(e, data) => {
                this.props.r.setInReduxState({ selectedAlgorithm: data.value as string });
                localStorage.setItem(GANTT_ASSIGNMENT_ALGORITHM_SELECTED, data.value as string);
            }} />
            <GanttAssignmentDeleteDataButtonRRC id="GanttAssignmentDeleteData" entity={this.props.entity} updateInputOutput={this.updateInputOutputCsv} />
            <GanttAssignmentCopyInputDataButtonRRC id="GanttAssignmentCopyInputData" entity={this.props.entity} entityRequirements={this.props.s.algorithms[this.props.s.selectedAlgorithm]?.entityRequirements} algorithmName={this.props.s.selectedAlgorithm} copyInputDataFromDb={this.copyInputDataFromDb} />
            {this.props.s.selectedAlgorithm == "DynamicProgramming" ? <Button primary icon="copy" content={_msg("GanttAssignment.createTasks")} onClick={() => this.createTasks()} /> : null}
            {this.props.s.selectedAlgorithm ? <Button positive content={_msg("GanttAssignment.runAlgorithm")} onClick={() => this.runGanttAssignmentAlgorithm()} /> : null}
            {this.props.s.selectedAlgorithm == "DynamicProgramming" ? <Button positive content={_msg("GanttAssignment.recalculate")} onClick={() => this.recalculateGanttAssignment()} /> : null}
            <Dropdown trigger={<>{_msg("GanttAssingment.copyGanttAssignmentToDb")}</>} icon='dropdown' floating button>
                <Dropdown.Menu>
                    <Dropdown.Item text={"Service"} onClick={() => this.copyGanttAssignmentToDb()} />
                    <Dropdown.Item text={"EntityManager"} onClick={() => this.copyGanttAssignmentToDbEm()} />
                    {this.props.s.selectedAlgorithm == "DynamicProgramming" ? <Dropdown.Item text={_msg("Flight.apuOffShouldProcess.label")} onClick={() => this.copyShouldProcess()} /> : null}
                </Dropdown.Menu>
            </Dropdown>
            <div style={{ flexGrow: 2 }}></div>
            {this.props.s.selectedAlgorithm == "DynamicProgramming" ? <div className="small-margin-right">
                <div>{_msg("GanttAssignmentEntityEditor.neededResources", this.props.resourcesData.needed !== undefined ? this.props.resourcesData.needed : "-")}</div>
                <div>{_msg("GanttAssignmentEntityEditor.availableResources")}: <Input value={this.props.s.currentlyUsedResources} onChange={data => this.changeInputCurrentlyUsedResources(Number(data.target.value))} ><input className='less-padding' style={{ width: 80, textAlign: "center" }} /></Input> <Button icon="plus" compact size="mini" onClick={() => this.changeInputCurrentlyUsedResources(this.props.s.currentlyUsedResources + 1)} />
                    <Button icon="minus" compact size="mini" onClick={() => this.changeInputCurrentlyUsedResources(this.props.s.currentlyUsedResources - 1)} />
                </div>
            </div> : null}
            <div className="flex-container-row flex-center" ref={this.tobBarRef} />
        </Segment>;
    }

    render() {
        return <>
            {this.renderTabBar()}
            <GanttDoublePageRRC id="GanttAssignmentPage_GanttDoublePage" ref={this.ganttDoublePageRef} flightsDate={this.props.entity?.flightsDate} portalContainerForTopBar={this.tobBarRef.current} />
        </>;
    }
}

export const GanttAssignmentPageRRC = ReduxReusableComponents.connectRRC(GanttAssignmentPageState, GanttAssignmentPageReducers, GanttAssignmentPage);
